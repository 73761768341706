@charset "UTF-8";
:root {
  --bs-blue: #1A16C0;
  --bs-indigo: #6366f1;
  --bs-purple: #1f1e46;
  --bs-pink: #ec4899;
  --bs-red: #F16063;
  --bs-orange: #F57648;
  --bs-yellow: #ED9830;
  --bs-green: #4AAE8C;
  --bs-teal: #14b8a6;
  --bs-cyan: #68B8F2;
  --bs-white: #fff;
  --bs-gray: #718096;
  --bs-gray-dark: #2D3748;
  --bs-gray-100: #F7FAFC;
  --bs-gray-200: #EDF2F7;
  --bs-gray-300: #E2E8F0;
  --bs-gray-400: #CBD5E0;
  --bs-gray-500: #A0AEC0;
  --bs-gray-600: #718096;
  --bs-gray-700: #4A5568;
  --bs-gray-800: #2D3748;
  --bs-gray-900: #1A202C;
  --bs-primary: #1A16C0;
  --bs-secondary: #E4ECF7;
  --bs-success: #4AAE8C;
  --bs-info: #68B8F2;
  --bs-warning: #ED9830;
  --bs-danger: #F16063;
  --bs-light: #F7FAFC;
  --bs-dark: #27272E;
  --bs-primary-rgb: 26, 22, 192;
  --bs-secondary-rgb: 228, 236, 247;
  --bs-success-rgb: 74, 174, 140;
  --bs-info-rgb: 104, 184, 242;
  --bs-warning-rgb: 237, 152, 48;
  --bs-danger-rgb: 241, 96, 99;
  --bs-light-rgb: 247, 250, 252;
  --bs-dark-rgb: 39, 39, 46;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 26, 32, 44;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.8rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1A202C;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.6rem;
  }
}

h2, .h2 {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.4rem;
  }
}

h3, .h3 {
  font-size: 1.2rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.8rem;
}

h6, .h6 {
  font-size: 0.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1A16C0;
  text-decoration: underline;
}
a:hover {
  color: #15129a;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #ec4899;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #1A202C;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #4A5568;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #718096;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E2E8F0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #718096;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #1A202C;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #1A202C;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #1A202C;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #1A202C;
  vertical-align: top;
  border-color: #E2E8F0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d1d0f2;
  --bs-table-striped-bg: #c7c6e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcbbda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c0e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcbbda;
}

.table-secondary {
  --bs-table-bg: #fafbfd;
  --bs-table-striped-bg: #eeeef0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1e2e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e8ea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1e2e4;
}

.table-success {
  --bs-table-bg: #dbefe8;
  --bs-table-striped-bg: #d0e3dc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5d7d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbddd7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c5d7d1;
}

.table-info {
  --bs-table-bg: #e1f1fc;
  --bs-table-striped-bg: #d6e5ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbd9e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0dfe9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbd9e3;
}

.table-warning {
  --bs-table-bg: #fbead6;
  --bs-table-striped-bg: #eedecb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d3c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8d8c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2d3c1;
}

.table-danger {
  --bs-table-bg: #fcdfe0;
  --bs-table-striped-bg: #efd4d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c9ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9cecf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3c9ca;
}

.table-light {
  --bs-table-bg: #F7FAFC;
  --bs-table-striped-bg: #ebeeef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dee1e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e7e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dee1e3;
}

.table-dark {
  --bs-table-bg: #27272E;
  --bs-table-striped-bg: #323238;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #3d3d43;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #37373e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #3d3d43;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4A5568;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CBD5E0;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1A202C;
  background-color: #fff;
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #718096;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #EDF2F7;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #1A202C;
  background-color: #EDF2F7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e1e6eb;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #1A202C;
  background-color: #EDF2F7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e1e6eb;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #1A202C;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #EDF2F7;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1A202C;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-check-input:checked {
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #1A16C0;
  border-color: #1A16C0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238d8be0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1A16C0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bab9ec;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E2E8F0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1A16C0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bab9ec;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E2E8F0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #A0AEC0;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #A0AEC0;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  text-align: center;
  white-space: nowrap;
  background-color: #EDF2F7;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4AAE8C;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  color: #000;
  background-color: rgba(74, 174, 140, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4AAE8C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234AAE8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4AAE8C;
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4AAE8C;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234AAE8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #4AAE8C;
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4AAE8C;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #4AAE8C;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4AAE8C;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #F16063;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  color: #000;
  background-color: rgba(241, 96, 99, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F16063;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F16063'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F16063' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F16063;
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F16063;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F16063'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F16063' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #F16063;
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F16063;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #F16063;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F16063;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #1A202C;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1613a3;
  border-color: #15129a;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #1613a3;
  border-color: #15129a;
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 201, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #15129a;
  border-color: #141190;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 201, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}

.btn-secondary {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-secondary:hover {
  color: #000;
  background-color: #e8eff8;
  border-color: #e7eef8;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #e8eff8;
  border-color: #e7eef8;
  box-shadow: 0 0 0 0.25rem rgba(194, 201, 210, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #e9f0f9;
  border-color: #e7eef8;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(194, 201, 210, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}

.btn-success {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-success:hover {
  color: #000;
  background-color: #65ba9d;
  border-color: #5cb698;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #65ba9d;
  border-color: #5cb698;
  box-shadow: 0 0 0 0.25rem rgba(63, 148, 119, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #6ebea3;
  border-color: #5cb698;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(63, 148, 119, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}

.btn-info {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-info:hover {
  color: #000;
  background-color: #7fc3f4;
  border-color: #77bff3;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #7fc3f4;
  border-color: #77bff3;
  box-shadow: 0 0 0 0.25rem rgba(88, 156, 206, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #86c6f5;
  border-color: #77bff3;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 156, 206, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}

.btn-warning {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-warning:hover {
  color: #000;
  background-color: #f0a74f;
  border-color: #efa245;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #f0a74f;
  border-color: #efa245;
  box-shadow: 0 0 0 0.25rem rgba(201, 129, 41, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f1ad59;
  border-color: #efa245;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(201, 129, 41, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}

.btn-danger {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-danger:hover {
  color: #000;
  background-color: #f3787a;
  border-color: #f27073;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #f3787a;
  border-color: #f27073;
  box-shadow: 0 0 0 0.25rem rgba(205, 82, 84, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #f48082;
  border-color: #f27073;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 82, 84, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}

.btn-light {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-light:hover {
  color: #000;
  background-color: #f8fbfc;
  border-color: #f8fbfc;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f8fbfc;
  border-color: #f8fbfc;
  box-shadow: 0 0 0 0.25rem rgba(210, 213, 214, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fbfd;
  border-color: #f8fbfc;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 213, 214, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}

.btn-dark {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-dark:hover {
  color: #fff;
  background-color: #212127;
  border-color: #1f1f25;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #212127;
  border-color: #1f1f25;
  box-shadow: 0 0 0 0.25rem rgba(71, 71, 77, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1f1f25;
  border-color: #1d1d23;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(71, 71, 77, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}

.btn-outline-primary {
  color: #1A16C0;
  border-color: #1A16C0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #1A16C0;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 236, 247, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 236, 247, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #E4ECF7;
  background-color: transparent;
}

.btn-outline-success {
  color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #4AAE8C;
  background-color: transparent;
}

.btn-outline-info {
  color: #68B8F2;
  border-color: #68B8F2;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(104, 184, 242, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(104, 184, 242, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #68B8F2;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ED9830;
  border-color: #ED9830;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 152, 48, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 152, 48, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ED9830;
  background-color: transparent;
}

.btn-outline-danger {
  color: #F16063;
  border-color: #F16063;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #F16063;
  background-color: transparent;
}

.btn-outline-light {
  color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 250, 252, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 250, 252, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F7FAFC;
  background-color: transparent;
}

.btn-outline-dark {
  color: #27272E;
  border-color: #27272E;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 39, 46, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 39, 46, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #27272E;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1A16C0;
  text-decoration: underline;
}
.btn-link:hover {
  color: #15129a;
}
.btn-link:disabled, .btn-link.disabled {
  color: #718096;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.8rem;
  color: #1A202C;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1A202C;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #171d28;
  background-color: #EDF2F7;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1A16C0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #A0AEC0;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  color: #718096;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #1A202C;
}

.dropdown-menu-dark {
  color: #E2E8F0;
  background-color: #2D3748;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #E2E8F0;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1A16C0;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #A0AEC0;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #E2E8F0;
}
.dropdown-menu-dark .dropdown-header {
  color: #A0AEC0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #1A16C0;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #15129a;
}
.nav-link.disabled {
  color: #718096;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E2E8F0;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #EDF2F7 #EDF2F7 #E2E8F0;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #718096;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4A5568;
  background-color: #fff;
  border-color: #E2E8F0 #E2E8F0 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1A16C0;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  margin-right: 1rem;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.8rem;
  color: #1A202C;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #1714ad;
  background-color: #e8e8f9;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231714ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231A202C'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #718096;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #718096;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1A16C0;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #E2E8F0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #15129a;
  background-color: #EDF2F7;
  border-color: #E2E8F0;
}
.page-link:focus {
  z-index: 3;
  color: #15129a;
  background-color: #EDF2F7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.page-item.disabled .page-link {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
  border-color: #E2E8F0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #100d73;
  background-color: #d1d0f2;
  border-color: #bab9ec;
}
.alert-primary .alert-link {
  color: #0d0a5c;
}

.alert-secondary {
  color: #5b5e63;
  background-color: #fafbfd;
  border-color: #f7f9fd;
}
.alert-secondary .alert-link {
  color: #494b4f;
}

.alert-success {
  color: #2c6854;
  background-color: #dbefe8;
  border-color: #c9e7dd;
}
.alert-success .alert-link {
  color: #235343;
}

.alert-info {
  color: #3e6e91;
  background-color: #e1f1fc;
  border-color: #d2eafb;
}
.alert-info .alert-link {
  color: #325874;
}

.alert-warning {
  color: #8e5b1d;
  background-color: #fbead6;
  border-color: #fae0c1;
}
.alert-warning .alert-link {
  color: #724917;
}

.alert-danger {
  color: #913a3b;
  background-color: #fcdfe0;
  border-color: #fbcfd0;
}
.alert-danger .alert-link {
  color: #742e2f;
}

.alert-light {
  color: #636465;
  background-color: #fdfefe;
  border-color: #fdfefe;
}
.alert-light .alert-link {
  color: #4f5051;
}

.alert-dark {
  color: #17171c;
  background-color: #d4d4d5;
  border-color: #bebec0;
}
.alert-dark .alert-link {
  color: #121216;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.6rem;
  background-color: #EDF2F7;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1A16C0;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #4A5568;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #4A5568;
  text-decoration: none;
  background-color: #F7FAFC;
}
.list-group-item-action:active {
  color: #1A202C;
  background-color: #EDF2F7;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #1A202C;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #100d73;
  background-color: #d1d0f2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #100d73;
  background-color: #bcbbda;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #100d73;
  border-color: #100d73;
}

.list-group-item-secondary {
  color: #5b5e63;
  background-color: #fafbfd;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5b5e63;
  background-color: #e1e2e4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5b5e63;
  border-color: #5b5e63;
}

.list-group-item-success {
  color: #2c6854;
  background-color: #dbefe8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2c6854;
  background-color: #c5d7d1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2c6854;
  border-color: #2c6854;
}

.list-group-item-info {
  color: #3e6e91;
  background-color: #e1f1fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #3e6e91;
  background-color: #cbd9e3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #3e6e91;
  border-color: #3e6e91;
}

.list-group-item-warning {
  color: #8e5b1d;
  background-color: #fbead6;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #8e5b1d;
  background-color: #e2d3c1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #8e5b1d;
  border-color: #8e5b1d;
}

.list-group-item-danger {
  color: #913a3b;
  background-color: #fcdfe0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #913a3b;
  background-color: #e3c9ca;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #913a3b;
  border-color: #913a3b;
}

.list-group-item-light {
  color: #636465;
  background-color: #fdfefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636465;
  background-color: #e4e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636465;
  border-color: #636465;
}

.list-group-item-dark {
  color: #17171c;
  background-color: #d4d4d5;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #17171c;
  background-color: #bfbfc0;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #17171c;
  border-color: #17171c;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #718096;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E2E8F0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E2E8F0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #1A202C;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #1A16C0;
}
.link-primary:hover, .link-primary:focus {
  color: #15129a;
}

.link-secondary {
  color: #E4ECF7;
}
.link-secondary:hover, .link-secondary:focus {
  color: #e9f0f9;
}

.link-success {
  color: #4AAE8C;
}
.link-success:hover, .link-success:focus {
  color: #6ebea3;
}

.link-info {
  color: #68B8F2;
}
.link-info:hover, .link-info:focus {
  color: #86c6f5;
}

.link-warning {
  color: #ED9830;
}
.link-warning:hover, .link-warning:focus {
  color: #f1ad59;
}

.link-danger {
  color: #F16063;
}
.link-danger:hover, .link-danger:focus {
  color: #f48082;
}

.link-light {
  color: #F7FAFC;
}
.link-light:hover, .link-light:focus {
  color: #f9fbfd;
}

.link-dark {
  color: #27272E;
}
.link-dark:hover, .link-dark:focus {
  color: #1f1f25;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #E2E8F0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #E2E8F0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #E2E8F0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #E2E8F0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #E2E8F0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1A16C0 !important;
}

.border-secondary {
  border-color: #E4ECF7 !important;
}

.border-success {
  border-color: #4AAE8C !important;
}

.border-info {
  border-color: #68B8F2 !important;
}

.border-warning {
  border-color: #ED9830 !important;
}

.border-danger {
  border-color: #F16063 !important;
}

.border-light {
  border-color: #F7FAFC !important;
}

.border-dark {
  border-color: #27272E !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.fs-2 {
  font-size: calc(1.265rem + 0.18vw) !important;
}

.fs-3 {
  font-size: 1.2rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 0.8rem !important;
}

.fs-6 {
  font-size: 0.8rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #4A5568 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.6rem !important;
  }
  .fs-2 {
    font-size: 1.4rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-blue: #1A16C0;
  --bs-indigo: #6366f1;
  --bs-purple: #1f1e46;
  --bs-pink: #ec4899;
  --bs-red: #F16063;
  --bs-orange: #F57648;
  --bs-yellow: #ED9830;
  --bs-green: #4AAE8C;
  --bs-teal: #14b8a6;
  --bs-cyan: #68B8F2;
  --bs-white: #fff;
  --bs-gray: #718096;
  --bs-gray-dark: #2D3748;
  --bs-primary: #1A16C0;
  --bs-shade-primary: #1714ad;
  --bs-tint-primary: #312dc6;
  --bs-secondary: #E4ECF7;
  --bs-shade-secondary: #cdd4de;
  --bs-tint-secondary: #e7eef8;
  --bs-success: #4AAE8C;
  --bs-shade-success: #439d7e;
  --bs-tint-success: #5cb698;
  --bs-info: #68B8F2;
  --bs-shade-info: #5ea6da;
  --bs-tint-info: #77bff3;
  --bs-warning: #ED9830;
  --bs-shade-warning: #d5892b;
  --bs-tint-warning: #efa245;
  --bs-danger: #F16063;
  --bs-shade-danger: #d95659;
  --bs-tint-danger: #f27073;
  --bs-light: #F7FAFC;
  --bs-shade-light: #dee1e3;
  --bs-tint-light: #f8fbfc;
  --bs-dark: #27272E;
  --bs-shade-dark: #232329;
  --bs-tint-dark: #3d3d43;
  --bs-primary-rgb: 26, 22, 192;
  --bs-secondary-rgb: 228, 236, 247;
  --bs-success-rgb: 74, 174, 140;
  --bs-info-rgb: 104, 184, 242;
  --bs-warning-rgb: 237, 152, 48;
  --bs-danger-rgb: 241, 96, 99;
  --bs-light-rgb: 247, 250, 252;
  --bs-dark-rgb: 39, 39, 46;
  --bs-surface-primary: #fff;
  --bs-surface-secondary: #F7FAFC;
  --bs-surface-tertiary: #FAFAFA;
  --bs-surface-light: #EDF2F7;
  --bs-surface-dark: #24242b;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-muted-rgb: 74, 85, 104;
  --bs-body-rgb: 26, 32, 44;
  --bs-heading-rgb: 26, 32, 44;
  --bs-card-rgb: 255, 255, 255;
  --bs-font-sans-serif: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-font-display: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.8rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1A202C;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #1A202C;
}

h1, .h1 {
  font-size: calc(1.285rem + 0.42vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.6rem;
  }
}

h2, .h2 {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.4rem;
  }
}

h3, .h3 {
  font-size: 1.2rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.8rem;
}

h6, .h6 {
  font-size: 0.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1A16C0;
  text-decoration: underline;
}
a:hover {
  color: #15129a;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #ec4899;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #1A202C;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #4A5568;
  text-align: left;
}

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

*,
*::before,
*::after {
  border: 0 solid #E2E8F0;
}

iframe {
  display: block;
  vertical-align: middle;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

img,
video {
  max-width: 100%;
  height: auto;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

a {
  outline: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E2E8F0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #718096;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.lead {
  font-size: 1rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #718096;
}
.blockquote-footer::before {
  content: "— ";
}

.surtitle {
  color: #4A5568;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.text-highlight-primary {
  background-image: linear-gradient(transparent 66%, rgba(26, 22, 192, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-secondary {
  background-image: linear-gradient(transparent 66%, rgba(228, 236, 247, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-success {
  background-image: linear-gradient(transparent 66%, rgba(74, 174, 140, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-info {
  background-image: linear-gradient(transparent 66%, rgba(104, 184, 242, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(transparent 66%, rgba(237, 152, 48, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-danger {
  background-image: linear-gradient(transparent 66%, rgba(241, 96, 99, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-light {
  background-image: linear-gradient(transparent 66%, rgba(247, 250, 252, 0.15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-dark {
  background-image: linear-gradient(transparent 66%, rgba(39, 39, 46, 0.15) 34%);
  background-repeat: repeat-x;
}

.dropcaps > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.dropcaps > p:first-child:first-letter {
  font-size: 3.5em;
  font-weight: 700;
  float: left;
  line-height: 1;
  margin-top: 0.05em;
  margin-right: 0.15em;
}

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #1A202C;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: #1A202C;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4A5568;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CBD5E0;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #1A202C;
  background-color: #fff;
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #718096;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #EDF2F7;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #1A202C;
  background-color: #EDF2F7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e1e6eb;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #1A202C;
  background-color: #EDF2F7;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e1e6eb;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #1A202C;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #718096;
  background-color: #EDF2F7;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #1A202C;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-check-input:checked {
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #1A16C0;
  border-color: #1A16C0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238d8be0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1A16C0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bab9ec;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E2E8F0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1A16C0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bab9ec;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E2E8F0;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #A0AEC0;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #A0AEC0;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  text-align: center;
  white-space: nowrap;
  background-color: #EDF2F7;
  border: 1px solid #CBD5E0;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #4AAE8C;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  color: #000;
  background-color: rgba(74, 174, 140, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4AAE8C;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234AAE8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #4AAE8C;
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #4AAE8C;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234AAE8C' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #4AAE8C;
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #4AAE8C;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #4AAE8C;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4AAE8C;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #F16063;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  color: #000;
  background-color: rgba(241, 96, 99, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F16063;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F16063'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F16063' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #F16063;
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #F16063;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F16063'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F16063' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #F16063;
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #F16063;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #F16063;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F16063;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

form {
  margin-block-end: 0;
}

.form-control:focus::placeholder {
  color: #CBD5E0;
}

.form-control-muted {
  background-color: #f2f6f9;
  border-color: #f2f6f9;
}
.form-control-muted:focus {
  background-color: #f4f7fa;
}

.form-control-alt {
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-control-alt:focus {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 0 0 2px #1A16C0;
}

.form-control-flush {
  padding: 0;
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}
.form-control-flush:focus {
  background-color: transparent;
  box-shadow: none;
  border-width: 0;
}

.form-check-label {
  font-size: 0.7rem;
  font-weight: 500;
  color: #1A202C;
}

textarea[resize=none] {
  resize: none !important;
}

textarea[resize=both] {
  resize: both !important;
}

textarea[resize=vertical] {
  resize: vertical !important;
}

textarea[resize=horizontal] {
  resize: horizontal !important;
}

.textarea-autosize {
  display: grid;
}
.textarea-autosize:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.textarea-autosize > textarea {
  resize: none;
  overflow: hidden;
}
.textarea-autosize > textarea, .textarea-autosize:after {
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;
  grid-area: 1/1/2/2;
}

.form-group-stacked .form-control,
.form-group-stacked .form-select {
  position: relative;
  box-sizing: border-box;
  height: auto;
}
.form-group-stacked .form-control:focus,
.form-group-stacked .form-select:focus {
  z-index: 2;
}
.form-group-stacked > :first-child .form-control,
.form-group-stacked > :first-child .form-select {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-group-stacked > :last-child .form-control,
.form-group-stacked > :last-child .form-select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-group-stacked > .row {
  margin: 0;
}
.form-group-stacked > .row > :first-child,
.form-group-stacked > .row > :last-child {
  padding: 0;
}
.form-group-stacked > .row > :first-child .form-control,
.form-group-stacked > .row > :first-child .form-select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-group-stacked > .row > :last-child .form-control,
.form-group-stacked > .row > :last-child .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-dark .form-control {
  color: rgba(255, 255, 255, 0.9);
  background-color: #3d3d43;
  border: 1px solid #3d3d43;
}
.form-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.form-dark .form-control:focus {
  background-color: #323238;
  border: 1px solid #323238;
}
.form-dark .form-control:focus::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.form-dark .input-group .input-group-text {
  background-color: #3d3d43;
  border-color: #3d3d43;
  color: rgba(255, 255, 255, 0.9);
}
.form-dark .input-group:focus-within .input-group-text {
  background-color: #323238;
  border-color: #323238;
}

.form-stacked .form-control {
  position: relative;
  box-sizing: border-box;
}
.form-stacked .form-control:first-of-type {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
  margin-bottom: -1px;
  border-radius: 0;
}
.form-stacked .form-control:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-stacked .form-control:focus {
  z-index: 2;
}

.input-group {
  border-radius: 0.25rem;
}
.input-group .form-control {
  box-shadow: none;
}
.input-group:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.input-group:focus-within .input-group-text {
  border-color: #8d8be0;
}
.input-group:focus-within .form-control {
  box-shadow: none;
  border-color: #8d8be0;
}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-flush .form-control,
.input-group-flush .input-group-text {
  border: 0 !important;
}

.input-group-inline {
  position: relative;
  border-radius: 0.25rem;
}
.input-group-inline.input-group-sm {
  border-radius: 0.2rem;
}
.input-group-inline.input-group-lg {
  border-radius: 0.3rem;
}
.input-group-inline .input-group-text {
  background: #fff;
}
.input-group-inline > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0 !important;
  border-left: 0;
  padding-left: 0.25rem;
}
.input-group-inline > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: 0 !important;
  border-right: 0;
  padding-right: 0.25rem;
}

.form-switch {
  min-height: 1.5rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}

.form-switch > .form-check-label {
  margin-top: calc((1.5rem - 1.5 * 1em) / 2);
}

.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}

.form-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  display: none;
}

.form-item-check:hover + .form-item .form-item-click {
  border-color: #1A16C0;
}

.form-item-check:checked + .form-item .form-item-click {
  border-color: #1A16C0;
}

.form-item-check[disabled] + .form-item .form-item-click,
.form-item-check:disabled + .form-item .form-item-click {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-color {
  list-style: none;
  padding: 0;
}
.form-color:after, .form-color:before {
  content: "";
  display: table;
}
.form-color:after {
  clear: both;
}
.form-color label {
  width: 2.25rem;
  height: 2.25rem;
  float: left;
  padding: 0.375rem;
  margin-bottom: 0;
  margin-right: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  transform: scale(0.95);
}
@media (prefers-reduced-motion: reduce) {
  .form-color label {
    transition: none;
  }
}
.form-color label:hover {
  cursor: pointer;
  opacity: 1;
}
.form-color label i, .form-color label svg {
  opacity: 0;
  color: rgba(255, 255, 255, 0.9);
}
.form-color input {
  left: -9999px;
  position: absolute;
}
.form-color input:checked + label {
  transform: scale(1.1);
  opacity: 1;
}
.form-color input:checked + label i, .form-color input:checked + label svg {
  opacity: 1;
}

.form-color-wide label {
  height: 1.5rem;
}

.form-color-xl label {
  width: 5rem;
  height: 5rem;
}
.form-color-xl.form-color-wide label {
  width: 5rem;
  height: 3.5rem;
}

.form-color-lg label {
  width: 3.25rem;
  height: 3.25rem;
}

.form-color-sm label {
  width: 1.25rem;
  height: 1.25rem;
}

.was-validated .input-group:valid .form-control,
.was-validated .input-group:valid .form-select,
.was-validated .input-group:valid .input-group-text, .input-group.is-valid .form-control,
.input-group.is-valid .form-select,
.input-group.is-valid .input-group-text {
  border-color: #4AAE8C;
}
.was-validated .input-group:valid:focus-within, .input-group.is-valid:focus-within {
  border-color: #4AAE8C;
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.25);
}

.was-validated .input-group:invalid .form-control,
.was-validated .input-group:invalid .form-select,
.was-validated .input-group:invalid .input-group-text, .input-group.is-invalid .form-control,
.input-group.is-invalid .form-select,
.input-group.is-invalid .input-group-text {
  border-color: #F16063;
}
.was-validated .input-group:invalid:focus-within, .input-group.is-invalid:focus-within {
  border-color: #F16063;
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.25);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.8rem;
  color: #1A202C;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #1714ad;
  background-color: #e8e8f9;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231714ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231A202C'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #8d8be0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.accordion-card .card-header {
  position: relative;
  cursor: pointer;
}
.accordion-card .card-header:after {
  content: "+";
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--bs-font-sans-serif);
  font-weight: 700;
}
.accordion-card .card-header[aria-expanded=false]:after {
  content: "+";
}
.accordion-card .card-header[aria-expanded=true]:after {
  content: "-";
}
.accordion-card .card-header[aria-expanded=true] .heading {
  color: theme-color("primary");
}

.accordion-spaced > .card {
  border-radius: 0.25rem !important;
}
.accordion-spaced > .card:not(:last-of-type) {
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #100d73;
  background-color: #d1d0f2;
  border-color: #bab9ec;
}
.alert-primary .alert-link {
  color: #0d0a5c;
}

.alert-secondary {
  color: #5b5e63;
  background-color: #fafbfd;
  border-color: #f7f9fd;
}
.alert-secondary .alert-link {
  color: #494b4f;
}

.alert-success {
  color: #2c6854;
  background-color: #dbefe8;
  border-color: #c9e7dd;
}
.alert-success .alert-link {
  color: #235343;
}

.alert-info {
  color: #3e6e91;
  background-color: #e1f1fc;
  border-color: #d2eafb;
}
.alert-info .alert-link {
  color: #325874;
}

.alert-warning {
  color: #8e5b1d;
  background-color: #fbead6;
  border-color: #fae0c1;
}
.alert-warning .alert-link {
  color: #724917;
}

.alert-danger {
  color: #913a3b;
  background-color: #fcdfe0;
  border-color: #fbcfd0;
}
.alert-danger .alert-link {
  color: #742e2f;
}

.alert-light {
  color: #636465;
  background-color: #fdfefe;
  border-color: #fdfefe;
}
.alert-light .alert-link {
  color: #4f5051;
}

.alert-dark {
  color: #17171c;
  background-color: #d4d4d5;
  border-color: #bebec0;
}
.alert-dark .alert-link {
  color: #121216;
}

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 700;
  height: 2.875rem;
  width: 2.875rem;
  border-radius: 0.25rem;
}
.avatar img {
  width: 100%;
  border-radius: inherit;
}
.avatar + .avatar {
  margin-left: 0.25rem;
}
.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-2xl {
  width: 7.5rem;
  height: 7.5rem;
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .avatar-2xl {
    font-size: 2.5rem;
  }
}

.avatar-xl {
  width: 6rem;
  height: 6rem;
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .avatar-xl {
    font-size: 1.375rem;
  }
}

.avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.25rem;
}

.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.675rem;
  border-radius: 0.2rem;
}

.avatar-group {
  display: inline-block;
  line-height: 1;
}
.avatar-group .avatar {
  z-index: 1;
  transition: margin 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .avatar-group .avatar {
    transition: none;
  }
}
.avatar-group .avatar:hover {
  z-index: 2;
}
.avatar-group .avatar + .avatar {
  margin-left: -1.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.avatar-group .avatar-sm + .avatar-sm,
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -1rem;
}
.avatar-group:hover .avatar {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.avatar-group:hover .avatar-sm {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.avatar-ungroup-hover:hover .avatar:not(:first-child) {
  margin-left: 0;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge {
  line-height: 1rem;
}

.badge-xs {
  padding: 0.1rem 0.5rem;
  font-size: 60%;
}

.badge-md {
  padding: 0.4rem 1rem;
}

.badge-lg {
  padding: 0.6rem 1rem;
  font-size: 1em;
}

.badge-count {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 600;
}
.badge-count.badge-xs {
  width: 1rem;
  height: 1rem;
  font-size: 0.5rem;
}
.badge-count.badge-md {
  width: 1.5rem;
  height: 1.5rem;
}
.badge-count.badge-lg {
  width: 2rem;
  height: 2rem;
}
.badge-count svg {
  margin: 0;
}

.badge-dot {
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  font-weight: 400;
  color: #1A202C;
}
.badge-dot i {
  display: inline-block;
  vertical-align: middle;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.badge-dot.badge-md i {
  width: 0.5rem;
  height: 0.5rem;
}
.badge-dot.badge-lg i {
  width: 0.625rem;
  height: 0.625rem;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  font-size: 0.7rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.375rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.375rem;
  color: #CBD5E0;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #1A202C;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #1A202C;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #1A202C;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1613a3;
  border-color: #15129a;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #1613a3;
  border-color: #15129a;
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 201, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #15129a;
  border-color: #141190;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 57, 201, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}

.btn-secondary {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-secondary:hover {
  color: #000;
  background-color: #e8eff8;
  border-color: #e7eef8;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #e8eff8;
  border-color: #e7eef8;
  box-shadow: 0 0 0 0.25rem rgba(194, 201, 210, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #e9f0f9;
  border-color: #e7eef8;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(194, 201, 210, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}

.btn-success {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-success:hover {
  color: #000;
  background-color: #65ba9d;
  border-color: #5cb698;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #65ba9d;
  border-color: #5cb698;
  box-shadow: 0 0 0 0.25rem rgba(63, 148, 119, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #6ebea3;
  border-color: #5cb698;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(63, 148, 119, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}

.btn-info {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-info:hover {
  color: #000;
  background-color: #7fc3f4;
  border-color: #77bff3;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #7fc3f4;
  border-color: #77bff3;
  box-shadow: 0 0 0 0.25rem rgba(88, 156, 206, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #86c6f5;
  border-color: #77bff3;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 156, 206, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}

.btn-warning {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-warning:hover {
  color: #000;
  background-color: #f0a74f;
  border-color: #efa245;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #f0a74f;
  border-color: #efa245;
  box-shadow: 0 0 0 0.25rem rgba(201, 129, 41, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f1ad59;
  border-color: #efa245;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(201, 129, 41, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}

.btn-danger {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-danger:hover {
  color: #000;
  background-color: #f3787a;
  border-color: #f27073;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #f3787a;
  border-color: #f27073;
  box-shadow: 0 0 0 0.25rem rgba(205, 82, 84, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #f48082;
  border-color: #f27073;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 82, 84, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}

.btn-light {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-light:hover {
  color: #000;
  background-color: #f8fbfc;
  border-color: #f8fbfc;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f8fbfc;
  border-color: #f8fbfc;
  box-shadow: 0 0 0 0.25rem rgba(210, 213, 214, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fbfd;
  border-color: #f8fbfc;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 213, 214, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}

.btn-dark {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-dark:hover {
  color: #fff;
  background-color: #212127;
  border-color: #1f1f25;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #212127;
  border-color: #1f1f25;
  box-shadow: 0 0 0 0.25rem rgba(71, 71, 77, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1f1f25;
  border-color: #1d1d23;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(71, 71, 77, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}

.btn-outline-primary {
  color: #1A16C0;
  border-color: #1A16C0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #1A16C0;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 236, 247, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #E4ECF7;
  border-color: #E4ECF7;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 236, 247, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #E4ECF7;
  background-color: transparent;
}

.btn-outline-success {
  color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #4AAE8C;
  border-color: #4AAE8C;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(74, 174, 140, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #4AAE8C;
  background-color: transparent;
}

.btn-outline-info {
  color: #68B8F2;
  border-color: #68B8F2;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(104, 184, 242, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #68B8F2;
  border-color: #68B8F2;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(104, 184, 242, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #68B8F2;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ED9830;
  border-color: #ED9830;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 152, 48, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ED9830;
  border-color: #ED9830;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 152, 48, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ED9830;
  background-color: transparent;
}

.btn-outline-danger {
  color: #F16063;
  border-color: #F16063;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #F16063;
  border-color: #F16063;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 96, 99, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #F16063;
  background-color: transparent;
}

.btn-outline-light {
  color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 250, 252, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #F7FAFC;
  border-color: #F7FAFC;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 250, 252, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #F7FAFC;
  background-color: transparent;
}

.btn-outline-dark {
  color: #27272E;
  border-color: #27272E;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 39, 46, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #27272E;
  border-color: #27272E;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 39, 46, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #27272E;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1A16C0;
  text-decoration: underline;
}
.btn-link:hover {
  color: #15129a;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #718096;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.2rem;
}

.btn-neutral {
  color: #1A202C;
  background-color: #fff;
  border-color: #E2E8F0;
}
.btn-neutral:hover {
  color: #1A202C;
  background-color: #E2E8F0;
  border-color: #E2E8F0;
}
.btn-check:focus + .btn-neutral, .btn-neutral:focus {
  color: #1A202C;
  background-color: #E2E8F0;
  border-color: #E2E8F0;
  box-shadow: 0 0 0 0.25rem rgba(196, 202, 211, 0.5);
}
.btn-check:checked + .btn-neutral, .btn-check:active + .btn-neutral, .btn-neutral:active, .btn-neutral.active, .show > .btn-neutral.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: #e5eaf2;
}
.btn-check:checked + .btn-neutral:focus, .btn-check:active + .btn-neutral:focus, .btn-neutral:active:focus, .btn-neutral.active:focus, .show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(196, 202, 211, 0.5);
}
.btn-neutral:disabled, .btn-neutral.disabled {
  color: #000;
  background-color: #fff;
  border-color: #E2E8F0;
}

.btn-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.875rem;
  height: 2.875rem;
  padding: 0;
}
.btn-square.btn-xs {
  width: 1.75rem;
  height: 1.75rem;
}
.btn-square.btn-sm, .btn-group-sm > .btn-square.btn {
  width: 2.25rem;
  height: 2.25rem;
}
.btn-square.btn-lg, .btn-group-lg > .btn-square.btn {
  width: 3.5rem;
  height: 3.5rem;
}
.btn-square.btn-xl {
  width: 6rem;
  height: 6rem;
}
.btn-square.btn-2xl {
  width: 7.5rem;
  height: 7.5rem;
}

.btn-app {
  background: #000;
  color: #fff;
  position: relative;
  padding-left: 4.5rem;
  padding-right: 1.5rem;
  text-align: left;
}
.btn-app i, .btn-app svg {
  width: 1em;
  font-size: 2.5rem;
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.btn-app .btn-text {
  display: block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-bottom: 0.125rem;
}
.btn-app .btn-brand {
  display: block;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
}
.btn-app:hover {
  background-color: #1814b3;
  color: #fff;
}

.btn-animated {
  position: relative;
  overflow: hidden;
}
.btn-animated .btn-inner-visible {
  position: relative;
}
.btn-animated .btn-inner-hidden {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.btn-animated-x .btn-inner-visible {
  right: 0;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-visible {
    transition: none;
  }
}
.btn-animated-x .btn-inner-hidden {
  right: -100%;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-hidden {
    transition: none;
  }
}
.btn-animated-x:hover .btn-inner-hidden {
  right: 0;
}
.btn-animated-x:hover .btn-inner-visible {
  right: 150%;
}

.btn-animated-y .btn-inner-visible {
  top: 0;
  transition: top 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-visible {
    transition: none;
  }
}
.btn-animated-y .btn-inner-hidden {
  left: 0;
  top: -100%;
  transition: top 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-hidden {
    transition: none;
  }
}
.btn-animated-y:hover .btn-inner-hidden {
  top: 50%;
}
.btn-animated-y:hover .btn-inner-visible {
  top: 100px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.8rem;
  color: #1A202C;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1A202C;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #171d28;
  background-color: #EDF2F7;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1A16C0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #A0AEC0;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  color: #718096;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #1A202C;
}

.dropdown-menu-dark {
  color: #E2E8F0;
  background-color: #2D3748;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #E2E8F0;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1A16C0;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #A0AEC0;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #E2E8F0;
}
.dropdown-menu-dark .dropdown-header {
  color: #A0AEC0;
}

.dropdown,
.dropup,
.dropright,
.dropleft {
  display: inline-block;
}

.dropdown-heading {
  color: #1A202C;
  font-size: 0.8rem;
  font-weight: 500;
}

.dropdown-helper {
  color: #4A5568;
}

.dropdown-group {
  display: block;
}
.dropdown-group:hover .dropdown-heading {
  color: #1A16C0;
}

.dropdown-img-left {
  position: relative;
  display: flex;
  min-height: 200px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.625rem 1.75rem;
  background: no-repeat center center/cover;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.dropdown-img-left.dropdown-img-mask:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 22, 192, 0.7);
  border-radius: inherit;
}

.dropdown-secondary {
  background-color: #F7FAFC;
}

.dropdown-body-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.dropdown-body-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.dropdown-menu-fluid {
  width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
  left: var(--x-gutter-x, 1.5rem) !important;
}
.dropdown-menu-fluid[data-bs-popper] {
  left: var(--x-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
  .dropdown-menu-sm-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-sm-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-md-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-lg-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-xl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-xxl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
.dropdown-submenu .dropdown-menu {
  min-width: 16rem;
  margin-left: 1rem;
}
.dropdown-submenu .dropdown-menu:before {
  content: "";
  border-left: 1.5rem solid transparent;
  position: absolute;
  left: -1rem;
  top: 0;
  height: 100%;
}
.dropdown-body {
  padding: 2rem 2.5rem;
}

.delimiter {
  border-right: 1px solid #E2E8F0;
}

@media (min-width: 768px) {
  .dropdown-menu-sm {
    min-width: 10rem !important;
  }
  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    right: 2rem;
    left: auto;
  }
  .dropdown-menu-md {
    min-width: 25rem !important;
  }
  .dropdown-menu-lg {
    min-width: 30rem !important;
  }
  .dropdown-menu-xl {
    min-width: 40rem !important;
  }
  .dropdown-menu-2xl {
    min-width: 50rem !important;
  }
  .dropdown-menu-centered {
    left: 50% !important;
    transform: translateX(-50%) translateY(-3px) scale(0.96);
  }
}
.dropdown-animate > .dropdown-menu {
  opacity: 0;
  position: absolute;
  display: block;
  pointer-events: none;
}

.dropdown-animate:not([data-toggle=hover]) .dropdown-menu.show {
  pointer-events: auto;
  animation: show-dropdown 0.3s ease forwards;
}
.dropdown-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
  display: block;
  animation: hide-dropdown 0.3s ease backwards;
}

.dropdown-animate[data-toggle=hover] > .dropdown-menu {
  display: block;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dropdown-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
.dropdown-animate[data-toggle=hover]:hover > .dropdown-menu {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transform: translateX(0) translateY(-6px) scale(1);
}

@media (min-width: 576px) {
  .dropdown-sm-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }
  .dropdown-sm-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-sm-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    animation: hide-dropdown 0.3s ease backwards;
  }
  .dropdown-sm-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .dropdown-sm-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 576px) {
  .dropdown-sm-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 768px) {
  .dropdown-md-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }
  .dropdown-md-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-md-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    animation: hide-dropdown 0.3s ease backwards;
  }
  .dropdown-md-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .dropdown-md-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 768px) {
  .dropdown-md-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 992px) {
  .dropdown-lg-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }
  .dropdown-lg-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-lg-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    animation: hide-dropdown 0.3s ease backwards;
  }
  .dropdown-lg-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .dropdown-lg-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 992px) {
  .dropdown-lg-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 1200px) {
  .dropdown-xl-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }
  .dropdown-xl-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-xl-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    animation: hide-dropdown 0.3s ease backwards;
  }
  .dropdown-xl-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .dropdown-xl-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .dropdown-xl-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 1400px) {
  .dropdown-xxl-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }
  .dropdown-xxl-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-xxl-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    animation: hide-dropdown 0.3s ease backwards;
  }
  .dropdown-xxl-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .dropdown-xxl-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .dropdown-xxl-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
    transition: all 0.3s ease;
  }
  100% {
    transform: translateX(0) translateY(-3px) scale(1);
    opacity: 1;
  }
}
@keyframes hide-dropdown {
  from {
    opacity: 1;
    transform: translateX(0) translateY(-3px) scale(1);
    transition: all 0.3s ease;
  }
  to {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
  }
}
.dropdown-menu .dropdown-menu {
  left: 100%;
  margin: 0;
  right: auto;
  top: 0;
}

.dropdown-submenu {
  display: block;
  position: relative;
}
.dropdown-submenu > .dropdown-menu::after {
  border: 0 !important;
}
.dropdown-submenu > .dropdown-item::after,
.dropdown-submenu > .list-group-item::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropdown-submenu > .dropdown-item:empty::after,
.dropdown-submenu > .list-group-item:empty::after {
  margin-left: 0;
}
.dropdown-submenu > .dropdown-item:after,
.dropdown-submenu > .list-group-item:after {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-left: 0;
  font-size: 0.75rem;
  transform: translateY(-50%);
}
.dropdown-submenu[data-toggle=hover] > .dropdown-menu {
  left: 100%;
  transform: translateX(0) translateY(-3px) scale(0.97);
}
.dropdown-submenu[data-toggle=hover]:hover > .dropdown-menu {
  transform: translateX(0) translateY(-3px) scale(1);
}

@media (max-width: 767.98px) {
  .dropdown-submenu .dropdown-menu {
    padding: 0;
    box-shadow: none;
    top: 0;
  }
  .dropdown-submenu .dropdown-item {
    padding-left: 3rem;
  }
}
.frame-laptop {
  position: relative;
}
.frame-laptop .frame-inner {
  position: absolute;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 78%;
  margin: 2.5% 15% 0 15%;
}

.frame-iphone {
  position: relative;
  z-index: 100;
}
.frame-iphone .frame-inner {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 92%;
  height: 96%;
  margin: 4% 4% 0 4%;
  border-radius: 5%;
}

.frame-stack {
  position: relative;
}
.frame-stack .frame {
  position: absolute;
}

.frame-stack-iphone-iphone {
  padding-bottom: 130.250482%;
}
.frame-stack-iphone-iphone > .frame-iphone:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1;
}
.frame-stack-iphone-iphone > .frame-iphone:last-child {
  top: 0;
  right: 0;
  width: 72.8323699%;
}

.frame-stack-iphone-laptop,
.frame-stack-laptop-iphone {
  padding-bottom: 62.4260355%;
}

.frame-stack-iphone-laptop > .frame-laptop,
.frame-stack-laptop-iphone > .frame-laptop {
  width: 91.7159763%;
}

.frame-stack-iphone-laptop > .frame-iphone,
.frame-stack-laptop-iphone > .frame-iphone {
  width: 27.9585799%;
  z-index: 1;
}

.frame-stack-laptop-iphone > .frame-laptop {
  top: 0;
  left: 0;
}

.frame-stack-iphone-laptop > .frame-laptop {
  top: 0;
  right: 0;
}

.frame-stack-laptop-iphone > .frame-iphone {
  bottom: 0;
  right: 0;
}

.frame-stack-iphone-laptop > .frame-iphone {
  bottom: 0;
  left: 0;
}

.icon {
  font-size: 1.7142857143rem;
  line-height: 1;
}
.icon svg {
  width: 1em;
  height: 1em;
}

.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - 3rem - 1);
}

.icon-xl {
  font-size: 2.8571428571rem;
}

.icon-xl + .icon-text {
  width: calc(100% - 5rem - 1);
}

.icon-lg {
  font-size: 2.2857142857rem;
}

.icon-lg + .icon-text {
  width: calc(100% - 4rem - 1);
}

.icon-sm {
  font-size: 1.1428571429rem;
}

.icon-sm + .icon-text {
  width: calc(100% - 2rem - 1);
}

.icon-xs {
  font-size: 0.7142857143rem;
}

.icon-xs + .icon-text {
  width: calc(100% - 1.25rem - 1);
}

.icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  width: 3rem;
  height: 3rem;
}
.icon-shape.icon-xl {
  width: 5rem;
  height: 5rem;
}
.icon-shape.icon-lg {
  width: 4rem;
  height: 4rem;
}
.icon-shape.icon-sm {
  width: 2rem;
  height: 2rem;
}
.icon-shape.icon-xs {
  width: 1.25rem;
  height: 1.25rem;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #4A5568;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #4A5568;
  text-decoration: none;
  background-color: #F7FAFC;
}
.list-group-item-action:active {
  color: #1A202C;
  background-color: #EDF2F7;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #1A202C;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #100d73;
  background-color: #d1d0f2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #100d73;
  background-color: #bcbbda;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #100d73;
  border-color: #100d73;
}

.list-group-item-secondary {
  color: #5b5e63;
  background-color: #fafbfd;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5b5e63;
  background-color: #e1e2e4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5b5e63;
  border-color: #5b5e63;
}

.list-group-item-success {
  color: #2c6854;
  background-color: #dbefe8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #2c6854;
  background-color: #c5d7d1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #2c6854;
  border-color: #2c6854;
}

.list-group-item-info {
  color: #3e6e91;
  background-color: #e1f1fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #3e6e91;
  background-color: #cbd9e3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #3e6e91;
  border-color: #3e6e91;
}

.list-group-item-warning {
  color: #8e5b1d;
  background-color: #fbead6;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #8e5b1d;
  background-color: #e2d3c1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #8e5b1d;
  border-color: #8e5b1d;
}

.list-group-item-danger {
  color: #913a3b;
  background-color: #fcdfe0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #913a3b;
  background-color: #e3c9ca;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #913a3b;
  border-color: #913a3b;
}

.list-group-item-light {
  color: #636465;
  background-color: #fdfefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636465;
  background-color: #e4e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636465;
  border-color: #636465;
}

.list-group-item-dark {
  color: #17171c;
  background-color: #d4d4d5;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #17171c;
  background-color: #bfbfc0;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #17171c;
  border-color: #17171c;
}

.list-group {
  list-style-type: none;
}

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-borderless > .list-group-item {
  border: 0;
}

.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
}

.list-group-item {
  font-size: 0.7rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}

.list-group-content {
  flex: 1;
  min-width: 0;
}
.list-group-content p {
  line-height: 1.5;
  margin: 0.2rem 0 0;
}

.list-group-heading {
  color: #2D3748;
}
.list-group-heading > small, .list-group-heading > .small {
  float: right;
  color: #A0AEC0;
  font-weight: 500;
}

.list-group-sm .list-group-item {
  padding: 0.675rem 1.25rem;
  font-size: 0.7rem;
}

.list-group-emphasized .list-group-item {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .list-group-emphasized .list-group-item {
    transition: none;
  }
}
.list-group-emphasized .list-group-item.active {
  z-index: 11;
  transform: scale(1.05);
  background-color: #1A16C0;
  border-radius: 0.25rem;
}
.list-group-emphasized .list-group-item.active .media a {
  color: theme-color("primary");
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E2E8F0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E2E8F0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.modal-content {
  border: 0;
  border-radius: 0.3rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}

.modal-open {
  max-height: 100vh;
}

.modal-dialog-aside {
  position: absolute;
  margin: 0;
}

.modal-primary .modal-title {
  color: #fff;
}
.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-primary .modal-content {
  background-color: #1A16C0;
  color: #fff;
}
.modal-primary .modal-content .heading {
  color: #fff;
}
.modal-primary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-secondary .modal-title {
  color: #000;
}
.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-secondary .modal-content {
  background-color: #E4ECF7;
  color: #000;
}
.modal-secondary .modal-content .heading {
  color: #000;
}
.modal-secondary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-success .modal-title {
  color: #000;
}
.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-success .modal-content {
  background-color: #4AAE8C;
  color: #000;
}
.modal-success .modal-content .heading {
  color: #000;
}
.modal-success .close > span:not(.sr-only) {
  color: #fff;
}

.modal-info .modal-title {
  color: #000;
}
.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-info .modal-content {
  background-color: #68B8F2;
  color: #000;
}
.modal-info .modal-content .heading {
  color: #000;
}
.modal-info .close > span:not(.sr-only) {
  color: #fff;
}

.modal-warning .modal-title {
  color: #000;
}
.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-warning .modal-content {
  background-color: #ED9830;
  color: #000;
}
.modal-warning .modal-content .heading {
  color: #000;
}
.modal-warning .close > span:not(.sr-only) {
  color: #fff;
}

.modal-danger .modal-title {
  color: #000;
}
.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-danger .modal-content {
  background-color: #F16063;
  color: #000;
}
.modal-danger .modal-content .heading {
  color: #000;
}
.modal-danger .close > span:not(.sr-only) {
  color: #fff;
}

.modal-light .modal-title {
  color: #000;
}
.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-light .modal-content {
  background-color: #F7FAFC;
  color: #000;
}
.modal-light .modal-content .heading {
  color: #000;
}
.modal-light .close > span:not(.sr-only) {
  color: #fff;
}

.modal-dark .modal-title {
  color: #fff;
}
.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-dark .modal-content {
  background-color: #27272E;
  color: #fff;
}
.modal-dark .modal-content .heading {
  color: #fff;
}
.modal-dark .close > span:not(.sr-only) {
  color: #fff;
}

.modal-vertical {
  height: calc(100% - 0.5rem);
  max-width: 350px;
  margin: 0.5rem 0;
}
.modal-vertical.modal-lg {
  max-width: 450px;
}
.modal-vertical .modal-content {
  height: inherit;
  border-width: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}
.modal-vertical .modal-body {
  height: inherit;
  overflow-y: auto;
}

.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%);
}
.modal.show .modal-dialog-vertical {
  transform: translateX(0);
}

.modal.fixed-right {
  padding-right: 0 !important;
}
.modal.fixed-right .modal-vertical {
  margin-left: auto;
}
.modal.fixed-right.fade .modal-vertical {
  transform: translateX(100%);
}
.modal.fixed-right.show .modal-vertical {
  transform: translateX(0);
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  margin-right: 1rem;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar {
  position: relative;
  z-index: 100;
}
.navbar [class^=container] {
  position: relative;
}

.navbar-nav .nav-link {
  font-size: 0.925rem;
  font-weight: 500;
  letter-spacing: 0;
  transition: all 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}

.navbar-text {
  font-size: 0.925rem;
}

.navbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-border.navbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.navbar-border.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.navbar-border.navbar-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}
.navbar-border.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.navbar-top {
  position: relative;
  display: flex;
  align-items: center;
}
.navbar-top .navbar-nav {
  flex-direction: row;
}
.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar-top .navbar-nav .nav-link {
  padding: 0.5rem 0.5rem;
}

.navbar-collapse:before {
  content: "";
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0.75rem -1rem;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-collapse:before {
    transition: none;
  }
}
.navbar-collapse.show:before, .navbar-collapse.collapsing:before {
  opacity: 1;
}

.navbar-light .navbar-collapse::before {
  border-top-color: rgba(39, 39, 46, 0.1);
}
.navbar-light .collapse .nav:before {
  border-color: rgba(39, 39, 46, 0.1);
}

.navbar-dark .navbar-collapse::before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.navbar-dark .collapse .nav:before {
  border-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-sm .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-md .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-lg .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-xl .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-xxl .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-xxl .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
}
.navbar-expand .navbar-brand img {
  height: 40px;
}
.navbar-expand .navbar-brand.dropdown-toggle:after {
  display: none;
}
.navbar-expand .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0;
}
.navbar-expand .navbar-collapse .collapse-header {
  display: none;
}
.navbar-expand .navbar-collapse:before {
  display: none;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

.navbar-search .input-group {
  border-radius: 0.3rem;
  border: 0 solid;
  transition: background-color 0.4s linear;
  transition-delay: 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-search .input-group {
    transition: none;
  }
}
.navbar-search .input-group .input-group-text {
  background-color: transparent;
  padding-left: 1rem;
  border: 0;
}
.navbar-search .form-control {
  width: 250px;
  background-color: transparent;
  border: 0;
  transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-search .form-control {
    transition: none;
  }
}
.navbar-search .focused .input-group .form-control {
  width: 380px;
}
.navbar-search .close {
  display: none;
}

.navbar-search-dark .input-group {
  background-color: #101012;
  border-color: #101012;
}
.navbar-search-dark .input-group-text {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-search-dark .form-control {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-search-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-search-dark .focused .input-group {
  background-color: #040405;
  border-color: #040405;
}

.navbar-search-light .input-group {
  background-color: #f2f2f2;
  border-color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .input-group-text {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .form-control {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-search-light .form-control::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .focused .input-group {
  background-color: #e6e6e6;
  border-color: rgba(0, 0, 0, 0.9);
}

.navbar-vertical .navbar-nav {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.navbar-vertical .navbar-nav .nav-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.navbar-vertical .navbar-nav .nav-link.active {
  position: relative;
}
.navbar-vertical .navbar-nav .nav-link i,
.navbar-vertical .navbar-nav .nav-link svg {
  min-width: 1.875rem;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: 0.5rem;
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-size: 0.875rem 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
    transition: none;
  }
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  color: theme-color("primary");
  transform: rotate(90deg);
}
.navbar-vertical .navbar-nav > .nav-item {
  margin-top: 2px;
}
.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: 0.7rem;
}
.navbar-vertical .navbar-nav .nav .nav-link {
  padding-top: 0.4285714286rem;
  padding-bottom: 0.4285714286rem;
  padding-left: 3.375rem;
  font-weight: 400;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.8125rem;
}
.navbar-vertical.navbar-light {
  background-color: #fff;
  border-color: #E2E8F0;
}
.navbar-vertical.navbar-light .navbar-nav .nav-link i, .navbar-vertical.navbar-light .navbar-nav .nav-link svg {
  color: #4A5568;
}
.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(0, 0, 0, 0.55)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link:hover {
  background-color: #F7FAFC;
}
.navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link.active {
  background-color: #F7FAFC;
}
.navbar-vertical.navbar-light .nav-link-text,
.navbar-vertical.navbar-light .navbar-heading {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-vertical.navbar-dark {
  background-color: #27272E;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .navbar-nav .nav-link i, .navbar-vertical.navbar-dark .navbar-nav .nav-link svg {
  color: #F7FAFC;
}
.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .nav-link-text,
.navbar-vertical.navbar-dark .navbar-heading {
  color: rgba(255, 255, 255, 0.55);
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm {
    transition: none;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-sm .nav-link-text,
  .navbar-vertical.navbar-expand-sm .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm .nav-link-text,
  .navbar-vertical.navbar-expand-sm .navbar-heading {
    transition: none;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-sm.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-sm.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-sm.show .nav-link-text,
  .navbar-vertical.navbar-expand-sm.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-sm.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-sm.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-sm.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand-sm > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-md .nav-link-text,
  .navbar-vertical.navbar-expand-md .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md .nav-link-text,
  .navbar-vertical.navbar-expand-md .navbar-heading {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-md.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-md.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-md.show .nav-link-text,
  .navbar-vertical.navbar-expand-md.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-md.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-md.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-md.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-md.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand-md > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg {
    transition: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-lg .nav-link-text,
  .navbar-vertical.navbar-expand-lg .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg .nav-link-text,
  .navbar-vertical.navbar-expand-lg .navbar-heading {
    transition: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-lg.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-lg.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-lg.show .nav-link-text,
  .navbar-vertical.navbar-expand-lg.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-lg.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-lg.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-lg.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand-lg > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xl .nav-link-text,
  .navbar-vertical.navbar-expand-xl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl .nav-link-text,
  .navbar-vertical.navbar-expand-xl .navbar-heading {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-xl.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-xl.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-xl.show .nav-link-text,
  .navbar-vertical.navbar-expand-xl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-xl.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xl.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-xl.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand-xl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xxl .nav-link-text,
  .navbar-vertical.navbar-expand-xxl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text,
  .navbar-vertical.navbar-expand-xxl .navbar-heading {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-xxl.show .nav-link-text,
  .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-xxl.show + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xxl.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-xxl.hide + .main-content {
    margin-left: 270px;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-vertical.navbar-expand-xxl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
.navbar-vertical.navbar-expand {
  display: block;
  width: 100%;
  max-width: 270px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand {
    transition: none;
  }
}
.navbar-vertical.navbar-expand + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand .nav-link-text,
.navbar-vertical.navbar-expand .navbar-heading {
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand .nav-link-text,
  .navbar-vertical.navbar-expand .navbar-heading {
    transition: none;
  }
}
.navbar-vertical.navbar-expand.show {
  max-width: 270px;
  border-radius: 0;
}
.navbar-vertical.navbar-expand.show.navbar-lg {
  max-width: 320px;
}
.navbar-vertical.navbar-expand.show.navbar-xl {
  max-width: 370px;
}
.navbar-vertical.navbar-expand.show .nav-link-text,
.navbar-vertical.navbar-expand.show .navbar-heading {
  opacity: 1;
  pointer-events: auto;
}
.navbar-vertical.navbar-expand.show + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand.hide {
  max-width: 270px;
}
.navbar-vertical.navbar-expand.hide .nav-link-text {
  opacity: 0;
  pointer-events: none;
}
.navbar-vertical.navbar-expand.hide + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand .navbar-brand {
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.navbar-vertical.navbar-expand > [class*=container] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.navbar-vertical.navbar-expand .navbar-collapse {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  opacity: 1;
}
.navbar-vertical.navbar-expand .navbar-nav {
  margin-left: 0;
  margin-right: 0;
  flex-direction: column;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-light .navbar-toggler {
  color: #718096;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler:focus {
  color: transparent;
  background-color: #F7FAFC;
}

.navbar-dark .navbar-toggler {
  color: #EDF2F7;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler:focus {
  color: transparent;
  background-color: #F7FAFC;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #1A16C0;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #15129a;
}
.nav-link.disabled {
  color: #718096;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E2E8F0;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #EDF2F7 #EDF2F7 #E2E8F0;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #718096;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4A5568;
  background-color: #fff;
  border-color: #E2E8F0 #E2E8F0 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1A16C0;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

@media (min-width: 992px) {
  .nav-link-badge {
    position: relative;
  }
  .nav-link-badge .badge {
    position: relative;
    top: -15px;
    left: -5px;
  }
  .nav-link-badge .nav-link-text {
    position: relative;
  }
  .nav-link-badge .nav-link-text:before {
    position: absolute;
    top: 0;
    left: -7px;
    display: block;
    width: 20px;
    height: 20px;
    margin: auto;
    content: "";
    transform: translate(-50%, -50%) rotate(100deg);
    border-left: 1px dashed #fff;
    border-radius: 10px;
  }
}
.nav.overflow-x {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav.overflow-x .nav-item {
  width: auto;
  max-width: 100%;
  flex: 0 0 auto;
}
.nav.overflow-x::-webkit-scrollbar {
  display: none;
}

.nav-header {
  border-bottom-width: 0;
}

.nav-application > .btn {
  width: 123px !important;
  height: 123px !important;
  float: left;
  background: transparent;
  color: #4A5568;
  margin: 0;
  margin-bottom: 24px;
}
.nav-application > .btn:nth-child(odd) {
  margin-right: 24px;
}
.nav-application > .btn.active {
  background-color: theme-color("primary");
  color: color-yiq(theme-color("primary"));
}
.nav-application > .btn:hover:not(.active) {
  color: theme-color("primary");
  transform: scale(1.05);
}

.nav-dots {
  top: 50%;
  right: 2rem;
  position: fixed;
  z-index: 200;
  transform: translateY(-50%);
}
.nav-dots .nav-link {
  display: block;
  margin: 5px;
  position: relative;
}
.nav-dots .nav-link:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 6px;
  width: 6px;
  border: 0;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-out;
}
.nav-dots .nav-link:hover:before, .nav-dots .nav-link.active:before {
  height: 12px;
  width: 12px;
}

.nav-menu .nav-link {
  padding: 0.35rem 0;
}
.nav-menu .nav-link:not(.active):hover {
  color: #2D3748;
}
.nav-menu .nav-link:not(.active).active {
  font-weight: bolder;
}

.nav-tabs .nav-item {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
}
.nav-tabs .nav-link {
  padding: 0.5rem 0;
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: #1A202C;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  color: #1A202C;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  color: #1A202C;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-header-tabs {
  margin-top: calc(-1rem - 1px);
  margin-bottom: calc(-1rem + 1px);
  margin-left: 0;
  margin-right: 0;
}
.card-header-tabs .nav-item {
  margin-right: 0.75rem;
}
.card-header-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: #1A16C0;
}

.card-comment-box {
  width: 100%;
}
.card-comment-box input,
.card-comment-box textarea {
  border: 0;
  box-shadow: none;
  background: transparent;
  padding: 0.3125rem 0;
  margin: 0;
  color: #2D3748;
  outline: none;
  resize: none;
}
.card-comment-box input:focus,
.card-comment-box textarea:focus {
  background: transparent;
  box-shadow: none;
}

.card-list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
}
.card-list .card {
  box-shadow: none !important;
}

.card-list-body .card:last-of-type {
  margin-bottom: 0 !important;
}

.empty-container:after {
  pointer-events: none;
  content: attr(data-placeholder);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  color: #caced0;
  border: 1px dashed #caced0;
  height: 0;
  opacity: 0;
  border-radius: 0.25rem;
  transition: opacity 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .empty-container:after {
    transition: none;
  }
}
.empty-container:only-child:after {
  opacity: 1;
  height: 70px;
}

.card-list-flush {
  border: 0;
  border-radius: 0;
}

.card-placeholder {
  padding: 3rem 0;
  background-color: transparent;
  border: 1px dashed #E2E8F0;
}
.card-placeholder:hover {
  border-color: #d2d8df;
}
.card-placeholder .card-body {
  text-align: center;
}

.card-progress {
  margin-bottom: 0.75rem;
}
.card-progress .card-body {
  padding: 1rem;
}
.card-progress .progress {
  height: 2px;
  margin: 2px 4px;
}

.omnisearch {
  width: 100%;
  margin-top: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch {
    transition: none;
  }
}
.omnisearch-container {
  margin: auto;
}
@media (max-width: 767.98px) {
  .omnisearch-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .omnisearch-container {
    width: 580px;
  }
}
.omnisearch.show {
  opacity: 1;
  transform: translateY(10%);
}
.omnisearch .container {
  position: relative;
  height: 100%;
}
.omnisearch .omnisearch-form {
  display: block;
  position: relative;
  z-index: 700;
  background: #fff;
  border-radius: 0.3rem;
}
.omnisearch .omnisearch-form .input-group-text {
  font-size: 1.25rem;
  background: transparent;
}
.omnisearch .omnisearch-form .form-control {
  display: block;
  height: 58px;
  font-size: 1.25rem;
  color: #718096;
  background-color: transparent;
  background-image: none;
}
.omnisearch .omnisearch-form .form-control::placeholder {
  color: #718096;
}
.omnisearch .omnisearch-suggestions {
  min-height: 150px;
  padding: 1.5rem;
  background: white;
  border-radius: 0.3rem;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.21s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch .omnisearch-suggestions {
    transition: none;
  }
}
.omnisearch .omnisearch-suggestions:before {
  background: #fff;
  box-shadow: none;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  left: 20px;
  position: absolute;
  bottom: 100%;
  transform: rotate(-45deg) translateY(1rem);
  z-index: -5;
  border-radius: 0.2rem;
}
.omnisearch .omnisearch-suggestions .heading {
  color: #4A5568;
}
.omnisearch .omnisearch-suggestions .list-link span {
  font-weight: 700;
  color: #2D3748;
}
.omnisearch .omnisearch-suggestions .list-link:hover {
  color: theme-color("primary");
}
.omnisearch .omnisearch-suggestions .list-link:hover span {
  color: theme-color("primary");
}
.omnisearch.show .omnisearch-form,
.omnisearch.show .omnisearch-suggestions {
  pointer-events: auto;
}
.omnisearch.show .omnisearch-suggestions {
  opacity: 1;
}

.omnisearch-open {
  overflow: hidden;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1A16C0;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #E2E8F0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #15129a;
  background-color: #EDF2F7;
  border-color: #E2E8F0;
}
.page-link:focus {
  z-index: 3;
  color: #15129a;
  background-color: #EDF2F7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(26, 22, 192, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1A16C0;
  border-color: #1A16C0;
}
.page-item.disabled .page-link {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
  border-color: #E2E8F0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.pagination {
  margin-bottom: 0;
}
.pagination .page-link {
  font-size: 0.7rem;
}

.pagination-lg .page-link {
  font-size: 0.8rem;
}

.pagination-sm .page-link {
  font-size: 0.7rem;
}

.pagination-tabs {
  border-radius: 0;
}
.pagination-tabs .page-link {
  margin-top: -1px;
  padding: 1.25rem 0.75rem;
  background-color: transparent;
  border-width: 1px 0 0 0;
  border-color: transparent;
  border-radius: 0 !important;
}
.pagination-tabs .page-link:hover {
  color: #1A16C0;
}
.pagination-tabs .page-item.active .page-link {
  background-color: transparent;
  border-color: #1A16C0;
  color: #fff;
}

.pagination-spaced {
  border-radius: 0;
}
.pagination-spaced .page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0.25rem;
  width: 36px;
  height: 36px;
}
.pagination-spaced.pagination-sm .page-link {
  width: 30px;
  height: 30px;
  border-radius: 0.2rem;
}
.pagination-spaced.pagination-lg .page-link {
  width: 52px;
  height: 52px;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #1A202C;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #1A202C;
}

.popover-header {
  font-weight: 700;
}

.popover-primary {
  background-color: #1A16C0;
}
.popover-primary .popover-header {
  background-color: #1A16C0;
  color: #fff;
}
.popover-primary .popover-body {
  color: #fff;
}
.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #1A16C0;
}
.popover-primary.bs-popover-right .arrow::after {
  border-right-color: #1A16C0;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #1A16C0;
}
.popover-primary.bs-popover-left .arrow::after {
  border-left-color: #1A16C0;
}

.popover-secondary {
  background-color: #E4ECF7;
}
.popover-secondary .popover-header {
  background-color: #E4ECF7;
  color: #000;
}
.popover-secondary .popover-body {
  color: #000;
}
.popover-secondary .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #E4ECF7;
}
.popover-secondary.bs-popover-right .arrow::after {
  border-right-color: #E4ECF7;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #E4ECF7;
}
.popover-secondary.bs-popover-left .arrow::after {
  border-left-color: #E4ECF7;
}

.popover-success {
  background-color: #4AAE8C;
}
.popover-success .popover-header {
  background-color: #4AAE8C;
  color: #000;
}
.popover-success .popover-body {
  color: #000;
}
.popover-success .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #4AAE8C;
}
.popover-success.bs-popover-right .arrow::after {
  border-right-color: #4AAE8C;
}
.popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #4AAE8C;
}
.popover-success.bs-popover-left .arrow::after {
  border-left-color: #4AAE8C;
}

.popover-info {
  background-color: #68B8F2;
}
.popover-info .popover-header {
  background-color: #68B8F2;
  color: #000;
}
.popover-info .popover-body {
  color: #000;
}
.popover-info .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #68B8F2;
}
.popover-info.bs-popover-right .arrow::after {
  border-right-color: #68B8F2;
}
.popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #68B8F2;
}
.popover-info.bs-popover-left .arrow::after {
  border-left-color: #68B8F2;
}

.popover-warning {
  background-color: #ED9830;
}
.popover-warning .popover-header {
  background-color: #ED9830;
  color: #000;
}
.popover-warning .popover-body {
  color: #000;
}
.popover-warning .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #ED9830;
}
.popover-warning.bs-popover-right .arrow::after {
  border-right-color: #ED9830;
}
.popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #ED9830;
}
.popover-warning.bs-popover-left .arrow::after {
  border-left-color: #ED9830;
}

.popover-danger {
  background-color: #F16063;
}
.popover-danger .popover-header {
  background-color: #F16063;
  color: #000;
}
.popover-danger .popover-body {
  color: #000;
}
.popover-danger .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #F16063;
}
.popover-danger.bs-popover-right .arrow::after {
  border-right-color: #F16063;
}
.popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #F16063;
}
.popover-danger.bs-popover-left .arrow::after {
  border-left-color: #F16063;
}

.popover-light {
  background-color: #F7FAFC;
}
.popover-light .popover-header {
  background-color: #F7FAFC;
  color: #000;
}
.popover-light .popover-body {
  color: #000;
}
.popover-light .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-light.bs-popover-top .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #F7FAFC;
}
.popover-light.bs-popover-right .arrow::after {
  border-right-color: #F7FAFC;
}
.popover-light.bs-popover-bottom .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #F7FAFC;
}
.popover-light.bs-popover-left .arrow::after {
  border-left-color: #F7FAFC;
}

.popover-dark {
  background-color: #27272E;
}
.popover-dark .popover-header {
  background-color: #27272E;
  color: #fff;
}
.popover-dark .popover-body {
  color: #fff;
}
.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #27272E;
}
.popover-dark.bs-popover-right .arrow::after {
  border-right-color: #27272E;
}
.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #27272E;
}
.popover-dark.bs-popover-left .arrow::after {
  border-left-color: #27272E;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.6rem;
  background-color: #EDF2F7;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1A16C0;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.progress-wrapper {
  position: relative;
  padding-top: 1.5rem;
}
.progress-wrapper .progress {
  margin-bottom: 1rem;
}

.progress-inverse {
  background-color: inverse(#EDF2F7);
}

.progress-heading {
  font-size: 0.7rem;
  font-weight: 700;
  margin: 0 0 2px;
  padding: 0;
}

.progress-text {
  margin-bottom: 0;
}

.progress-lg {
  height: 1rem;
}

.progress-md {
  height: 0.75rem;
}

.progress-sm {
  height: 0.375rem;
}

.progress-xs {
  height: 0.125rem;
}

.progress-group {
  position: relative;
}

.progress-prepend-icon {
  position: absolute;
  transform: translateY(-50%);
  font-size: 20px;
  top: 50%;
}
.progress-prepend-icon:not(:first-child) {
  right: -17px;
}
.progress-prepend-icon:not(:last-child) {
  left: -17px;
}

.progress-circle {
  width: 100px;
  height: 100px;
}
.progress-circle .progressbar-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #1A202C;
}
.progress-circle [class^=display],
.progress-circle .h1, .progress-circle .h2, .progress-circle .h3 {
  color: #1A202C;
}
.progress-circle svg path {
  stroke-linecap: round;
}
.progress-circle.progress-sm {
  width: 60px;
  height: 60px;
}
.progress-circle.progress-sm .progressbar-text {
  font-size: 0.875rem;
}
.progress-circle.progress-lg {
  width: 140px;
  height: 140px;
}
.progress-circle.progress-lg .progressbar-text {
  font-size: 1.25rem;
}

.progress-label {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: #1A202C;
}

.progress-percentage {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.progress-tooltip {
  display: inline-block;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: 0.7rem;
  position: relative;
  bottom: 8px;
  border-radius: 3px;
  margin-left: -15px;
}
.progress-tooltip:after {
  top: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-top-color: rgba(0, 0, 0, 0.8);
  border-width: 5px;
}

.shape-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transform: translateZ(0);
  overflow: hidden;
  color: #fff;
}
.shape-container svg {
  display: block;
  pointer-events: none;
  vertical-align: baseline;
}

.shape-position-top {
  top: 0;
  margin-top: -1px;
}

.shape-position-bottom {
  bottom: 0;
  margin-bottom: -1px;
}

.shape-orientation-inverse {
  transform: rotate(180deg);
}

.shape-line {
  min-height: 19px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #1A202C;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #1A202C;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #1A202C;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #1A202C;
  vertical-align: top;
  border-color: #E2E8F0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d1d0f2;
  --bs-table-striped-bg: #c7c6e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcbbda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1c0e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcbbda;
}

.table-secondary {
  --bs-table-bg: #fafbfd;
  --bs-table-striped-bg: #eeeef0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1e2e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e8ea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1e2e4;
}

.table-success {
  --bs-table-bg: #dbefe8;
  --bs-table-striped-bg: #d0e3dc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5d7d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbddd7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c5d7d1;
}

.table-info {
  --bs-table-bg: #e1f1fc;
  --bs-table-striped-bg: #d6e5ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbd9e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0dfe9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbd9e3;
}

.table-warning {
  --bs-table-bg: #fbead6;
  --bs-table-striped-bg: #eedecb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e2d3c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e8d8c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e2d3c1;
}

.table-danger {
  --bs-table-bg: #fcdfe0;
  --bs-table-striped-bg: #efd4d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c9ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9cecf;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3c9ca;
}

.table-light {
  --bs-table-bg: #F7FAFC;
  --bs-table-striped-bg: #ebeeef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dee1e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e7e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dee1e3;
}

.table-dark {
  --bs-table-bg: #27272E;
  --bs-table-striped-bg: #323238;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #3d3d43;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #37373e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #3d3d43;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.table {
  margin-bottom: 0;
}
.table thead th {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.675rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  border-bottom-width: 1px;
  white-space: nowrap;
  vertical-align: top;
}
.table tbody th {
  font-size: 0.8125rem;
}
.table td {
  font-size: 0.8125rem;
  white-space: nowrap;
}
.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}
.table.table-dark th,
.table .table-dark th {
  background-color: #303036;
  color: #737377;
}
.table.table-dark th a,
.table .table-dark th a {
  color: #737377;
}
.table.table-light th,
.table .table-light th {
  background-color: #F7FAFC;
  color: #4A5568;
}
.table.table-light th a,
.table .table-light th a {
  color: #4A5568;
}

.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.table-spaced thead th {
  border: 0;
}
.table-spaced thead tr th,
.table-spaced thead tr td,
.table-spaced tbody tr th,
.table-spaced tbody tr td {
  position: relative;
  background-color: transparent;
  border-top: 1px solid #E2E8F0 !important;
  border-bottom: 1px solid #E2E8F0 !important;
}
.table-spaced thead tr th:first-child,
.table-spaced thead tr td:first-child,
.table-spaced tbody tr th:first-child,
.table-spaced tbody tr td:first-child {
  border-left: 1px solid #E2E8F0 !important;
  border-radius: 0.25rem 0 0 0.25rem;
}
.table-spaced thead tr th:first-child:after,
.table-spaced thead tr td:first-child:after,
.table-spaced tbody tr th:first-child:after,
.table-spaced tbody tr td:first-child:after {
  border-left: 1px solid #E2E8F0;
  border-radius: 0.25rem 0 0 0.25rem;
}
.table-spaced thead tr th:last-child,
.table-spaced thead tr td:last-child,
.table-spaced tbody tr th:last-child,
.table-spaced tbody tr td:last-child {
  border-right: 1px solid #E2E8F0 !important;
  border-radius: 0 0.25rem 0.25rem 0;
}
.table-flush th, .table-flush td {
  border-left: 0;
  border-right: 0;
}
.table-flush thead > tr th {
  border-top: 0;
}
.table-flush tbody > tr:last-child th, .table-flush tbody > tr:last-child td {
  border-bottom: 0;
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #718096;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.article {
  font-size: 1rem;
  color: #4A5568;
  line-height: 1.9;
}
.article > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.article > h2, .article > .h2 {
  font-size: 1.5em;
  font-weight: bolder;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
  letter-spacing: -0.025em;
}
.article > h2:first-child, .article > .h2:first-child {
  margin-top: 1em;
}
.article > h3, .article > .h3 {
  font-size: 1.25em;
  font-weight: 700;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
}
.article > h4, .article > .h4 {
  font-size: 1em;
  font-weight: 500;
  margin-top: 2em;
}
.article > img {
  border-radius: 0.25rem;
}
.article > figure,
.article > img,
.article > video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.article pre {
  font-size: 0.875em;
  line-height: 1.7;
  padding: 1rem 1rem;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  overflow-x: auto;
  background-color: #27272E;
  color: #fff;
  border-radius: 0.25rem;
}
.article > :first-child {
  margin-top: 0;
}
.article h2 + *, .article .h2 + *,
.article h3 + *,
.article .h3 + *,
.article hr + * {
  margin-top: 0;
}
.article > hr {
  margin-top: 3em;
  margin-bottom: 3em;
}
.article > ul,
.article > ol {
  padding-left: 1.5rem;
}
.article > ul li,
.article > ol li {
  padding: 0.25rem 0;
}
.article > blockquote {
  font-weight: 500;
  font-size: 1rem;
  color: #1A202C;
  letter-spacing: -0.012em;
  padding: 1.5rem 3.5rem;
  margin-top: 3em;
  margin-bottom: 3em;
  background-color: var(--x-surface-secondary);
  border-radius: 0.3rem;
}
.article > blockquote > p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #fff !important;
  font-weight: 500;
  color: #3f4254;
  border-bottom: 1px solid #f5f8fa !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: 0.5rem 1rem;
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
  border-radius: 0.475rem !important;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15) !important;
  border: 0 !important;
  background: #fff !important;
  color: #3f4254;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0 !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #fff !important;
}

.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.rounded .apexcharts-canvas svg {
  border-radius: 0.475rem !important;
}

.rounded-sm .apexcharts-canvas svg {
  border-radius: 0.275rem !important;
}

.rounded-lg .apexcharts-canvas svg {
  border-radius: 0.775rem !important;
}

.rounded-xl .apexcharts-canvas svg {
  border-radius: 1rem !important;
}

.apexcharts-bar-hover-white .apexcharts-bar-area:hover {
  fill: #fff !important;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  border: 0;
  width: auto;
  margin-top: -2px;
  user-select: none;
  background: #fff;
  border-radius: 0.25rem;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}

.flatpickr-months {
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.flatpickr-months .flatpickr-month {
  height: 60px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1A202C;
  top: 13px;
  transition: background-color 0.4s linear;
}
@media (prefers-reduced-motion: reduce) {
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    transition: none;
  }
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: rgba(26, 32, 44, 0.7);
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #1A202C;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #1A202C;
}
.flatpickr-months .flatpickr-prev-month {
  margin-left: 15px;
}
.flatpickr-months .flatpickr-next-month {
  margin-right: 15px;
}

.flatpickr-current-month {
  font-size: 1.125rem;
  color: #1A202C;
  padding-top: 18px;
}
.flatpickr-current-month span.cur-month:hover {
  background-color: transparent;
}
.flatpickr-current-month .numInputWrapper:hover {
  background-color: transparent;
}
.flatpickr-current-month .numInputWrapper span {
  border: 0;
  right: -5px;
  padding: 0;
}
.flatpickr-current-month .numInputWrapper span:after {
  left: 3px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}
.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: #fff;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255, 255, 255, 0.7);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
  border-top-color: #fff;
}

span.flatpickr-weekday {
  font-weight: 700;
  color: #1A202C;
}

.flatpickr-day {
  font-size: 0.7rem;
  border: 0;
  color: #1A202C;
  border-radius: 0.25rem;
}
.flatpickr-day.today {
  color: #1A16C0 !important;
}
.flatpickr-day.today:hover {
  background-color: #1A16C0;
  color: #fff;
}
.flatpickr-day:hover {
  background-color: #EDF2F7;
  color: #171d28;
}
.flatpickr-day.selected {
  background-color: #1A16C0;
  color: #fff !important;
}
.flatpickr-day.selected:hover {
  background-color: #1A16C0;
  color: #fff;
}

.numInputWrapper span:hover {
  background-color: transparent;
}

.flatpickr-time {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.flatpickr-innerContainer {
  padding: 15px;
}

.bg-blue {
  background-color: #1a16c0 !important;
}

.bg-indigo {
  background-color: #6366f1 !important;
}

.bg-purple {
  background-color: #1f1e46 !important;
}

.bg-pink {
  background-color: #ec4899 !important;
}

.bg-red {
  background-color: #f16063 !important;
}

.bg-orange {
  background-color: #f57648 !important;
}

.bg-yellow {
  background-color: #ed9830 !important;
}

.bg-green {
  background-color: #4aae8c !important;
}

.bg-teal {
  background-color: #14b8a6 !important;
}

.bg-cyan {
  background-color: #68b8f2 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-gray {
  background-color: #718096 !important;
}

.bg-gray-dark {
  background-color: #2d3748 !important;
}

.bg-tint-primary {
  background-color: #3c39c9 !important;
}

.bg-shade-primary {
  background-color: #1613a3 !important;
}

.bg-soft-primary {
  background-color: #d1d0f2 !important;
}

.bg-tint-secondary {
  background-color: #e8eff8 !important;
}

.bg-shade-secondary {
  background-color: #c2c9d2 !important;
}

.bg-soft-secondary {
  background-color: #fafbfd !important;
}

.bg-tint-success {
  background-color: #65ba9d !important;
}

.bg-shade-success {
  background-color: #3f9477 !important;
}

.bg-soft-success {
  background-color: #dbefe8 !important;
}

.bg-tint-info {
  background-color: #7fc3f4 !important;
}

.bg-shade-info {
  background-color: #589cce !important;
}

.bg-soft-info {
  background-color: #e1f1fc !important;
}

.bg-tint-warning {
  background-color: #f0a74f !important;
}

.bg-shade-warning {
  background-color: #c98129 !important;
}

.bg-soft-warning {
  background-color: #fbead6 !important;
}

.bg-tint-danger {
  background-color: #f3787a !important;
}

.bg-shade-danger {
  background-color: #cd5254 !important;
}

.bg-soft-danger {
  background-color: #fcdfe0 !important;
}

.bg-tint-light {
  background-color: #f8fbfc !important;
}

.bg-shade-light {
  background-color: #d2d5d6 !important;
}

.bg-soft-light {
  background-color: #fdfefe !important;
}

.bg-tint-dark {
  background-color: #47474d !important;
}

.bg-shade-dark {
  background-color: #212127 !important;
}

.bg-soft-dark {
  background-color: #d4d4d5 !important;
}

.bg-surface-primary {
  background-color: #fff !important;
}

.bg-surface-secondary {
  background-color: #F7FAFC !important;
}

.bg-surface-tertiary {
  background-color: #FAFAFA !important;
}

.bg-surface-light {
  background-color: #EDF2F7 !important;
}

.bg-surface-dark {
  background-color: #24242b !important;
}

.bg-fade-light {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(243, 248, 255, 0)), to(#f3f8ff));
  background-image: -o-linear-gradient(top, rgba(243, 248, 255, 0), #f3f8ff);
  background-image: linear-gradient(to bottom, rgba(243, 248, 255, 0), #f3f8ff);
}

.bg-light-boxed-right {
  background: no-repeat left center -webkit-gradient(linear, left top, right top, from(#f3f8ff));
}

@media (min-width: 1200px) {
  .bg-light-boxed-right {
    background-size: calc(1140px + (100vw - 1140px) / 2) 100%;
  }
}
.bg-checkered {
  background-repeat: no-repeat, repeat, repeat;
  background-image: -o-radial-gradient(transparent, transparent 50%, #fff), -o-linear-gradient(left, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), -o-linear-gradient(top, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
  background-image: radial-gradient(transparent, transparent 50%, #fff), linear-gradient(to right, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), linear-gradient(to bottom, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
  background-size: 100% 100%, 33px 33px, 33px 33px;
}

.bg-hero-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.966) 13.11%, hsla(0, 0%, 100%, 0.874) 24.72%, hsla(0, 0%, 100%, 0.741) 35.26%, hsla(0, 0%, 100%, 0.583) 45.16%, hsla(0, 0%, 100%, 0.417) 54.84%, hsla(0, 0%, 100%, 0.259) 64.74%, hsla(0, 0%, 100%, 0.126) 75.28%, hsla(0, 0%, 100%, 0.034) 86.89%, hsla(0, 0%, 100%, 0));
}

.bg-translucent-white {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.bg-translucent-dark {
  background-color: rgba(39, 39, 46, 0.05) !important;
}

.bg-none {
  background-image: none !important;
}

.bg-between {
  background-repeat: no-repeat;
  background-position: left center, right center;
  background-size: auto 90%;
}

@media (min-width: 576px) {
  .bg-sm-none {
    background-image: none !important;
  }
  .bg-sm-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
}
@media (min-width: 768px) {
  .bg-md-none {
    background-image: none !important;
  }
  .bg-md-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
}
@media (min-width: 992px) {
  .bg-lg-none {
    background-image: none !important;
  }
  .bg-lg-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
}
@media (min-width: 1200px) {
  .bg-xl-none {
    background-image: none !important;
  }
  .bg-xl-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
}
@media (min-width: 1400px) {
  .bg-xxl-none {
    background-image: none !important;
  }
  .bg-xxl-between {
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: auto 90%;
  }
}
.gradient-top {
  background-image: linear-gradient(to top, var(--bs-gradient)) !important;
}

.gradient-top-right {
  background-image: linear-gradient(to top right, var(--bs-gradient)) !important;
}

.gradient-right {
  background-image: linear-gradient(to right, var(--bs-gradient)) !important;
}

.gradient-bottom-right {
  background-image: linear-gradient(to bottom right, var(--bs-gradient)) !important;
}

.gradient-bottom {
  background-image: linear-gradient(to bottom, var(--bs-gradient)) !important;
}

.gradient-bottom-left {
  background-image: linear-gradient(to bottom left, var(--bs-gradient)) !important;
}

.gradient-left {
  background-image: linear-gradient(to left, var(--bs-gradient)) !important;
}

.gradient-top-left {
  background-image: linear-gradient(to top left, var(--bs-gradient)) !important;
}

.start-blue,
.start-blue-hover:hover {
  --bs-start-color: #1A16C0;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(26, 22, 192, 0));
}

.start-indigo,
.start-indigo-hover:hover {
  --bs-start-color: #6366f1;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(99, 102, 241, 0));
}

.start-purple,
.start-purple-hover:hover {
  --bs-start-color: #1f1e46;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(31, 30, 70, 0));
}

.start-pink,
.start-pink-hover:hover {
  --bs-start-color: #ec4899;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(236, 72, 153, 0));
}

.start-red,
.start-red-hover:hover {
  --bs-start-color: #F16063;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(241, 96, 99, 0));
}

.start-orange,
.start-orange-hover:hover {
  --bs-start-color: #F57648;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(245, 118, 72, 0));
}

.start-yellow,
.start-yellow-hover:hover {
  --bs-start-color: #ED9830;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(237, 152, 48, 0));
}

.start-green,
.start-green-hover:hover {
  --bs-start-color: #4AAE8C;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(74, 174, 140, 0));
}

.start-teal,
.start-teal-hover:hover {
  --bs-start-color: #14b8a6;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(20, 184, 166, 0));
}

.start-cyan,
.start-cyan-hover:hover {
  --bs-start-color: #68B8F2;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(104, 184, 242, 0));
}

.start-white,
.start-white-hover:hover {
  --bs-start-color: #fff;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(255, 255, 255, 0));
}

.start-gray,
.start-gray-hover:hover {
  --bs-start-color: #718096;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(113, 128, 150, 0));
}

.start-gray-dark,
.start-gray-dark-hover:hover {
  --bs-start-color: #2D3748;
  --bs-gradient: var(--bs-start-color), var(--bs-end-color, rgba(45, 55, 72, 0));
}

.middle-blue,
.middle-blue-hover:hover {
  --bs-gradient: var(--bs-start-color), #1A16C0, var(--bs-end-color, rgba(26, 22, 192, 0));
}

.middle-indigo,
.middle-indigo-hover:hover {
  --bs-gradient: var(--bs-start-color), #6366f1, var(--bs-end-color, rgba(99, 102, 241, 0));
}

.middle-purple,
.middle-purple-hover:hover {
  --bs-gradient: var(--bs-start-color), #1f1e46, var(--bs-end-color, rgba(31, 30, 70, 0));
}

.middle-pink,
.middle-pink-hover:hover {
  --bs-gradient: var(--bs-start-color), #ec4899, var(--bs-end-color, rgba(236, 72, 153, 0));
}

.middle-red,
.middle-red-hover:hover {
  --bs-gradient: var(--bs-start-color), #F16063, var(--bs-end-color, rgba(241, 96, 99, 0));
}

.middle-orange,
.middle-orange-hover:hover {
  --bs-gradient: var(--bs-start-color), #F57648, var(--bs-end-color, rgba(245, 118, 72, 0));
}

.middle-yellow,
.middle-yellow-hover:hover {
  --bs-gradient: var(--bs-start-color), #ED9830, var(--bs-end-color, rgba(237, 152, 48, 0));
}

.middle-green,
.middle-green-hover:hover {
  --bs-gradient: var(--bs-start-color), #4AAE8C, var(--bs-end-color, rgba(74, 174, 140, 0));
}

.middle-teal,
.middle-teal-hover:hover {
  --bs-gradient: var(--bs-start-color), #14b8a6, var(--bs-end-color, rgba(20, 184, 166, 0));
}

.middle-cyan,
.middle-cyan-hover:hover {
  --bs-gradient: var(--bs-start-color), #68B8F2, var(--bs-end-color, rgba(104, 184, 242, 0));
}

.middle-white,
.middle-white-hover:hover {
  --bs-gradient: var(--bs-start-color), #fff, var(--bs-end-color, rgba(255, 255, 255, 0));
}

.middle-gray,
.middle-gray-hover:hover {
  --bs-gradient: var(--bs-start-color), #718096, var(--bs-end-color, rgba(113, 128, 150, 0));
}

.middle-gray-dark,
.middle-gray-dark-hover:hover {
  --bs-gradient: var(--bs-start-color), #2D3748, var(--bs-end-color, rgba(45, 55, 72, 0));
}

.end-blue,
.end-blue-hover:hover {
  --bs-end-color: #1A16C0;
}

.end-indigo,
.end-indigo-hover:hover {
  --bs-end-color: #6366f1;
}

.end-purple,
.end-purple-hover:hover {
  --bs-end-color: #1f1e46;
}

.end-pink,
.end-pink-hover:hover {
  --bs-end-color: #ec4899;
}

.end-red,
.end-red-hover:hover {
  --bs-end-color: #F16063;
}

.end-orange,
.end-orange-hover:hover {
  --bs-end-color: #F57648;
}

.end-yellow,
.end-yellow-hover:hover {
  --bs-end-color: #ED9830;
}

.end-green,
.end-green-hover:hover {
  --bs-end-color: #4AAE8C;
}

.end-teal,
.end-teal-hover:hover {
  --bs-end-color: #14b8a6;
}

.end-cyan,
.end-cyan-hover:hover {
  --bs-end-color: #68B8F2;
}

.end-white,
.end-white-hover:hover {
  --bs-end-color: #fff;
}

.end-gray,
.end-gray-hover:hover {
  --bs-end-color: #718096;
}

.end-gray-dark,
.end-gray-dark-hover:hover {
  --bs-end-color: #2D3748;
}

.filter {
  --bs-blur: 0;
  --bs-contrast: 0;
  --bs-grayscale: 0;
  --bs-hue-rotate: 0;
  --bs-drop-shadow: 0;
  filter: blur(var(--bs-blur));
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.transform {
  --bs-translate-x: 0;
  --bs-translate-y: 0;
  --bs-translate-z: 0;
  --bs-perspective: 0;
  --bs-rotate: 0;
  --bs-rotate-x: 0;
  --bs-rotate-y: 0;
  --bs-skew-x: 0;
  --bs-skew-y: 0;
  --bs-scale-x: 1;
  --bs-scale-y: 1;
  transform: translateX(var(--bs-translate-x)) translateY(var(--bs-translate-y)) perspective(var(--bs-perspective)) rotate(var(--bs-rotate)) rotateX(var(--bs-rotate-x)) rotateY(var(--bs-rotate-y)) skewX(var(--bs-skew-x)) skewY(var(--bs-skew-y)) scaleX(var(--bs-scale-x)) scaleY(var(--bs-scale-y));
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.h-calc {
  --bs-h: 0px;
  height: calc(100vh - var(--bs-h));
}

.w-calc {
  --bs-w: 0px;
  width: calc(100vw - var(--bs-w));
}

@media (min-width: 576px) {
  .h-sm-calc {
    --bs-h-sm: 0px;
    height: calc(100vh - var(--bs-h-sm));
  }
  .w-sm-calc {
    --bs-w-sm: 0px;
    width: calc(100vw - var(--bs-w-sm));
  }
}
@media (min-width: 768px) {
  .h-md-calc {
    --bs-h-md: 0px;
    height: calc(100vh - var(--bs-h-md));
  }
  .w-md-calc {
    --bs-w-md: 0px;
    width: calc(100vw - var(--bs-w-md));
  }
}
@media (min-width: 992px) {
  .h-lg-calc {
    --bs-h-lg: 0px;
    height: calc(100vh - var(--bs-h-lg));
  }
  .w-lg-calc {
    --bs-w-lg: 0px;
    width: calc(100vw - var(--bs-w-lg));
  }
}
@media (min-width: 1200px) {
  .h-xl-calc {
    --bs-h-xl: 0px;
    height: calc(100vh - var(--bs-h-xl));
  }
  .w-xl-calc {
    --bs-w-xl: 0px;
    width: calc(100vw - var(--bs-w-xl));
  }
}
@media (min-width: 1400px) {
  .h-xxl-calc {
    --bs-h-xxl: 0px;
    height: calc(100vh - var(--bs-h-xxl));
  }
  .w-xxl-calc {
    --bs-w-xxl: 0px;
    width: calc(100vw - var(--bs-w-xxl));
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #1A16C0;
}
.link-primary:hover, .link-primary:focus {
  color: #15129a;
}

.link-secondary {
  color: #E4ECF7;
}
.link-secondary:hover, .link-secondary:focus {
  color: #e9f0f9;
}

.link-success {
  color: #4AAE8C;
}
.link-success:hover, .link-success:focus {
  color: #6ebea3;
}

.link-info {
  color: #68B8F2;
}
.link-info:hover, .link-info:focus {
  color: #86c6f5;
}

.link-warning {
  color: #ED9830;
}
.link-warning:hover, .link-warning:focus {
  color: #f1ad59;
}

.link-danger {
  color: #F16063;
}
.link-danger:hover, .link-danger:focus {
  color: #f48082;
}

.link-light {
  color: #F7FAFC;
}
.link-light:hover, .link-light:focus {
  color: #f9fbfd;
}

.link-dark {
  color: #27272E;
}
.link-dark:hover, .link-dark:focus {
  color: #1f1f25;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.floatfix:before, .floatfix:after {
  content: "";
  display: table;
}
.floatfix:after {
  clear: both;
}

.scrollable-x {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scrollable-x::-webkit-scrollbar {
  display: none;
}

.scrollable-y {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scrollable-y::-webkit-scrollbar {
  display: none;
}

[class*=faded],
[class*=blured] {
  position: relative;
}
[class*=faded]:after,
[class*=blured]:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 3.9375rem;
}

.faded-top:after {
  top: 0;
  background: -moz-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= "#00000000", endColorstr="#a6000000",GradientType=0 );
}

.faded-bottom:after {
  bottom: 0;
  background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= "#00000000", endColorstr="#a6000000",GradientType=0 );
}

.blured-top:after {
  top: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px) saturate(200%);
}

.blured-bottom:after {
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px) saturate(200%);
}

[class*=hover-] {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  [class*=hover-] {
    transition: none;
  }
}

[class*=shadow] {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  [class*=shadow] {
    transition: none;
  }
}

a.text-muted:hover,
a.text-heading:hover,
a.text-current:hover {
  color: #15129a;
}

.text-limit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg-fluid svg {
  max-width: 100%;
  height: 100%;
}

.svg-current svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-grayscale {
  filter: grayscale(100%);
}

.img-saturate {
  filter: saturate(150%);
}

.svg-icon {
  display: inline-flex;
  align-self: center;
}
.svg-icon svg {
  width: 1em;
  height: 1em;
  position: relative;
}
.svg-icon[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important;
}

.svg-align-baseline svg {
  top: 0.125em;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialised {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-hover:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-focus:focus {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-1 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-1-hover:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-1-focus:focus {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-2 {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-2-hover:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-2-focus:focus {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-3 {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-3-hover:hover {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-3-focus:focus {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-4 {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-4-hover:hover {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-4-focus:focus {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-5 {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-5-hover:hover {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-5-focus:focus {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-6 {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-6-hover:hover {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-6-focus:focus {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}

.shadow-inset {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
}

.shadow-inset-hover:hover {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
}

.shadow-inset-focus:focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-outline-hover:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-outline-focus:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-focus-hover:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-focus-focus:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-none-hover:hover {
  box-shadow: none !important;
}

.shadow-none-focus:focus {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 0.25rem !important;
}

.top-2 {
  top: 0.5rem !important;
}

.top-3 {
  top: 0.75rem !important;
}

.top-4 {
  top: 1rem !important;
}

.top-5 {
  top: 1.25rem !important;
}

.top-6 {
  top: 1.5rem !important;
}

.top-7 {
  top: 1.75rem !important;
}

.top-8 {
  top: 2rem !important;
}

.top-10 {
  top: 2.5rem !important;
}

.top-12 {
  top: 3rem !important;
}

.top-14 {
  top: 3.5rem !important;
}

.top-16 {
  top: 4rem !important;
}

.top-18 {
  top: 4.5rem !important;
}

.top-20 {
  top: 5rem !important;
}

.top-24 {
  top: 6rem !important;
}

.top-32 {
  top: 8rem !important;
}

.top-40 {
  top: 10rem !important;
}

.top-48 {
  top: 12rem !important;
}

.top-56 {
  top: 14rem !important;
}

.top-64 {
  top: 16rem !important;
}

.top-72 {
  top: 18rem !important;
}

.top-80 {
  top: 20rem !important;
}

.top-88 {
  top: 22rem !important;
}

.top-96 {
  top: 24rem !important;
}

.top-px {
  top: 1px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-1 {
  bottom: 0.25rem !important;
}

.bottom-2 {
  bottom: 0.5rem !important;
}

.bottom-3 {
  bottom: 0.75rem !important;
}

.bottom-4 {
  bottom: 1rem !important;
}

.bottom-5 {
  bottom: 1.25rem !important;
}

.bottom-6 {
  bottom: 1.5rem !important;
}

.bottom-7 {
  bottom: 1.75rem !important;
}

.bottom-8 {
  bottom: 2rem !important;
}

.bottom-10 {
  bottom: 2.5rem !important;
}

.bottom-12 {
  bottom: 3rem !important;
}

.bottom-14 {
  bottom: 3.5rem !important;
}

.bottom-16 {
  bottom: 4rem !important;
}

.bottom-18 {
  bottom: 4.5rem !important;
}

.bottom-20 {
  bottom: 5rem !important;
}

.bottom-24 {
  bottom: 6rem !important;
}

.bottom-32 {
  bottom: 8rem !important;
}

.bottom-40 {
  bottom: 10rem !important;
}

.bottom-48 {
  bottom: 12rem !important;
}

.bottom-56 {
  bottom: 14rem !important;
}

.bottom-64 {
  bottom: 16rem !important;
}

.bottom-72 {
  bottom: 18rem !important;
}

.bottom-80 {
  bottom: 20rem !important;
}

.bottom-88 {
  bottom: 22rem !important;
}

.bottom-96 {
  bottom: 24rem !important;
}

.bottom-px {
  bottom: 1px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-1 {
  left: 0.25rem !important;
}

.start-2 {
  left: 0.5rem !important;
}

.start-3 {
  left: 0.75rem !important;
}

.start-4 {
  left: 1rem !important;
}

.start-5 {
  left: 1.25rem !important;
}

.start-6 {
  left: 1.5rem !important;
}

.start-7 {
  left: 1.75rem !important;
}

.start-8 {
  left: 2rem !important;
}

.start-10 {
  left: 2.5rem !important;
}

.start-12 {
  left: 3rem !important;
}

.start-14 {
  left: 3.5rem !important;
}

.start-16 {
  left: 4rem !important;
}

.start-18 {
  left: 4.5rem !important;
}

.start-20 {
  left: 5rem !important;
}

.start-24 {
  left: 6rem !important;
}

.start-32 {
  left: 8rem !important;
}

.start-40 {
  left: 10rem !important;
}

.start-48 {
  left: 12rem !important;
}

.start-56 {
  left: 14rem !important;
}

.start-64 {
  left: 16rem !important;
}

.start-72 {
  left: 18rem !important;
}

.start-80 {
  left: 20rem !important;
}

.start-88 {
  left: 22rem !important;
}

.start-96 {
  left: 24rem !important;
}

.start-px {
  left: 1px !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-1 {
  right: 0.25rem !important;
}

.end-2 {
  right: 0.5rem !important;
}

.end-3 {
  right: 0.75rem !important;
}

.end-4 {
  right: 1rem !important;
}

.end-5 {
  right: 1.25rem !important;
}

.end-6 {
  right: 1.5rem !important;
}

.end-7 {
  right: 1.75rem !important;
}

.end-8 {
  right: 2rem !important;
}

.end-10 {
  right: 2.5rem !important;
}

.end-12 {
  right: 3rem !important;
}

.end-14 {
  right: 3.5rem !important;
}

.end-16 {
  right: 4rem !important;
}

.end-18 {
  right: 4.5rem !important;
}

.end-20 {
  right: 5rem !important;
}

.end-24 {
  right: 6rem !important;
}

.end-32 {
  right: 8rem !important;
}

.end-40 {
  right: 10rem !important;
}

.end-48 {
  right: 12rem !important;
}

.end-56 {
  right: 14rem !important;
}

.end-64 {
  right: 16rem !important;
}

.end-72 {
  right: 18rem !important;
}

.end-80 {
  right: 20rem !important;
}

.end-88 {
  right: 22rem !important;
}

.end-96 {
  right: 24rem !important;
}

.end-px {
  right: 1px !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-top-0-hover:hover {
  border-top-width: 0 !important;
}

.border-top-0-focus:focus {
  border-top-width: 0 !important;
}

.border-top {
  border-top-width: 1px !important;
}

.border-top-hover:hover {
  border-top-width: 1px !important;
}

.border-top-focus:focus {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-2-hover:hover {
  border-top-width: 2px !important;
}

.border-top-2-focus:focus {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-3-hover:hover {
  border-top-width: 3px !important;
}

.border-top-3-focus:focus {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-4-hover:hover {
  border-top-width: 4px !important;
}

.border-top-4-focus:focus {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-top-5-hover:hover {
  border-top-width: 5px !important;
}

.border-top-5-focus:focus {
  border-top-width: 5px !important;
}

.border-top-6 {
  border-top-width: 6px !important;
}

.border-top-6-hover:hover {
  border-top-width: 6px !important;
}

.border-top-6-focus:focus {
  border-top-width: 6px !important;
}

.border-top-7 {
  border-top-width: 7px !important;
}

.border-top-7-hover:hover {
  border-top-width: 7px !important;
}

.border-top-7-focus:focus {
  border-top-width: 7px !important;
}

.border-top-8 {
  border-top-width: 8px !important;
}

.border-top-8-hover:hover {
  border-top-width: 8px !important;
}

.border-top-8-focus:focus {
  border-top-width: 8px !important;
}

.border-end-0 {
  border-right-width: 0 !important;
}

.border-end-0-hover:hover {
  border-right-width: 0 !important;
}

.border-end-0-focus:focus {
  border-right-width: 0 !important;
}

.border-end {
  border-right-width: 1px !important;
}

.border-end-hover:hover {
  border-right-width: 1px !important;
}

.border-end-focus:focus {
  border-right-width: 1px !important;
}

.border-end-2 {
  border-right-width: 2px !important;
}

.border-end-2-hover:hover {
  border-right-width: 2px !important;
}

.border-end-2-focus:focus {
  border-right-width: 2px !important;
}

.border-end-3 {
  border-right-width: 3px !important;
}

.border-end-3-hover:hover {
  border-right-width: 3px !important;
}

.border-end-3-focus:focus {
  border-right-width: 3px !important;
}

.border-end-4 {
  border-right-width: 4px !important;
}

.border-end-4-hover:hover {
  border-right-width: 4px !important;
}

.border-end-4-focus:focus {
  border-right-width: 4px !important;
}

.border-end-5 {
  border-right-width: 5px !important;
}

.border-end-5-hover:hover {
  border-right-width: 5px !important;
}

.border-end-5-focus:focus {
  border-right-width: 5px !important;
}

.border-end-6 {
  border-right-width: 6px !important;
}

.border-end-6-hover:hover {
  border-right-width: 6px !important;
}

.border-end-6-focus:focus {
  border-right-width: 6px !important;
}

.border-end-7 {
  border-right-width: 7px !important;
}

.border-end-7-hover:hover {
  border-right-width: 7px !important;
}

.border-end-7-focus:focus {
  border-right-width: 7px !important;
}

.border-end-8 {
  border-right-width: 8px !important;
}

.border-end-8-hover:hover {
  border-right-width: 8px !important;
}

.border-end-8-focus:focus {
  border-right-width: 8px !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-bottom-0-hover:hover {
  border-bottom-width: 0 !important;
}

.border-bottom-0-focus:focus {
  border-bottom-width: 0 !important;
}

.border-bottom {
  border-bottom-width: 1px !important;
}

.border-bottom-hover:hover {
  border-bottom-width: 1px !important;
}

.border-bottom-focus:focus {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-2-hover:hover {
  border-bottom-width: 2px !important;
}

.border-bottom-2-focus:focus {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-3-hover:hover {
  border-bottom-width: 3px !important;
}

.border-bottom-3-focus:focus {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-4-hover:hover {
  border-bottom-width: 4px !important;
}

.border-bottom-4-focus:focus {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

.border-bottom-5-hover:hover {
  border-bottom-width: 5px !important;
}

.border-bottom-5-focus:focus {
  border-bottom-width: 5px !important;
}

.border-bottom-6 {
  border-bottom-width: 6px !important;
}

.border-bottom-6-hover:hover {
  border-bottom-width: 6px !important;
}

.border-bottom-6-focus:focus {
  border-bottom-width: 6px !important;
}

.border-bottom-7 {
  border-bottom-width: 7px !important;
}

.border-bottom-7-hover:hover {
  border-bottom-width: 7px !important;
}

.border-bottom-7-focus:focus {
  border-bottom-width: 7px !important;
}

.border-bottom-8 {
  border-bottom-width: 8px !important;
}

.border-bottom-8-hover:hover {
  border-bottom-width: 8px !important;
}

.border-bottom-8-focus:focus {
  border-bottom-width: 8px !important;
}

.border-start-0 {
  border-left-width: 0 !important;
}

.border-start-0-hover:hover {
  border-left-width: 0 !important;
}

.border-start-0-focus:focus {
  border-left-width: 0 !important;
}

.border-start {
  border-left-width: 1px !important;
}

.border-start-hover:hover {
  border-left-width: 1px !important;
}

.border-start-focus:focus {
  border-left-width: 1px !important;
}

.border-start-2 {
  border-left-width: 2px !important;
}

.border-start-2-hover:hover {
  border-left-width: 2px !important;
}

.border-start-2-focus:focus {
  border-left-width: 2px !important;
}

.border-start-3 {
  border-left-width: 3px !important;
}

.border-start-3-hover:hover {
  border-left-width: 3px !important;
}

.border-start-3-focus:focus {
  border-left-width: 3px !important;
}

.border-start-4 {
  border-left-width: 4px !important;
}

.border-start-4-hover:hover {
  border-left-width: 4px !important;
}

.border-start-4-focus:focus {
  border-left-width: 4px !important;
}

.border-start-5 {
  border-left-width: 5px !important;
}

.border-start-5-hover:hover {
  border-left-width: 5px !important;
}

.border-start-5-focus:focus {
  border-left-width: 5px !important;
}

.border-start-6 {
  border-left-width: 6px !important;
}

.border-start-6-hover:hover {
  border-left-width: 6px !important;
}

.border-start-6-focus:focus {
  border-left-width: 6px !important;
}

.border-start-7 {
  border-left-width: 7px !important;
}

.border-start-7-hover:hover {
  border-left-width: 7px !important;
}

.border-start-7-focus:focus {
  border-left-width: 7px !important;
}

.border-start-8 {
  border-left-width: 8px !important;
}

.border-start-8-hover:hover {
  border-left-width: 8px !important;
}

.border-start-8-focus:focus {
  border-left-width: 8px !important;
}

.border-primary {
  border-color: #1A16C0 !important;
}

.border-primary-hover:hover {
  border-color: #1A16C0 !important;
}

.border-primary-focus:focus {
  border-color: #1A16C0 !important;
}

.border-primary-focus-within:focus-within {
  border-color: #1A16C0 !important;
}

.border-secondary {
  border-color: #E4ECF7 !important;
}

.border-secondary-hover:hover {
  border-color: #E4ECF7 !important;
}

.border-secondary-focus:focus {
  border-color: #E4ECF7 !important;
}

.border-secondary-focus-within:focus-within {
  border-color: #E4ECF7 !important;
}

.border-success {
  border-color: #4AAE8C !important;
}

.border-success-hover:hover {
  border-color: #4AAE8C !important;
}

.border-success-focus:focus {
  border-color: #4AAE8C !important;
}

.border-success-focus-within:focus-within {
  border-color: #4AAE8C !important;
}

.border-info {
  border-color: #68B8F2 !important;
}

.border-info-hover:hover {
  border-color: #68B8F2 !important;
}

.border-info-focus:focus {
  border-color: #68B8F2 !important;
}

.border-info-focus-within:focus-within {
  border-color: #68B8F2 !important;
}

.border-warning {
  border-color: #ED9830 !important;
}

.border-warning-hover:hover {
  border-color: #ED9830 !important;
}

.border-warning-focus:focus {
  border-color: #ED9830 !important;
}

.border-warning-focus-within:focus-within {
  border-color: #ED9830 !important;
}

.border-danger {
  border-color: #F16063 !important;
}

.border-danger-hover:hover {
  border-color: #F16063 !important;
}

.border-danger-focus:focus {
  border-color: #F16063 !important;
}

.border-danger-focus-within:focus-within {
  border-color: #F16063 !important;
}

.border-light {
  border-color: #F7FAFC !important;
}

.border-light-hover:hover {
  border-color: #F7FAFC !important;
}

.border-light-focus:focus {
  border-color: #F7FAFC !important;
}

.border-light-focus-within:focus-within {
  border-color: #F7FAFC !important;
}

.border-dark {
  border-color: #27272E !important;
}

.border-dark-hover:hover {
  border-color: #27272E !important;
}

.border-dark-focus:focus {
  border-color: #27272E !important;
}

.border-dark-focus-within:focus-within {
  border-color: #27272E !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-transparent-hover:hover {
  border-color: transparent !important;
}

.border-transparent-focus:focus {
  border-color: transparent !important;
}

.border-transparent-focus-within:focus-within {
  border-color: transparent !important;
}

.border-current {
  border-color: currentColor !important;
}

.border-current-hover:hover {
  border-color: currentColor !important;
}

.border-current-focus:focus {
  border-color: currentColor !important;
}

.border-current-focus-within:focus-within {
  border-color: currentColor !important;
}

.border-base {
  border-color: #E2E8F0 !important;
}

.border-base-hover:hover {
  border-color: #E2E8F0 !important;
}

.border-base-focus:focus {
  border-color: #E2E8F0 !important;
}

.border-base-focus-within:focus-within {
  border-color: #E2E8F0 !important;
}

.border-body {
  border-color: #fff !important;
}

.border-body-hover:hover {
  border-color: #fff !important;
}

.border-body-focus:focus {
  border-color: #fff !important;
}

.border-body-focus-within:focus-within {
  border-color: #fff !important;
}

.border-card {
  border-color: #fff !important;
}

.border-card-hover:hover {
  border-color: #fff !important;
}

.border-card-focus:focus {
  border-color: #fff !important;
}

.border-card-focus-within:focus-within {
  border-color: #fff !important;
}

.border-black {
  border-color: #000 !important;
}

.border-black-hover:hover {
  border-color: #000 !important;
}

.border-black-focus:focus {
  border-color: #000 !important;
}

.border-black-focus-within:focus-within {
  border-color: #000 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-hover:hover {
  border-color: #fff !important;
}

.border-white-focus:focus {
  border-color: #fff !important;
}

.border-white-focus-within:focus-within {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-0-hover:hover {
  border-width: 0 !important;
}

.border-0-focus:focus {
  border-width: 0 !important;
}

.border {
  border-width: 1px !important;
}

.border-hover:hover {
  border-width: 1px !important;
}

.border-focus:focus {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-2-hover:hover {
  border-width: 2px !important;
}

.border-2-focus:focus {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-3-hover:hover {
  border-width: 3px !important;
}

.border-3-focus:focus {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-4-hover:hover {
  border-width: 4px !important;
}

.border-4-focus:focus {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-5-hover:hover {
  border-width: 5px !important;
}

.border-5-focus:focus {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-6-hover:hover {
  border-width: 6px !important;
}

.border-6-focus:focus {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-7-hover:hover {
  border-width: 7px !important;
}

.border-7-focus:focus {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-8-hover:hover {
  border-width: 8px !important;
}

.border-8-focus:focus {
  border-width: 8px !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-14 {
  width: 3.5rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-18 {
  width: 4.5rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-56 {
  width: 14rem !important;
}

.w-64 {
  width: 16rem !important;
}

.w-72 {
  width: 18rem !important;
}

.w-80 {
  width: 20rem !important;
}

.w-88 {
  width: 22rem !important;
}

.w-96 {
  width: 24rem !important;
}

.w-px {
  width: 1px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.3333333333% !important;
}

.w-2\/3 {
  width: 66.6666666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-1\/6 {
  width: 16.6666666667% !important;
}

.w-2\/6 {
  width: 33.3333333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.6666666667% !important;
}

.w-5\/6 {
  width: 83.3333333333% !important;
}

.w-11\/10 {
  width: 110% !important;
}

.w-12\/10 {
  width: 120% !important;
}

.w-13\/10 {
  width: 130% !important;
}

.w-14\/10 {
  width: 140% !important;
}

.w-15\/10 {
  width: 150% !important;
}

.w-screen-sm {
  width: 640px !important;
}

.w-screen-md {
  width: 768px !important;
}

.w-screen-lg {
  width: 1024px !important;
}

.w-screen-xl {
  width: 1280px !important;
}

.w-screen-xxl {
  width: 1536px !important;
}

.w-auto {
  width: auto !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.max-w-screen-sm {
  max-width: 640px !important;
}

.max-w-screen-md {
  max-width: 768px !important;
}

.max-w-screen-lg {
  max-width: 1024px !important;
}

.max-w-screen-xl {
  max-width: 1280px !important;
}

.max-w-screen-xxl {
  max-width: 1536px !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-read {
  max-width: 65ch !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-14 {
  height: 3.5rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-18 {
  height: 4.5rem !important;
}

.h-20 {
  height: 5rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-56 {
  height: 14rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-72 {
  height: 18rem !important;
}

.h-80 {
  height: 20rem !important;
}

.h-88 {
  height: 22rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-px {
  height: 1px !important;
}

.h-1\/2 {
  height: 50% !important;
}

.h-1\/3 {
  height: 33.3333333333% !important;
}

.h-2\/3 {
  height: 66.6666666667% !important;
}

.h-1\/4 {
  height: 25% !important;
}

.h-2\/4 {
  height: 50% !important;
}

.h-3\/4 {
  height: 75% !important;
}

.h-1\/5 {
  height: 20% !important;
}

.h-2\/5 {
  height: 40% !important;
}

.h-3\/5 {
  height: 60% !important;
}

.h-4\/5 {
  height: 80% !important;
}

.h-1\/6 {
  height: 16.6666666667% !important;
}

.h-2\/6 {
  height: 33.3333333333% !important;
}

.h-3\/6 {
  height: 50% !important;
}

.h-4\/6 {
  height: 66.6666666667% !important;
}

.h-5\/6 {
  height: 83.3333333333% !important;
}

.h-11\/10 {
  height: 110% !important;
}

.h-12\/10 {
  height: 120% !important;
}

.h-13\/10 {
  height: 130% !important;
}

.h-14\/10 {
  height: 140% !important;
}

.h-15\/10 {
  height: 150% !important;
}

.h-auto {
  height: auto !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.max-h-0 {
  max-height: 0 !important;
}

.max-h-1 {
  max-height: 0.25rem !important;
}

.max-h-2 {
  max-height: 0.5rem !important;
}

.max-h-3 {
  max-height: 0.75rem !important;
}

.max-h-4 {
  max-height: 1rem !important;
}

.max-h-5 {
  max-height: 1.25rem !important;
}

.max-h-6 {
  max-height: 1.5rem !important;
}

.max-h-7 {
  max-height: 1.75rem !important;
}

.max-h-8 {
  max-height: 2rem !important;
}

.max-h-10 {
  max-height: 2.5rem !important;
}

.max-h-12 {
  max-height: 3rem !important;
}

.max-h-14 {
  max-height: 3.5rem !important;
}

.max-h-16 {
  max-height: 4rem !important;
}

.max-h-18 {
  max-height: 4.5rem !important;
}

.max-h-20 {
  max-height: 5rem !important;
}

.max-h-24 {
  max-height: 6rem !important;
}

.max-h-32 {
  max-height: 8rem !important;
}

.max-h-40 {
  max-height: 10rem !important;
}

.max-h-48 {
  max-height: 12rem !important;
}

.max-h-56 {
  max-height: 14rem !important;
}

.max-h-64 {
  max-height: 16rem !important;
}

.max-h-72 {
  max-height: 18rem !important;
}

.max-h-80 {
  max-height: 20rem !important;
}

.max-h-88 {
  max-height: 22rem !important;
}

.max-h-96 {
  max-height: 24rem !important;
}

.max-h-px {
  max-height: 1px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.gap-24 {
  gap: 6rem !important;
}

.gap-32 {
  gap: 8rem !important;
}

.gap-40 {
  gap: 10rem !important;
}

.gap-48 {
  gap: 12rem !important;
}

.gap-56 {
  gap: 14rem !important;
}

.gap-64 {
  gap: 16rem !important;
}

.gap-72 {
  gap: 18rem !important;
}

.gap-80 {
  gap: 20rem !important;
}

.gap-88 {
  gap: 22rem !important;
}

.gap-96 {
  gap: 24rem !important;
}

.gap-px {
  gap: 1px !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-72 {
  margin: 18rem !important;
}

.m-80 {
  margin: 20rem !important;
}

.m-88 {
  margin: 22rem !important;
}

.m-96 {
  margin: 24rem !important;
}

.m-px {
  margin: 1px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-24 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-32 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-40 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-48 {
  margin-right: 12rem !important;
  margin-left: 12rem !important;
}

.mx-56 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-64 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-72 {
  margin-right: 18rem !important;
  margin-left: 18rem !important;
}

.mx-80 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-88 {
  margin-right: 22rem !important;
  margin-left: 22rem !important;
}

.mx-96 {
  margin-right: 24rem !important;
  margin-left: 24rem !important;
}

.mx-px {
  margin-right: 1px !important;
  margin-left: 1px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-72 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-80 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-88 {
  margin-top: 22rem !important;
  margin-bottom: 22rem !important;
}

.my-96 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mt-72 {
  margin-top: 18rem !important;
}

.mt-80 {
  margin-top: 20rem !important;
}

.mt-88 {
  margin-top: 22rem !important;
}

.mt-96 {
  margin-top: 24rem !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-24 {
  margin-right: 6rem !important;
}

.me-32 {
  margin-right: 8rem !important;
}

.me-40 {
  margin-right: 10rem !important;
}

.me-48 {
  margin-right: 12rem !important;
}

.me-56 {
  margin-right: 14rem !important;
}

.me-64 {
  margin-right: 16rem !important;
}

.me-72 {
  margin-right: 18rem !important;
}

.me-80 {
  margin-right: 20rem !important;
}

.me-88 {
  margin-right: 22rem !important;
}

.me-96 {
  margin-right: 24rem !important;
}

.me-px {
  margin-right: 1px !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.mb-72 {
  margin-bottom: 18rem !important;
}

.mb-80 {
  margin-bottom: 20rem !important;
}

.mb-88 {
  margin-bottom: 22rem !important;
}

.mb-96 {
  margin-bottom: 24rem !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-24 {
  margin-left: 6rem !important;
}

.ms-32 {
  margin-left: 8rem !important;
}

.ms-40 {
  margin-left: 10rem !important;
}

.ms-48 {
  margin-left: 12rem !important;
}

.ms-56 {
  margin-left: 14rem !important;
}

.ms-64 {
  margin-left: 16rem !important;
}

.ms-72 {
  margin-left: 18rem !important;
}

.ms-80 {
  margin-left: 20rem !important;
}

.ms-88 {
  margin-left: 22rem !important;
}

.ms-96 {
  margin-left: 24rem !important;
}

.ms-px {
  margin-left: 1px !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-72 {
  padding: 18rem !important;
}

.p-80 {
  padding: 20rem !important;
}

.p-88 {
  padding: 22rem !important;
}

.p-96 {
  padding: 24rem !important;
}

.p-px {
  padding: 1px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-24 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-32 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-40 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-48 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.px-56 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-64 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-72 {
  padding-right: 18rem !important;
  padding-left: 18rem !important;
}

.px-80 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-88 {
  padding-right: 22rem !important;
  padding-left: 22rem !important;
}

.px-96 {
  padding-right: 24rem !important;
  padding-left: 24rem !important;
}

.px-px {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-80 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-88 {
  padding-top: 22rem !important;
  padding-bottom: 22rem !important;
}

.py-96 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pt-72 {
  padding-top: 18rem !important;
}

.pt-80 {
  padding-top: 20rem !important;
}

.pt-88 {
  padding-top: 22rem !important;
}

.pt-96 {
  padding-top: 24rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pe-24 {
  padding-right: 6rem !important;
}

.pe-32 {
  padding-right: 8rem !important;
}

.pe-40 {
  padding-right: 10rem !important;
}

.pe-48 {
  padding-right: 12rem !important;
}

.pe-56 {
  padding-right: 14rem !important;
}

.pe-64 {
  padding-right: 16rem !important;
}

.pe-72 {
  padding-right: 18rem !important;
}

.pe-80 {
  padding-right: 20rem !important;
}

.pe-88 {
  padding-right: 22rem !important;
}

.pe-96 {
  padding-right: 24rem !important;
}

.pe-px {
  padding-right: 1px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pb-72 {
  padding-bottom: 18rem !important;
}

.pb-80 {
  padding-bottom: 20rem !important;
}

.pb-88 {
  padding-bottom: 22rem !important;
}

.pb-96 {
  padding-bottom: 24rem !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.ps-24 {
  padding-left: 6rem !important;
}

.ps-32 {
  padding-left: 8rem !important;
}

.ps-40 {
  padding-left: 10rem !important;
}

.ps-48 {
  padding-left: 12rem !important;
}

.ps-56 {
  padding-left: 14rem !important;
}

.ps-64 {
  padding-left: 16rem !important;
}

.ps-72 {
  padding-left: 18rem !important;
}

.ps-80 {
  padding-left: 20rem !important;
}

.ps-88 {
  padding-left: 22rem !important;
}

.ps-96 {
  padding-left: 24rem !important;
}

.ps-px {
  padding-left: 1px !important;
}

.font-base {
  font-family: var(--bs-font-sans-serif) !important;
}

.font-display {
  font-family: var(--bs-font-display) !important;
}

.font-serif {
  font-family: var(--bs-font-serif) !important;
}

.font-code {
  font-family: var(--bs-font-monospace) !important;
}

.text-xs {
  font-size: 0.6rem !important;
}

.text-sm {
  font-size: 0.7rem !important;
}

.text-base {
  font-size: 0.8rem !important;
}

.text-md {
  font-size: 0.9rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.2rem !important;
}

.text-2xl {
  font-size: calc(1.285rem + 0.42vw) !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-lighter {
  font-weight: lighter !important;
}

.font-regular {
  font-weight: 400 !important;
}

.font-semibold {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-bolder {
  font-weight: bolder !important;
}

.lh-none {
  line-height: 1 !important;
}

.lh-tighter {
  line-height: 1.125 !important;
}

.lh-tight {
  line-height: 1.2 !important;
}

.lh-snug {
  line-height: 1.375 !important;
}

.lh-normal {
  line-height: 1.5 !important;
}

.lh-relaxed {
  line-height: 1.625 !important;
}

.lh-loose {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-no-decoration {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-hover:hover {
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-focus:focus {
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-hover:hover {
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-focus:focus {
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-hover:hover {
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-success-focus:focus {
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-hover:hover {
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-info-focus:focus {
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-hover:hover {
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-warning-focus:focus {
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-hover:hover {
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-danger-focus:focus {
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-hover:hover {
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-light-focus:focus {
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-hover:hover {
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-focus:focus {
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-black-hover:hover {
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-black-focus:focus {
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-white-hover:hover {
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-white-focus:focus {
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-body-hover:hover {
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-body-focus:focus {
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-opacity-10 {
  --bs-text-opacity: 0.1;
}

.text-opacity-10-hover:hover {
  --bs-text-opacity: 0.1;
}

.text-opacity-10-focus:focus {
  --bs-text-opacity: 0.1;
}

.text-opacity-20 {
  --bs-text-opacity: 0.2;
}

.text-opacity-20-hover:hover {
  --bs-text-opacity: 0.2;
}

.text-opacity-20-focus:focus {
  --bs-text-opacity: 0.2;
}

.text-opacity-30 {
  --bs-text-opacity: 0.3;
}

.text-opacity-30-hover:hover {
  --bs-text-opacity: 0.3;
}

.text-opacity-30-focus:focus {
  --bs-text-opacity: 0.3;
}

.text-opacity-40 {
  --bs-text-opacity: 0.4;
}

.text-opacity-40-hover:hover {
  --bs-text-opacity: 0.4;
}

.text-opacity-40-focus:focus {
  --bs-text-opacity: 0.4;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-50-hover:hover {
  --bs-text-opacity: 0.5;
}

.text-opacity-50-focus:focus {
  --bs-text-opacity: 0.5;
}

.text-opacity-60 {
  --bs-text-opacity: 0.6;
}

.text-opacity-60-hover:hover {
  --bs-text-opacity: 0.6;
}

.text-opacity-60-focus:focus {
  --bs-text-opacity: 0.6;
}

.text-opacity-70 {
  --bs-text-opacity: 0.7;
}

.text-opacity-70-hover:hover {
  --bs-text-opacity: 0.7;
}

.text-opacity-70-focus:focus {
  --bs-text-opacity: 0.7;
}

.text-opacity-80 {
  --bs-text-opacity: 0.8;
}

.text-opacity-80-hover:hover {
  --bs-text-opacity: 0.8;
}

.text-opacity-80-focus:focus {
  --bs-text-opacity: 0.8;
}

.text-opacity-90 {
  --bs-text-opacity: 0.9;
}

.text-opacity-90-hover:hover {
  --bs-text-opacity: 0.9;
}

.text-opacity-90-focus:focus {
  --bs-text-opacity: 0.9;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-opacity-100-hover:hover {
  --bs-text-opacity: 1;
}

.text-opacity-100-focus:focus {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-hover:hover {
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-focus:focus {
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-focus-within:focus-within {
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-hover:hover {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-focus:focus {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-focus-within:focus-within {
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-hover:hover {
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-focus:focus {
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-focus-within:focus-within {
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-hover:hover {
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-focus:focus {
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info-focus-within:focus-within {
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-hover:hover {
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-focus:focus {
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning-focus-within:focus-within {
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-hover:hover {
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-focus:focus {
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger-focus-within:focus-within {
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-hover:hover {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-focus:focus {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-focus-within:focus-within {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-hover:hover {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-focus:focus {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-focus-within:focus-within {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black-hover:hover {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black-focus:focus {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black-focus-within:focus-within {
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white-hover:hover {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white-focus:focus {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white-focus-within:focus-within {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-hover:hover {
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-focus:focus {
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-focus-within:focus-within {
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-10-hover:hover {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-10-focus:focus {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-20-hover:hover {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-20-focus:focus {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-30 {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-30-hover:hover {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-30-focus:focus {
  --bs-bg-opacity: 0.3;
}

.bg-opacity-40 {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-40-hover:hover {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-40-focus:focus {
  --bs-bg-opacity: 0.4;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-50-hover:hover {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-50-focus:focus {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-60-hover:hover {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-60-focus:focus {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-70-hover:hover {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-70-focus:focus {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-80-hover:hover {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-80-focus:focus {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-90-hover:hover {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-90-focus:focus {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-opacity-100-hover:hover {
  --bs-bg-opacity: 1;
}

.bg-opacity-100-focus:focus {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.3rem !important;
}

.rounded-3 {
  border-radius: 0.75rem !important;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.rounded-5 {
  border-radius: 1.5rem !important;
}

.rounded-6 {
  border-radius: 3rem !important;
}

.rounded-7 {
  border-radius: 4rem !important;
}

.rounded-8 {
  border-radius: 5rem !important;
}

.rounded-9 {
  border-radius: 6rem !important;
}

.rounded-10 {
  border-radius: 7rem !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-card {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: 0.2rem !important;
  border-top-right-radius: 0.2rem !important;
}

.rounded-top-2 {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

.rounded-top-3 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-top-4 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-top-5 {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.rounded-top-6 {
  border-top-left-radius: 3rem !important;
  border-top-right-radius: 3rem !important;
}

.rounded-top-7 {
  border-top-left-radius: 4rem !important;
  border-top-right-radius: 4rem !important;
}

.rounded-top-8 {
  border-top-left-radius: 5rem !important;
  border-top-right-radius: 5rem !important;
}

.rounded-top-9 {
  border-top-left-radius: 6rem !important;
  border-top-right-radius: 6rem !important;
}

.rounded-top-10 {
  border-top-left-radius: 7rem !important;
  border-top-right-radius: 7rem !important;
}

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-card {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: 0.2rem !important;
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-end-2 {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.rounded-end-3 {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-end-4 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-end-5 {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-end-6 {
  border-top-right-radius: 3rem !important;
  border-bottom-right-radius: 3rem !important;
}

.rounded-end-7 {
  border-top-right-radius: 4rem !important;
  border-bottom-right-radius: 4rem !important;
}

.rounded-end-8 {
  border-top-right-radius: 5rem !important;
  border-bottom-right-radius: 5rem !important;
}

.rounded-end-9 {
  border-top-right-radius: 6rem !important;
  border-bottom-right-radius: 6rem !important;
}

.rounded-end-10 {
  border-top-right-radius: 7rem !important;
  border-bottom-right-radius: 7rem !important;
}

.rounded-end-pill {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-card {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-bottom-6 {
  border-bottom-right-radius: 3rem !important;
  border-bottom-left-radius: 3rem !important;
}

.rounded-bottom-7 {
  border-bottom-right-radius: 4rem !important;
  border-bottom-left-radius: 4rem !important;
}

.rounded-bottom-8 {
  border-bottom-right-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}

.rounded-bottom-9 {
  border-bottom-right-radius: 6rem !important;
  border-bottom-left-radius: 6rem !important;
}

.rounded-bottom-10 {
  border-bottom-right-radius: 7rem !important;
  border-bottom-left-radius: 7rem !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-card {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
}

.rounded-start-2 {
  border-bottom-left-radius: 0.3rem !important;
  border-top-left-radius: 0.3rem !important;
}

.rounded-start-3 {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}

.rounded-start-4 {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.rounded-start-5 {
  border-bottom-left-radius: 1.5rem !important;
  border-top-left-radius: 1.5rem !important;
}

.rounded-start-6 {
  border-bottom-left-radius: 3rem !important;
  border-top-left-radius: 3rem !important;
}

.rounded-start-7 {
  border-bottom-left-radius: 4rem !important;
  border-top-left-radius: 4rem !important;
}

.rounded-start-8 {
  border-bottom-left-radius: 5rem !important;
  border-top-left-radius: 5rem !important;
}

.rounded-start-9 {
  border-bottom-left-radius: 6rem !important;
  border-top-left-radius: 6rem !important;
}

.rounded-start-10 {
  border-bottom-left-radius: 7rem !important;
  border-top-left-radius: 7rem !important;
}

.rounded-start-pill {
  border-bottom-left-radius: 50rem !important;
  border-top-left-radius: 50rem !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-card {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.overlap-10 {
  z-index: 10 !important;
}

.overlap-20 {
  z-index: 20 !important;
}

.overlap-30 {
  z-index: 30 !important;
}

.overlap-40 {
  z-index: 40 !important;
}

.overlap-50 {
  z-index: 50 !important;
}

.overlap-100 {
  z-index: 100 !important;
}

.overlap-200 {
  z-index: 200 !important;
}

.overlap-300 {
  z-index: 300 !important;
}

.overlap-400 {
  z-index: 400 !important;
}

.overlap-500 {
  z-index: 500 !important;
}

.overlap-1000 {
  z-index: 1000 !important;
}

.overlap-auto {
  z-index: auto !important;
}

.rounded-top-start {
  border-top-left-radius: 0.25rem !important;
}

.rounded-top-start-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-start-1 {
  border-top-left-radius: 0.2rem !important;
}

.rounded-top-start-2 {
  border-top-left-radius: 0.3rem !important;
}

.rounded-top-start-3 {
  border-top-left-radius: 0.75rem !important;
}

.rounded-top-start-4 {
  border-top-left-radius: 1rem !important;
}

.rounded-top-start-5 {
  border-top-left-radius: 1.5rem !important;
}

.rounded-top-start-6 {
  border-top-left-radius: 3rem !important;
}

.rounded-top-start-7 {
  border-top-left-radius: 4rem !important;
}

.rounded-top-start-8 {
  border-top-left-radius: 5rem !important;
}

.rounded-top-start-9 {
  border-top-left-radius: 6rem !important;
}

.rounded-top-start-10 {
  border-top-left-radius: 7rem !important;
}

.rounded-top-start-pill {
  border-top-left-radius: 50rem !important;
}

.rounded-top-start-circle {
  border-top-left-radius: 50% !important;
}

.rounded-top-start-card {
  border-top-left-radius: 0.25rem !important;
}

.rounded-top-end {
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-end-0 {
  border-top-right-radius: 0 !important;
}

.rounded-top-end-1 {
  border-top-right-radius: 0.2rem !important;
}

.rounded-top-end-2 {
  border-top-right-radius: 0.3rem !important;
}

.rounded-top-end-3 {
  border-top-right-radius: 0.75rem !important;
}

.rounded-top-end-4 {
  border-top-right-radius: 1rem !important;
}

.rounded-top-end-5 {
  border-top-right-radius: 1.5rem !important;
}

.rounded-top-end-6 {
  border-top-right-radius: 3rem !important;
}

.rounded-top-end-7 {
  border-top-right-radius: 4rem !important;
}

.rounded-top-end-8 {
  border-top-right-radius: 5rem !important;
}

.rounded-top-end-9 {
  border-top-right-radius: 6rem !important;
}

.rounded-top-end-10 {
  border-top-right-radius: 7rem !important;
}

.rounded-top-end-pill {
  border-top-right-radius: 50rem !important;
}

.rounded-top-end-circle {
  border-top-right-radius: 50% !important;
}

.rounded-top-end-card {
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom-end {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom-end-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-end-1 {
  border-bottom-right-radius: 0.2rem !important;
}

.rounded-bottom-end-2 {
  border-bottom-right-radius: 0.3rem !important;
}

.rounded-bottom-end-3 {
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom-end-4 {
  border-bottom-right-radius: 1rem !important;
}

.rounded-bottom-end-5 {
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-bottom-end-6 {
  border-bottom-right-radius: 3rem !important;
}

.rounded-bottom-end-7 {
  border-bottom-right-radius: 4rem !important;
}

.rounded-bottom-end-8 {
  border-bottom-right-radius: 5rem !important;
}

.rounded-bottom-end-9 {
  border-bottom-right-radius: 6rem !important;
}

.rounded-bottom-end-10 {
  border-bottom-right-radius: 7rem !important;
}

.rounded-bottom-end-pill {
  border-bottom-right-radius: 50rem !important;
}

.rounded-bottom-end-circle {
  border-bottom-right-radius: 50% !important;
}

.rounded-bottom-end-card {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom-start {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-start-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-start-1 {
  border-bottom-left-radius: 0.2rem !important;
}

.rounded-bottom-start-2 {
  border-bottom-left-radius: 0.3rem !important;
}

.rounded-bottom-start-3 {
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-bottom-start-4 {
  border-bottom-left-radius: 1rem !important;
}

.rounded-bottom-start-5 {
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-bottom-start-6 {
  border-bottom-left-radius: 3rem !important;
}

.rounded-bottom-start-7 {
  border-bottom-left-radius: 4rem !important;
}

.rounded-bottom-start-8 {
  border-bottom-left-radius: 5rem !important;
}

.rounded-bottom-start-9 {
  border-bottom-left-radius: 6rem !important;
}

.rounded-bottom-start-10 {
  border-bottom-left-radius: 7rem !important;
}

.rounded-bottom-start-pill {
  border-bottom-left-radius: 50rem !important;
}

.rounded-bottom-start-circle {
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-start-card {
  border-bottom-left-radius: 0.25rem !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-groove {
  border-style: groove !important;
}

.border-none {
  border-style: none !important;
}

.border-auto {
  border-style: auto !important;
}

.border-pointer {
  border-style: pointer !important;
}

.border-wait {
  border-style: wait !important;
}

.border-text {
  border-style: text !important;
}

.ls-tighter {
  letter-spacing: -0.05em !important;
}

.ls-tight {
  letter-spacing: -0.025em !important;
}

.ls-normal {
  letter-spacing: 0 !important;
}

.ls-wide {
  letter-spacing: 0.025em !important;
}

.ls-wider {
  letter-spacing: 0.05em !important;
}

.ls-widest {
  letter-spacing: 0.1em !important;
}

.text-transparent {
  color: transparent !important;
}

.text-transparent-hover:hover {
  color: transparent !important;
}

.text-transparent-focus:focus {
  color: transparent !important;
}

.text-current {
  color: currentColor !important;
}

.text-current-hover:hover {
  color: currentColor !important;
}

.text-current-focus:focus {
  color: currentColor !important;
}

.text-reset {
  color: inherit !important;
}

.text-reset-hover:hover {
  color: inherit !important;
}

.text-reset-focus:focus {
  color: inherit !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-transparent-hover:hover {
  background-color: transparent !important;
}

.bg-transparent-focus:focus {
  background-color: transparent !important;
}

.bg-transparent-focus-within:focus-within {
  background-color: transparent !important;
}

.bg-current {
  background-color: currentColor !important;
}

.bg-current-hover:hover {
  background-color: currentColor !important;
}

.bg-current-focus:focus {
  background-color: currentColor !important;
}

.bg-current-focus-within:focus-within {
  background-color: currentColor !important;
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.bg-clip-border {
  background-clip: border-box !important;
}

.bg-clip-padding {
  background-clip: padding-box !important;
}

.bg-clip-content {
  background-clip: content-box !important;
}

.bg-clip-text {
  background-clip: text !important;
}

.shadow-soft-1 {
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-1-hover:hover {
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-1-focus:focus {
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-2 {
  box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-2-hover:hover {
  box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-2-focus:focus {
  box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-3 {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-3-hover:hover {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-3-focus:focus {
  box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-4 {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-4-hover:hover {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-4-focus:focus {
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-5 {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-5-hover:hover {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-5-focus:focus {
  box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-6 {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-6-hover:hover {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-6-focus:focus {
  box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
}

.shadow-soft-none {
  box-shadow: none !important;
}

.shadow-soft-none-hover:hover {
  box-shadow: none !important;
}

.shadow-soft-none-focus:focus {
  box-shadow: none !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.place-content-start {
  place-content: flex-start !important;
}

.place-content-end {
  place-content: flex-end !important;
}

.place-content-center {
  place-content: center !important;
}

.place-content-between {
  place-content: space-between !important;
}

.place-content-around {
  place-content: space-around !important;
}

.place-content-evenly {
  place-content: space-evenly !important;
}

.place-content-stretch {
  place-content: stretch !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity {
  transition-property: opacity !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
}

.transition-transform {
  transition-property: transform !important;
}

.ease-base {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.duration-base {
  transition-duration: 150ms !important;
}

.duration-75 {
  transition-duration: 75ms !important;
}

.duration-100 {
  transition-duration: 100ms !important;
}

.duration-150 {
  transition-duration: 150ms !important;
}

.duration-200 {
  transition-duration: 200ms !important;
}

.duration-300 {
  transition-duration: 300ms !important;
}

.duration-500 {
  transition-duration: 500ms !important;
}

.duration-700 {
  transition-duration: 700ms !important;
}

.duration-1000 {
  transition-duration: 1000ms !important;
}

.delay-75 {
  transition-delay: 75ms !important;
}

.delay-100 {
  transition-delay: 100ms !important;
}

.delay-150 {
  transition-delay: 150ms !important;
}

.delay-200 {
  transition-delay: 200ms !important;
}

.delay-300 {
  transition-delay: 300ms !important;
}

.delay-500 {
  transition-delay: 500ms !important;
}

.delay-700 {
  transition-delay: 700ms !important;
}

.delay-1000 {
  transition-delay: 1000ms !important;
}

.animation-none {
  animation: none !important;
}

.animation-spin {
  animation: spin 1s linear infinite !important;
}

.animation-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.animation-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.animation-bounce {
  animation: bounce 1s infinite !important;
}

.animation-slide-left {
  animation: slideLeft 3s infinite !important;
}

.animation-slide-right {
  animation: slideRight 3s infinite !important;
}

.animation-move-slow-left {
  animation: slideLeft 15s linear infinite !important;
}

.animation-move-slow-right {
  animation: slideRight 15s linear infinite !important;
}

.blur-5 {
  --bs-blur: 5px !important;
}

.blur-10 {
  --bs-blur: 10px !important;
}

.blur-25 {
  --bs-blur: 25px !important;
}

.blur-50 {
  --bs-blur: 50px !important;
}

.blur-75 {
  --bs-blur: 75px !important;
}

.blur-100 {
  --bs-blur: 100px !important;
}

.blur-125 {
  --bs-blur: 125px !important;
}

.blur-150 {
  --bs-blur: 150px !important;
}

.blur-200 {
  --bs-blur: 200px !important;
}

.transform-none {
  transform: none !important;
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top left !important;
}

.scale-0 {
  --bs-scale-x: 0 !important;
  --bs-scale-y: 0 !important;
}

.scale-0-hover:hover {
  --bs-scale-x: 0 !important;
  --bs-scale-y: 0 !important;
}

.scale-0-focus:focus {
  --bs-scale-x: 0 !important;
  --bs-scale-y: 0 !important;
}

.scale-25 {
  --bs-scale-x: 0.25 !important;
  --bs-scale-y: 0.25 !important;
}

.scale-25-hover:hover {
  --bs-scale-x: 0.25 !important;
  --bs-scale-y: 0.25 !important;
}

.scale-25-focus:focus {
  --bs-scale-x: 0.25 !important;
  --bs-scale-y: 0.25 !important;
}

.scale-50 {
  --bs-scale-x: 0.5 !important;
  --bs-scale-y: 0.5 !important;
}

.scale-50-hover:hover {
  --bs-scale-x: 0.5 !important;
  --bs-scale-y: 0.5 !important;
}

.scale-50-focus:focus {
  --bs-scale-x: 0.5 !important;
  --bs-scale-y: 0.5 !important;
}

.scale-75 {
  --bs-scale-x: 0.75 !important;
  --bs-scale-y: 0.75 !important;
}

.scale-75-hover:hover {
  --bs-scale-x: 0.75 !important;
  --bs-scale-y: 0.75 !important;
}

.scale-75-focus:focus {
  --bs-scale-x: 0.75 !important;
  --bs-scale-y: 0.75 !important;
}

.scale-90 {
  --bs-scale-x: 0.9 !important;
  --bs-scale-y: 0.9 !important;
}

.scale-90-hover:hover {
  --bs-scale-x: 0.9 !important;
  --bs-scale-y: 0.9 !important;
}

.scale-90-focus:focus {
  --bs-scale-x: 0.9 !important;
  --bs-scale-y: 0.9 !important;
}

.scale-95 {
  --bs-scale-x: 0.95 !important;
  --bs-scale-y: 0.95 !important;
}

.scale-95-hover:hover {
  --bs-scale-x: 0.95 !important;
  --bs-scale-y: 0.95 !important;
}

.scale-95-focus:focus {
  --bs-scale-x: 0.95 !important;
  --bs-scale-y: 0.95 !important;
}

.scale-100 {
  --bs-scale-x: 1 !important;
  --bs-scale-y: 1 !important;
}

.scale-100-hover:hover {
  --bs-scale-x: 1 !important;
  --bs-scale-y: 1 !important;
}

.scale-100-focus:focus {
  --bs-scale-x: 1 !important;
  --bs-scale-y: 1 !important;
}

.scale-105 {
  --bs-scale-x: 1.05 !important;
  --bs-scale-y: 1.05 !important;
}

.scale-105-hover:hover {
  --bs-scale-x: 1.05 !important;
  --bs-scale-y: 1.05 !important;
}

.scale-105-focus:focus {
  --bs-scale-x: 1.05 !important;
  --bs-scale-y: 1.05 !important;
}

.scale-110 {
  --bs-scale-x: 1.1 !important;
  --bs-scale-y: 1.1 !important;
}

.scale-110-hover:hover {
  --bs-scale-x: 1.1 !important;
  --bs-scale-y: 1.1 !important;
}

.scale-110-focus:focus {
  --bs-scale-x: 1.1 !important;
  --bs-scale-y: 1.1 !important;
}

.scale-125 {
  --bs-scale-x: 1.25 !important;
  --bs-scale-y: 1.25 !important;
}

.scale-125-hover:hover {
  --bs-scale-x: 1.25 !important;
  --bs-scale-y: 1.25 !important;
}

.scale-125-focus:focus {
  --bs-scale-x: 1.25 !important;
  --bs-scale-y: 1.25 !important;
}

.scale-150 {
  --bs-scale-x: 1.5 !important;
  --bs-scale-y: 1.5 !important;
}

.scale-150-hover:hover {
  --bs-scale-x: 1.5 !important;
  --bs-scale-y: 1.5 !important;
}

.scale-150-focus:focus {
  --bs-scale-x: 1.5 !important;
  --bs-scale-y: 1.5 !important;
}

.scale-200 {
  --bs-scale-x: 2 !important;
  --bs-scale-y: 2 !important;
}

.scale-200-hover:hover {
  --bs-scale-x: 2 !important;
  --bs-scale-y: 2 !important;
}

.scale-200-focus:focus {
  --bs-scale-x: 2 !important;
  --bs-scale-y: 2 !important;
}

.scale-y-0 {
  --bs--scale-y: 0 !important;
}

.scale-y-0-hover:hover {
  --bs--scale-y: 0 !important;
}

.scale-y-0-focus:focus {
  --bs--scale-y: 0 !important;
}

.scale-y-25 {
  --bs--scale-y: 0.25 !important;
}

.scale-y-25-hover:hover {
  --bs--scale-y: 0.25 !important;
}

.scale-y-25-focus:focus {
  --bs--scale-y: 0.25 !important;
}

.scale-y-50 {
  --bs--scale-y: 0.5 !important;
}

.scale-y-50-hover:hover {
  --bs--scale-y: 0.5 !important;
}

.scale-y-50-focus:focus {
  --bs--scale-y: 0.5 !important;
}

.scale-y-75 {
  --bs--scale-y: 0.75 !important;
}

.scale-y-75-hover:hover {
  --bs--scale-y: 0.75 !important;
}

.scale-y-75-focus:focus {
  --bs--scale-y: 0.75 !important;
}

.scale-y-90 {
  --bs--scale-y: 0.9 !important;
}

.scale-y-90-hover:hover {
  --bs--scale-y: 0.9 !important;
}

.scale-y-90-focus:focus {
  --bs--scale-y: 0.9 !important;
}

.scale-y-95 {
  --bs--scale-y: 0.95 !important;
}

.scale-y-95-hover:hover {
  --bs--scale-y: 0.95 !important;
}

.scale-y-95-focus:focus {
  --bs--scale-y: 0.95 !important;
}

.scale-y-100 {
  --bs--scale-y: 1 !important;
}

.scale-y-100-hover:hover {
  --bs--scale-y: 1 !important;
}

.scale-y-100-focus:focus {
  --bs--scale-y: 1 !important;
}

.scale-y-105 {
  --bs--scale-y: 1.05 !important;
}

.scale-y-105-hover:hover {
  --bs--scale-y: 1.05 !important;
}

.scale-y-105-focus:focus {
  --bs--scale-y: 1.05 !important;
}

.scale-y-110 {
  --bs--scale-y: 1.1 !important;
}

.scale-y-110-hover:hover {
  --bs--scale-y: 1.1 !important;
}

.scale-y-110-focus:focus {
  --bs--scale-y: 1.1 !important;
}

.scale-y-125 {
  --bs--scale-y: 1.25 !important;
}

.scale-y-125-hover:hover {
  --bs--scale-y: 1.25 !important;
}

.scale-y-125-focus:focus {
  --bs--scale-y: 1.25 !important;
}

.scale-y-150 {
  --bs--scale-y: 1.5 !important;
}

.scale-y-150-hover:hover {
  --bs--scale-y: 1.5 !important;
}

.scale-y-150-focus:focus {
  --bs--scale-y: 1.5 !important;
}

.scale-y-200 {
  --bs--scale-y: 2 !important;
}

.scale-y-200-hover:hover {
  --bs--scale-y: 2 !important;
}

.scale-y-200-focus:focus {
  --bs--scale-y: 2 !important;
}

.scale-x-0 {
  --bs--scale-x: 0 !important;
}

.scale-x-0-hover:hover {
  --bs--scale-x: 0 !important;
}

.scale-x-0-focus:focus {
  --bs--scale-x: 0 !important;
}

.scale-x-25 {
  --bs--scale-x: 0.25 !important;
}

.scale-x-25-hover:hover {
  --bs--scale-x: 0.25 !important;
}

.scale-x-25-focus:focus {
  --bs--scale-x: 0.25 !important;
}

.scale-x-50 {
  --bs--scale-x: 0.5 !important;
}

.scale-x-50-hover:hover {
  --bs--scale-x: 0.5 !important;
}

.scale-x-50-focus:focus {
  --bs--scale-x: 0.5 !important;
}

.scale-x-75 {
  --bs--scale-x: 0.75 !important;
}

.scale-x-75-hover:hover {
  --bs--scale-x: 0.75 !important;
}

.scale-x-75-focus:focus {
  --bs--scale-x: 0.75 !important;
}

.scale-x-90 {
  --bs--scale-x: 0.9 !important;
}

.scale-x-90-hover:hover {
  --bs--scale-x: 0.9 !important;
}

.scale-x-90-focus:focus {
  --bs--scale-x: 0.9 !important;
}

.scale-x-95 {
  --bs--scale-x: 0.95 !important;
}

.scale-x-95-hover:hover {
  --bs--scale-x: 0.95 !important;
}

.scale-x-95-focus:focus {
  --bs--scale-x: 0.95 !important;
}

.scale-x-100 {
  --bs--scale-x: 1 !important;
}

.scale-x-100-hover:hover {
  --bs--scale-x: 1 !important;
}

.scale-x-100-focus:focus {
  --bs--scale-x: 1 !important;
}

.scale-x-105 {
  --bs--scale-x: 1.05 !important;
}

.scale-x-105-hover:hover {
  --bs--scale-x: 1.05 !important;
}

.scale-x-105-focus:focus {
  --bs--scale-x: 1.05 !important;
}

.scale-x-110 {
  --bs--scale-x: 1.1 !important;
}

.scale-x-110-hover:hover {
  --bs--scale-x: 1.1 !important;
}

.scale-x-110-focus:focus {
  --bs--scale-x: 1.1 !important;
}

.scale-x-125 {
  --bs--scale-x: 1.25 !important;
}

.scale-x-125-hover:hover {
  --bs--scale-x: 1.25 !important;
}

.scale-x-125-focus:focus {
  --bs--scale-x: 1.25 !important;
}

.scale-x-150 {
  --bs--scale-x: 1.5 !important;
}

.scale-x-150-hover:hover {
  --bs--scale-x: 1.5 !important;
}

.scale-x-150-focus:focus {
  --bs--scale-x: 1.5 !important;
}

.scale-x-200 {
  --bs--scale-x: 2 !important;
}

.scale-x-200-hover:hover {
  --bs--scale-x: 2 !important;
}

.scale-x-200-focus:focus {
  --bs--scale-x: 2 !important;
}

.rotate-0 {
  --bs-rotate: 0deg !important;
}

.rotate-0-hover:hover {
  --bs-rotate: 0deg !important;
}

.rotate-0-focus:focus {
  --bs-rotate: 0deg !important;
}

.rotate-1 {
  --bs-rotate: 1deg !important;
}

.rotate-1-hover:hover {
  --bs-rotate: 1deg !important;
}

.rotate-1-focus:focus {
  --bs-rotate: 1deg !important;
}

.rotate-2 {
  --bs-rotate: 2deg !important;
}

.rotate-2-hover:hover {
  --bs-rotate: 2deg !important;
}

.rotate-2-focus:focus {
  --bs-rotate: 2deg !important;
}

.rotate-3 {
  --bs-rotate: 3deg !important;
}

.rotate-3-hover:hover {
  --bs-rotate: 3deg !important;
}

.rotate-3-focus:focus {
  --bs-rotate: 3deg !important;
}

.rotate-6 {
  --bs-rotate: 6deg !important;
}

.rotate-6-hover:hover {
  --bs-rotate: 6deg !important;
}

.rotate-6-focus:focus {
  --bs-rotate: 6deg !important;
}

.rotate-12 {
  --bs-rotate: 12deg !important;
}

.rotate-12-hover:hover {
  --bs-rotate: 12deg !important;
}

.rotate-12-focus:focus {
  --bs-rotate: 12deg !important;
}

.rotate-30 {
  --bs-rotate: 30deg !important;
}

.rotate-30-hover:hover {
  --bs-rotate: 30deg !important;
}

.rotate-30-focus:focus {
  --bs-rotate: 30deg !important;
}

.rotate-45 {
  --bs-rotate: 45deg !important;
}

.rotate-45-hover:hover {
  --bs-rotate: 45deg !important;
}

.rotate-45-focus:focus {
  --bs-rotate: 45deg !important;
}

.rotate-90 {
  --bs-rotate: 90deg !important;
}

.rotate-90-hover:hover {
  --bs-rotate: 90deg !important;
}

.rotate-90-focus:focus {
  --bs-rotate: 90deg !important;
}

.rotate-180 {
  --bs-rotate: 180deg !important;
}

.rotate-180-hover:hover {
  --bs-rotate: 180deg !important;
}

.rotate-180-focus:focus {
  --bs-rotate: 180deg !important;
}

.rotate-n1 {
  --bs-rotate: -1deg !important;
}

.rotate-n1-hover:hover {
  --bs-rotate: -1deg !important;
}

.rotate-n1-focus:focus {
  --bs-rotate: -1deg !important;
}

.rotate-n2 {
  --bs-rotate: -2deg !important;
}

.rotate-n2-hover:hover {
  --bs-rotate: -2deg !important;
}

.rotate-n2-focus:focus {
  --bs-rotate: -2deg !important;
}

.rotate-n3 {
  --bs-rotate: -3deg !important;
}

.rotate-n3-hover:hover {
  --bs-rotate: -3deg !important;
}

.rotate-n3-focus:focus {
  --bs-rotate: -3deg !important;
}

.rotate-n6 {
  --bs-rotate: -6deg !important;
}

.rotate-n6-hover:hover {
  --bs-rotate: -6deg !important;
}

.rotate-n6-focus:focus {
  --bs-rotate: -6deg !important;
}

.rotate-n12 {
  --bs-rotate: -12deg !important;
}

.rotate-n12-hover:hover {
  --bs-rotate: -12deg !important;
}

.rotate-n12-focus:focus {
  --bs-rotate: -12deg !important;
}

.rotate-n30 {
  --bs-rotate: -30deg !important;
}

.rotate-n30-hover:hover {
  --bs-rotate: -30deg !important;
}

.rotate-n30-focus:focus {
  --bs-rotate: -30deg !important;
}

.rotate-n45 {
  --bs-rotate: -45deg !important;
}

.rotate-n45-hover:hover {
  --bs-rotate: -45deg !important;
}

.rotate-n45-focus:focus {
  --bs-rotate: -45deg !important;
}

.rotate-n90 {
  --bs-rotate: -90deg !important;
}

.rotate-n90-hover:hover {
  --bs-rotate: -90deg !important;
}

.rotate-n90-focus:focus {
  --bs-rotate: -90deg !important;
}

.rotate-n180 {
  --bs-rotate: -180deg !important;
}

.rotate-n180-hover:hover {
  --bs-rotate: -180deg !important;
}

.rotate-n180-focus:focus {
  --bs-rotate: -180deg !important;
}

.rotate-y-0 {
  --bs-rotate-y: 0deg !important;
}

.rotate-y-0-hover:hover {
  --bs-rotate-y: 0deg !important;
}

.rotate-y-0-focus:focus {
  --bs-rotate-y: 0deg !important;
}

.rotate-y-1 {
  --bs-rotate-y: 1deg !important;
}

.rotate-y-1-hover:hover {
  --bs-rotate-y: 1deg !important;
}

.rotate-y-1-focus:focus {
  --bs-rotate-y: 1deg !important;
}

.rotate-y-2 {
  --bs-rotate-y: 2deg !important;
}

.rotate-y-2-hover:hover {
  --bs-rotate-y: 2deg !important;
}

.rotate-y-2-focus:focus {
  --bs-rotate-y: 2deg !important;
}

.rotate-y-3 {
  --bs-rotate-y: 3deg !important;
}

.rotate-y-3-hover:hover {
  --bs-rotate-y: 3deg !important;
}

.rotate-y-3-focus:focus {
  --bs-rotate-y: 3deg !important;
}

.rotate-y-6 {
  --bs-rotate-y: 6deg !important;
}

.rotate-y-6-hover:hover {
  --bs-rotate-y: 6deg !important;
}

.rotate-y-6-focus:focus {
  --bs-rotate-y: 6deg !important;
}

.rotate-y-12 {
  --bs-rotate-y: 12deg !important;
}

.rotate-y-12-hover:hover {
  --bs-rotate-y: 12deg !important;
}

.rotate-y-12-focus:focus {
  --bs-rotate-y: 12deg !important;
}

.rotate-y-30 {
  --bs-rotate-y: 30deg !important;
}

.rotate-y-30-hover:hover {
  --bs-rotate-y: 30deg !important;
}

.rotate-y-30-focus:focus {
  --bs-rotate-y: 30deg !important;
}

.rotate-y-45 {
  --bs-rotate-y: 45deg !important;
}

.rotate-y-45-hover:hover {
  --bs-rotate-y: 45deg !important;
}

.rotate-y-45-focus:focus {
  --bs-rotate-y: 45deg !important;
}

.rotate-y-90 {
  --bs-rotate-y: 90deg !important;
}

.rotate-y-90-hover:hover {
  --bs-rotate-y: 90deg !important;
}

.rotate-y-90-focus:focus {
  --bs-rotate-y: 90deg !important;
}

.rotate-y-180 {
  --bs-rotate-y: 180deg !important;
}

.rotate-y-180-hover:hover {
  --bs-rotate-y: 180deg !important;
}

.rotate-y-180-focus:focus {
  --bs-rotate-y: 180deg !important;
}

.rotate-y-n1 {
  --bs-rotate-y: -1deg !important;
}

.rotate-y-n1-hover:hover {
  --bs-rotate-y: -1deg !important;
}

.rotate-y-n1-focus:focus {
  --bs-rotate-y: -1deg !important;
}

.rotate-y-n2 {
  --bs-rotate-y: -2deg !important;
}

.rotate-y-n2-hover:hover {
  --bs-rotate-y: -2deg !important;
}

.rotate-y-n2-focus:focus {
  --bs-rotate-y: -2deg !important;
}

.rotate-y-n3 {
  --bs-rotate-y: -3deg !important;
}

.rotate-y-n3-hover:hover {
  --bs-rotate-y: -3deg !important;
}

.rotate-y-n3-focus:focus {
  --bs-rotate-y: -3deg !important;
}

.rotate-y-n6 {
  --bs-rotate-y: -6deg !important;
}

.rotate-y-n6-hover:hover {
  --bs-rotate-y: -6deg !important;
}

.rotate-y-n6-focus:focus {
  --bs-rotate-y: -6deg !important;
}

.rotate-y-n12 {
  --bs-rotate-y: -12deg !important;
}

.rotate-y-n12-hover:hover {
  --bs-rotate-y: -12deg !important;
}

.rotate-y-n12-focus:focus {
  --bs-rotate-y: -12deg !important;
}

.rotate-y-n30 {
  --bs-rotate-y: -30deg !important;
}

.rotate-y-n30-hover:hover {
  --bs-rotate-y: -30deg !important;
}

.rotate-y-n30-focus:focus {
  --bs-rotate-y: -30deg !important;
}

.rotate-y-n45 {
  --bs-rotate-y: -45deg !important;
}

.rotate-y-n45-hover:hover {
  --bs-rotate-y: -45deg !important;
}

.rotate-y-n45-focus:focus {
  --bs-rotate-y: -45deg !important;
}

.rotate-y-n90 {
  --bs-rotate-y: -90deg !important;
}

.rotate-y-n90-hover:hover {
  --bs-rotate-y: -90deg !important;
}

.rotate-y-n90-focus:focus {
  --bs-rotate-y: -90deg !important;
}

.rotate-y-n180 {
  --bs-rotate-y: -180deg !important;
}

.rotate-y-n180-hover:hover {
  --bs-rotate-y: -180deg !important;
}

.rotate-y-n180-focus:focus {
  --bs-rotate-y: -180deg !important;
}

.rotate-x-0 {
  --bs-rotate-x: 0deg !important;
}

.rotate-x-0-hover:hover {
  --bs-rotate-x: 0deg !important;
}

.rotate-x-0-focus:focus {
  --bs-rotate-x: 0deg !important;
}

.rotate-x-1 {
  --bs-rotate-x: 1deg !important;
}

.rotate-x-1-hover:hover {
  --bs-rotate-x: 1deg !important;
}

.rotate-x-1-focus:focus {
  --bs-rotate-x: 1deg !important;
}

.rotate-x-2 {
  --bs-rotate-x: 2deg !important;
}

.rotate-x-2-hover:hover {
  --bs-rotate-x: 2deg !important;
}

.rotate-x-2-focus:focus {
  --bs-rotate-x: 2deg !important;
}

.rotate-x-3 {
  --bs-rotate-x: 3deg !important;
}

.rotate-x-3-hover:hover {
  --bs-rotate-x: 3deg !important;
}

.rotate-x-3-focus:focus {
  --bs-rotate-x: 3deg !important;
}

.rotate-x-6 {
  --bs-rotate-x: 6deg !important;
}

.rotate-x-6-hover:hover {
  --bs-rotate-x: 6deg !important;
}

.rotate-x-6-focus:focus {
  --bs-rotate-x: 6deg !important;
}

.rotate-x-12 {
  --bs-rotate-x: 12deg !important;
}

.rotate-x-12-hover:hover {
  --bs-rotate-x: 12deg !important;
}

.rotate-x-12-focus:focus {
  --bs-rotate-x: 12deg !important;
}

.rotate-x-30 {
  --bs-rotate-x: 30deg !important;
}

.rotate-x-30-hover:hover {
  --bs-rotate-x: 30deg !important;
}

.rotate-x-30-focus:focus {
  --bs-rotate-x: 30deg !important;
}

.rotate-x-45 {
  --bs-rotate-x: 45deg !important;
}

.rotate-x-45-hover:hover {
  --bs-rotate-x: 45deg !important;
}

.rotate-x-45-focus:focus {
  --bs-rotate-x: 45deg !important;
}

.rotate-x-90 {
  --bs-rotate-x: 90deg !important;
}

.rotate-x-90-hover:hover {
  --bs-rotate-x: 90deg !important;
}

.rotate-x-90-focus:focus {
  --bs-rotate-x: 90deg !important;
}

.rotate-x-180 {
  --bs-rotate-x: 180deg !important;
}

.rotate-x-180-hover:hover {
  --bs-rotate-x: 180deg !important;
}

.rotate-x-180-focus:focus {
  --bs-rotate-x: 180deg !important;
}

.rotate-x-n1 {
  --bs-rotate-x: -1deg !important;
}

.rotate-x-n1-hover:hover {
  --bs-rotate-x: -1deg !important;
}

.rotate-x-n1-focus:focus {
  --bs-rotate-x: -1deg !important;
}

.rotate-x-n2 {
  --bs-rotate-x: -2deg !important;
}

.rotate-x-n2-hover:hover {
  --bs-rotate-x: -2deg !important;
}

.rotate-x-n2-focus:focus {
  --bs-rotate-x: -2deg !important;
}

.rotate-x-n3 {
  --bs-rotate-x: -3deg !important;
}

.rotate-x-n3-hover:hover {
  --bs-rotate-x: -3deg !important;
}

.rotate-x-n3-focus:focus {
  --bs-rotate-x: -3deg !important;
}

.rotate-x-n6 {
  --bs-rotate-x: -6deg !important;
}

.rotate-x-n6-hover:hover {
  --bs-rotate-x: -6deg !important;
}

.rotate-x-n6-focus:focus {
  --bs-rotate-x: -6deg !important;
}

.rotate-x-n12 {
  --bs-rotate-x: -12deg !important;
}

.rotate-x-n12-hover:hover {
  --bs-rotate-x: -12deg !important;
}

.rotate-x-n12-focus:focus {
  --bs-rotate-x: -12deg !important;
}

.rotate-x-n30 {
  --bs-rotate-x: -30deg !important;
}

.rotate-x-n30-hover:hover {
  --bs-rotate-x: -30deg !important;
}

.rotate-x-n30-focus:focus {
  --bs-rotate-x: -30deg !important;
}

.rotate-x-n45 {
  --bs-rotate-x: -45deg !important;
}

.rotate-x-n45-hover:hover {
  --bs-rotate-x: -45deg !important;
}

.rotate-x-n45-focus:focus {
  --bs-rotate-x: -45deg !important;
}

.rotate-x-n90 {
  --bs-rotate-x: -90deg !important;
}

.rotate-x-n90-hover:hover {
  --bs-rotate-x: -90deg !important;
}

.rotate-x-n90-focus:focus {
  --bs-rotate-x: -90deg !important;
}

.rotate-x-n180 {
  --bs-rotate-x: -180deg !important;
}

.rotate-x-n180-hover:hover {
  --bs-rotate-x: -180deg !important;
}

.rotate-x-n180-focus:focus {
  --bs-rotate-x: -180deg !important;
}

.perspective-100 {
  --bs-perspective: 100px !important;
}

.perspective-100-hover:hover {
  --bs-perspective: 100px !important;
}

.perspective-100-focus:focus {
  --bs-perspective: 100px !important;
}

.perspective-200 {
  --bs-perspective: 200px !important;
}

.perspective-200-hover:hover {
  --bs-perspective: 200px !important;
}

.perspective-200-focus:focus {
  --bs-perspective: 200px !important;
}

.perspective-300 {
  --bs-perspective: 300px !important;
}

.perspective-300-hover:hover {
  --bs-perspective: 300px !important;
}

.perspective-300-focus:focus {
  --bs-perspective: 300px !important;
}

.perspective-400 {
  --bs-perspective: 400px !important;
}

.perspective-400-hover:hover {
  --bs-perspective: 400px !important;
}

.perspective-400-focus:focus {
  --bs-perspective: 400px !important;
}

.perspective-500 {
  --bs-perspective: 500px !important;
}

.perspective-500-hover:hover {
  --bs-perspective: 500px !important;
}

.perspective-500-focus:focus {
  --bs-perspective: 500px !important;
}

.perspective-600 {
  --bs-perspective: 600px !important;
}

.perspective-600-hover:hover {
  --bs-perspective: 600px !important;
}

.perspective-600-focus:focus {
  --bs-perspective: 600px !important;
}

.perspective-700 {
  --bs-perspective: 700px !important;
}

.perspective-700-hover:hover {
  --bs-perspective: 700px !important;
}

.perspective-700-focus:focus {
  --bs-perspective: 700px !important;
}

.perspective-800 {
  --bs-perspective: 800px !important;
}

.perspective-800-hover:hover {
  --bs-perspective: 800px !important;
}

.perspective-800-focus:focus {
  --bs-perspective: 800px !important;
}

.perspective-1000 {
  --bs-perspective: 1000px !important;
}

.perspective-1000-hover:hover {
  --bs-perspective: 1000px !important;
}

.perspective-1000-focus:focus {
  --bs-perspective: 1000px !important;
}

.perspective-1250 {
  --bs-perspective: 1250px !important;
}

.perspective-1250-hover:hover {
  --bs-perspective: 1250px !important;
}

.perspective-1250-focus:focus {
  --bs-perspective: 1250px !important;
}

.perspective-1500 {
  --bs-perspective: 1500px !important;
}

.perspective-1500-hover:hover {
  --bs-perspective: 1500px !important;
}

.perspective-1500-focus:focus {
  --bs-perspective: 1500px !important;
}

.translate-y-0 {
  --bs-translate-y: 0 !important;
}

.translate-y-0-hover:hover {
  --bs-translate-y: 0 !important;
}

.translate-y-0-focus:focus {
  --bs-translate-y: 0 !important;
}

.translate-y-1 {
  --bs-translate-y: 0.25rem !important;
}

.translate-y-1-hover:hover {
  --bs-translate-y: 0.25rem !important;
}

.translate-y-1-focus:focus {
  --bs-translate-y: 0.25rem !important;
}

.translate-y-2 {
  --bs-translate-y: 0.5rem !important;
}

.translate-y-2-hover:hover {
  --bs-translate-y: 0.5rem !important;
}

.translate-y-2-focus:focus {
  --bs-translate-y: 0.5rem !important;
}

.translate-y-3 {
  --bs-translate-y: 0.75rem !important;
}

.translate-y-3-hover:hover {
  --bs-translate-y: 0.75rem !important;
}

.translate-y-3-focus:focus {
  --bs-translate-y: 0.75rem !important;
}

.translate-y-4 {
  --bs-translate-y: 1rem !important;
}

.translate-y-4-hover:hover {
  --bs-translate-y: 1rem !important;
}

.translate-y-4-focus:focus {
  --bs-translate-y: 1rem !important;
}

.translate-y-5 {
  --bs-translate-y: 1.25rem !important;
}

.translate-y-5-hover:hover {
  --bs-translate-y: 1.25rem !important;
}

.translate-y-5-focus:focus {
  --bs-translate-y: 1.25rem !important;
}

.translate-y-6 {
  --bs-translate-y: 1.5rem !important;
}

.translate-y-6-hover:hover {
  --bs-translate-y: 1.5rem !important;
}

.translate-y-6-focus:focus {
  --bs-translate-y: 1.5rem !important;
}

.translate-y-7 {
  --bs-translate-y: 1.75rem !important;
}

.translate-y-7-hover:hover {
  --bs-translate-y: 1.75rem !important;
}

.translate-y-7-focus:focus {
  --bs-translate-y: 1.75rem !important;
}

.translate-y-8 {
  --bs-translate-y: 2rem !important;
}

.translate-y-8-hover:hover {
  --bs-translate-y: 2rem !important;
}

.translate-y-8-focus:focus {
  --bs-translate-y: 2rem !important;
}

.translate-y-10 {
  --bs-translate-y: 2.5rem !important;
}

.translate-y-10-hover:hover {
  --bs-translate-y: 2.5rem !important;
}

.translate-y-10-focus:focus {
  --bs-translate-y: 2.5rem !important;
}

.translate-y-12 {
  --bs-translate-y: 3rem !important;
}

.translate-y-12-hover:hover {
  --bs-translate-y: 3rem !important;
}

.translate-y-12-focus:focus {
  --bs-translate-y: 3rem !important;
}

.translate-y-14 {
  --bs-translate-y: 3.5rem !important;
}

.translate-y-14-hover:hover {
  --bs-translate-y: 3.5rem !important;
}

.translate-y-14-focus:focus {
  --bs-translate-y: 3.5rem !important;
}

.translate-y-16 {
  --bs-translate-y: 4rem !important;
}

.translate-y-16-hover:hover {
  --bs-translate-y: 4rem !important;
}

.translate-y-16-focus:focus {
  --bs-translate-y: 4rem !important;
}

.translate-y-18 {
  --bs-translate-y: 4.5rem !important;
}

.translate-y-18-hover:hover {
  --bs-translate-y: 4.5rem !important;
}

.translate-y-18-focus:focus {
  --bs-translate-y: 4.5rem !important;
}

.translate-y-20 {
  --bs-translate-y: 5rem !important;
}

.translate-y-20-hover:hover {
  --bs-translate-y: 5rem !important;
}

.translate-y-20-focus:focus {
  --bs-translate-y: 5rem !important;
}

.translate-y-24 {
  --bs-translate-y: 6rem !important;
}

.translate-y-24-hover:hover {
  --bs-translate-y: 6rem !important;
}

.translate-y-24-focus:focus {
  --bs-translate-y: 6rem !important;
}

.translate-y-32 {
  --bs-translate-y: 8rem !important;
}

.translate-y-32-hover:hover {
  --bs-translate-y: 8rem !important;
}

.translate-y-32-focus:focus {
  --bs-translate-y: 8rem !important;
}

.translate-y-40 {
  --bs-translate-y: 10rem !important;
}

.translate-y-40-hover:hover {
  --bs-translate-y: 10rem !important;
}

.translate-y-40-focus:focus {
  --bs-translate-y: 10rem !important;
}

.translate-y-48 {
  --bs-translate-y: 12rem !important;
}

.translate-y-48-hover:hover {
  --bs-translate-y: 12rem !important;
}

.translate-y-48-focus:focus {
  --bs-translate-y: 12rem !important;
}

.translate-y-56 {
  --bs-translate-y: 14rem !important;
}

.translate-y-56-hover:hover {
  --bs-translate-y: 14rem !important;
}

.translate-y-56-focus:focus {
  --bs-translate-y: 14rem !important;
}

.translate-y-64 {
  --bs-translate-y: 16rem !important;
}

.translate-y-64-hover:hover {
  --bs-translate-y: 16rem !important;
}

.translate-y-64-focus:focus {
  --bs-translate-y: 16rem !important;
}

.translate-y-72 {
  --bs-translate-y: 18rem !important;
}

.translate-y-72-hover:hover {
  --bs-translate-y: 18rem !important;
}

.translate-y-72-focus:focus {
  --bs-translate-y: 18rem !important;
}

.translate-y-80 {
  --bs-translate-y: 20rem !important;
}

.translate-y-80-hover:hover {
  --bs-translate-y: 20rem !important;
}

.translate-y-80-focus:focus {
  --bs-translate-y: 20rem !important;
}

.translate-y-88 {
  --bs-translate-y: 22rem !important;
}

.translate-y-88-hover:hover {
  --bs-translate-y: 22rem !important;
}

.translate-y-88-focus:focus {
  --bs-translate-y: 22rem !important;
}

.translate-y-96 {
  --bs-translate-y: 24rem !important;
}

.translate-y-96-hover:hover {
  --bs-translate-y: 24rem !important;
}

.translate-y-96-focus:focus {
  --bs-translate-y: 24rem !important;
}

.translate-y-px {
  --bs-translate-y: 1px !important;
}

.translate-y-px-hover:hover {
  --bs-translate-y: 1px !important;
}

.translate-y-px-focus:focus {
  --bs-translate-y: 1px !important;
}

.translate-y-1\/2 {
  --bs-translate-y: 50% !important;
}

.translate-y-1\/2-hover:hover {
  --bs-translate-y: 50% !important;
}

.translate-y-1\/2-focus:focus {
  --bs-translate-y: 50% !important;
}

.translate-y-1\/3 {
  --bs-translate-y: 33.333333% !important;
}

.translate-y-1\/3-hover:hover {
  --bs-translate-y: 33.333333% !important;
}

.translate-y-1\/3-focus:focus {
  --bs-translate-y: 33.333333% !important;
}

.translate-y-1\/4 {
  --bs-translate-y: 25% !important;
}

.translate-y-1\/4-hover:hover {
  --bs-translate-y: 25% !important;
}

.translate-y-1\/4-focus:focus {
  --bs-translate-y: 25% !important;
}

.translate-y-1\/5 {
  --bs-translate-y: 20% !important;
}

.translate-y-1\/5-hover:hover {
  --bs-translate-y: 20% !important;
}

.translate-y-1\/5-focus:focus {
  --bs-translate-y: 20% !important;
}

.translate-y-2\/3 {
  --bs-translate-y: 66.666667% !important;
}

.translate-y-2\/3-hover:hover {
  --bs-translate-y: 66.666667% !important;
}

.translate-y-2\/3-focus:focus {
  --bs-translate-y: 66.666667% !important;
}

.translate-y-2\/5 {
  --bs-translate-y: 50% !important;
}

.translate-y-2\/5-hover:hover {
  --bs-translate-y: 50% !important;
}

.translate-y-2\/5-focus:focus {
  --bs-translate-y: 50% !important;
}

.translate-y-3\/4 {
  --bs-translate-y: 75% !important;
}

.translate-y-3\/4-hover:hover {
  --bs-translate-y: 75% !important;
}

.translate-y-3\/4-focus:focus {
  --bs-translate-y: 75% !important;
}

.translate-y-full {
  --bs-translate-y: 100% !important;
}

.translate-y-full-hover:hover {
  --bs-translate-y: 100% !important;
}

.translate-y-full-focus:focus {
  --bs-translate-y: 100% !important;
}

.translate-y-n1 {
  --bs-translate-y: -0.25rem !important;
}

.translate-y-n1-hover:hover {
  --bs-translate-y: -0.25rem !important;
}

.translate-y-n1-focus:focus {
  --bs-translate-y: -0.25rem !important;
}

.translate-y-n2 {
  --bs-translate-y: -0.5rem !important;
}

.translate-y-n2-hover:hover {
  --bs-translate-y: -0.5rem !important;
}

.translate-y-n2-focus:focus {
  --bs-translate-y: -0.5rem !important;
}

.translate-y-n3 {
  --bs-translate-y: -0.75rem !important;
}

.translate-y-n3-hover:hover {
  --bs-translate-y: -0.75rem !important;
}

.translate-y-n3-focus:focus {
  --bs-translate-y: -0.75rem !important;
}

.translate-y-n4 {
  --bs-translate-y: -1rem !important;
}

.translate-y-n4-hover:hover {
  --bs-translate-y: -1rem !important;
}

.translate-y-n4-focus:focus {
  --bs-translate-y: -1rem !important;
}

.translate-y-n5 {
  --bs-translate-y: -1.25rem !important;
}

.translate-y-n5-hover:hover {
  --bs-translate-y: -1.25rem !important;
}

.translate-y-n5-focus:focus {
  --bs-translate-y: -1.25rem !important;
}

.translate-y-n6 {
  --bs-translate-y: -1.5rem !important;
}

.translate-y-n6-hover:hover {
  --bs-translate-y: -1.5rem !important;
}

.translate-y-n6-focus:focus {
  --bs-translate-y: -1.5rem !important;
}

.translate-y-n7 {
  --bs-translate-y: -1.75rem !important;
}

.translate-y-n7-hover:hover {
  --bs-translate-y: -1.75rem !important;
}

.translate-y-n7-focus:focus {
  --bs-translate-y: -1.75rem !important;
}

.translate-y-n8 {
  --bs-translate-y: -2rem !important;
}

.translate-y-n8-hover:hover {
  --bs-translate-y: -2rem !important;
}

.translate-y-n8-focus:focus {
  --bs-translate-y: -2rem !important;
}

.translate-y-n10 {
  --bs-translate-y: -2.5rem !important;
}

.translate-y-n10-hover:hover {
  --bs-translate-y: -2.5rem !important;
}

.translate-y-n10-focus:focus {
  --bs-translate-y: -2.5rem !important;
}

.translate-y-n12 {
  --bs-translate-y: -3rem !important;
}

.translate-y-n12-hover:hover {
  --bs-translate-y: -3rem !important;
}

.translate-y-n12-focus:focus {
  --bs-translate-y: -3rem !important;
}

.translate-y-n14 {
  --bs-translate-y: -3.5rem !important;
}

.translate-y-n14-hover:hover {
  --bs-translate-y: -3.5rem !important;
}

.translate-y-n14-focus:focus {
  --bs-translate-y: -3.5rem !important;
}

.translate-y-n16 {
  --bs-translate-y: -4rem !important;
}

.translate-y-n16-hover:hover {
  --bs-translate-y: -4rem !important;
}

.translate-y-n16-focus:focus {
  --bs-translate-y: -4rem !important;
}

.translate-y-n18 {
  --bs-translate-y: -4.5rem !important;
}

.translate-y-n18-hover:hover {
  --bs-translate-y: -4.5rem !important;
}

.translate-y-n18-focus:focus {
  --bs-translate-y: -4.5rem !important;
}

.translate-y-n20 {
  --bs-translate-y: -5rem !important;
}

.translate-y-n20-hover:hover {
  --bs-translate-y: -5rem !important;
}

.translate-y-n20-focus:focus {
  --bs-translate-y: -5rem !important;
}

.translate-y-n24 {
  --bs-translate-y: -6rem !important;
}

.translate-y-n24-hover:hover {
  --bs-translate-y: -6rem !important;
}

.translate-y-n24-focus:focus {
  --bs-translate-y: -6rem !important;
}

.translate-y-n32 {
  --bs-translate-y: -8rem !important;
}

.translate-y-n32-hover:hover {
  --bs-translate-y: -8rem !important;
}

.translate-y-n32-focus:focus {
  --bs-translate-y: -8rem !important;
}

.translate-y-n40 {
  --bs-translate-y: -10rem !important;
}

.translate-y-n40-hover:hover {
  --bs-translate-y: -10rem !important;
}

.translate-y-n40-focus:focus {
  --bs-translate-y: -10rem !important;
}

.translate-y-n48 {
  --bs-translate-y: -12rem !important;
}

.translate-y-n48-hover:hover {
  --bs-translate-y: -12rem !important;
}

.translate-y-n48-focus:focus {
  --bs-translate-y: -12rem !important;
}

.translate-y-n56 {
  --bs-translate-y: -14rem !important;
}

.translate-y-n56-hover:hover {
  --bs-translate-y: -14rem !important;
}

.translate-y-n56-focus:focus {
  --bs-translate-y: -14rem !important;
}

.translate-y-n64 {
  --bs-translate-y: -16rem !important;
}

.translate-y-n64-hover:hover {
  --bs-translate-y: -16rem !important;
}

.translate-y-n64-focus:focus {
  --bs-translate-y: -16rem !important;
}

.translate-y-n72 {
  --bs-translate-y: -18rem !important;
}

.translate-y-n72-hover:hover {
  --bs-translate-y: -18rem !important;
}

.translate-y-n72-focus:focus {
  --bs-translate-y: -18rem !important;
}

.translate-y-n80 {
  --bs-translate-y: -20rem !important;
}

.translate-y-n80-hover:hover {
  --bs-translate-y: -20rem !important;
}

.translate-y-n80-focus:focus {
  --bs-translate-y: -20rem !important;
}

.translate-y-n88 {
  --bs-translate-y: -22rem !important;
}

.translate-y-n88-hover:hover {
  --bs-translate-y: -22rem !important;
}

.translate-y-n88-focus:focus {
  --bs-translate-y: -22rem !important;
}

.translate-y-n96 {
  --bs-translate-y: -24rem !important;
}

.translate-y-n96-hover:hover {
  --bs-translate-y: -24rem !important;
}

.translate-y-n96-focus:focus {
  --bs-translate-y: -24rem !important;
}

.translate-y-npx {
  --bs-translate-y: -1px !important;
}

.translate-y-npx-hover:hover {
  --bs-translate-y: -1px !important;
}

.translate-y-npx-focus:focus {
  --bs-translate-y: -1px !important;
}

.translate-y-n1\/2 {
  --bs-translate-y: -50% !important;
}

.translate-y-n1\/2-hover:hover {
  --bs-translate-y: -50% !important;
}

.translate-y-n1\/2-focus:focus {
  --bs-translate-y: -50% !important;
}

.translate-y-n1\/3 {
  --bs-translate-y: -33.333333% !important;
}

.translate-y-n1\/3-hover:hover {
  --bs-translate-y: -33.333333% !important;
}

.translate-y-n1\/3-focus:focus {
  --bs-translate-y: -33.333333% !important;
}

.translate-y-n1\/4 {
  --bs-translate-y: -25% !important;
}

.translate-y-n1\/4-hover:hover {
  --bs-translate-y: -25% !important;
}

.translate-y-n1\/4-focus:focus {
  --bs-translate-y: -25% !important;
}

.translate-y-n1\/5 {
  --bs-translate-y: -20% !important;
}

.translate-y-n1\/5-hover:hover {
  --bs-translate-y: -20% !important;
}

.translate-y-n1\/5-focus:focus {
  --bs-translate-y: -20% !important;
}

.translate-y-n2\/3 {
  --bs-translate-y: -66.666667% !important;
}

.translate-y-n2\/3-hover:hover {
  --bs-translate-y: -66.666667% !important;
}

.translate-y-n2\/3-focus:focus {
  --bs-translate-y: -66.666667% !important;
}

.translate-y-n2\/5 {
  --bs-translate-y: -50% !important;
}

.translate-y-n2\/5-hover:hover {
  --bs-translate-y: -50% !important;
}

.translate-y-n2\/5-focus:focus {
  --bs-translate-y: -50% !important;
}

.translate-y-n3\/4 {
  --bs-translate-y: -75% !important;
}

.translate-y-n3\/4-hover:hover {
  --bs-translate-y: -75% !important;
}

.translate-y-n3\/4-focus:focus {
  --bs-translate-y: -75% !important;
}

.translate-y-nfull {
  --bs-translate-y: -100% !important;
}

.translate-y-nfull-hover:hover {
  --bs-translate-y: -100% !important;
}

.translate-y-nfull-focus:focus {
  --bs-translate-y: -100% !important;
}

.translate-x-0 {
  --bs-translate-x: 0 !important;
}

.translate-x-0-hover:hover {
  --bs-translate-x: 0 !important;
}

.translate-x-0-focus:focus {
  --bs-translate-x: 0 !important;
}

.translate-x-1 {
  --bs-translate-x: 0.25rem !important;
}

.translate-x-1-hover:hover {
  --bs-translate-x: 0.25rem !important;
}

.translate-x-1-focus:focus {
  --bs-translate-x: 0.25rem !important;
}

.translate-x-2 {
  --bs-translate-x: 0.5rem !important;
}

.translate-x-2-hover:hover {
  --bs-translate-x: 0.5rem !important;
}

.translate-x-2-focus:focus {
  --bs-translate-x: 0.5rem !important;
}

.translate-x-3 {
  --bs-translate-x: 0.75rem !important;
}

.translate-x-3-hover:hover {
  --bs-translate-x: 0.75rem !important;
}

.translate-x-3-focus:focus {
  --bs-translate-x: 0.75rem !important;
}

.translate-x-4 {
  --bs-translate-x: 1rem !important;
}

.translate-x-4-hover:hover {
  --bs-translate-x: 1rem !important;
}

.translate-x-4-focus:focus {
  --bs-translate-x: 1rem !important;
}

.translate-x-5 {
  --bs-translate-x: 1.25rem !important;
}

.translate-x-5-hover:hover {
  --bs-translate-x: 1.25rem !important;
}

.translate-x-5-focus:focus {
  --bs-translate-x: 1.25rem !important;
}

.translate-x-6 {
  --bs-translate-x: 1.5rem !important;
}

.translate-x-6-hover:hover {
  --bs-translate-x: 1.5rem !important;
}

.translate-x-6-focus:focus {
  --bs-translate-x: 1.5rem !important;
}

.translate-x-7 {
  --bs-translate-x: 1.75rem !important;
}

.translate-x-7-hover:hover {
  --bs-translate-x: 1.75rem !important;
}

.translate-x-7-focus:focus {
  --bs-translate-x: 1.75rem !important;
}

.translate-x-8 {
  --bs-translate-x: 2rem !important;
}

.translate-x-8-hover:hover {
  --bs-translate-x: 2rem !important;
}

.translate-x-8-focus:focus {
  --bs-translate-x: 2rem !important;
}

.translate-x-10 {
  --bs-translate-x: 2.5rem !important;
}

.translate-x-10-hover:hover {
  --bs-translate-x: 2.5rem !important;
}

.translate-x-10-focus:focus {
  --bs-translate-x: 2.5rem !important;
}

.translate-x-12 {
  --bs-translate-x: 3rem !important;
}

.translate-x-12-hover:hover {
  --bs-translate-x: 3rem !important;
}

.translate-x-12-focus:focus {
  --bs-translate-x: 3rem !important;
}

.translate-x-14 {
  --bs-translate-x: 3.5rem !important;
}

.translate-x-14-hover:hover {
  --bs-translate-x: 3.5rem !important;
}

.translate-x-14-focus:focus {
  --bs-translate-x: 3.5rem !important;
}

.translate-x-16 {
  --bs-translate-x: 4rem !important;
}

.translate-x-16-hover:hover {
  --bs-translate-x: 4rem !important;
}

.translate-x-16-focus:focus {
  --bs-translate-x: 4rem !important;
}

.translate-x-18 {
  --bs-translate-x: 4.5rem !important;
}

.translate-x-18-hover:hover {
  --bs-translate-x: 4.5rem !important;
}

.translate-x-18-focus:focus {
  --bs-translate-x: 4.5rem !important;
}

.translate-x-20 {
  --bs-translate-x: 5rem !important;
}

.translate-x-20-hover:hover {
  --bs-translate-x: 5rem !important;
}

.translate-x-20-focus:focus {
  --bs-translate-x: 5rem !important;
}

.translate-x-24 {
  --bs-translate-x: 6rem !important;
}

.translate-x-24-hover:hover {
  --bs-translate-x: 6rem !important;
}

.translate-x-24-focus:focus {
  --bs-translate-x: 6rem !important;
}

.translate-x-32 {
  --bs-translate-x: 8rem !important;
}

.translate-x-32-hover:hover {
  --bs-translate-x: 8rem !important;
}

.translate-x-32-focus:focus {
  --bs-translate-x: 8rem !important;
}

.translate-x-40 {
  --bs-translate-x: 10rem !important;
}

.translate-x-40-hover:hover {
  --bs-translate-x: 10rem !important;
}

.translate-x-40-focus:focus {
  --bs-translate-x: 10rem !important;
}

.translate-x-48 {
  --bs-translate-x: 12rem !important;
}

.translate-x-48-hover:hover {
  --bs-translate-x: 12rem !important;
}

.translate-x-48-focus:focus {
  --bs-translate-x: 12rem !important;
}

.translate-x-56 {
  --bs-translate-x: 14rem !important;
}

.translate-x-56-hover:hover {
  --bs-translate-x: 14rem !important;
}

.translate-x-56-focus:focus {
  --bs-translate-x: 14rem !important;
}

.translate-x-64 {
  --bs-translate-x: 16rem !important;
}

.translate-x-64-hover:hover {
  --bs-translate-x: 16rem !important;
}

.translate-x-64-focus:focus {
  --bs-translate-x: 16rem !important;
}

.translate-x-72 {
  --bs-translate-x: 18rem !important;
}

.translate-x-72-hover:hover {
  --bs-translate-x: 18rem !important;
}

.translate-x-72-focus:focus {
  --bs-translate-x: 18rem !important;
}

.translate-x-80 {
  --bs-translate-x: 20rem !important;
}

.translate-x-80-hover:hover {
  --bs-translate-x: 20rem !important;
}

.translate-x-80-focus:focus {
  --bs-translate-x: 20rem !important;
}

.translate-x-88 {
  --bs-translate-x: 22rem !important;
}

.translate-x-88-hover:hover {
  --bs-translate-x: 22rem !important;
}

.translate-x-88-focus:focus {
  --bs-translate-x: 22rem !important;
}

.translate-x-96 {
  --bs-translate-x: 24rem !important;
}

.translate-x-96-hover:hover {
  --bs-translate-x: 24rem !important;
}

.translate-x-96-focus:focus {
  --bs-translate-x: 24rem !important;
}

.translate-x-px {
  --bs-translate-x: 1px !important;
}

.translate-x-px-hover:hover {
  --bs-translate-x: 1px !important;
}

.translate-x-px-focus:focus {
  --bs-translate-x: 1px !important;
}

.translate-x-1\/2 {
  --bs-translate-x: 50% !important;
}

.translate-x-1\/2-hover:hover {
  --bs-translate-x: 50% !important;
}

.translate-x-1\/2-focus:focus {
  --bs-translate-x: 50% !important;
}

.translate-x-1\/3 {
  --bs-translate-x: 33.333333% !important;
}

.translate-x-1\/3-hover:hover {
  --bs-translate-x: 33.333333% !important;
}

.translate-x-1\/3-focus:focus {
  --bs-translate-x: 33.333333% !important;
}

.translate-x-1\/4 {
  --bs-translate-x: 25% !important;
}

.translate-x-1\/4-hover:hover {
  --bs-translate-x: 25% !important;
}

.translate-x-1\/4-focus:focus {
  --bs-translate-x: 25% !important;
}

.translate-x-1\/5 {
  --bs-translate-x: 20% !important;
}

.translate-x-1\/5-hover:hover {
  --bs-translate-x: 20% !important;
}

.translate-x-1\/5-focus:focus {
  --bs-translate-x: 20% !important;
}

.translate-x-2\/3 {
  --bs-translate-x: 66.666667% !important;
}

.translate-x-2\/3-hover:hover {
  --bs-translate-x: 66.666667% !important;
}

.translate-x-2\/3-focus:focus {
  --bs-translate-x: 66.666667% !important;
}

.translate-x-2\/5 {
  --bs-translate-x: 50% !important;
}

.translate-x-2\/5-hover:hover {
  --bs-translate-x: 50% !important;
}

.translate-x-2\/5-focus:focus {
  --bs-translate-x: 50% !important;
}

.translate-x-3\/4 {
  --bs-translate-x: 75% !important;
}

.translate-x-3\/4-hover:hover {
  --bs-translate-x: 75% !important;
}

.translate-x-3\/4-focus:focus {
  --bs-translate-x: 75% !important;
}

.translate-x-full {
  --bs-translate-x: 100% !important;
}

.translate-x-full-hover:hover {
  --bs-translate-x: 100% !important;
}

.translate-x-full-focus:focus {
  --bs-translate-x: 100% !important;
}

.translate-x-n1 {
  --bs-translate-x: -0.25rem !important;
}

.translate-x-n1-hover:hover {
  --bs-translate-x: -0.25rem !important;
}

.translate-x-n1-focus:focus {
  --bs-translate-x: -0.25rem !important;
}

.translate-x-n2 {
  --bs-translate-x: -0.5rem !important;
}

.translate-x-n2-hover:hover {
  --bs-translate-x: -0.5rem !important;
}

.translate-x-n2-focus:focus {
  --bs-translate-x: -0.5rem !important;
}

.translate-x-n3 {
  --bs-translate-x: -0.75rem !important;
}

.translate-x-n3-hover:hover {
  --bs-translate-x: -0.75rem !important;
}

.translate-x-n3-focus:focus {
  --bs-translate-x: -0.75rem !important;
}

.translate-x-n4 {
  --bs-translate-x: -1rem !important;
}

.translate-x-n4-hover:hover {
  --bs-translate-x: -1rem !important;
}

.translate-x-n4-focus:focus {
  --bs-translate-x: -1rem !important;
}

.translate-x-n5 {
  --bs-translate-x: -1.25rem !important;
}

.translate-x-n5-hover:hover {
  --bs-translate-x: -1.25rem !important;
}

.translate-x-n5-focus:focus {
  --bs-translate-x: -1.25rem !important;
}

.translate-x-n6 {
  --bs-translate-x: -1.5rem !important;
}

.translate-x-n6-hover:hover {
  --bs-translate-x: -1.5rem !important;
}

.translate-x-n6-focus:focus {
  --bs-translate-x: -1.5rem !important;
}

.translate-x-n7 {
  --bs-translate-x: -1.75rem !important;
}

.translate-x-n7-hover:hover {
  --bs-translate-x: -1.75rem !important;
}

.translate-x-n7-focus:focus {
  --bs-translate-x: -1.75rem !important;
}

.translate-x-n8 {
  --bs-translate-x: -2rem !important;
}

.translate-x-n8-hover:hover {
  --bs-translate-x: -2rem !important;
}

.translate-x-n8-focus:focus {
  --bs-translate-x: -2rem !important;
}

.translate-x-n10 {
  --bs-translate-x: -2.5rem !important;
}

.translate-x-n10-hover:hover {
  --bs-translate-x: -2.5rem !important;
}

.translate-x-n10-focus:focus {
  --bs-translate-x: -2.5rem !important;
}

.translate-x-n12 {
  --bs-translate-x: -3rem !important;
}

.translate-x-n12-hover:hover {
  --bs-translate-x: -3rem !important;
}

.translate-x-n12-focus:focus {
  --bs-translate-x: -3rem !important;
}

.translate-x-n14 {
  --bs-translate-x: -3.5rem !important;
}

.translate-x-n14-hover:hover {
  --bs-translate-x: -3.5rem !important;
}

.translate-x-n14-focus:focus {
  --bs-translate-x: -3.5rem !important;
}

.translate-x-n16 {
  --bs-translate-x: -4rem !important;
}

.translate-x-n16-hover:hover {
  --bs-translate-x: -4rem !important;
}

.translate-x-n16-focus:focus {
  --bs-translate-x: -4rem !important;
}

.translate-x-n18 {
  --bs-translate-x: -4.5rem !important;
}

.translate-x-n18-hover:hover {
  --bs-translate-x: -4.5rem !important;
}

.translate-x-n18-focus:focus {
  --bs-translate-x: -4.5rem !important;
}

.translate-x-n20 {
  --bs-translate-x: -5rem !important;
}

.translate-x-n20-hover:hover {
  --bs-translate-x: -5rem !important;
}

.translate-x-n20-focus:focus {
  --bs-translate-x: -5rem !important;
}

.translate-x-n24 {
  --bs-translate-x: -6rem !important;
}

.translate-x-n24-hover:hover {
  --bs-translate-x: -6rem !important;
}

.translate-x-n24-focus:focus {
  --bs-translate-x: -6rem !important;
}

.translate-x-n32 {
  --bs-translate-x: -8rem !important;
}

.translate-x-n32-hover:hover {
  --bs-translate-x: -8rem !important;
}

.translate-x-n32-focus:focus {
  --bs-translate-x: -8rem !important;
}

.translate-x-n40 {
  --bs-translate-x: -10rem !important;
}

.translate-x-n40-hover:hover {
  --bs-translate-x: -10rem !important;
}

.translate-x-n40-focus:focus {
  --bs-translate-x: -10rem !important;
}

.translate-x-n48 {
  --bs-translate-x: -12rem !important;
}

.translate-x-n48-hover:hover {
  --bs-translate-x: -12rem !important;
}

.translate-x-n48-focus:focus {
  --bs-translate-x: -12rem !important;
}

.translate-x-n56 {
  --bs-translate-x: -14rem !important;
}

.translate-x-n56-hover:hover {
  --bs-translate-x: -14rem !important;
}

.translate-x-n56-focus:focus {
  --bs-translate-x: -14rem !important;
}

.translate-x-n64 {
  --bs-translate-x: -16rem !important;
}

.translate-x-n64-hover:hover {
  --bs-translate-x: -16rem !important;
}

.translate-x-n64-focus:focus {
  --bs-translate-x: -16rem !important;
}

.translate-x-n72 {
  --bs-translate-x: -18rem !important;
}

.translate-x-n72-hover:hover {
  --bs-translate-x: -18rem !important;
}

.translate-x-n72-focus:focus {
  --bs-translate-x: -18rem !important;
}

.translate-x-n80 {
  --bs-translate-x: -20rem !important;
}

.translate-x-n80-hover:hover {
  --bs-translate-x: -20rem !important;
}

.translate-x-n80-focus:focus {
  --bs-translate-x: -20rem !important;
}

.translate-x-n88 {
  --bs-translate-x: -22rem !important;
}

.translate-x-n88-hover:hover {
  --bs-translate-x: -22rem !important;
}

.translate-x-n88-focus:focus {
  --bs-translate-x: -22rem !important;
}

.translate-x-n96 {
  --bs-translate-x: -24rem !important;
}

.translate-x-n96-hover:hover {
  --bs-translate-x: -24rem !important;
}

.translate-x-n96-focus:focus {
  --bs-translate-x: -24rem !important;
}

.translate-x-npx {
  --bs-translate-x: -1px !important;
}

.translate-x-npx-hover:hover {
  --bs-translate-x: -1px !important;
}

.translate-x-npx-focus:focus {
  --bs-translate-x: -1px !important;
}

.translate-x-n1\/2 {
  --bs-translate-x: -50% !important;
}

.translate-x-n1\/2-hover:hover {
  --bs-translate-x: -50% !important;
}

.translate-x-n1\/2-focus:focus {
  --bs-translate-x: -50% !important;
}

.translate-x-n1\/3 {
  --bs-translate-x: -33.333333% !important;
}

.translate-x-n1\/3-hover:hover {
  --bs-translate-x: -33.333333% !important;
}

.translate-x-n1\/3-focus:focus {
  --bs-translate-x: -33.333333% !important;
}

.translate-x-n1\/4 {
  --bs-translate-x: -25% !important;
}

.translate-x-n1\/4-hover:hover {
  --bs-translate-x: -25% !important;
}

.translate-x-n1\/4-focus:focus {
  --bs-translate-x: -25% !important;
}

.translate-x-n1\/5 {
  --bs-translate-x: -20% !important;
}

.translate-x-n1\/5-hover:hover {
  --bs-translate-x: -20% !important;
}

.translate-x-n1\/5-focus:focus {
  --bs-translate-x: -20% !important;
}

.translate-x-n2\/3 {
  --bs-translate-x: -66.666667% !important;
}

.translate-x-n2\/3-hover:hover {
  --bs-translate-x: -66.666667% !important;
}

.translate-x-n2\/3-focus:focus {
  --bs-translate-x: -66.666667% !important;
}

.translate-x-n2\/5 {
  --bs-translate-x: -50% !important;
}

.translate-x-n2\/5-hover:hover {
  --bs-translate-x: -50% !important;
}

.translate-x-n2\/5-focus:focus {
  --bs-translate-x: -50% !important;
}

.translate-x-n3\/4 {
  --bs-translate-x: -75% !important;
}

.translate-x-n3\/4-hover:hover {
  --bs-translate-x: -75% !important;
}

.translate-x-n3\/4-focus:focus {
  --bs-translate-x: -75% !important;
}

.translate-x-nfull {
  --bs-translate-x: -100% !important;
}

.translate-x-nfull-hover:hover {
  --bs-translate-x: -100% !important;
}

.translate-x-nfull-focus:focus {
  --bs-translate-x: -100% !important;
}

.skew-y-0 {
  --bs-skew-y: 0deg !important;
}

.skew-y-0-hover:hover {
  --bs-skew-y: 0deg !important;
}

.skew-y-0-focus:focus {
  --bs-skew-y: 0deg !important;
}

.skew-y-1 {
  --bs-skew-y: 1deg !important;
}

.skew-y-1-hover:hover {
  --bs-skew-y: 1deg !important;
}

.skew-y-1-focus:focus {
  --bs-skew-y: 1deg !important;
}

.skew-y-2 {
  --bs-skew-y: 2deg !important;
}

.skew-y-2-hover:hover {
  --bs-skew-y: 2deg !important;
}

.skew-y-2-focus:focus {
  --bs-skew-y: 2deg !important;
}

.skew-y-3 {
  --bs-skew-y: 3deg !important;
}

.skew-y-3-hover:hover {
  --bs-skew-y: 3deg !important;
}

.skew-y-3-focus:focus {
  --bs-skew-y: 3deg !important;
}

.skew-y-6 {
  --bs-skew-y: 6deg !important;
}

.skew-y-6-hover:hover {
  --bs-skew-y: 6deg !important;
}

.skew-y-6-focus:focus {
  --bs-skew-y: 6deg !important;
}

.skew-y-12 {
  --bs-skew-y: 12deg !important;
}

.skew-y-12-hover:hover {
  --bs-skew-y: 12deg !important;
}

.skew-y-12-focus:focus {
  --bs-skew-y: 12deg !important;
}

.skew-y-n1 {
  --bs-skew-y: -1deg !important;
}

.skew-y-n1-hover:hover {
  --bs-skew-y: -1deg !important;
}

.skew-y-n1-focus:focus {
  --bs-skew-y: -1deg !important;
}

.skew-y-n2 {
  --bs-skew-y: -2deg !important;
}

.skew-y-n2-hover:hover {
  --bs-skew-y: -2deg !important;
}

.skew-y-n2-focus:focus {
  --bs-skew-y: -2deg !important;
}

.skew-y-n3 {
  --bs-skew-y: -3deg !important;
}

.skew-y-n3-hover:hover {
  --bs-skew-y: -3deg !important;
}

.skew-y-n3-focus:focus {
  --bs-skew-y: -3deg !important;
}

.skew-y-n6 {
  --bs-skew-y: -6deg !important;
}

.skew-y-n6-hover:hover {
  --bs-skew-y: -6deg !important;
}

.skew-y-n6-focus:focus {
  --bs-skew-y: -6deg !important;
}

.skew-y-n12 {
  --bs-skew-y: -12deg !important;
}

.skew-y-n12-hover:hover {
  --bs-skew-y: -12deg !important;
}

.skew-y-n12-focus:focus {
  --bs-skew-y: -12deg !important;
}

.skew-x-0 {
  --bs-skew-x: 0deg !important;
}

.skew-x-0-hover:hover {
  --bs-skew-x: 0deg !important;
}

.skew-x-0-focus:focus {
  --bs-skew-x: 0deg !important;
}

.skew-x-1 {
  --bs-skew-x: 1deg !important;
}

.skew-x-1-hover:hover {
  --bs-skew-x: 1deg !important;
}

.skew-x-1-focus:focus {
  --bs-skew-x: 1deg !important;
}

.skew-x-2 {
  --bs-skew-x: 2deg !important;
}

.skew-x-2-hover:hover {
  --bs-skew-x: 2deg !important;
}

.skew-x-2-focus:focus {
  --bs-skew-x: 2deg !important;
}

.skew-x-3 {
  --bs-skew-x: 3deg !important;
}

.skew-x-3-hover:hover {
  --bs-skew-x: 3deg !important;
}

.skew-x-3-focus:focus {
  --bs-skew-x: 3deg !important;
}

.skew-x-6 {
  --bs-skew-x: 6deg !important;
}

.skew-x-6-hover:hover {
  --bs-skew-x: 6deg !important;
}

.skew-x-6-focus:focus {
  --bs-skew-x: 6deg !important;
}

.skew-x-12 {
  --bs-skew-x: 12deg !important;
}

.skew-x-12-hover:hover {
  --bs-skew-x: 12deg !important;
}

.skew-x-12-focus:focus {
  --bs-skew-x: 12deg !important;
}

.skew-x-n1 {
  --bs-skew-x: -1deg !important;
}

.skew-x-n1-hover:hover {
  --bs-skew-x: -1deg !important;
}

.skew-x-n1-focus:focus {
  --bs-skew-x: -1deg !important;
}

.skew-x-n2 {
  --bs-skew-x: -2deg !important;
}

.skew-x-n2-hover:hover {
  --bs-skew-x: -2deg !important;
}

.skew-x-n2-focus:focus {
  --bs-skew-x: -2deg !important;
}

.skew-x-n3 {
  --bs-skew-x: -3deg !important;
}

.skew-x-n3-hover:hover {
  --bs-skew-x: -3deg !important;
}

.skew-x-n3-focus:focus {
  --bs-skew-x: -3deg !important;
}

.skew-x-n6 {
  --bs-skew-x: -6deg !important;
}

.skew-x-n6-hover:hover {
  --bs-skew-x: -6deg !important;
}

.skew-x-n6-focus:focus {
  --bs-skew-x: -6deg !important;
}

.skew-x-n12 {
  --bs-skew-x: -12deg !important;
}

.skew-x-n12-hover:hover {
  --bs-skew-x: -12deg !important;
}

.skew-x-n12-focus:focus {
  --bs-skew-x: -12deg !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .overflow-sm-auto {
    overflow: auto !important;
  }
  .overflow-sm-hidden {
    overflow: hidden !important;
  }
  .overflow-sm-visible {
    overflow: visible !important;
  }
  .overflow-sm-scroll {
    overflow: scroll !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .shadow-sm {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-sm-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-sm-focus:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-sm-1 {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-1-hover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-1-focus:focus {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-2 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-sm-2-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-sm-2-focus:focus {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-sm-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-sm-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-inset-focus:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-sm-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-outline-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-focus-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-sm-none {
    box-shadow: none !important;
  }
  .shadow-sm-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-sm-none-focus:focus {
    box-shadow: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .top-sm-1 {
    top: 0.25rem !important;
  }
  .top-sm-2 {
    top: 0.5rem !important;
  }
  .top-sm-3 {
    top: 0.75rem !important;
  }
  .top-sm-4 {
    top: 1rem !important;
  }
  .top-sm-5 {
    top: 1.25rem !important;
  }
  .top-sm-6 {
    top: 1.5rem !important;
  }
  .top-sm-7 {
    top: 1.75rem !important;
  }
  .top-sm-8 {
    top: 2rem !important;
  }
  .top-sm-10 {
    top: 2.5rem !important;
  }
  .top-sm-12 {
    top: 3rem !important;
  }
  .top-sm-14 {
    top: 3.5rem !important;
  }
  .top-sm-16 {
    top: 4rem !important;
  }
  .top-sm-18 {
    top: 4.5rem !important;
  }
  .top-sm-20 {
    top: 5rem !important;
  }
  .top-sm-24 {
    top: 6rem !important;
  }
  .top-sm-32 {
    top: 8rem !important;
  }
  .top-sm-40 {
    top: 10rem !important;
  }
  .top-sm-48 {
    top: 12rem !important;
  }
  .top-sm-56 {
    top: 14rem !important;
  }
  .top-sm-64 {
    top: 16rem !important;
  }
  .top-sm-72 {
    top: 18rem !important;
  }
  .top-sm-80 {
    top: 20rem !important;
  }
  .top-sm-88 {
    top: 22rem !important;
  }
  .top-sm-96 {
    top: 24rem !important;
  }
  .top-sm-px {
    top: 1px !important;
  }
  .top-sm-50 {
    top: 50% !important;
  }
  .top-sm-100 {
    top: 100% !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .bottom-sm-1 {
    bottom: 0.25rem !important;
  }
  .bottom-sm-2 {
    bottom: 0.5rem !important;
  }
  .bottom-sm-3 {
    bottom: 0.75rem !important;
  }
  .bottom-sm-4 {
    bottom: 1rem !important;
  }
  .bottom-sm-5 {
    bottom: 1.25rem !important;
  }
  .bottom-sm-6 {
    bottom: 1.5rem !important;
  }
  .bottom-sm-7 {
    bottom: 1.75rem !important;
  }
  .bottom-sm-8 {
    bottom: 2rem !important;
  }
  .bottom-sm-10 {
    bottom: 2.5rem !important;
  }
  .bottom-sm-12 {
    bottom: 3rem !important;
  }
  .bottom-sm-14 {
    bottom: 3.5rem !important;
  }
  .bottom-sm-16 {
    bottom: 4rem !important;
  }
  .bottom-sm-18 {
    bottom: 4.5rem !important;
  }
  .bottom-sm-20 {
    bottom: 5rem !important;
  }
  .bottom-sm-24 {
    bottom: 6rem !important;
  }
  .bottom-sm-32 {
    bottom: 8rem !important;
  }
  .bottom-sm-40 {
    bottom: 10rem !important;
  }
  .bottom-sm-48 {
    bottom: 12rem !important;
  }
  .bottom-sm-56 {
    bottom: 14rem !important;
  }
  .bottom-sm-64 {
    bottom: 16rem !important;
  }
  .bottom-sm-72 {
    bottom: 18rem !important;
  }
  .bottom-sm-80 {
    bottom: 20rem !important;
  }
  .bottom-sm-88 {
    bottom: 22rem !important;
  }
  .bottom-sm-96 {
    bottom: 24rem !important;
  }
  .bottom-sm-px {
    bottom: 1px !important;
  }
  .bottom-sm-50 {
    bottom: 50% !important;
  }
  .bottom-sm-100 {
    bottom: 100% !important;
  }
  .start-sm-0 {
    left: 0 !important;
  }
  .start-sm-1 {
    left: 0.25rem !important;
  }
  .start-sm-2 {
    left: 0.5rem !important;
  }
  .start-sm-3 {
    left: 0.75rem !important;
  }
  .start-sm-4 {
    left: 1rem !important;
  }
  .start-sm-5 {
    left: 1.25rem !important;
  }
  .start-sm-6 {
    left: 1.5rem !important;
  }
  .start-sm-7 {
    left: 1.75rem !important;
  }
  .start-sm-8 {
    left: 2rem !important;
  }
  .start-sm-10 {
    left: 2.5rem !important;
  }
  .start-sm-12 {
    left: 3rem !important;
  }
  .start-sm-14 {
    left: 3.5rem !important;
  }
  .start-sm-16 {
    left: 4rem !important;
  }
  .start-sm-18 {
    left: 4.5rem !important;
  }
  .start-sm-20 {
    left: 5rem !important;
  }
  .start-sm-24 {
    left: 6rem !important;
  }
  .start-sm-32 {
    left: 8rem !important;
  }
  .start-sm-40 {
    left: 10rem !important;
  }
  .start-sm-48 {
    left: 12rem !important;
  }
  .start-sm-56 {
    left: 14rem !important;
  }
  .start-sm-64 {
    left: 16rem !important;
  }
  .start-sm-72 {
    left: 18rem !important;
  }
  .start-sm-80 {
    left: 20rem !important;
  }
  .start-sm-88 {
    left: 22rem !important;
  }
  .start-sm-96 {
    left: 24rem !important;
  }
  .start-sm-px {
    left: 1px !important;
  }
  .start-sm-50 {
    left: 50% !important;
  }
  .start-sm-100 {
    left: 100% !important;
  }
  .end-sm-0 {
    right: 0 !important;
  }
  .end-sm-1 {
    right: 0.25rem !important;
  }
  .end-sm-2 {
    right: 0.5rem !important;
  }
  .end-sm-3 {
    right: 0.75rem !important;
  }
  .end-sm-4 {
    right: 1rem !important;
  }
  .end-sm-5 {
    right: 1.25rem !important;
  }
  .end-sm-6 {
    right: 1.5rem !important;
  }
  .end-sm-7 {
    right: 1.75rem !important;
  }
  .end-sm-8 {
    right: 2rem !important;
  }
  .end-sm-10 {
    right: 2.5rem !important;
  }
  .end-sm-12 {
    right: 3rem !important;
  }
  .end-sm-14 {
    right: 3.5rem !important;
  }
  .end-sm-16 {
    right: 4rem !important;
  }
  .end-sm-18 {
    right: 4.5rem !important;
  }
  .end-sm-20 {
    right: 5rem !important;
  }
  .end-sm-24 {
    right: 6rem !important;
  }
  .end-sm-32 {
    right: 8rem !important;
  }
  .end-sm-40 {
    right: 10rem !important;
  }
  .end-sm-48 {
    right: 12rem !important;
  }
  .end-sm-56 {
    right: 14rem !important;
  }
  .end-sm-64 {
    right: 16rem !important;
  }
  .end-sm-72 {
    right: 18rem !important;
  }
  .end-sm-80 {
    right: 20rem !important;
  }
  .end-sm-88 {
    right: 22rem !important;
  }
  .end-sm-96 {
    right: 24rem !important;
  }
  .end-sm-px {
    right: 1px !important;
  }
  .end-sm-50 {
    right: 50% !important;
  }
  .end-sm-100 {
    right: 100% !important;
  }
  .border-top-sm-0 {
    border-top-width: 0 !important;
  }
  .border-top-sm-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-sm-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top-sm {
    border-top-width: 1px !important;
  }
  .border-top-sm-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-sm-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-sm-2 {
    border-top-width: 2px !important;
  }
  .border-top-sm-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-sm-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-sm-3 {
    border-top-width: 3px !important;
  }
  .border-top-sm-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-sm-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-sm-4 {
    border-top-width: 4px !important;
  }
  .border-top-sm-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-sm-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-sm-5 {
    border-top-width: 5px !important;
  }
  .border-top-sm-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-sm-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-sm-6 {
    border-top-width: 6px !important;
  }
  .border-top-sm-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-sm-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-sm-7 {
    border-top-width: 7px !important;
  }
  .border-top-sm-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-sm-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-sm-8 {
    border-top-width: 8px !important;
  }
  .border-top-sm-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-sm-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-sm-0 {
    border-right-width: 0 !important;
  }
  .border-end-sm-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-sm-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end-sm {
    border-right-width: 1px !important;
  }
  .border-end-sm-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-sm-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-sm-2 {
    border-right-width: 2px !important;
  }
  .border-end-sm-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-sm-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-sm-3 {
    border-right-width: 3px !important;
  }
  .border-end-sm-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-sm-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-sm-4 {
    border-right-width: 4px !important;
  }
  .border-end-sm-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-sm-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-sm-5 {
    border-right-width: 5px !important;
  }
  .border-end-sm-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-sm-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-sm-6 {
    border-right-width: 6px !important;
  }
  .border-end-sm-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-sm-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-sm-7 {
    border-right-width: 7px !important;
  }
  .border-end-sm-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-sm-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-sm-8 {
    border-right-width: 8px !important;
  }
  .border-end-sm-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-sm-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-sm-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-sm-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-sm-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom-sm {
    border-bottom-width: 1px !important;
  }
  .border-bottom-sm-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-sm-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-sm-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-sm-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-sm-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-sm-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-sm-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-sm-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-sm-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-sm-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-sm-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-sm-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-sm-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-sm-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-sm-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-sm-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-sm-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-sm-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-sm-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-sm-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-sm-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-sm-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-sm-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-sm-0 {
    border-left-width: 0 !important;
  }
  .border-start-sm-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-sm-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start-sm {
    border-left-width: 1px !important;
  }
  .border-start-sm-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-sm-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-sm-2 {
    border-left-width: 2px !important;
  }
  .border-start-sm-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-sm-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-sm-3 {
    border-left-width: 3px !important;
  }
  .border-start-sm-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-sm-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-sm-4 {
    border-left-width: 4px !important;
  }
  .border-start-sm-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-sm-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-sm-5 {
    border-left-width: 5px !important;
  }
  .border-start-sm-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-sm-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-sm-6 {
    border-left-width: 6px !important;
  }
  .border-start-sm-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-sm-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-sm-7 {
    border-left-width: 7px !important;
  }
  .border-start-sm-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-sm-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-sm-8 {
    border-left-width: 8px !important;
  }
  .border-start-sm-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-sm-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-sm-primary {
    border-color: #1A16C0 !important;
  }
  .border-sm-primary-hover:hover {
    border-color: #1A16C0 !important;
  }
  .border-sm-primary-focus:focus {
    border-color: #1A16C0 !important;
  }
  .border-sm-primary-focus-within:focus-within {
    border-color: #1A16C0 !important;
  }
  .border-sm-secondary {
    border-color: #E4ECF7 !important;
  }
  .border-sm-secondary-hover:hover {
    border-color: #E4ECF7 !important;
  }
  .border-sm-secondary-focus:focus {
    border-color: #E4ECF7 !important;
  }
  .border-sm-secondary-focus-within:focus-within {
    border-color: #E4ECF7 !important;
  }
  .border-sm-success {
    border-color: #4AAE8C !important;
  }
  .border-sm-success-hover:hover {
    border-color: #4AAE8C !important;
  }
  .border-sm-success-focus:focus {
    border-color: #4AAE8C !important;
  }
  .border-sm-success-focus-within:focus-within {
    border-color: #4AAE8C !important;
  }
  .border-sm-info {
    border-color: #68B8F2 !important;
  }
  .border-sm-info-hover:hover {
    border-color: #68B8F2 !important;
  }
  .border-sm-info-focus:focus {
    border-color: #68B8F2 !important;
  }
  .border-sm-info-focus-within:focus-within {
    border-color: #68B8F2 !important;
  }
  .border-sm-warning {
    border-color: #ED9830 !important;
  }
  .border-sm-warning-hover:hover {
    border-color: #ED9830 !important;
  }
  .border-sm-warning-focus:focus {
    border-color: #ED9830 !important;
  }
  .border-sm-warning-focus-within:focus-within {
    border-color: #ED9830 !important;
  }
  .border-sm-danger {
    border-color: #F16063 !important;
  }
  .border-sm-danger-hover:hover {
    border-color: #F16063 !important;
  }
  .border-sm-danger-focus:focus {
    border-color: #F16063 !important;
  }
  .border-sm-danger-focus-within:focus-within {
    border-color: #F16063 !important;
  }
  .border-sm-light {
    border-color: #F7FAFC !important;
  }
  .border-sm-light-hover:hover {
    border-color: #F7FAFC !important;
  }
  .border-sm-light-focus:focus {
    border-color: #F7FAFC !important;
  }
  .border-sm-light-focus-within:focus-within {
    border-color: #F7FAFC !important;
  }
  .border-sm-dark {
    border-color: #27272E !important;
  }
  .border-sm-dark-hover:hover {
    border-color: #27272E !important;
  }
  .border-sm-dark-focus:focus {
    border-color: #27272E !important;
  }
  .border-sm-dark-focus-within:focus-within {
    border-color: #27272E !important;
  }
  .border-sm-transparent {
    border-color: transparent !important;
  }
  .border-sm-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-sm-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-sm-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-sm-current {
    border-color: currentColor !important;
  }
  .border-sm-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-sm-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-sm-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-sm-base {
    border-color: #E2E8F0 !important;
  }
  .border-sm-base-hover:hover {
    border-color: #E2E8F0 !important;
  }
  .border-sm-base-focus:focus {
    border-color: #E2E8F0 !important;
  }
  .border-sm-base-focus-within:focus-within {
    border-color: #E2E8F0 !important;
  }
  .border-sm-body {
    border-color: #fff !important;
  }
  .border-sm-body-hover:hover {
    border-color: #fff !important;
  }
  .border-sm-body-focus:focus {
    border-color: #fff !important;
  }
  .border-sm-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-sm-card {
    border-color: #fff !important;
  }
  .border-sm-card-hover:hover {
    border-color: #fff !important;
  }
  .border-sm-card-focus:focus {
    border-color: #fff !important;
  }
  .border-sm-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-sm-black {
    border-color: #000 !important;
  }
  .border-sm-black-hover:hover {
    border-color: #000 !important;
  }
  .border-sm-black-focus:focus {
    border-color: #000 !important;
  }
  .border-sm-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-sm-white {
    border-color: #fff !important;
  }
  .border-sm-white-hover:hover {
    border-color: #fff !important;
  }
  .border-sm-white-focus:focus {
    border-color: #fff !important;
  }
  .border-sm-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-sm-0 {
    border-width: 0 !important;
  }
  .border-sm-0-hover:hover {
    border-width: 0 !important;
  }
  .border-sm-0-focus:focus {
    border-width: 0 !important;
  }
  .border-sm {
    border-width: 1px !important;
  }
  .border-sm-hover:hover {
    border-width: 1px !important;
  }
  .border-sm-focus:focus {
    border-width: 1px !important;
  }
  .border-sm-2 {
    border-width: 2px !important;
  }
  .border-sm-2-hover:hover {
    border-width: 2px !important;
  }
  .border-sm-2-focus:focus {
    border-width: 2px !important;
  }
  .border-sm-3 {
    border-width: 3px !important;
  }
  .border-sm-3-hover:hover {
    border-width: 3px !important;
  }
  .border-sm-3-focus:focus {
    border-width: 3px !important;
  }
  .border-sm-4 {
    border-width: 4px !important;
  }
  .border-sm-4-hover:hover {
    border-width: 4px !important;
  }
  .border-sm-4-focus:focus {
    border-width: 4px !important;
  }
  .border-sm-5 {
    border-width: 5px !important;
  }
  .border-sm-5-hover:hover {
    border-width: 5px !important;
  }
  .border-sm-5-focus:focus {
    border-width: 5px !important;
  }
  .border-sm-6 {
    border-width: 6px !important;
  }
  .border-sm-6-hover:hover {
    border-width: 6px !important;
  }
  .border-sm-6-focus:focus {
    border-width: 6px !important;
  }
  .border-sm-7 {
    border-width: 7px !important;
  }
  .border-sm-7-hover:hover {
    border-width: 7px !important;
  }
  .border-sm-7-focus:focus {
    border-width: 7px !important;
  }
  .border-sm-8 {
    border-width: 8px !important;
  }
  .border-sm-8-hover:hover {
    border-width: 8px !important;
  }
  .border-sm-8-focus:focus {
    border-width: 8px !important;
  }
  .w-sm-0 {
    width: 0 !important;
  }
  .w-sm-1 {
    width: 0.25rem !important;
  }
  .w-sm-2 {
    width: 0.5rem !important;
  }
  .w-sm-3 {
    width: 0.75rem !important;
  }
  .w-sm-4 {
    width: 1rem !important;
  }
  .w-sm-5 {
    width: 1.25rem !important;
  }
  .w-sm-6 {
    width: 1.5rem !important;
  }
  .w-sm-7 {
    width: 1.75rem !important;
  }
  .w-sm-8 {
    width: 2rem !important;
  }
  .w-sm-10 {
    width: 2.5rem !important;
  }
  .w-sm-12 {
    width: 3rem !important;
  }
  .w-sm-14 {
    width: 3.5rem !important;
  }
  .w-sm-16 {
    width: 4rem !important;
  }
  .w-sm-18 {
    width: 4.5rem !important;
  }
  .w-sm-20 {
    width: 5rem !important;
  }
  .w-sm-24 {
    width: 6rem !important;
  }
  .w-sm-32 {
    width: 8rem !important;
  }
  .w-sm-40 {
    width: 10rem !important;
  }
  .w-sm-48 {
    width: 12rem !important;
  }
  .w-sm-56 {
    width: 14rem !important;
  }
  .w-sm-64 {
    width: 16rem !important;
  }
  .w-sm-72 {
    width: 18rem !important;
  }
  .w-sm-80 {
    width: 20rem !important;
  }
  .w-sm-88 {
    width: 22rem !important;
  }
  .w-sm-96 {
    width: 24rem !important;
  }
  .w-sm-px {
    width: 1px !important;
  }
  .w-sm-1\/2 {
    width: 50% !important;
  }
  .w-sm-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-sm-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-sm-1\/4 {
    width: 25% !important;
  }
  .w-sm-2\/4 {
    width: 50% !important;
  }
  .w-sm-3\/4 {
    width: 75% !important;
  }
  .w-sm-1\/5 {
    width: 20% !important;
  }
  .w-sm-2\/5 {
    width: 40% !important;
  }
  .w-sm-3\/5 {
    width: 60% !important;
  }
  .w-sm-4\/5 {
    width: 80% !important;
  }
  .w-sm-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-sm-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-sm-3\/6 {
    width: 50% !important;
  }
  .w-sm-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-sm-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-sm-11\/10 {
    width: 110% !important;
  }
  .w-sm-12\/10 {
    width: 120% !important;
  }
  .w-sm-13\/10 {
    width: 130% !important;
  }
  .w-sm-14\/10 {
    width: 140% !important;
  }
  .w-sm-15\/10 {
    width: 150% !important;
  }
  .w-sm-screen-sm {
    width: 640px !important;
  }
  .w-sm-screen-md {
    width: 768px !important;
  }
  .w-sm-screen-lg {
    width: 1024px !important;
  }
  .w-sm-screen-xl {
    width: 1280px !important;
  }
  .w-sm-screen-xxl {
    width: 1536px !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-full {
    width: 100% !important;
  }
  .w-sm-screen {
    width: 100vw !important;
  }
  .w-sm-min {
    width: min-content !important;
  }
  .w-sm-max {
    width: max-content !important;
  }
  .max-w-sm-screen-sm {
    max-width: 640px !important;
  }
  .max-w-sm-screen-md {
    max-width: 768px !important;
  }
  .max-w-sm-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-sm-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-sm-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-sm-0 {
    max-width: 0 !important;
  }
  .max-w-sm-full {
    max-width: 100% !important;
  }
  .max-w-sm-read {
    max-width: 65ch !important;
  }
  .max-w-sm-min {
    max-width: min-content !important;
  }
  .max-w-sm-max {
    max-width: max-content !important;
  }
  .h-sm-0 {
    height: 0 !important;
  }
  .h-sm-1 {
    height: 0.25rem !important;
  }
  .h-sm-2 {
    height: 0.5rem !important;
  }
  .h-sm-3 {
    height: 0.75rem !important;
  }
  .h-sm-4 {
    height: 1rem !important;
  }
  .h-sm-5 {
    height: 1.25rem !important;
  }
  .h-sm-6 {
    height: 1.5rem !important;
  }
  .h-sm-7 {
    height: 1.75rem !important;
  }
  .h-sm-8 {
    height: 2rem !important;
  }
  .h-sm-10 {
    height: 2.5rem !important;
  }
  .h-sm-12 {
    height: 3rem !important;
  }
  .h-sm-14 {
    height: 3.5rem !important;
  }
  .h-sm-16 {
    height: 4rem !important;
  }
  .h-sm-18 {
    height: 4.5rem !important;
  }
  .h-sm-20 {
    height: 5rem !important;
  }
  .h-sm-24 {
    height: 6rem !important;
  }
  .h-sm-32 {
    height: 8rem !important;
  }
  .h-sm-40 {
    height: 10rem !important;
  }
  .h-sm-48 {
    height: 12rem !important;
  }
  .h-sm-56 {
    height: 14rem !important;
  }
  .h-sm-64 {
    height: 16rem !important;
  }
  .h-sm-72 {
    height: 18rem !important;
  }
  .h-sm-80 {
    height: 20rem !important;
  }
  .h-sm-88 {
    height: 22rem !important;
  }
  .h-sm-96 {
    height: 24rem !important;
  }
  .h-sm-px {
    height: 1px !important;
  }
  .h-sm-1\/2 {
    height: 50% !important;
  }
  .h-sm-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-sm-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-sm-1\/4 {
    height: 25% !important;
  }
  .h-sm-2\/4 {
    height: 50% !important;
  }
  .h-sm-3\/4 {
    height: 75% !important;
  }
  .h-sm-1\/5 {
    height: 20% !important;
  }
  .h-sm-2\/5 {
    height: 40% !important;
  }
  .h-sm-3\/5 {
    height: 60% !important;
  }
  .h-sm-4\/5 {
    height: 80% !important;
  }
  .h-sm-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-sm-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-sm-3\/6 {
    height: 50% !important;
  }
  .h-sm-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-sm-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-sm-11\/10 {
    height: 110% !important;
  }
  .h-sm-12\/10 {
    height: 120% !important;
  }
  .h-sm-13\/10 {
    height: 130% !important;
  }
  .h-sm-14\/10 {
    height: 140% !important;
  }
  .h-sm-15\/10 {
    height: 150% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-full {
    height: 100% !important;
  }
  .h-sm-screen {
    height: 100vh !important;
  }
  .max-h-sm-0 {
    max-height: 0 !important;
  }
  .max-h-sm-1 {
    max-height: 0.25rem !important;
  }
  .max-h-sm-2 {
    max-height: 0.5rem !important;
  }
  .max-h-sm-3 {
    max-height: 0.75rem !important;
  }
  .max-h-sm-4 {
    max-height: 1rem !important;
  }
  .max-h-sm-5 {
    max-height: 1.25rem !important;
  }
  .max-h-sm-6 {
    max-height: 1.5rem !important;
  }
  .max-h-sm-7 {
    max-height: 1.75rem !important;
  }
  .max-h-sm-8 {
    max-height: 2rem !important;
  }
  .max-h-sm-10 {
    max-height: 2.5rem !important;
  }
  .max-h-sm-12 {
    max-height: 3rem !important;
  }
  .max-h-sm-14 {
    max-height: 3.5rem !important;
  }
  .max-h-sm-16 {
    max-height: 4rem !important;
  }
  .max-h-sm-18 {
    max-height: 4.5rem !important;
  }
  .max-h-sm-20 {
    max-height: 5rem !important;
  }
  .max-h-sm-24 {
    max-height: 6rem !important;
  }
  .max-h-sm-32 {
    max-height: 8rem !important;
  }
  .max-h-sm-40 {
    max-height: 10rem !important;
  }
  .max-h-sm-48 {
    max-height: 12rem !important;
  }
  .max-h-sm-56 {
    max-height: 14rem !important;
  }
  .max-h-sm-64 {
    max-height: 16rem !important;
  }
  .max-h-sm-72 {
    max-height: 18rem !important;
  }
  .max-h-sm-80 {
    max-height: 20rem !important;
  }
  .max-h-sm-88 {
    max-height: 22rem !important;
  }
  .max-h-sm-96 {
    max-height: 24rem !important;
  }
  .max-h-sm-px {
    max-height: 1px !important;
  }
  .max-h-sm-full {
    max-height: 100% !important;
  }
  .max-h-sm-screen {
    max-height: 100vh !important;
  }
  .flex-sm-1 {
    flex: 1 1 0% !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-initial {
    flex: 0 1 auto !important;
  }
  .flex-sm-none {
    flex: none !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-10 {
    gap: 2.5rem !important;
  }
  .gap-sm-12 {
    gap: 3rem !important;
  }
  .gap-sm-14 {
    gap: 3.5rem !important;
  }
  .gap-sm-16 {
    gap: 4rem !important;
  }
  .gap-sm-18 {
    gap: 4.5rem !important;
  }
  .gap-sm-20 {
    gap: 5rem !important;
  }
  .gap-sm-24 {
    gap: 6rem !important;
  }
  .gap-sm-32 {
    gap: 8rem !important;
  }
  .gap-sm-40 {
    gap: 10rem !important;
  }
  .gap-sm-48 {
    gap: 12rem !important;
  }
  .gap-sm-56 {
    gap: 14rem !important;
  }
  .gap-sm-64 {
    gap: 16rem !important;
  }
  .gap-sm-72 {
    gap: 18rem !important;
  }
  .gap-sm-80 {
    gap: 20rem !important;
  }
  .gap-sm-88 {
    gap: 22rem !important;
  }
  .gap-sm-96 {
    gap: 24rem !important;
  }
  .gap-sm-px {
    gap: 1px !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-evenly {
    align-content: space-evenly !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-10 {
    margin: 2.5rem !important;
  }
  .m-sm-12 {
    margin: 3rem !important;
  }
  .m-sm-14 {
    margin: 3.5rem !important;
  }
  .m-sm-16 {
    margin: 4rem !important;
  }
  .m-sm-18 {
    margin: 4.5rem !important;
  }
  .m-sm-20 {
    margin: 5rem !important;
  }
  .m-sm-24 {
    margin: 6rem !important;
  }
  .m-sm-32 {
    margin: 8rem !important;
  }
  .m-sm-40 {
    margin: 10rem !important;
  }
  .m-sm-48 {
    margin: 12rem !important;
  }
  .m-sm-56 {
    margin: 14rem !important;
  }
  .m-sm-64 {
    margin: 16rem !important;
  }
  .m-sm-72 {
    margin: 18rem !important;
  }
  .m-sm-80 {
    margin: 20rem !important;
  }
  .m-sm-88 {
    margin: 22rem !important;
  }
  .m-sm-96 {
    margin: 24rem !important;
  }
  .m-sm-px {
    margin: 1px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-sm-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-sm-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-sm-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-sm-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-sm-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-sm-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-sm-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-sm-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-sm-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-sm-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 2rem !important;
  }
  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-12 {
    margin-top: 3rem !important;
  }
  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-16 {
    margin-top: 4rem !important;
  }
  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-20 {
    margin-top: 5rem !important;
  }
  .mt-sm-24 {
    margin-top: 6rem !important;
  }
  .mt-sm-32 {
    margin-top: 8rem !important;
  }
  .mt-sm-40 {
    margin-top: 10rem !important;
  }
  .mt-sm-48 {
    margin-top: 12rem !important;
  }
  .mt-sm-56 {
    margin-top: 14rem !important;
  }
  .mt-sm-64 {
    margin-top: 16rem !important;
  }
  .mt-sm-72 {
    margin-top: 18rem !important;
  }
  .mt-sm-80 {
    margin-top: 20rem !important;
  }
  .mt-sm-88 {
    margin-top: 22rem !important;
  }
  .mt-sm-96 {
    margin-top: 24rem !important;
  }
  .mt-sm-px {
    margin-top: 1px !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.25rem !important;
  }
  .me-sm-6 {
    margin-right: 1.5rem !important;
  }
  .me-sm-7 {
    margin-right: 1.75rem !important;
  }
  .me-sm-8 {
    margin-right: 2rem !important;
  }
  .me-sm-10 {
    margin-right: 2.5rem !important;
  }
  .me-sm-12 {
    margin-right: 3rem !important;
  }
  .me-sm-14 {
    margin-right: 3.5rem !important;
  }
  .me-sm-16 {
    margin-right: 4rem !important;
  }
  .me-sm-18 {
    margin-right: 4.5rem !important;
  }
  .me-sm-20 {
    margin-right: 5rem !important;
  }
  .me-sm-24 {
    margin-right: 6rem !important;
  }
  .me-sm-32 {
    margin-right: 8rem !important;
  }
  .me-sm-40 {
    margin-right: 10rem !important;
  }
  .me-sm-48 {
    margin-right: 12rem !important;
  }
  .me-sm-56 {
    margin-right: 14rem !important;
  }
  .me-sm-64 {
    margin-right: 16rem !important;
  }
  .me-sm-72 {
    margin-right: 18rem !important;
  }
  .me-sm-80 {
    margin-right: 20rem !important;
  }
  .me-sm-88 {
    margin-right: 22rem !important;
  }
  .me-sm-96 {
    margin-right: 24rem !important;
  }
  .me-sm-px {
    margin-right: 1px !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-24 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-32 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-48 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-56 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-64 {
    margin-bottom: 16rem !important;
  }
  .mb-sm-72 {
    margin-bottom: 18rem !important;
  }
  .mb-sm-80 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-88 {
    margin-bottom: 22rem !important;
  }
  .mb-sm-96 {
    margin-bottom: 24rem !important;
  }
  .mb-sm-px {
    margin-bottom: 1px !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 2rem !important;
  }
  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-12 {
    margin-left: 3rem !important;
  }
  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-16 {
    margin-left: 4rem !important;
  }
  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-20 {
    margin-left: 5rem !important;
  }
  .ms-sm-24 {
    margin-left: 6rem !important;
  }
  .ms-sm-32 {
    margin-left: 8rem !important;
  }
  .ms-sm-40 {
    margin-left: 10rem !important;
  }
  .ms-sm-48 {
    margin-left: 12rem !important;
  }
  .ms-sm-56 {
    margin-left: 14rem !important;
  }
  .ms-sm-64 {
    margin-left: 16rem !important;
  }
  .ms-sm-72 {
    margin-left: 18rem !important;
  }
  .ms-sm-80 {
    margin-left: 20rem !important;
  }
  .ms-sm-88 {
    margin-left: 22rem !important;
  }
  .ms-sm-96 {
    margin-left: 24rem !important;
  }
  .ms-sm-px {
    margin-left: 1px !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-10 {
    padding: 2.5rem !important;
  }
  .p-sm-12 {
    padding: 3rem !important;
  }
  .p-sm-14 {
    padding: 3.5rem !important;
  }
  .p-sm-16 {
    padding: 4rem !important;
  }
  .p-sm-18 {
    padding: 4.5rem !important;
  }
  .p-sm-20 {
    padding: 5rem !important;
  }
  .p-sm-24 {
    padding: 6rem !important;
  }
  .p-sm-32 {
    padding: 8rem !important;
  }
  .p-sm-40 {
    padding: 10rem !important;
  }
  .p-sm-48 {
    padding: 12rem !important;
  }
  .p-sm-56 {
    padding: 14rem !important;
  }
  .p-sm-64 {
    padding: 16rem !important;
  }
  .p-sm-72 {
    padding: 18rem !important;
  }
  .p-sm-80 {
    padding: 20rem !important;
  }
  .p-sm-88 {
    padding: 22rem !important;
  }
  .p-sm-96 {
    padding: 24rem !important;
  }
  .p-sm-px {
    padding: 1px !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-sm-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-sm-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-sm-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-sm-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-sm-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-sm-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-sm-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-sm-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-sm-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-sm-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-sm-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-sm-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-sm-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-sm-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 2rem !important;
  }
  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-12 {
    padding-top: 3rem !important;
  }
  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-16 {
    padding-top: 4rem !important;
  }
  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-20 {
    padding-top: 5rem !important;
  }
  .pt-sm-24 {
    padding-top: 6rem !important;
  }
  .pt-sm-32 {
    padding-top: 8rem !important;
  }
  .pt-sm-40 {
    padding-top: 10rem !important;
  }
  .pt-sm-48 {
    padding-top: 12rem !important;
  }
  .pt-sm-56 {
    padding-top: 14rem !important;
  }
  .pt-sm-64 {
    padding-top: 16rem !important;
  }
  .pt-sm-72 {
    padding-top: 18rem !important;
  }
  .pt-sm-80 {
    padding-top: 20rem !important;
  }
  .pt-sm-88 {
    padding-top: 22rem !important;
  }
  .pt-sm-96 {
    padding-top: 24rem !important;
  }
  .pt-sm-px {
    padding-top: 1px !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 2rem !important;
  }
  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-12 {
    padding-right: 3rem !important;
  }
  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-16 {
    padding-right: 4rem !important;
  }
  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-20 {
    padding-right: 5rem !important;
  }
  .pe-sm-24 {
    padding-right: 6rem !important;
  }
  .pe-sm-32 {
    padding-right: 8rem !important;
  }
  .pe-sm-40 {
    padding-right: 10rem !important;
  }
  .pe-sm-48 {
    padding-right: 12rem !important;
  }
  .pe-sm-56 {
    padding-right: 14rem !important;
  }
  .pe-sm-64 {
    padding-right: 16rem !important;
  }
  .pe-sm-72 {
    padding-right: 18rem !important;
  }
  .pe-sm-80 {
    padding-right: 20rem !important;
  }
  .pe-sm-88 {
    padding-right: 22rem !important;
  }
  .pe-sm-96 {
    padding-right: 24rem !important;
  }
  .pe-sm-px {
    padding-right: 1px !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-24 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-32 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-48 {
    padding-bottom: 12rem !important;
  }
  .pb-sm-56 {
    padding-bottom: 14rem !important;
  }
  .pb-sm-64 {
    padding-bottom: 16rem !important;
  }
  .pb-sm-72 {
    padding-bottom: 18rem !important;
  }
  .pb-sm-80 {
    padding-bottom: 20rem !important;
  }
  .pb-sm-88 {
    padding-bottom: 22rem !important;
  }
  .pb-sm-96 {
    padding-bottom: 24rem !important;
  }
  .pb-sm-px {
    padding-bottom: 1px !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 2rem !important;
  }
  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-12 {
    padding-left: 3rem !important;
  }
  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-16 {
    padding-left: 4rem !important;
  }
  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-20 {
    padding-left: 5rem !important;
  }
  .ps-sm-24 {
    padding-left: 6rem !important;
  }
  .ps-sm-32 {
    padding-left: 8rem !important;
  }
  .ps-sm-40 {
    padding-left: 10rem !important;
  }
  .ps-sm-48 {
    padding-left: 12rem !important;
  }
  .ps-sm-56 {
    padding-left: 14rem !important;
  }
  .ps-sm-64 {
    padding-left: 16rem !important;
  }
  .ps-sm-72 {
    padding-left: 18rem !important;
  }
  .ps-sm-80 {
    padding-left: 20rem !important;
  }
  .ps-sm-88 {
    padding-left: 22rem !important;
  }
  .ps-sm-96 {
    padding-left: 24rem !important;
  }
  .ps-sm-px {
    padding-left: 1px !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-primary-hover:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-primary-focus:focus {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-secondary-hover:hover {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-secondary-focus:focus {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-success-hover:hover {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-success-focus:focus {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-info-hover:hover {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-info-focus:focus {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-warning-hover:hover {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-warning-focus:focus {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-danger-hover:hover {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-danger-focus:focus {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-light-hover:hover {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-light-focus:focus {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-dark-hover:hover {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-dark-focus:focus {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-black-hover:hover {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-black-focus:focus {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-white-hover:hover {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-white-focus:focus {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-body-hover:hover {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-sm-body-focus:focus {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .rounded-sm {
    border-radius: 0.25rem !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
  .rounded-sm-1 {
    border-radius: 0.2rem !important;
  }
  .rounded-sm-2 {
    border-radius: 0.3rem !important;
  }
  .rounded-sm-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-sm-4 {
    border-radius: 1rem !important;
  }
  .rounded-sm-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-sm-6 {
    border-radius: 3rem !important;
  }
  .rounded-sm-7 {
    border-radius: 4rem !important;
  }
  .rounded-sm-8 {
    border-radius: 5rem !important;
  }
  .rounded-sm-9 {
    border-radius: 6rem !important;
  }
  .rounded-sm-10 {
    border-radius: 7rem !important;
  }
  .rounded-sm-pill {
    border-radius: 50rem !important;
  }
  .rounded-sm-circle {
    border-radius: 50% !important;
  }
  .rounded-sm-card {
    border-radius: 0.25rem !important;
  }
  .rounded-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-sm-1 {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-sm-2 {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-sm-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-sm-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-sm-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-sm-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-sm-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-sm-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-sm-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-sm-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-sm-card {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-end-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-sm-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-sm-1 {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-end-sm-2 {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-end-sm-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-sm-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-sm-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-sm-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-sm-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-sm-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-sm-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-sm-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-sm-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-sm-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-sm-card {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-sm {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-sm-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-sm-1 {
    border-bottom-right-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-sm-2 {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-sm-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-sm-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-sm-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-sm-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-sm-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-sm-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-sm-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-sm-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-sm-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-sm-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-sm-card {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-start-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-sm-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-sm-1 {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-start-sm-2 {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-start-sm-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-sm-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-sm-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-sm-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-sm-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-sm-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-sm-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-sm-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-sm-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-sm-card {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-sm {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-sm-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-sm-1 {
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-top-start-sm-2 {
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-top-start-sm-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-sm-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-sm-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-sm-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-sm-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-sm-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-sm-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-sm-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-sm-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-sm-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-sm-card {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-end-sm {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-sm-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-sm-1 {
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-end-sm-2 {
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-end-sm-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-sm-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-sm-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-sm-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-sm-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-sm-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-sm-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-sm-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-sm-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-sm-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-sm-card {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-sm {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-sm-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-sm-1 {
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-bottom-end-sm-2 {
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-bottom-end-sm-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-sm-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-sm-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-sm-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-sm-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-sm-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-sm-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-sm-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-sm-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-sm-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-sm-card {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-start-sm {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-sm-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-sm-1 {
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-start-sm-2 {
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-start-sm-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-sm-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-sm-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-sm-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-sm-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-sm-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-sm-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-sm-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-sm-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-sm-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-sm-card {
    border-bottom-left-radius: 0.25rem !important;
  }
  .border-sm-solid {
    border-style: solid !important;
  }
  .border-sm-dashed {
    border-style: dashed !important;
  }
  .border-sm-dotted {
    border-style: dotted !important;
  }
  .border-sm-double {
    border-style: double !important;
  }
  .border-sm-groove {
    border-style: groove !important;
  }
  .border-sm-none {
    border-style: none !important;
  }
  .text-sm-transparent {
    color: transparent !important;
  }
  .text-sm-transparent-hover:hover {
    color: transparent !important;
  }
  .text-sm-transparent-focus:focus {
    color: transparent !important;
  }
  .text-sm-current {
    color: currentColor !important;
  }
  .text-sm-current-hover:hover {
    color: currentColor !important;
  }
  .text-sm-current-focus:focus {
    color: currentColor !important;
  }
  .text-sm-reset {
    color: inherit !important;
  }
  .text-sm-reset-hover:hover {
    color: inherit !important;
  }
  .text-sm-reset-focus:focus {
    color: inherit !important;
  }
  .shadow-soft-sm-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-1-focus:focus {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-2-focus:focus {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-sm-none {
    box-shadow: none !important;
  }
  .shadow-soft-sm-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-soft-sm-none-focus:focus {
    box-shadow: none !important;
  }
  .min-w-sm-0 {
    min-width: 0 !important;
  }
  .min-w-sm-full {
    min-width: 100% !important;
  }
  .min-w-sm-min {
    min-width: min-content !important;
  }
  .min-w-sm-max {
    min-width: max-content !important;
  }
  .min-h-sm-0 {
    min-height: 0 !important;
  }
  .min-h-sm-full {
    min-height: 100% !important;
  }
  .min-h-sm-screen {
    min-height: 100vh !important;
  }
  .place-content-sm-start {
    place-content: flex-start !important;
  }
  .place-content-sm-end {
    place-content: flex-end !important;
  }
  .place-content-sm-center {
    place-content: center !important;
  }
  .place-content-sm-between {
    place-content: space-between !important;
  }
  .place-content-sm-around {
    place-content: space-around !important;
  }
  .place-content-sm-evenly {
    place-content: space-evenly !important;
  }
  .place-content-sm-stretch {
    place-content: stretch !important;
  }
  .overflow-x-sm-auto {
    overflow-x: auto !important;
  }
  .overflow-x-sm-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-sm-visible {
    overflow-x: visible !important;
  }
  .overflow-x-sm-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-sm-auto {
    overflow-y: auto !important;
  }
  .overflow-y-sm-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-sm-visible {
    overflow-y: visible !important;
  }
  .overflow-y-sm-scroll {
    overflow-y: scroll !important;
  }
  .transition-sm {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }
  .transition-sm-none {
    transition-property: none !important;
  }
  .transition-sm-all {
    transition-property: all !important;
  }
  .transition-sm-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-sm-opacity {
    transition-property: opacity !important;
  }
  .transition-sm-shadow {
    transition-property: box-shadow !important;
  }
  .transition-sm-transform {
    transition-property: transform !important;
  }
  .ease-sm-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-sm-linear {
    transition-timing-function: linear !important;
  }
  .ease-sm-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-sm-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-sm-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .transform-sm-none {
    transform: none !important;
  }
  .origin-sm-center {
    transform-origin: center !important;
  }
  .origin-sm-top {
    transform-origin: top !important;
  }
  .origin-sm-top-right {
    transform-origin: top right !important;
  }
  .origin-sm-right {
    transform-origin: right !important;
  }
  .origin-sm-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-sm-bottom {
    transform-origin: bottom !important;
  }
  .origin-sm-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-sm-left {
    transform-origin: left !important;
  }
  .origin-sm-top-left {
    transform-origin: top left !important;
  }
  .scale-sm-0 {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-sm-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-sm-0-focus:focus {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-sm-25 {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-sm-25-hover:hover {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-sm-25-focus:focus {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-sm-50 {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-sm-50-hover:hover {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-sm-50-focus:focus {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-sm-75 {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-sm-75-hover:hover {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-sm-75-focus:focus {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-sm-90 {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-sm-90-hover:hover {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-sm-90-focus:focus {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-sm-95 {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-sm-95-hover:hover {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-sm-95-focus:focus {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-sm-100 {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-sm-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-sm-100-focus:focus {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-sm-105 {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-sm-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-sm-105-focus:focus {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-sm-110 {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-sm-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-sm-110-focus:focus {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-sm-125 {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-sm-125-hover:hover {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-sm-125-focus:focus {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-sm-150 {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-sm-150-hover:hover {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-sm-150-focus:focus {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-sm-200 {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-sm-200-hover:hover {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-sm-200-focus:focus {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-y-sm-0 {
    --bs--scale-y: 0 !important;
  }
  .scale-y-sm-0-hover:hover {
    --bs--scale-y: 0 !important;
  }
  .scale-y-sm-0-focus:focus {
    --bs--scale-y: 0 !important;
  }
  .scale-y-sm-25 {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-sm-25-hover:hover {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-sm-25-focus:focus {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-sm-50 {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-sm-50-hover:hover {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-sm-50-focus:focus {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-sm-75 {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-sm-75-hover:hover {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-sm-75-focus:focus {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-sm-90 {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-sm-90-hover:hover {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-sm-90-focus:focus {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-sm-95 {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-sm-95-hover:hover {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-sm-95-focus:focus {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-sm-100 {
    --bs--scale-y: 1 !important;
  }
  .scale-y-sm-100-hover:hover {
    --bs--scale-y: 1 !important;
  }
  .scale-y-sm-100-focus:focus {
    --bs--scale-y: 1 !important;
  }
  .scale-y-sm-105 {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-sm-105-hover:hover {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-sm-105-focus:focus {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-sm-110 {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-sm-110-hover:hover {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-sm-110-focus:focus {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-sm-125 {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-sm-125-hover:hover {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-sm-125-focus:focus {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-sm-150 {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-sm-150-hover:hover {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-sm-150-focus:focus {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-sm-200 {
    --bs--scale-y: 2 !important;
  }
  .scale-y-sm-200-hover:hover {
    --bs--scale-y: 2 !important;
  }
  .scale-y-sm-200-focus:focus {
    --bs--scale-y: 2 !important;
  }
  .scale-x-sm-0 {
    --bs--scale-x: 0 !important;
  }
  .scale-x-sm-0-hover:hover {
    --bs--scale-x: 0 !important;
  }
  .scale-x-sm-0-focus:focus {
    --bs--scale-x: 0 !important;
  }
  .scale-x-sm-25 {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-sm-25-hover:hover {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-sm-25-focus:focus {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-sm-50 {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-sm-50-hover:hover {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-sm-50-focus:focus {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-sm-75 {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-sm-75-hover:hover {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-sm-75-focus:focus {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-sm-90 {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-sm-90-hover:hover {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-sm-90-focus:focus {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-sm-95 {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-sm-95-hover:hover {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-sm-95-focus:focus {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-sm-100 {
    --bs--scale-x: 1 !important;
  }
  .scale-x-sm-100-hover:hover {
    --bs--scale-x: 1 !important;
  }
  .scale-x-sm-100-focus:focus {
    --bs--scale-x: 1 !important;
  }
  .scale-x-sm-105 {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-sm-105-hover:hover {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-sm-105-focus:focus {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-sm-110 {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-sm-110-hover:hover {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-sm-110-focus:focus {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-sm-125 {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-sm-125-hover:hover {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-sm-125-focus:focus {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-sm-150 {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-sm-150-hover:hover {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-sm-150-focus:focus {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-sm-200 {
    --bs--scale-x: 2 !important;
  }
  .scale-x-sm-200-hover:hover {
    --bs--scale-x: 2 !important;
  }
  .scale-x-sm-200-focus:focus {
    --bs--scale-x: 2 !important;
  }
  .rotate-sm-0 {
    --bs-rotate: 0deg !important;
  }
  .rotate-sm-0-hover:hover {
    --bs-rotate: 0deg !important;
  }
  .rotate-sm-0-focus:focus {
    --bs-rotate: 0deg !important;
  }
  .rotate-sm-1 {
    --bs-rotate: 1deg !important;
  }
  .rotate-sm-1-hover:hover {
    --bs-rotate: 1deg !important;
  }
  .rotate-sm-1-focus:focus {
    --bs-rotate: 1deg !important;
  }
  .rotate-sm-2 {
    --bs-rotate: 2deg !important;
  }
  .rotate-sm-2-hover:hover {
    --bs-rotate: 2deg !important;
  }
  .rotate-sm-2-focus:focus {
    --bs-rotate: 2deg !important;
  }
  .rotate-sm-3 {
    --bs-rotate: 3deg !important;
  }
  .rotate-sm-3-hover:hover {
    --bs-rotate: 3deg !important;
  }
  .rotate-sm-3-focus:focus {
    --bs-rotate: 3deg !important;
  }
  .rotate-sm-6 {
    --bs-rotate: 6deg !important;
  }
  .rotate-sm-6-hover:hover {
    --bs-rotate: 6deg !important;
  }
  .rotate-sm-6-focus:focus {
    --bs-rotate: 6deg !important;
  }
  .rotate-sm-12 {
    --bs-rotate: 12deg !important;
  }
  .rotate-sm-12-hover:hover {
    --bs-rotate: 12deg !important;
  }
  .rotate-sm-12-focus:focus {
    --bs-rotate: 12deg !important;
  }
  .rotate-sm-30 {
    --bs-rotate: 30deg !important;
  }
  .rotate-sm-30-hover:hover {
    --bs-rotate: 30deg !important;
  }
  .rotate-sm-30-focus:focus {
    --bs-rotate: 30deg !important;
  }
  .rotate-sm-45 {
    --bs-rotate: 45deg !important;
  }
  .rotate-sm-45-hover:hover {
    --bs-rotate: 45deg !important;
  }
  .rotate-sm-45-focus:focus {
    --bs-rotate: 45deg !important;
  }
  .rotate-sm-90 {
    --bs-rotate: 90deg !important;
  }
  .rotate-sm-90-hover:hover {
    --bs-rotate: 90deg !important;
  }
  .rotate-sm-90-focus:focus {
    --bs-rotate: 90deg !important;
  }
  .rotate-sm-180 {
    --bs-rotate: 180deg !important;
  }
  .rotate-sm-180-hover:hover {
    --bs-rotate: 180deg !important;
  }
  .rotate-sm-180-focus:focus {
    --bs-rotate: 180deg !important;
  }
  .rotate-sm-n1 {
    --bs-rotate: -1deg !important;
  }
  .rotate-sm-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }
  .rotate-sm-n1-focus:focus {
    --bs-rotate: -1deg !important;
  }
  .rotate-sm-n2 {
    --bs-rotate: -2deg !important;
  }
  .rotate-sm-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }
  .rotate-sm-n2-focus:focus {
    --bs-rotate: -2deg !important;
  }
  .rotate-sm-n3 {
    --bs-rotate: -3deg !important;
  }
  .rotate-sm-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }
  .rotate-sm-n3-focus:focus {
    --bs-rotate: -3deg !important;
  }
  .rotate-sm-n6 {
    --bs-rotate: -6deg !important;
  }
  .rotate-sm-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }
  .rotate-sm-n6-focus:focus {
    --bs-rotate: -6deg !important;
  }
  .rotate-sm-n12 {
    --bs-rotate: -12deg !important;
  }
  .rotate-sm-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }
  .rotate-sm-n12-focus:focus {
    --bs-rotate: -12deg !important;
  }
  .rotate-sm-n30 {
    --bs-rotate: -30deg !important;
  }
  .rotate-sm-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }
  .rotate-sm-n30-focus:focus {
    --bs-rotate: -30deg !important;
  }
  .rotate-sm-n45 {
    --bs-rotate: -45deg !important;
  }
  .rotate-sm-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }
  .rotate-sm-n45-focus:focus {
    --bs-rotate: -45deg !important;
  }
  .rotate-sm-n90 {
    --bs-rotate: -90deg !important;
  }
  .rotate-sm-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }
  .rotate-sm-n90-focus:focus {
    --bs-rotate: -90deg !important;
  }
  .rotate-sm-n180 {
    --bs-rotate: -180deg !important;
  }
  .rotate-sm-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }
  .rotate-sm-n180-focus:focus {
    --bs-rotate: -180deg !important;
  }
  .rotate-y-sm-0 {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-sm-0-hover:hover {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-sm-0-focus:focus {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-sm-1 {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-sm-1-hover:hover {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-sm-1-focus:focus {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-sm-2 {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-sm-2-hover:hover {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-sm-2-focus:focus {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-sm-3 {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-sm-3-hover:hover {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-sm-3-focus:focus {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-sm-6 {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-sm-6-hover:hover {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-sm-6-focus:focus {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-sm-12 {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-sm-12-hover:hover {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-sm-12-focus:focus {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-sm-30 {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-sm-30-hover:hover {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-sm-30-focus:focus {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-sm-45 {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-sm-45-hover:hover {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-sm-45-focus:focus {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-sm-90 {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-sm-90-hover:hover {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-sm-90-focus:focus {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-sm-180 {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-sm-180-hover:hover {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-sm-180-focus:focus {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-sm-n1 {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-sm-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-sm-n1-focus:focus {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-sm-n2 {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-sm-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-sm-n2-focus:focus {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-sm-n3 {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-sm-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-sm-n3-focus:focus {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-sm-n6 {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-sm-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-sm-n6-focus:focus {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-sm-n12 {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-sm-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-sm-n12-focus:focus {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-sm-n30 {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-sm-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-sm-n30-focus:focus {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-sm-n45 {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-sm-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-sm-n45-focus:focus {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-sm-n90 {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-sm-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-sm-n90-focus:focus {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-sm-n180 {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-sm-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-sm-n180-focus:focus {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-x-sm-0 {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-sm-0-hover:hover {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-sm-0-focus:focus {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-sm-1 {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-sm-1-hover:hover {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-sm-1-focus:focus {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-sm-2 {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-sm-2-hover:hover {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-sm-2-focus:focus {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-sm-3 {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-sm-3-hover:hover {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-sm-3-focus:focus {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-sm-6 {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-sm-6-hover:hover {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-sm-6-focus:focus {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-sm-12 {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-sm-12-hover:hover {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-sm-12-focus:focus {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-sm-30 {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-sm-30-hover:hover {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-sm-30-focus:focus {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-sm-45 {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-sm-45-hover:hover {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-sm-45-focus:focus {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-sm-90 {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-sm-90-hover:hover {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-sm-90-focus:focus {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-sm-180 {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-sm-180-hover:hover {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-sm-180-focus:focus {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-sm-n1 {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-sm-n1-hover:hover {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-sm-n1-focus:focus {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-sm-n2 {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-sm-n2-hover:hover {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-sm-n2-focus:focus {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-sm-n3 {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-sm-n3-hover:hover {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-sm-n3-focus:focus {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-sm-n6 {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-sm-n6-hover:hover {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-sm-n6-focus:focus {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-sm-n12 {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-sm-n12-hover:hover {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-sm-n12-focus:focus {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-sm-n30 {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-sm-n30-hover:hover {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-sm-n30-focus:focus {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-sm-n45 {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-sm-n45-hover:hover {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-sm-n45-focus:focus {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-sm-n90 {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-sm-n90-hover:hover {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-sm-n90-focus:focus {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-sm-n180 {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-sm-n180-hover:hover {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-sm-n180-focus:focus {
    --bs-rotate-x: -180deg !important;
  }
  .perspective-sm-100 {
    --bs-perspective: 100px !important;
  }
  .perspective-sm-100-hover:hover {
    --bs-perspective: 100px !important;
  }
  .perspective-sm-100-focus:focus {
    --bs-perspective: 100px !important;
  }
  .perspective-sm-200 {
    --bs-perspective: 200px !important;
  }
  .perspective-sm-200-hover:hover {
    --bs-perspective: 200px !important;
  }
  .perspective-sm-200-focus:focus {
    --bs-perspective: 200px !important;
  }
  .perspective-sm-300 {
    --bs-perspective: 300px !important;
  }
  .perspective-sm-300-hover:hover {
    --bs-perspective: 300px !important;
  }
  .perspective-sm-300-focus:focus {
    --bs-perspective: 300px !important;
  }
  .perspective-sm-400 {
    --bs-perspective: 400px !important;
  }
  .perspective-sm-400-hover:hover {
    --bs-perspective: 400px !important;
  }
  .perspective-sm-400-focus:focus {
    --bs-perspective: 400px !important;
  }
  .perspective-sm-500 {
    --bs-perspective: 500px !important;
  }
  .perspective-sm-500-hover:hover {
    --bs-perspective: 500px !important;
  }
  .perspective-sm-500-focus:focus {
    --bs-perspective: 500px !important;
  }
  .perspective-sm-600 {
    --bs-perspective: 600px !important;
  }
  .perspective-sm-600-hover:hover {
    --bs-perspective: 600px !important;
  }
  .perspective-sm-600-focus:focus {
    --bs-perspective: 600px !important;
  }
  .perspective-sm-700 {
    --bs-perspective: 700px !important;
  }
  .perspective-sm-700-hover:hover {
    --bs-perspective: 700px !important;
  }
  .perspective-sm-700-focus:focus {
    --bs-perspective: 700px !important;
  }
  .perspective-sm-800 {
    --bs-perspective: 800px !important;
  }
  .perspective-sm-800-hover:hover {
    --bs-perspective: 800px !important;
  }
  .perspective-sm-800-focus:focus {
    --bs-perspective: 800px !important;
  }
  .perspective-sm-1000 {
    --bs-perspective: 1000px !important;
  }
  .perspective-sm-1000-hover:hover {
    --bs-perspective: 1000px !important;
  }
  .perspective-sm-1000-focus:focus {
    --bs-perspective: 1000px !important;
  }
  .perspective-sm-1250 {
    --bs-perspective: 1250px !important;
  }
  .perspective-sm-1250-hover:hover {
    --bs-perspective: 1250px !important;
  }
  .perspective-sm-1250-focus:focus {
    --bs-perspective: 1250px !important;
  }
  .perspective-sm-1500 {
    --bs-perspective: 1500px !important;
  }
  .perspective-sm-1500-hover:hover {
    --bs-perspective: 1500px !important;
  }
  .perspective-sm-1500-focus:focus {
    --bs-perspective: 1500px !important;
  }
  .translate-y-sm-0 {
    --bs-translate-y: 0 !important;
  }
  .translate-y-sm-0-hover:hover {
    --bs-translate-y: 0 !important;
  }
  .translate-y-sm-0-focus:focus {
    --bs-translate-y: 0 !important;
  }
  .translate-y-sm-1 {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-sm-1-hover:hover {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-sm-1-focus:focus {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-sm-2 {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-sm-2-hover:hover {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-sm-2-focus:focus {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-sm-3 {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-sm-3-hover:hover {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-sm-3-focus:focus {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-sm-4 {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-sm-4-hover:hover {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-sm-4-focus:focus {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-sm-5 {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-sm-5-hover:hover {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-sm-5-focus:focus {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-sm-6 {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-sm-6-hover:hover {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-sm-6-focus:focus {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-sm-7 {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-sm-7-hover:hover {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-sm-7-focus:focus {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-sm-8 {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-sm-8-hover:hover {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-sm-8-focus:focus {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-sm-10 {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-sm-10-hover:hover {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-sm-10-focus:focus {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-sm-12 {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-sm-12-hover:hover {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-sm-12-focus:focus {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-sm-14 {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-sm-14-hover:hover {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-sm-14-focus:focus {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-sm-16 {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-sm-16-hover:hover {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-sm-16-focus:focus {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-sm-18 {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-sm-18-hover:hover {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-sm-18-focus:focus {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-sm-20 {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-sm-20-hover:hover {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-sm-20-focus:focus {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-sm-24 {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-sm-24-hover:hover {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-sm-24-focus:focus {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-sm-32 {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-sm-32-hover:hover {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-sm-32-focus:focus {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-sm-40 {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-sm-40-hover:hover {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-sm-40-focus:focus {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-sm-48 {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-sm-48-hover:hover {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-sm-48-focus:focus {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-sm-56 {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-sm-56-hover:hover {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-sm-56-focus:focus {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-sm-64 {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-sm-64-hover:hover {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-sm-64-focus:focus {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-sm-72 {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-sm-72-hover:hover {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-sm-72-focus:focus {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-sm-80 {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-sm-80-hover:hover {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-sm-80-focus:focus {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-sm-88 {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-sm-88-hover:hover {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-sm-88-focus:focus {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-sm-96 {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-sm-96-hover:hover {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-sm-96-focus:focus {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-sm-px {
    --bs-translate-y: 1px !important;
  }
  .translate-y-sm-px-hover:hover {
    --bs-translate-y: 1px !important;
  }
  .translate-y-sm-px-focus:focus {
    --bs-translate-y: 1px !important;
  }
  .translate-y-sm-1\/2 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-1\/2-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-1\/2-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-1\/3 {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-sm-1\/3-hover:hover {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-sm-1\/3-focus:focus {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-sm-1\/4 {
    --bs-translate-y: 25% !important;
  }
  .translate-y-sm-1\/4-hover:hover {
    --bs-translate-y: 25% !important;
  }
  .translate-y-sm-1\/4-focus:focus {
    --bs-translate-y: 25% !important;
  }
  .translate-y-sm-1\/5 {
    --bs-translate-y: 20% !important;
  }
  .translate-y-sm-1\/5-hover:hover {
    --bs-translate-y: 20% !important;
  }
  .translate-y-sm-1\/5-focus:focus {
    --bs-translate-y: 20% !important;
  }
  .translate-y-sm-2\/3 {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-sm-2\/3-hover:hover {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-sm-2\/3-focus:focus {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-sm-2\/5 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-2\/5-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-2\/5-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-sm-3\/4 {
    --bs-translate-y: 75% !important;
  }
  .translate-y-sm-3\/4-hover:hover {
    --bs-translate-y: 75% !important;
  }
  .translate-y-sm-3\/4-focus:focus {
    --bs-translate-y: 75% !important;
  }
  .translate-y-sm-full {
    --bs-translate-y: 100% !important;
  }
  .translate-y-sm-full-hover:hover {
    --bs-translate-y: 100% !important;
  }
  .translate-y-sm-full-focus:focus {
    --bs-translate-y: 100% !important;
  }
  .translate-y-sm-n1 {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-sm-n1-hover:hover {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-sm-n1-focus:focus {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-sm-n2 {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-sm-n2-hover:hover {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-sm-n2-focus:focus {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-sm-n3 {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-sm-n3-hover:hover {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-sm-n3-focus:focus {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-sm-n4 {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-sm-n4-hover:hover {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-sm-n4-focus:focus {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-sm-n5 {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-sm-n5-hover:hover {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-sm-n5-focus:focus {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-sm-n6 {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-sm-n6-hover:hover {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-sm-n6-focus:focus {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-sm-n7 {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-sm-n7-hover:hover {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-sm-n7-focus:focus {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-sm-n8 {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-sm-n8-hover:hover {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-sm-n8-focus:focus {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-sm-n10 {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-sm-n10-hover:hover {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-sm-n10-focus:focus {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-sm-n12 {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-sm-n12-hover:hover {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-sm-n12-focus:focus {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-sm-n14 {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-sm-n14-hover:hover {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-sm-n14-focus:focus {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-sm-n16 {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-sm-n16-hover:hover {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-sm-n16-focus:focus {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-sm-n18 {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-sm-n18-hover:hover {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-sm-n18-focus:focus {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-sm-n20 {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-sm-n20-hover:hover {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-sm-n20-focus:focus {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-sm-n24 {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-sm-n24-hover:hover {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-sm-n24-focus:focus {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-sm-n32 {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-sm-n32-hover:hover {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-sm-n32-focus:focus {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-sm-n40 {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-sm-n40-hover:hover {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-sm-n40-focus:focus {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-sm-n48 {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-sm-n48-hover:hover {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-sm-n48-focus:focus {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-sm-n56 {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-sm-n56-hover:hover {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-sm-n56-focus:focus {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-sm-n64 {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-sm-n64-hover:hover {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-sm-n64-focus:focus {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-sm-n72 {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-sm-n72-hover:hover {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-sm-n72-focus:focus {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-sm-n80 {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-sm-n80-hover:hover {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-sm-n80-focus:focus {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-sm-n88 {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-sm-n88-hover:hover {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-sm-n88-focus:focus {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-sm-n96 {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-sm-n96-hover:hover {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-sm-n96-focus:focus {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-sm-npx {
    --bs-translate-y: -1px !important;
  }
  .translate-y-sm-npx-hover:hover {
    --bs-translate-y: -1px !important;
  }
  .translate-y-sm-npx-focus:focus {
    --bs-translate-y: -1px !important;
  }
  .translate-y-sm-n1\/2 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n1\/2-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n1\/2-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n1\/3 {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-sm-n1\/3-hover:hover {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-sm-n1\/3-focus:focus {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-sm-n1\/4 {
    --bs-translate-y: -25% !important;
  }
  .translate-y-sm-n1\/4-hover:hover {
    --bs-translate-y: -25% !important;
  }
  .translate-y-sm-n1\/4-focus:focus {
    --bs-translate-y: -25% !important;
  }
  .translate-y-sm-n1\/5 {
    --bs-translate-y: -20% !important;
  }
  .translate-y-sm-n1\/5-hover:hover {
    --bs-translate-y: -20% !important;
  }
  .translate-y-sm-n1\/5-focus:focus {
    --bs-translate-y: -20% !important;
  }
  .translate-y-sm-n2\/3 {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-sm-n2\/3-hover:hover {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-sm-n2\/3-focus:focus {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-sm-n2\/5 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n2\/5-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n2\/5-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-sm-n3\/4 {
    --bs-translate-y: -75% !important;
  }
  .translate-y-sm-n3\/4-hover:hover {
    --bs-translate-y: -75% !important;
  }
  .translate-y-sm-n3\/4-focus:focus {
    --bs-translate-y: -75% !important;
  }
  .translate-y-sm-nfull {
    --bs-translate-y: -100% !important;
  }
  .translate-y-sm-nfull-hover:hover {
    --bs-translate-y: -100% !important;
  }
  .translate-y-sm-nfull-focus:focus {
    --bs-translate-y: -100% !important;
  }
  .translate-x-sm-0 {
    --bs-translate-x: 0 !important;
  }
  .translate-x-sm-0-hover:hover {
    --bs-translate-x: 0 !important;
  }
  .translate-x-sm-0-focus:focus {
    --bs-translate-x: 0 !important;
  }
  .translate-x-sm-1 {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-sm-1-hover:hover {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-sm-1-focus:focus {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-sm-2 {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-sm-2-hover:hover {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-sm-2-focus:focus {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-sm-3 {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-sm-3-hover:hover {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-sm-3-focus:focus {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-sm-4 {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-sm-4-hover:hover {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-sm-4-focus:focus {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-sm-5 {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-sm-5-hover:hover {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-sm-5-focus:focus {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-sm-6 {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-sm-6-hover:hover {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-sm-6-focus:focus {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-sm-7 {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-sm-7-hover:hover {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-sm-7-focus:focus {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-sm-8 {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-sm-8-hover:hover {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-sm-8-focus:focus {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-sm-10 {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-sm-10-hover:hover {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-sm-10-focus:focus {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-sm-12 {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-sm-12-hover:hover {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-sm-12-focus:focus {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-sm-14 {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-sm-14-hover:hover {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-sm-14-focus:focus {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-sm-16 {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-sm-16-hover:hover {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-sm-16-focus:focus {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-sm-18 {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-sm-18-hover:hover {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-sm-18-focus:focus {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-sm-20 {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-sm-20-hover:hover {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-sm-20-focus:focus {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-sm-24 {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-sm-24-hover:hover {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-sm-24-focus:focus {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-sm-32 {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-sm-32-hover:hover {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-sm-32-focus:focus {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-sm-40 {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-sm-40-hover:hover {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-sm-40-focus:focus {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-sm-48 {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-sm-48-hover:hover {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-sm-48-focus:focus {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-sm-56 {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-sm-56-hover:hover {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-sm-56-focus:focus {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-sm-64 {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-sm-64-hover:hover {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-sm-64-focus:focus {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-sm-72 {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-sm-72-hover:hover {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-sm-72-focus:focus {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-sm-80 {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-sm-80-hover:hover {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-sm-80-focus:focus {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-sm-88 {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-sm-88-hover:hover {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-sm-88-focus:focus {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-sm-96 {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-sm-96-hover:hover {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-sm-96-focus:focus {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-sm-px {
    --bs-translate-x: 1px !important;
  }
  .translate-x-sm-px-hover:hover {
    --bs-translate-x: 1px !important;
  }
  .translate-x-sm-px-focus:focus {
    --bs-translate-x: 1px !important;
  }
  .translate-x-sm-1\/2 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-1\/2-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-1\/2-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-1\/3 {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-sm-1\/3-hover:hover {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-sm-1\/3-focus:focus {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-sm-1\/4 {
    --bs-translate-x: 25% !important;
  }
  .translate-x-sm-1\/4-hover:hover {
    --bs-translate-x: 25% !important;
  }
  .translate-x-sm-1\/4-focus:focus {
    --bs-translate-x: 25% !important;
  }
  .translate-x-sm-1\/5 {
    --bs-translate-x: 20% !important;
  }
  .translate-x-sm-1\/5-hover:hover {
    --bs-translate-x: 20% !important;
  }
  .translate-x-sm-1\/5-focus:focus {
    --bs-translate-x: 20% !important;
  }
  .translate-x-sm-2\/3 {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-sm-2\/3-hover:hover {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-sm-2\/3-focus:focus {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-sm-2\/5 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-2\/5-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-2\/5-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-sm-3\/4 {
    --bs-translate-x: 75% !important;
  }
  .translate-x-sm-3\/4-hover:hover {
    --bs-translate-x: 75% !important;
  }
  .translate-x-sm-3\/4-focus:focus {
    --bs-translate-x: 75% !important;
  }
  .translate-x-sm-full {
    --bs-translate-x: 100% !important;
  }
  .translate-x-sm-full-hover:hover {
    --bs-translate-x: 100% !important;
  }
  .translate-x-sm-full-focus:focus {
    --bs-translate-x: 100% !important;
  }
  .translate-x-sm-n1 {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-sm-n1-hover:hover {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-sm-n1-focus:focus {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-sm-n2 {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-sm-n2-hover:hover {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-sm-n2-focus:focus {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-sm-n3 {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-sm-n3-hover:hover {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-sm-n3-focus:focus {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-sm-n4 {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-sm-n4-hover:hover {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-sm-n4-focus:focus {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-sm-n5 {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-sm-n5-hover:hover {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-sm-n5-focus:focus {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-sm-n6 {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-sm-n6-hover:hover {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-sm-n6-focus:focus {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-sm-n7 {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-sm-n7-hover:hover {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-sm-n7-focus:focus {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-sm-n8 {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-sm-n8-hover:hover {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-sm-n8-focus:focus {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-sm-n10 {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-sm-n10-hover:hover {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-sm-n10-focus:focus {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-sm-n12 {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-sm-n12-hover:hover {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-sm-n12-focus:focus {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-sm-n14 {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-sm-n14-hover:hover {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-sm-n14-focus:focus {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-sm-n16 {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-sm-n16-hover:hover {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-sm-n16-focus:focus {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-sm-n18 {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-sm-n18-hover:hover {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-sm-n18-focus:focus {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-sm-n20 {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-sm-n20-hover:hover {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-sm-n20-focus:focus {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-sm-n24 {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-sm-n24-hover:hover {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-sm-n24-focus:focus {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-sm-n32 {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-sm-n32-hover:hover {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-sm-n32-focus:focus {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-sm-n40 {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-sm-n40-hover:hover {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-sm-n40-focus:focus {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-sm-n48 {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-sm-n48-hover:hover {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-sm-n48-focus:focus {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-sm-n56 {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-sm-n56-hover:hover {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-sm-n56-focus:focus {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-sm-n64 {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-sm-n64-hover:hover {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-sm-n64-focus:focus {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-sm-n72 {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-sm-n72-hover:hover {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-sm-n72-focus:focus {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-sm-n80 {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-sm-n80-hover:hover {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-sm-n80-focus:focus {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-sm-n88 {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-sm-n88-hover:hover {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-sm-n88-focus:focus {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-sm-n96 {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-sm-n96-hover:hover {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-sm-n96-focus:focus {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-sm-npx {
    --bs-translate-x: -1px !important;
  }
  .translate-x-sm-npx-hover:hover {
    --bs-translate-x: -1px !important;
  }
  .translate-x-sm-npx-focus:focus {
    --bs-translate-x: -1px !important;
  }
  .translate-x-sm-n1\/2 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n1\/2-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n1\/2-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n1\/3 {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-sm-n1\/3-hover:hover {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-sm-n1\/3-focus:focus {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-sm-n1\/4 {
    --bs-translate-x: -25% !important;
  }
  .translate-x-sm-n1\/4-hover:hover {
    --bs-translate-x: -25% !important;
  }
  .translate-x-sm-n1\/4-focus:focus {
    --bs-translate-x: -25% !important;
  }
  .translate-x-sm-n1\/5 {
    --bs-translate-x: -20% !important;
  }
  .translate-x-sm-n1\/5-hover:hover {
    --bs-translate-x: -20% !important;
  }
  .translate-x-sm-n1\/5-focus:focus {
    --bs-translate-x: -20% !important;
  }
  .translate-x-sm-n2\/3 {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-sm-n2\/3-hover:hover {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-sm-n2\/3-focus:focus {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-sm-n2\/5 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n2\/5-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n2\/5-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-sm-n3\/4 {
    --bs-translate-x: -75% !important;
  }
  .translate-x-sm-n3\/4-hover:hover {
    --bs-translate-x: -75% !important;
  }
  .translate-x-sm-n3\/4-focus:focus {
    --bs-translate-x: -75% !important;
  }
  .translate-x-sm-nfull {
    --bs-translate-x: -100% !important;
  }
  .translate-x-sm-nfull-hover:hover {
    --bs-translate-x: -100% !important;
  }
  .translate-x-sm-nfull-focus:focus {
    --bs-translate-x: -100% !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .overflow-md-auto {
    overflow: auto !important;
  }
  .overflow-md-hidden {
    overflow: hidden !important;
  }
  .overflow-md-visible {
    overflow: visible !important;
  }
  .overflow-md-scroll {
    overflow: scroll !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .shadow-md {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-md-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-md-focus:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-md-1 {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-1-hover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-1-focus:focus {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-2 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-md-2-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-md-2-focus:focus {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-md-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-md-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-inset-focus:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-md-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-outline-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-focus-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-md-none {
    box-shadow: none !important;
  }
  .shadow-md-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-md-none-focus:focus {
    box-shadow: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .top-md-1 {
    top: 0.25rem !important;
  }
  .top-md-2 {
    top: 0.5rem !important;
  }
  .top-md-3 {
    top: 0.75rem !important;
  }
  .top-md-4 {
    top: 1rem !important;
  }
  .top-md-5 {
    top: 1.25rem !important;
  }
  .top-md-6 {
    top: 1.5rem !important;
  }
  .top-md-7 {
    top: 1.75rem !important;
  }
  .top-md-8 {
    top: 2rem !important;
  }
  .top-md-10 {
    top: 2.5rem !important;
  }
  .top-md-12 {
    top: 3rem !important;
  }
  .top-md-14 {
    top: 3.5rem !important;
  }
  .top-md-16 {
    top: 4rem !important;
  }
  .top-md-18 {
    top: 4.5rem !important;
  }
  .top-md-20 {
    top: 5rem !important;
  }
  .top-md-24 {
    top: 6rem !important;
  }
  .top-md-32 {
    top: 8rem !important;
  }
  .top-md-40 {
    top: 10rem !important;
  }
  .top-md-48 {
    top: 12rem !important;
  }
  .top-md-56 {
    top: 14rem !important;
  }
  .top-md-64 {
    top: 16rem !important;
  }
  .top-md-72 {
    top: 18rem !important;
  }
  .top-md-80 {
    top: 20rem !important;
  }
  .top-md-88 {
    top: 22rem !important;
  }
  .top-md-96 {
    top: 24rem !important;
  }
  .top-md-px {
    top: 1px !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .bottom-md-1 {
    bottom: 0.25rem !important;
  }
  .bottom-md-2 {
    bottom: 0.5rem !important;
  }
  .bottom-md-3 {
    bottom: 0.75rem !important;
  }
  .bottom-md-4 {
    bottom: 1rem !important;
  }
  .bottom-md-5 {
    bottom: 1.25rem !important;
  }
  .bottom-md-6 {
    bottom: 1.5rem !important;
  }
  .bottom-md-7 {
    bottom: 1.75rem !important;
  }
  .bottom-md-8 {
    bottom: 2rem !important;
  }
  .bottom-md-10 {
    bottom: 2.5rem !important;
  }
  .bottom-md-12 {
    bottom: 3rem !important;
  }
  .bottom-md-14 {
    bottom: 3.5rem !important;
  }
  .bottom-md-16 {
    bottom: 4rem !important;
  }
  .bottom-md-18 {
    bottom: 4.5rem !important;
  }
  .bottom-md-20 {
    bottom: 5rem !important;
  }
  .bottom-md-24 {
    bottom: 6rem !important;
  }
  .bottom-md-32 {
    bottom: 8rem !important;
  }
  .bottom-md-40 {
    bottom: 10rem !important;
  }
  .bottom-md-48 {
    bottom: 12rem !important;
  }
  .bottom-md-56 {
    bottom: 14rem !important;
  }
  .bottom-md-64 {
    bottom: 16rem !important;
  }
  .bottom-md-72 {
    bottom: 18rem !important;
  }
  .bottom-md-80 {
    bottom: 20rem !important;
  }
  .bottom-md-88 {
    bottom: 22rem !important;
  }
  .bottom-md-96 {
    bottom: 24rem !important;
  }
  .bottom-md-px {
    bottom: 1px !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
  .start-md-0 {
    left: 0 !important;
  }
  .start-md-1 {
    left: 0.25rem !important;
  }
  .start-md-2 {
    left: 0.5rem !important;
  }
  .start-md-3 {
    left: 0.75rem !important;
  }
  .start-md-4 {
    left: 1rem !important;
  }
  .start-md-5 {
    left: 1.25rem !important;
  }
  .start-md-6 {
    left: 1.5rem !important;
  }
  .start-md-7 {
    left: 1.75rem !important;
  }
  .start-md-8 {
    left: 2rem !important;
  }
  .start-md-10 {
    left: 2.5rem !important;
  }
  .start-md-12 {
    left: 3rem !important;
  }
  .start-md-14 {
    left: 3.5rem !important;
  }
  .start-md-16 {
    left: 4rem !important;
  }
  .start-md-18 {
    left: 4.5rem !important;
  }
  .start-md-20 {
    left: 5rem !important;
  }
  .start-md-24 {
    left: 6rem !important;
  }
  .start-md-32 {
    left: 8rem !important;
  }
  .start-md-40 {
    left: 10rem !important;
  }
  .start-md-48 {
    left: 12rem !important;
  }
  .start-md-56 {
    left: 14rem !important;
  }
  .start-md-64 {
    left: 16rem !important;
  }
  .start-md-72 {
    left: 18rem !important;
  }
  .start-md-80 {
    left: 20rem !important;
  }
  .start-md-88 {
    left: 22rem !important;
  }
  .start-md-96 {
    left: 24rem !important;
  }
  .start-md-px {
    left: 1px !important;
  }
  .start-md-50 {
    left: 50% !important;
  }
  .start-md-100 {
    left: 100% !important;
  }
  .end-md-0 {
    right: 0 !important;
  }
  .end-md-1 {
    right: 0.25rem !important;
  }
  .end-md-2 {
    right: 0.5rem !important;
  }
  .end-md-3 {
    right: 0.75rem !important;
  }
  .end-md-4 {
    right: 1rem !important;
  }
  .end-md-5 {
    right: 1.25rem !important;
  }
  .end-md-6 {
    right: 1.5rem !important;
  }
  .end-md-7 {
    right: 1.75rem !important;
  }
  .end-md-8 {
    right: 2rem !important;
  }
  .end-md-10 {
    right: 2.5rem !important;
  }
  .end-md-12 {
    right: 3rem !important;
  }
  .end-md-14 {
    right: 3.5rem !important;
  }
  .end-md-16 {
    right: 4rem !important;
  }
  .end-md-18 {
    right: 4.5rem !important;
  }
  .end-md-20 {
    right: 5rem !important;
  }
  .end-md-24 {
    right: 6rem !important;
  }
  .end-md-32 {
    right: 8rem !important;
  }
  .end-md-40 {
    right: 10rem !important;
  }
  .end-md-48 {
    right: 12rem !important;
  }
  .end-md-56 {
    right: 14rem !important;
  }
  .end-md-64 {
    right: 16rem !important;
  }
  .end-md-72 {
    right: 18rem !important;
  }
  .end-md-80 {
    right: 20rem !important;
  }
  .end-md-88 {
    right: 22rem !important;
  }
  .end-md-96 {
    right: 24rem !important;
  }
  .end-md-px {
    right: 1px !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
  .border-top-md-0 {
    border-top-width: 0 !important;
  }
  .border-top-md-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-md-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top-md {
    border-top-width: 1px !important;
  }
  .border-top-md-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-md-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-md-2 {
    border-top-width: 2px !important;
  }
  .border-top-md-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-md-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-md-3 {
    border-top-width: 3px !important;
  }
  .border-top-md-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-md-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-md-4 {
    border-top-width: 4px !important;
  }
  .border-top-md-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-md-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-md-5 {
    border-top-width: 5px !important;
  }
  .border-top-md-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-md-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-md-6 {
    border-top-width: 6px !important;
  }
  .border-top-md-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-md-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-md-7 {
    border-top-width: 7px !important;
  }
  .border-top-md-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-md-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-md-8 {
    border-top-width: 8px !important;
  }
  .border-top-md-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-md-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-md-0 {
    border-right-width: 0 !important;
  }
  .border-end-md-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-md-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end-md {
    border-right-width: 1px !important;
  }
  .border-end-md-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-md-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-md-2 {
    border-right-width: 2px !important;
  }
  .border-end-md-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-md-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-md-3 {
    border-right-width: 3px !important;
  }
  .border-end-md-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-md-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-md-4 {
    border-right-width: 4px !important;
  }
  .border-end-md-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-md-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-md-5 {
    border-right-width: 5px !important;
  }
  .border-end-md-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-md-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-md-6 {
    border-right-width: 6px !important;
  }
  .border-end-md-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-md-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-md-7 {
    border-right-width: 7px !important;
  }
  .border-end-md-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-md-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-md-8 {
    border-right-width: 8px !important;
  }
  .border-end-md-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-md-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-md-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-md-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-md-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom-md {
    border-bottom-width: 1px !important;
  }
  .border-bottom-md-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-md-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-md-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-md-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-md-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-md-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-md-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-md-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-md-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-md-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-md-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-md-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-md-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-md-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-md-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-md-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-md-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-md-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-md-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-md-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-md-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-md-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-md-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-md-0 {
    border-left-width: 0 !important;
  }
  .border-start-md-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-md-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start-md {
    border-left-width: 1px !important;
  }
  .border-start-md-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-md-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-md-2 {
    border-left-width: 2px !important;
  }
  .border-start-md-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-md-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-md-3 {
    border-left-width: 3px !important;
  }
  .border-start-md-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-md-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-md-4 {
    border-left-width: 4px !important;
  }
  .border-start-md-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-md-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-md-5 {
    border-left-width: 5px !important;
  }
  .border-start-md-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-md-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-md-6 {
    border-left-width: 6px !important;
  }
  .border-start-md-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-md-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-md-7 {
    border-left-width: 7px !important;
  }
  .border-start-md-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-md-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-md-8 {
    border-left-width: 8px !important;
  }
  .border-start-md-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-md-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-md-primary {
    border-color: #1A16C0 !important;
  }
  .border-md-primary-hover:hover {
    border-color: #1A16C0 !important;
  }
  .border-md-primary-focus:focus {
    border-color: #1A16C0 !important;
  }
  .border-md-primary-focus-within:focus-within {
    border-color: #1A16C0 !important;
  }
  .border-md-secondary {
    border-color: #E4ECF7 !important;
  }
  .border-md-secondary-hover:hover {
    border-color: #E4ECF7 !important;
  }
  .border-md-secondary-focus:focus {
    border-color: #E4ECF7 !important;
  }
  .border-md-secondary-focus-within:focus-within {
    border-color: #E4ECF7 !important;
  }
  .border-md-success {
    border-color: #4AAE8C !important;
  }
  .border-md-success-hover:hover {
    border-color: #4AAE8C !important;
  }
  .border-md-success-focus:focus {
    border-color: #4AAE8C !important;
  }
  .border-md-success-focus-within:focus-within {
    border-color: #4AAE8C !important;
  }
  .border-md-info {
    border-color: #68B8F2 !important;
  }
  .border-md-info-hover:hover {
    border-color: #68B8F2 !important;
  }
  .border-md-info-focus:focus {
    border-color: #68B8F2 !important;
  }
  .border-md-info-focus-within:focus-within {
    border-color: #68B8F2 !important;
  }
  .border-md-warning {
    border-color: #ED9830 !important;
  }
  .border-md-warning-hover:hover {
    border-color: #ED9830 !important;
  }
  .border-md-warning-focus:focus {
    border-color: #ED9830 !important;
  }
  .border-md-warning-focus-within:focus-within {
    border-color: #ED9830 !important;
  }
  .border-md-danger {
    border-color: #F16063 !important;
  }
  .border-md-danger-hover:hover {
    border-color: #F16063 !important;
  }
  .border-md-danger-focus:focus {
    border-color: #F16063 !important;
  }
  .border-md-danger-focus-within:focus-within {
    border-color: #F16063 !important;
  }
  .border-md-light {
    border-color: #F7FAFC !important;
  }
  .border-md-light-hover:hover {
    border-color: #F7FAFC !important;
  }
  .border-md-light-focus:focus {
    border-color: #F7FAFC !important;
  }
  .border-md-light-focus-within:focus-within {
    border-color: #F7FAFC !important;
  }
  .border-md-dark {
    border-color: #27272E !important;
  }
  .border-md-dark-hover:hover {
    border-color: #27272E !important;
  }
  .border-md-dark-focus:focus {
    border-color: #27272E !important;
  }
  .border-md-dark-focus-within:focus-within {
    border-color: #27272E !important;
  }
  .border-md-transparent {
    border-color: transparent !important;
  }
  .border-md-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-md-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-md-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-md-current {
    border-color: currentColor !important;
  }
  .border-md-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-md-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-md-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-md-base {
    border-color: #E2E8F0 !important;
  }
  .border-md-base-hover:hover {
    border-color: #E2E8F0 !important;
  }
  .border-md-base-focus:focus {
    border-color: #E2E8F0 !important;
  }
  .border-md-base-focus-within:focus-within {
    border-color: #E2E8F0 !important;
  }
  .border-md-body {
    border-color: #fff !important;
  }
  .border-md-body-hover:hover {
    border-color: #fff !important;
  }
  .border-md-body-focus:focus {
    border-color: #fff !important;
  }
  .border-md-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-md-card {
    border-color: #fff !important;
  }
  .border-md-card-hover:hover {
    border-color: #fff !important;
  }
  .border-md-card-focus:focus {
    border-color: #fff !important;
  }
  .border-md-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-md-black {
    border-color: #000 !important;
  }
  .border-md-black-hover:hover {
    border-color: #000 !important;
  }
  .border-md-black-focus:focus {
    border-color: #000 !important;
  }
  .border-md-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-md-white {
    border-color: #fff !important;
  }
  .border-md-white-hover:hover {
    border-color: #fff !important;
  }
  .border-md-white-focus:focus {
    border-color: #fff !important;
  }
  .border-md-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-md-0 {
    border-width: 0 !important;
  }
  .border-md-0-hover:hover {
    border-width: 0 !important;
  }
  .border-md-0-focus:focus {
    border-width: 0 !important;
  }
  .border-md {
    border-width: 1px !important;
  }
  .border-md-hover:hover {
    border-width: 1px !important;
  }
  .border-md-focus:focus {
    border-width: 1px !important;
  }
  .border-md-2 {
    border-width: 2px !important;
  }
  .border-md-2-hover:hover {
    border-width: 2px !important;
  }
  .border-md-2-focus:focus {
    border-width: 2px !important;
  }
  .border-md-3 {
    border-width: 3px !important;
  }
  .border-md-3-hover:hover {
    border-width: 3px !important;
  }
  .border-md-3-focus:focus {
    border-width: 3px !important;
  }
  .border-md-4 {
    border-width: 4px !important;
  }
  .border-md-4-hover:hover {
    border-width: 4px !important;
  }
  .border-md-4-focus:focus {
    border-width: 4px !important;
  }
  .border-md-5 {
    border-width: 5px !important;
  }
  .border-md-5-hover:hover {
    border-width: 5px !important;
  }
  .border-md-5-focus:focus {
    border-width: 5px !important;
  }
  .border-md-6 {
    border-width: 6px !important;
  }
  .border-md-6-hover:hover {
    border-width: 6px !important;
  }
  .border-md-6-focus:focus {
    border-width: 6px !important;
  }
  .border-md-7 {
    border-width: 7px !important;
  }
  .border-md-7-hover:hover {
    border-width: 7px !important;
  }
  .border-md-7-focus:focus {
    border-width: 7px !important;
  }
  .border-md-8 {
    border-width: 8px !important;
  }
  .border-md-8-hover:hover {
    border-width: 8px !important;
  }
  .border-md-8-focus:focus {
    border-width: 8px !important;
  }
  .w-md-0 {
    width: 0 !important;
  }
  .w-md-1 {
    width: 0.25rem !important;
  }
  .w-md-2 {
    width: 0.5rem !important;
  }
  .w-md-3 {
    width: 0.75rem !important;
  }
  .w-md-4 {
    width: 1rem !important;
  }
  .w-md-5 {
    width: 1.25rem !important;
  }
  .w-md-6 {
    width: 1.5rem !important;
  }
  .w-md-7 {
    width: 1.75rem !important;
  }
  .w-md-8 {
    width: 2rem !important;
  }
  .w-md-10 {
    width: 2.5rem !important;
  }
  .w-md-12 {
    width: 3rem !important;
  }
  .w-md-14 {
    width: 3.5rem !important;
  }
  .w-md-16 {
    width: 4rem !important;
  }
  .w-md-18 {
    width: 4.5rem !important;
  }
  .w-md-20 {
    width: 5rem !important;
  }
  .w-md-24 {
    width: 6rem !important;
  }
  .w-md-32 {
    width: 8rem !important;
  }
  .w-md-40 {
    width: 10rem !important;
  }
  .w-md-48 {
    width: 12rem !important;
  }
  .w-md-56 {
    width: 14rem !important;
  }
  .w-md-64 {
    width: 16rem !important;
  }
  .w-md-72 {
    width: 18rem !important;
  }
  .w-md-80 {
    width: 20rem !important;
  }
  .w-md-88 {
    width: 22rem !important;
  }
  .w-md-96 {
    width: 24rem !important;
  }
  .w-md-px {
    width: 1px !important;
  }
  .w-md-1\/2 {
    width: 50% !important;
  }
  .w-md-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-md-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-md-1\/4 {
    width: 25% !important;
  }
  .w-md-2\/4 {
    width: 50% !important;
  }
  .w-md-3\/4 {
    width: 75% !important;
  }
  .w-md-1\/5 {
    width: 20% !important;
  }
  .w-md-2\/5 {
    width: 40% !important;
  }
  .w-md-3\/5 {
    width: 60% !important;
  }
  .w-md-4\/5 {
    width: 80% !important;
  }
  .w-md-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-md-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-md-3\/6 {
    width: 50% !important;
  }
  .w-md-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-md-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-md-11\/10 {
    width: 110% !important;
  }
  .w-md-12\/10 {
    width: 120% !important;
  }
  .w-md-13\/10 {
    width: 130% !important;
  }
  .w-md-14\/10 {
    width: 140% !important;
  }
  .w-md-15\/10 {
    width: 150% !important;
  }
  .w-md-screen-sm {
    width: 640px !important;
  }
  .w-md-screen-md {
    width: 768px !important;
  }
  .w-md-screen-lg {
    width: 1024px !important;
  }
  .w-md-screen-xl {
    width: 1280px !important;
  }
  .w-md-screen-xxl {
    width: 1536px !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-full {
    width: 100% !important;
  }
  .w-md-screen {
    width: 100vw !important;
  }
  .w-md-min {
    width: min-content !important;
  }
  .w-md-max {
    width: max-content !important;
  }
  .max-w-md-screen-sm {
    max-width: 640px !important;
  }
  .max-w-md-screen-md {
    max-width: 768px !important;
  }
  .max-w-md-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-md-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-md-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-md-0 {
    max-width: 0 !important;
  }
  .max-w-md-full {
    max-width: 100% !important;
  }
  .max-w-md-read {
    max-width: 65ch !important;
  }
  .max-w-md-min {
    max-width: min-content !important;
  }
  .max-w-md-max {
    max-width: max-content !important;
  }
  .h-md-0 {
    height: 0 !important;
  }
  .h-md-1 {
    height: 0.25rem !important;
  }
  .h-md-2 {
    height: 0.5rem !important;
  }
  .h-md-3 {
    height: 0.75rem !important;
  }
  .h-md-4 {
    height: 1rem !important;
  }
  .h-md-5 {
    height: 1.25rem !important;
  }
  .h-md-6 {
    height: 1.5rem !important;
  }
  .h-md-7 {
    height: 1.75rem !important;
  }
  .h-md-8 {
    height: 2rem !important;
  }
  .h-md-10 {
    height: 2.5rem !important;
  }
  .h-md-12 {
    height: 3rem !important;
  }
  .h-md-14 {
    height: 3.5rem !important;
  }
  .h-md-16 {
    height: 4rem !important;
  }
  .h-md-18 {
    height: 4.5rem !important;
  }
  .h-md-20 {
    height: 5rem !important;
  }
  .h-md-24 {
    height: 6rem !important;
  }
  .h-md-32 {
    height: 8rem !important;
  }
  .h-md-40 {
    height: 10rem !important;
  }
  .h-md-48 {
    height: 12rem !important;
  }
  .h-md-56 {
    height: 14rem !important;
  }
  .h-md-64 {
    height: 16rem !important;
  }
  .h-md-72 {
    height: 18rem !important;
  }
  .h-md-80 {
    height: 20rem !important;
  }
  .h-md-88 {
    height: 22rem !important;
  }
  .h-md-96 {
    height: 24rem !important;
  }
  .h-md-px {
    height: 1px !important;
  }
  .h-md-1\/2 {
    height: 50% !important;
  }
  .h-md-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-md-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-md-1\/4 {
    height: 25% !important;
  }
  .h-md-2\/4 {
    height: 50% !important;
  }
  .h-md-3\/4 {
    height: 75% !important;
  }
  .h-md-1\/5 {
    height: 20% !important;
  }
  .h-md-2\/5 {
    height: 40% !important;
  }
  .h-md-3\/5 {
    height: 60% !important;
  }
  .h-md-4\/5 {
    height: 80% !important;
  }
  .h-md-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-md-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-md-3\/6 {
    height: 50% !important;
  }
  .h-md-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-md-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-md-11\/10 {
    height: 110% !important;
  }
  .h-md-12\/10 {
    height: 120% !important;
  }
  .h-md-13\/10 {
    height: 130% !important;
  }
  .h-md-14\/10 {
    height: 140% !important;
  }
  .h-md-15\/10 {
    height: 150% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-full {
    height: 100% !important;
  }
  .h-md-screen {
    height: 100vh !important;
  }
  .max-h-md-0 {
    max-height: 0 !important;
  }
  .max-h-md-1 {
    max-height: 0.25rem !important;
  }
  .max-h-md-2 {
    max-height: 0.5rem !important;
  }
  .max-h-md-3 {
    max-height: 0.75rem !important;
  }
  .max-h-md-4 {
    max-height: 1rem !important;
  }
  .max-h-md-5 {
    max-height: 1.25rem !important;
  }
  .max-h-md-6 {
    max-height: 1.5rem !important;
  }
  .max-h-md-7 {
    max-height: 1.75rem !important;
  }
  .max-h-md-8 {
    max-height: 2rem !important;
  }
  .max-h-md-10 {
    max-height: 2.5rem !important;
  }
  .max-h-md-12 {
    max-height: 3rem !important;
  }
  .max-h-md-14 {
    max-height: 3.5rem !important;
  }
  .max-h-md-16 {
    max-height: 4rem !important;
  }
  .max-h-md-18 {
    max-height: 4.5rem !important;
  }
  .max-h-md-20 {
    max-height: 5rem !important;
  }
  .max-h-md-24 {
    max-height: 6rem !important;
  }
  .max-h-md-32 {
    max-height: 8rem !important;
  }
  .max-h-md-40 {
    max-height: 10rem !important;
  }
  .max-h-md-48 {
    max-height: 12rem !important;
  }
  .max-h-md-56 {
    max-height: 14rem !important;
  }
  .max-h-md-64 {
    max-height: 16rem !important;
  }
  .max-h-md-72 {
    max-height: 18rem !important;
  }
  .max-h-md-80 {
    max-height: 20rem !important;
  }
  .max-h-md-88 {
    max-height: 22rem !important;
  }
  .max-h-md-96 {
    max-height: 24rem !important;
  }
  .max-h-md-px {
    max-height: 1px !important;
  }
  .max-h-md-full {
    max-height: 100% !important;
  }
  .max-h-md-screen {
    max-height: 100vh !important;
  }
  .flex-md-1 {
    flex: 1 1 0% !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-initial {
    flex: 0 1 auto !important;
  }
  .flex-md-none {
    flex: none !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-10 {
    gap: 2.5rem !important;
  }
  .gap-md-12 {
    gap: 3rem !important;
  }
  .gap-md-14 {
    gap: 3.5rem !important;
  }
  .gap-md-16 {
    gap: 4rem !important;
  }
  .gap-md-18 {
    gap: 4.5rem !important;
  }
  .gap-md-20 {
    gap: 5rem !important;
  }
  .gap-md-24 {
    gap: 6rem !important;
  }
  .gap-md-32 {
    gap: 8rem !important;
  }
  .gap-md-40 {
    gap: 10rem !important;
  }
  .gap-md-48 {
    gap: 12rem !important;
  }
  .gap-md-56 {
    gap: 14rem !important;
  }
  .gap-md-64 {
    gap: 16rem !important;
  }
  .gap-md-72 {
    gap: 18rem !important;
  }
  .gap-md-80 {
    gap: 20rem !important;
  }
  .gap-md-88 {
    gap: 22rem !important;
  }
  .gap-md-96 {
    gap: 24rem !important;
  }
  .gap-md-px {
    gap: 1px !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-evenly {
    align-content: space-evenly !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-10 {
    margin: 2.5rem !important;
  }
  .m-md-12 {
    margin: 3rem !important;
  }
  .m-md-14 {
    margin: 3.5rem !important;
  }
  .m-md-16 {
    margin: 4rem !important;
  }
  .m-md-18 {
    margin: 4.5rem !important;
  }
  .m-md-20 {
    margin: 5rem !important;
  }
  .m-md-24 {
    margin: 6rem !important;
  }
  .m-md-32 {
    margin: 8rem !important;
  }
  .m-md-40 {
    margin: 10rem !important;
  }
  .m-md-48 {
    margin: 12rem !important;
  }
  .m-md-56 {
    margin: 14rem !important;
  }
  .m-md-64 {
    margin: 16rem !important;
  }
  .m-md-72 {
    margin: 18rem !important;
  }
  .m-md-80 {
    margin: 20rem !important;
  }
  .m-md-88 {
    margin: 22rem !important;
  }
  .m-md-96 {
    margin: 24rem !important;
  }
  .m-md-px {
    margin: 1px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-md-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-md-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-md-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-md-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-md-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-md-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-md-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-md-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-md-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-md-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.25rem !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-7 {
    margin-top: 1.75rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-10 {
    margin-top: 2.5rem !important;
  }
  .mt-md-12 {
    margin-top: 3rem !important;
  }
  .mt-md-14 {
    margin-top: 3.5rem !important;
  }
  .mt-md-16 {
    margin-top: 4rem !important;
  }
  .mt-md-18 {
    margin-top: 4.5rem !important;
  }
  .mt-md-20 {
    margin-top: 5rem !important;
  }
  .mt-md-24 {
    margin-top: 6rem !important;
  }
  .mt-md-32 {
    margin-top: 8rem !important;
  }
  .mt-md-40 {
    margin-top: 10rem !important;
  }
  .mt-md-48 {
    margin-top: 12rem !important;
  }
  .mt-md-56 {
    margin-top: 14rem !important;
  }
  .mt-md-64 {
    margin-top: 16rem !important;
  }
  .mt-md-72 {
    margin-top: 18rem !important;
  }
  .mt-md-80 {
    margin-top: 20rem !important;
  }
  .mt-md-88 {
    margin-top: 22rem !important;
  }
  .mt-md-96 {
    margin-top: 24rem !important;
  }
  .mt-md-px {
    margin-top: 1px !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.25rem !important;
  }
  .me-md-6 {
    margin-right: 1.5rem !important;
  }
  .me-md-7 {
    margin-right: 1.75rem !important;
  }
  .me-md-8 {
    margin-right: 2rem !important;
  }
  .me-md-10 {
    margin-right: 2.5rem !important;
  }
  .me-md-12 {
    margin-right: 3rem !important;
  }
  .me-md-14 {
    margin-right: 3.5rem !important;
  }
  .me-md-16 {
    margin-right: 4rem !important;
  }
  .me-md-18 {
    margin-right: 4.5rem !important;
  }
  .me-md-20 {
    margin-right: 5rem !important;
  }
  .me-md-24 {
    margin-right: 6rem !important;
  }
  .me-md-32 {
    margin-right: 8rem !important;
  }
  .me-md-40 {
    margin-right: 10rem !important;
  }
  .me-md-48 {
    margin-right: 12rem !important;
  }
  .me-md-56 {
    margin-right: 14rem !important;
  }
  .me-md-64 {
    margin-right: 16rem !important;
  }
  .me-md-72 {
    margin-right: 18rem !important;
  }
  .me-md-80 {
    margin-right: 20rem !important;
  }
  .me-md-88 {
    margin-right: 22rem !important;
  }
  .me-md-96 {
    margin-right: 24rem !important;
  }
  .me-md-px {
    margin-right: 1px !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-12 {
    margin-bottom: 3rem !important;
  }
  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-16 {
    margin-bottom: 4rem !important;
  }
  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-20 {
    margin-bottom: 5rem !important;
  }
  .mb-md-24 {
    margin-bottom: 6rem !important;
  }
  .mb-md-32 {
    margin-bottom: 8rem !important;
  }
  .mb-md-40 {
    margin-bottom: 10rem !important;
  }
  .mb-md-48 {
    margin-bottom: 12rem !important;
  }
  .mb-md-56 {
    margin-bottom: 14rem !important;
  }
  .mb-md-64 {
    margin-bottom: 16rem !important;
  }
  .mb-md-72 {
    margin-bottom: 18rem !important;
  }
  .mb-md-80 {
    margin-bottom: 20rem !important;
  }
  .mb-md-88 {
    margin-bottom: 22rem !important;
  }
  .mb-md-96 {
    margin-bottom: 24rem !important;
  }
  .mb-md-px {
    margin-bottom: 1px !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.25rem !important;
  }
  .ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .ms-md-7 {
    margin-left: 1.75rem !important;
  }
  .ms-md-8 {
    margin-left: 2rem !important;
  }
  .ms-md-10 {
    margin-left: 2.5rem !important;
  }
  .ms-md-12 {
    margin-left: 3rem !important;
  }
  .ms-md-14 {
    margin-left: 3.5rem !important;
  }
  .ms-md-16 {
    margin-left: 4rem !important;
  }
  .ms-md-18 {
    margin-left: 4.5rem !important;
  }
  .ms-md-20 {
    margin-left: 5rem !important;
  }
  .ms-md-24 {
    margin-left: 6rem !important;
  }
  .ms-md-32 {
    margin-left: 8rem !important;
  }
  .ms-md-40 {
    margin-left: 10rem !important;
  }
  .ms-md-48 {
    margin-left: 12rem !important;
  }
  .ms-md-56 {
    margin-left: 14rem !important;
  }
  .ms-md-64 {
    margin-left: 16rem !important;
  }
  .ms-md-72 {
    margin-left: 18rem !important;
  }
  .ms-md-80 {
    margin-left: 20rem !important;
  }
  .ms-md-88 {
    margin-left: 22rem !important;
  }
  .ms-md-96 {
    margin-left: 24rem !important;
  }
  .ms-md-px {
    margin-left: 1px !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .p-md-12 {
    padding: 3rem !important;
  }
  .p-md-14 {
    padding: 3.5rem !important;
  }
  .p-md-16 {
    padding: 4rem !important;
  }
  .p-md-18 {
    padding: 4.5rem !important;
  }
  .p-md-20 {
    padding: 5rem !important;
  }
  .p-md-24 {
    padding: 6rem !important;
  }
  .p-md-32 {
    padding: 8rem !important;
  }
  .p-md-40 {
    padding: 10rem !important;
  }
  .p-md-48 {
    padding: 12rem !important;
  }
  .p-md-56 {
    padding: 14rem !important;
  }
  .p-md-64 {
    padding: 16rem !important;
  }
  .p-md-72 {
    padding: 18rem !important;
  }
  .p-md-80 {
    padding: 20rem !important;
  }
  .p-md-88 {
    padding: 22rem !important;
  }
  .p-md-96 {
    padding: 24rem !important;
  }
  .p-md-px {
    padding: 1px !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-md-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-md-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-md-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-md-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-md-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-md-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-md-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-md-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-md-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-md-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-md-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-md-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-md-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-md-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.25rem !important;
  }
  .pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .pt-md-7 {
    padding-top: 1.75rem !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-10 {
    padding-top: 2.5rem !important;
  }
  .pt-md-12 {
    padding-top: 3rem !important;
  }
  .pt-md-14 {
    padding-top: 3.5rem !important;
  }
  .pt-md-16 {
    padding-top: 4rem !important;
  }
  .pt-md-18 {
    padding-top: 4.5rem !important;
  }
  .pt-md-20 {
    padding-top: 5rem !important;
  }
  .pt-md-24 {
    padding-top: 6rem !important;
  }
  .pt-md-32 {
    padding-top: 8rem !important;
  }
  .pt-md-40 {
    padding-top: 10rem !important;
  }
  .pt-md-48 {
    padding-top: 12rem !important;
  }
  .pt-md-56 {
    padding-top: 14rem !important;
  }
  .pt-md-64 {
    padding-top: 16rem !important;
  }
  .pt-md-72 {
    padding-top: 18rem !important;
  }
  .pt-md-80 {
    padding-top: 20rem !important;
  }
  .pt-md-88 {
    padding-top: 22rem !important;
  }
  .pt-md-96 {
    padding-top: 24rem !important;
  }
  .pt-md-px {
    padding-top: 1px !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.25rem !important;
  }
  .pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .pe-md-7 {
    padding-right: 1.75rem !important;
  }
  .pe-md-8 {
    padding-right: 2rem !important;
  }
  .pe-md-10 {
    padding-right: 2.5rem !important;
  }
  .pe-md-12 {
    padding-right: 3rem !important;
  }
  .pe-md-14 {
    padding-right: 3.5rem !important;
  }
  .pe-md-16 {
    padding-right: 4rem !important;
  }
  .pe-md-18 {
    padding-right: 4.5rem !important;
  }
  .pe-md-20 {
    padding-right: 5rem !important;
  }
  .pe-md-24 {
    padding-right: 6rem !important;
  }
  .pe-md-32 {
    padding-right: 8rem !important;
  }
  .pe-md-40 {
    padding-right: 10rem !important;
  }
  .pe-md-48 {
    padding-right: 12rem !important;
  }
  .pe-md-56 {
    padding-right: 14rem !important;
  }
  .pe-md-64 {
    padding-right: 16rem !important;
  }
  .pe-md-72 {
    padding-right: 18rem !important;
  }
  .pe-md-80 {
    padding-right: 20rem !important;
  }
  .pe-md-88 {
    padding-right: 22rem !important;
  }
  .pe-md-96 {
    padding-right: 24rem !important;
  }
  .pe-md-px {
    padding-right: 1px !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2rem !important;
  }
  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-12 {
    padding-bottom: 3rem !important;
  }
  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-16 {
    padding-bottom: 4rem !important;
  }
  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-20 {
    padding-bottom: 5rem !important;
  }
  .pb-md-24 {
    padding-bottom: 6rem !important;
  }
  .pb-md-32 {
    padding-bottom: 8rem !important;
  }
  .pb-md-40 {
    padding-bottom: 10rem !important;
  }
  .pb-md-48 {
    padding-bottom: 12rem !important;
  }
  .pb-md-56 {
    padding-bottom: 14rem !important;
  }
  .pb-md-64 {
    padding-bottom: 16rem !important;
  }
  .pb-md-72 {
    padding-bottom: 18rem !important;
  }
  .pb-md-80 {
    padding-bottom: 20rem !important;
  }
  .pb-md-88 {
    padding-bottom: 22rem !important;
  }
  .pb-md-96 {
    padding-bottom: 24rem !important;
  }
  .pb-md-px {
    padding-bottom: 1px !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.25rem !important;
  }
  .ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .ps-md-7 {
    padding-left: 1.75rem !important;
  }
  .ps-md-8 {
    padding-left: 2rem !important;
  }
  .ps-md-10 {
    padding-left: 2.5rem !important;
  }
  .ps-md-12 {
    padding-left: 3rem !important;
  }
  .ps-md-14 {
    padding-left: 3.5rem !important;
  }
  .ps-md-16 {
    padding-left: 4rem !important;
  }
  .ps-md-18 {
    padding-left: 4.5rem !important;
  }
  .ps-md-20 {
    padding-left: 5rem !important;
  }
  .ps-md-24 {
    padding-left: 6rem !important;
  }
  .ps-md-32 {
    padding-left: 8rem !important;
  }
  .ps-md-40 {
    padding-left: 10rem !important;
  }
  .ps-md-48 {
    padding-left: 12rem !important;
  }
  .ps-md-56 {
    padding-left: 14rem !important;
  }
  .ps-md-64 {
    padding-left: 16rem !important;
  }
  .ps-md-72 {
    padding-left: 18rem !important;
  }
  .ps-md-80 {
    padding-left: 20rem !important;
  }
  .ps-md-88 {
    padding-left: 22rem !important;
  }
  .ps-md-96 {
    padding-left: 24rem !important;
  }
  .ps-md-px {
    padding-left: 1px !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-primary-hover:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-primary-focus:focus {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-secondary-hover:hover {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-secondary-focus:focus {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-success-hover:hover {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-success-focus:focus {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-info-hover:hover {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-info-focus:focus {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-warning-hover:hover {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-warning-focus:focus {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-danger-hover:hover {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-danger-focus:focus {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-light-hover:hover {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-light-focus:focus {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-dark-hover:hover {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-dark-focus:focus {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-black-hover:hover {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-black-focus:focus {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-white-hover:hover {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-white-focus:focus {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-body-hover:hover {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-md-body-focus:focus {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .rounded-md {
    border-radius: 0.25rem !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
  .rounded-md-1 {
    border-radius: 0.2rem !important;
  }
  .rounded-md-2 {
    border-radius: 0.3rem !important;
  }
  .rounded-md-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-md-4 {
    border-radius: 1rem !important;
  }
  .rounded-md-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-md-6 {
    border-radius: 3rem !important;
  }
  .rounded-md-7 {
    border-radius: 4rem !important;
  }
  .rounded-md-8 {
    border-radius: 5rem !important;
  }
  .rounded-md-9 {
    border-radius: 6rem !important;
  }
  .rounded-md-10 {
    border-radius: 7rem !important;
  }
  .rounded-md-pill {
    border-radius: 50rem !important;
  }
  .rounded-md-circle {
    border-radius: 50% !important;
  }
  .rounded-md-card {
    border-radius: 0.25rem !important;
  }
  .rounded-top-md {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-md-1 {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-md-2 {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-md-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-md-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-md-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-md-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-md-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-md-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-md-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-md-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-md-card {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-end-md {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-md-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-md-1 {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-end-md-2 {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-end-md-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-md-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-md-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-md-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-md-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-md-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-md-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-md-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-md-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-md-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-md-card {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-md {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-md-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-md-1 {
    border-bottom-right-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-md-2 {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-md-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-md-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-md-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-md-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-md-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-md-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-md-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-md-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-md-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-md-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-md-card {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-start-md {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-md-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-md-1 {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-start-md-2 {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-start-md-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-md-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-md-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-md-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-md-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-md-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-md-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-md-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-md-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-md-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-md-card {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-md {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-md-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-md-1 {
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-top-start-md-2 {
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-top-start-md-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-md-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-md-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-md-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-md-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-md-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-md-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-md-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-md-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-md-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-md-card {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-end-md {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-md-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-md-1 {
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-end-md-2 {
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-end-md-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-md-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-md-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-md-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-md-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-md-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-md-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-md-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-md-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-md-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-md-card {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-md {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-md-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-md-1 {
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-bottom-end-md-2 {
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-bottom-end-md-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-md-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-md-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-md-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-md-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-md-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-md-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-md-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-md-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-md-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-md-card {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-start-md {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-md-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-md-1 {
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-start-md-2 {
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-start-md-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-md-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-md-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-md-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-md-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-md-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-md-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-md-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-md-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-md-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-md-card {
    border-bottom-left-radius: 0.25rem !important;
  }
  .border-md-solid {
    border-style: solid !important;
  }
  .border-md-dashed {
    border-style: dashed !important;
  }
  .border-md-dotted {
    border-style: dotted !important;
  }
  .border-md-double {
    border-style: double !important;
  }
  .border-md-groove {
    border-style: groove !important;
  }
  .border-md-none {
    border-style: none !important;
  }
  .text-md-transparent {
    color: transparent !important;
  }
  .text-md-transparent-hover:hover {
    color: transparent !important;
  }
  .text-md-transparent-focus:focus {
    color: transparent !important;
  }
  .text-md-current {
    color: currentColor !important;
  }
  .text-md-current-hover:hover {
    color: currentColor !important;
  }
  .text-md-current-focus:focus {
    color: currentColor !important;
  }
  .text-md-reset {
    color: inherit !important;
  }
  .text-md-reset-hover:hover {
    color: inherit !important;
  }
  .text-md-reset-focus:focus {
    color: inherit !important;
  }
  .shadow-soft-md-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-1-focus:focus {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-2-focus:focus {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-md-none {
    box-shadow: none !important;
  }
  .shadow-soft-md-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-soft-md-none-focus:focus {
    box-shadow: none !important;
  }
  .min-w-md-0 {
    min-width: 0 !important;
  }
  .min-w-md-full {
    min-width: 100% !important;
  }
  .min-w-md-min {
    min-width: min-content !important;
  }
  .min-w-md-max {
    min-width: max-content !important;
  }
  .min-h-md-0 {
    min-height: 0 !important;
  }
  .min-h-md-full {
    min-height: 100% !important;
  }
  .min-h-md-screen {
    min-height: 100vh !important;
  }
  .place-content-md-start {
    place-content: flex-start !important;
  }
  .place-content-md-end {
    place-content: flex-end !important;
  }
  .place-content-md-center {
    place-content: center !important;
  }
  .place-content-md-between {
    place-content: space-between !important;
  }
  .place-content-md-around {
    place-content: space-around !important;
  }
  .place-content-md-evenly {
    place-content: space-evenly !important;
  }
  .place-content-md-stretch {
    place-content: stretch !important;
  }
  .overflow-x-md-auto {
    overflow-x: auto !important;
  }
  .overflow-x-md-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-md-visible {
    overflow-x: visible !important;
  }
  .overflow-x-md-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-md-auto {
    overflow-y: auto !important;
  }
  .overflow-y-md-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-md-visible {
    overflow-y: visible !important;
  }
  .overflow-y-md-scroll {
    overflow-y: scroll !important;
  }
  .transition-md {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }
  .transition-md-none {
    transition-property: none !important;
  }
  .transition-md-all {
    transition-property: all !important;
  }
  .transition-md-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-md-opacity {
    transition-property: opacity !important;
  }
  .transition-md-shadow {
    transition-property: box-shadow !important;
  }
  .transition-md-transform {
    transition-property: transform !important;
  }
  .ease-md-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-md-linear {
    transition-timing-function: linear !important;
  }
  .ease-md-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-md-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-md-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .transform-md-none {
    transform: none !important;
  }
  .origin-md-center {
    transform-origin: center !important;
  }
  .origin-md-top {
    transform-origin: top !important;
  }
  .origin-md-top-right {
    transform-origin: top right !important;
  }
  .origin-md-right {
    transform-origin: right !important;
  }
  .origin-md-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-md-bottom {
    transform-origin: bottom !important;
  }
  .origin-md-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-md-left {
    transform-origin: left !important;
  }
  .origin-md-top-left {
    transform-origin: top left !important;
  }
  .scale-md-0 {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-md-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-md-0-focus:focus {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-md-25 {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-md-25-hover:hover {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-md-25-focus:focus {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-md-50 {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-md-50-hover:hover {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-md-50-focus:focus {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-md-75 {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-md-75-hover:hover {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-md-75-focus:focus {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-md-90 {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-md-90-hover:hover {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-md-90-focus:focus {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-md-95 {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-md-95-hover:hover {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-md-95-focus:focus {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-md-100 {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-md-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-md-100-focus:focus {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-md-105 {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-md-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-md-105-focus:focus {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-md-110 {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-md-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-md-110-focus:focus {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-md-125 {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-md-125-hover:hover {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-md-125-focus:focus {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-md-150 {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-md-150-hover:hover {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-md-150-focus:focus {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-md-200 {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-md-200-hover:hover {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-md-200-focus:focus {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-y-md-0 {
    --bs--scale-y: 0 !important;
  }
  .scale-y-md-0-hover:hover {
    --bs--scale-y: 0 !important;
  }
  .scale-y-md-0-focus:focus {
    --bs--scale-y: 0 !important;
  }
  .scale-y-md-25 {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-md-25-hover:hover {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-md-25-focus:focus {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-md-50 {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-md-50-hover:hover {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-md-50-focus:focus {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-md-75 {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-md-75-hover:hover {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-md-75-focus:focus {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-md-90 {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-md-90-hover:hover {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-md-90-focus:focus {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-md-95 {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-md-95-hover:hover {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-md-95-focus:focus {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-md-100 {
    --bs--scale-y: 1 !important;
  }
  .scale-y-md-100-hover:hover {
    --bs--scale-y: 1 !important;
  }
  .scale-y-md-100-focus:focus {
    --bs--scale-y: 1 !important;
  }
  .scale-y-md-105 {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-md-105-hover:hover {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-md-105-focus:focus {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-md-110 {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-md-110-hover:hover {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-md-110-focus:focus {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-md-125 {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-md-125-hover:hover {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-md-125-focus:focus {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-md-150 {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-md-150-hover:hover {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-md-150-focus:focus {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-md-200 {
    --bs--scale-y: 2 !important;
  }
  .scale-y-md-200-hover:hover {
    --bs--scale-y: 2 !important;
  }
  .scale-y-md-200-focus:focus {
    --bs--scale-y: 2 !important;
  }
  .scale-x-md-0 {
    --bs--scale-x: 0 !important;
  }
  .scale-x-md-0-hover:hover {
    --bs--scale-x: 0 !important;
  }
  .scale-x-md-0-focus:focus {
    --bs--scale-x: 0 !important;
  }
  .scale-x-md-25 {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-md-25-hover:hover {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-md-25-focus:focus {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-md-50 {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-md-50-hover:hover {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-md-50-focus:focus {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-md-75 {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-md-75-hover:hover {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-md-75-focus:focus {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-md-90 {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-md-90-hover:hover {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-md-90-focus:focus {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-md-95 {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-md-95-hover:hover {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-md-95-focus:focus {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-md-100 {
    --bs--scale-x: 1 !important;
  }
  .scale-x-md-100-hover:hover {
    --bs--scale-x: 1 !important;
  }
  .scale-x-md-100-focus:focus {
    --bs--scale-x: 1 !important;
  }
  .scale-x-md-105 {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-md-105-hover:hover {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-md-105-focus:focus {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-md-110 {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-md-110-hover:hover {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-md-110-focus:focus {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-md-125 {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-md-125-hover:hover {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-md-125-focus:focus {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-md-150 {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-md-150-hover:hover {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-md-150-focus:focus {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-md-200 {
    --bs--scale-x: 2 !important;
  }
  .scale-x-md-200-hover:hover {
    --bs--scale-x: 2 !important;
  }
  .scale-x-md-200-focus:focus {
    --bs--scale-x: 2 !important;
  }
  .rotate-md-0 {
    --bs-rotate: 0deg !important;
  }
  .rotate-md-0-hover:hover {
    --bs-rotate: 0deg !important;
  }
  .rotate-md-0-focus:focus {
    --bs-rotate: 0deg !important;
  }
  .rotate-md-1 {
    --bs-rotate: 1deg !important;
  }
  .rotate-md-1-hover:hover {
    --bs-rotate: 1deg !important;
  }
  .rotate-md-1-focus:focus {
    --bs-rotate: 1deg !important;
  }
  .rotate-md-2 {
    --bs-rotate: 2deg !important;
  }
  .rotate-md-2-hover:hover {
    --bs-rotate: 2deg !important;
  }
  .rotate-md-2-focus:focus {
    --bs-rotate: 2deg !important;
  }
  .rotate-md-3 {
    --bs-rotate: 3deg !important;
  }
  .rotate-md-3-hover:hover {
    --bs-rotate: 3deg !important;
  }
  .rotate-md-3-focus:focus {
    --bs-rotate: 3deg !important;
  }
  .rotate-md-6 {
    --bs-rotate: 6deg !important;
  }
  .rotate-md-6-hover:hover {
    --bs-rotate: 6deg !important;
  }
  .rotate-md-6-focus:focus {
    --bs-rotate: 6deg !important;
  }
  .rotate-md-12 {
    --bs-rotate: 12deg !important;
  }
  .rotate-md-12-hover:hover {
    --bs-rotate: 12deg !important;
  }
  .rotate-md-12-focus:focus {
    --bs-rotate: 12deg !important;
  }
  .rotate-md-30 {
    --bs-rotate: 30deg !important;
  }
  .rotate-md-30-hover:hover {
    --bs-rotate: 30deg !important;
  }
  .rotate-md-30-focus:focus {
    --bs-rotate: 30deg !important;
  }
  .rotate-md-45 {
    --bs-rotate: 45deg !important;
  }
  .rotate-md-45-hover:hover {
    --bs-rotate: 45deg !important;
  }
  .rotate-md-45-focus:focus {
    --bs-rotate: 45deg !important;
  }
  .rotate-md-90 {
    --bs-rotate: 90deg !important;
  }
  .rotate-md-90-hover:hover {
    --bs-rotate: 90deg !important;
  }
  .rotate-md-90-focus:focus {
    --bs-rotate: 90deg !important;
  }
  .rotate-md-180 {
    --bs-rotate: 180deg !important;
  }
  .rotate-md-180-hover:hover {
    --bs-rotate: 180deg !important;
  }
  .rotate-md-180-focus:focus {
    --bs-rotate: 180deg !important;
  }
  .rotate-md-n1 {
    --bs-rotate: -1deg !important;
  }
  .rotate-md-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }
  .rotate-md-n1-focus:focus {
    --bs-rotate: -1deg !important;
  }
  .rotate-md-n2 {
    --bs-rotate: -2deg !important;
  }
  .rotate-md-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }
  .rotate-md-n2-focus:focus {
    --bs-rotate: -2deg !important;
  }
  .rotate-md-n3 {
    --bs-rotate: -3deg !important;
  }
  .rotate-md-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }
  .rotate-md-n3-focus:focus {
    --bs-rotate: -3deg !important;
  }
  .rotate-md-n6 {
    --bs-rotate: -6deg !important;
  }
  .rotate-md-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }
  .rotate-md-n6-focus:focus {
    --bs-rotate: -6deg !important;
  }
  .rotate-md-n12 {
    --bs-rotate: -12deg !important;
  }
  .rotate-md-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }
  .rotate-md-n12-focus:focus {
    --bs-rotate: -12deg !important;
  }
  .rotate-md-n30 {
    --bs-rotate: -30deg !important;
  }
  .rotate-md-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }
  .rotate-md-n30-focus:focus {
    --bs-rotate: -30deg !important;
  }
  .rotate-md-n45 {
    --bs-rotate: -45deg !important;
  }
  .rotate-md-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }
  .rotate-md-n45-focus:focus {
    --bs-rotate: -45deg !important;
  }
  .rotate-md-n90 {
    --bs-rotate: -90deg !important;
  }
  .rotate-md-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }
  .rotate-md-n90-focus:focus {
    --bs-rotate: -90deg !important;
  }
  .rotate-md-n180 {
    --bs-rotate: -180deg !important;
  }
  .rotate-md-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }
  .rotate-md-n180-focus:focus {
    --bs-rotate: -180deg !important;
  }
  .rotate-y-md-0 {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-md-0-hover:hover {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-md-0-focus:focus {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-md-1 {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-md-1-hover:hover {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-md-1-focus:focus {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-md-2 {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-md-2-hover:hover {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-md-2-focus:focus {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-md-3 {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-md-3-hover:hover {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-md-3-focus:focus {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-md-6 {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-md-6-hover:hover {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-md-6-focus:focus {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-md-12 {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-md-12-hover:hover {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-md-12-focus:focus {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-md-30 {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-md-30-hover:hover {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-md-30-focus:focus {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-md-45 {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-md-45-hover:hover {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-md-45-focus:focus {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-md-90 {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-md-90-hover:hover {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-md-90-focus:focus {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-md-180 {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-md-180-hover:hover {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-md-180-focus:focus {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-md-n1 {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-md-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-md-n1-focus:focus {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-md-n2 {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-md-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-md-n2-focus:focus {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-md-n3 {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-md-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-md-n3-focus:focus {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-md-n6 {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-md-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-md-n6-focus:focus {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-md-n12 {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-md-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-md-n12-focus:focus {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-md-n30 {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-md-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-md-n30-focus:focus {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-md-n45 {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-md-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-md-n45-focus:focus {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-md-n90 {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-md-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-md-n90-focus:focus {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-md-n180 {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-md-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-md-n180-focus:focus {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-x-md-0 {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-md-0-hover:hover {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-md-0-focus:focus {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-md-1 {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-md-1-hover:hover {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-md-1-focus:focus {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-md-2 {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-md-2-hover:hover {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-md-2-focus:focus {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-md-3 {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-md-3-hover:hover {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-md-3-focus:focus {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-md-6 {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-md-6-hover:hover {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-md-6-focus:focus {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-md-12 {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-md-12-hover:hover {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-md-12-focus:focus {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-md-30 {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-md-30-hover:hover {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-md-30-focus:focus {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-md-45 {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-md-45-hover:hover {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-md-45-focus:focus {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-md-90 {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-md-90-hover:hover {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-md-90-focus:focus {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-md-180 {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-md-180-hover:hover {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-md-180-focus:focus {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-md-n1 {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-md-n1-hover:hover {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-md-n1-focus:focus {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-md-n2 {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-md-n2-hover:hover {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-md-n2-focus:focus {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-md-n3 {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-md-n3-hover:hover {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-md-n3-focus:focus {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-md-n6 {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-md-n6-hover:hover {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-md-n6-focus:focus {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-md-n12 {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-md-n12-hover:hover {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-md-n12-focus:focus {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-md-n30 {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-md-n30-hover:hover {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-md-n30-focus:focus {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-md-n45 {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-md-n45-hover:hover {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-md-n45-focus:focus {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-md-n90 {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-md-n90-hover:hover {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-md-n90-focus:focus {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-md-n180 {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-md-n180-hover:hover {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-md-n180-focus:focus {
    --bs-rotate-x: -180deg !important;
  }
  .perspective-md-100 {
    --bs-perspective: 100px !important;
  }
  .perspective-md-100-hover:hover {
    --bs-perspective: 100px !important;
  }
  .perspective-md-100-focus:focus {
    --bs-perspective: 100px !important;
  }
  .perspective-md-200 {
    --bs-perspective: 200px !important;
  }
  .perspective-md-200-hover:hover {
    --bs-perspective: 200px !important;
  }
  .perspective-md-200-focus:focus {
    --bs-perspective: 200px !important;
  }
  .perspective-md-300 {
    --bs-perspective: 300px !important;
  }
  .perspective-md-300-hover:hover {
    --bs-perspective: 300px !important;
  }
  .perspective-md-300-focus:focus {
    --bs-perspective: 300px !important;
  }
  .perspective-md-400 {
    --bs-perspective: 400px !important;
  }
  .perspective-md-400-hover:hover {
    --bs-perspective: 400px !important;
  }
  .perspective-md-400-focus:focus {
    --bs-perspective: 400px !important;
  }
  .perspective-md-500 {
    --bs-perspective: 500px !important;
  }
  .perspective-md-500-hover:hover {
    --bs-perspective: 500px !important;
  }
  .perspective-md-500-focus:focus {
    --bs-perspective: 500px !important;
  }
  .perspective-md-600 {
    --bs-perspective: 600px !important;
  }
  .perspective-md-600-hover:hover {
    --bs-perspective: 600px !important;
  }
  .perspective-md-600-focus:focus {
    --bs-perspective: 600px !important;
  }
  .perspective-md-700 {
    --bs-perspective: 700px !important;
  }
  .perspective-md-700-hover:hover {
    --bs-perspective: 700px !important;
  }
  .perspective-md-700-focus:focus {
    --bs-perspective: 700px !important;
  }
  .perspective-md-800 {
    --bs-perspective: 800px !important;
  }
  .perspective-md-800-hover:hover {
    --bs-perspective: 800px !important;
  }
  .perspective-md-800-focus:focus {
    --bs-perspective: 800px !important;
  }
  .perspective-md-1000 {
    --bs-perspective: 1000px !important;
  }
  .perspective-md-1000-hover:hover {
    --bs-perspective: 1000px !important;
  }
  .perspective-md-1000-focus:focus {
    --bs-perspective: 1000px !important;
  }
  .perspective-md-1250 {
    --bs-perspective: 1250px !important;
  }
  .perspective-md-1250-hover:hover {
    --bs-perspective: 1250px !important;
  }
  .perspective-md-1250-focus:focus {
    --bs-perspective: 1250px !important;
  }
  .perspective-md-1500 {
    --bs-perspective: 1500px !important;
  }
  .perspective-md-1500-hover:hover {
    --bs-perspective: 1500px !important;
  }
  .perspective-md-1500-focus:focus {
    --bs-perspective: 1500px !important;
  }
  .translate-y-md-0 {
    --bs-translate-y: 0 !important;
  }
  .translate-y-md-0-hover:hover {
    --bs-translate-y: 0 !important;
  }
  .translate-y-md-0-focus:focus {
    --bs-translate-y: 0 !important;
  }
  .translate-y-md-1 {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-md-1-hover:hover {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-md-1-focus:focus {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-md-2 {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-md-2-hover:hover {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-md-2-focus:focus {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-md-3 {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-md-3-hover:hover {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-md-3-focus:focus {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-md-4 {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-md-4-hover:hover {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-md-4-focus:focus {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-md-5 {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-md-5-hover:hover {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-md-5-focus:focus {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-md-6 {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-md-6-hover:hover {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-md-6-focus:focus {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-md-7 {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-md-7-hover:hover {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-md-7-focus:focus {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-md-8 {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-md-8-hover:hover {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-md-8-focus:focus {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-md-10 {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-md-10-hover:hover {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-md-10-focus:focus {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-md-12 {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-md-12-hover:hover {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-md-12-focus:focus {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-md-14 {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-md-14-hover:hover {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-md-14-focus:focus {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-md-16 {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-md-16-hover:hover {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-md-16-focus:focus {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-md-18 {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-md-18-hover:hover {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-md-18-focus:focus {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-md-20 {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-md-20-hover:hover {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-md-20-focus:focus {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-md-24 {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-md-24-hover:hover {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-md-24-focus:focus {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-md-32 {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-md-32-hover:hover {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-md-32-focus:focus {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-md-40 {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-md-40-hover:hover {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-md-40-focus:focus {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-md-48 {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-md-48-hover:hover {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-md-48-focus:focus {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-md-56 {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-md-56-hover:hover {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-md-56-focus:focus {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-md-64 {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-md-64-hover:hover {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-md-64-focus:focus {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-md-72 {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-md-72-hover:hover {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-md-72-focus:focus {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-md-80 {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-md-80-hover:hover {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-md-80-focus:focus {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-md-88 {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-md-88-hover:hover {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-md-88-focus:focus {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-md-96 {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-md-96-hover:hover {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-md-96-focus:focus {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-md-px {
    --bs-translate-y: 1px !important;
  }
  .translate-y-md-px-hover:hover {
    --bs-translate-y: 1px !important;
  }
  .translate-y-md-px-focus:focus {
    --bs-translate-y: 1px !important;
  }
  .translate-y-md-1\/2 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-1\/2-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-1\/2-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-1\/3 {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-md-1\/3-hover:hover {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-md-1\/3-focus:focus {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-md-1\/4 {
    --bs-translate-y: 25% !important;
  }
  .translate-y-md-1\/4-hover:hover {
    --bs-translate-y: 25% !important;
  }
  .translate-y-md-1\/4-focus:focus {
    --bs-translate-y: 25% !important;
  }
  .translate-y-md-1\/5 {
    --bs-translate-y: 20% !important;
  }
  .translate-y-md-1\/5-hover:hover {
    --bs-translate-y: 20% !important;
  }
  .translate-y-md-1\/5-focus:focus {
    --bs-translate-y: 20% !important;
  }
  .translate-y-md-2\/3 {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-md-2\/3-hover:hover {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-md-2\/3-focus:focus {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-md-2\/5 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-2\/5-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-2\/5-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-md-3\/4 {
    --bs-translate-y: 75% !important;
  }
  .translate-y-md-3\/4-hover:hover {
    --bs-translate-y: 75% !important;
  }
  .translate-y-md-3\/4-focus:focus {
    --bs-translate-y: 75% !important;
  }
  .translate-y-md-full {
    --bs-translate-y: 100% !important;
  }
  .translate-y-md-full-hover:hover {
    --bs-translate-y: 100% !important;
  }
  .translate-y-md-full-focus:focus {
    --bs-translate-y: 100% !important;
  }
  .translate-y-md-n1 {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-md-n1-hover:hover {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-md-n1-focus:focus {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-md-n2 {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-md-n2-hover:hover {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-md-n2-focus:focus {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-md-n3 {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-md-n3-hover:hover {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-md-n3-focus:focus {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-md-n4 {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-md-n4-hover:hover {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-md-n4-focus:focus {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-md-n5 {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-md-n5-hover:hover {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-md-n5-focus:focus {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-md-n6 {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-md-n6-hover:hover {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-md-n6-focus:focus {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-md-n7 {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-md-n7-hover:hover {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-md-n7-focus:focus {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-md-n8 {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-md-n8-hover:hover {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-md-n8-focus:focus {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-md-n10 {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-md-n10-hover:hover {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-md-n10-focus:focus {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-md-n12 {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-md-n12-hover:hover {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-md-n12-focus:focus {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-md-n14 {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-md-n14-hover:hover {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-md-n14-focus:focus {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-md-n16 {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-md-n16-hover:hover {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-md-n16-focus:focus {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-md-n18 {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-md-n18-hover:hover {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-md-n18-focus:focus {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-md-n20 {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-md-n20-hover:hover {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-md-n20-focus:focus {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-md-n24 {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-md-n24-hover:hover {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-md-n24-focus:focus {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-md-n32 {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-md-n32-hover:hover {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-md-n32-focus:focus {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-md-n40 {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-md-n40-hover:hover {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-md-n40-focus:focus {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-md-n48 {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-md-n48-hover:hover {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-md-n48-focus:focus {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-md-n56 {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-md-n56-hover:hover {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-md-n56-focus:focus {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-md-n64 {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-md-n64-hover:hover {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-md-n64-focus:focus {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-md-n72 {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-md-n72-hover:hover {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-md-n72-focus:focus {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-md-n80 {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-md-n80-hover:hover {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-md-n80-focus:focus {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-md-n88 {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-md-n88-hover:hover {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-md-n88-focus:focus {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-md-n96 {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-md-n96-hover:hover {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-md-n96-focus:focus {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-md-npx {
    --bs-translate-y: -1px !important;
  }
  .translate-y-md-npx-hover:hover {
    --bs-translate-y: -1px !important;
  }
  .translate-y-md-npx-focus:focus {
    --bs-translate-y: -1px !important;
  }
  .translate-y-md-n1\/2 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n1\/2-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n1\/2-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n1\/3 {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-md-n1\/3-hover:hover {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-md-n1\/3-focus:focus {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-md-n1\/4 {
    --bs-translate-y: -25% !important;
  }
  .translate-y-md-n1\/4-hover:hover {
    --bs-translate-y: -25% !important;
  }
  .translate-y-md-n1\/4-focus:focus {
    --bs-translate-y: -25% !important;
  }
  .translate-y-md-n1\/5 {
    --bs-translate-y: -20% !important;
  }
  .translate-y-md-n1\/5-hover:hover {
    --bs-translate-y: -20% !important;
  }
  .translate-y-md-n1\/5-focus:focus {
    --bs-translate-y: -20% !important;
  }
  .translate-y-md-n2\/3 {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-md-n2\/3-hover:hover {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-md-n2\/3-focus:focus {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-md-n2\/5 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n2\/5-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n2\/5-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-md-n3\/4 {
    --bs-translate-y: -75% !important;
  }
  .translate-y-md-n3\/4-hover:hover {
    --bs-translate-y: -75% !important;
  }
  .translate-y-md-n3\/4-focus:focus {
    --bs-translate-y: -75% !important;
  }
  .translate-y-md-nfull {
    --bs-translate-y: -100% !important;
  }
  .translate-y-md-nfull-hover:hover {
    --bs-translate-y: -100% !important;
  }
  .translate-y-md-nfull-focus:focus {
    --bs-translate-y: -100% !important;
  }
  .translate-x-md-0 {
    --bs-translate-x: 0 !important;
  }
  .translate-x-md-0-hover:hover {
    --bs-translate-x: 0 !important;
  }
  .translate-x-md-0-focus:focus {
    --bs-translate-x: 0 !important;
  }
  .translate-x-md-1 {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-md-1-hover:hover {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-md-1-focus:focus {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-md-2 {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-md-2-hover:hover {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-md-2-focus:focus {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-md-3 {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-md-3-hover:hover {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-md-3-focus:focus {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-md-4 {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-md-4-hover:hover {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-md-4-focus:focus {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-md-5 {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-md-5-hover:hover {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-md-5-focus:focus {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-md-6 {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-md-6-hover:hover {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-md-6-focus:focus {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-md-7 {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-md-7-hover:hover {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-md-7-focus:focus {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-md-8 {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-md-8-hover:hover {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-md-8-focus:focus {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-md-10 {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-md-10-hover:hover {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-md-10-focus:focus {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-md-12 {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-md-12-hover:hover {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-md-12-focus:focus {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-md-14 {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-md-14-hover:hover {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-md-14-focus:focus {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-md-16 {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-md-16-hover:hover {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-md-16-focus:focus {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-md-18 {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-md-18-hover:hover {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-md-18-focus:focus {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-md-20 {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-md-20-hover:hover {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-md-20-focus:focus {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-md-24 {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-md-24-hover:hover {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-md-24-focus:focus {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-md-32 {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-md-32-hover:hover {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-md-32-focus:focus {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-md-40 {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-md-40-hover:hover {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-md-40-focus:focus {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-md-48 {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-md-48-hover:hover {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-md-48-focus:focus {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-md-56 {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-md-56-hover:hover {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-md-56-focus:focus {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-md-64 {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-md-64-hover:hover {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-md-64-focus:focus {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-md-72 {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-md-72-hover:hover {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-md-72-focus:focus {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-md-80 {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-md-80-hover:hover {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-md-80-focus:focus {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-md-88 {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-md-88-hover:hover {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-md-88-focus:focus {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-md-96 {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-md-96-hover:hover {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-md-96-focus:focus {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-md-px {
    --bs-translate-x: 1px !important;
  }
  .translate-x-md-px-hover:hover {
    --bs-translate-x: 1px !important;
  }
  .translate-x-md-px-focus:focus {
    --bs-translate-x: 1px !important;
  }
  .translate-x-md-1\/2 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-1\/2-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-1\/2-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-1\/3 {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-md-1\/3-hover:hover {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-md-1\/3-focus:focus {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-md-1\/4 {
    --bs-translate-x: 25% !important;
  }
  .translate-x-md-1\/4-hover:hover {
    --bs-translate-x: 25% !important;
  }
  .translate-x-md-1\/4-focus:focus {
    --bs-translate-x: 25% !important;
  }
  .translate-x-md-1\/5 {
    --bs-translate-x: 20% !important;
  }
  .translate-x-md-1\/5-hover:hover {
    --bs-translate-x: 20% !important;
  }
  .translate-x-md-1\/5-focus:focus {
    --bs-translate-x: 20% !important;
  }
  .translate-x-md-2\/3 {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-md-2\/3-hover:hover {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-md-2\/3-focus:focus {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-md-2\/5 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-2\/5-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-2\/5-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-md-3\/4 {
    --bs-translate-x: 75% !important;
  }
  .translate-x-md-3\/4-hover:hover {
    --bs-translate-x: 75% !important;
  }
  .translate-x-md-3\/4-focus:focus {
    --bs-translate-x: 75% !important;
  }
  .translate-x-md-full {
    --bs-translate-x: 100% !important;
  }
  .translate-x-md-full-hover:hover {
    --bs-translate-x: 100% !important;
  }
  .translate-x-md-full-focus:focus {
    --bs-translate-x: 100% !important;
  }
  .translate-x-md-n1 {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-md-n1-hover:hover {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-md-n1-focus:focus {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-md-n2 {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-md-n2-hover:hover {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-md-n2-focus:focus {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-md-n3 {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-md-n3-hover:hover {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-md-n3-focus:focus {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-md-n4 {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-md-n4-hover:hover {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-md-n4-focus:focus {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-md-n5 {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-md-n5-hover:hover {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-md-n5-focus:focus {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-md-n6 {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-md-n6-hover:hover {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-md-n6-focus:focus {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-md-n7 {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-md-n7-hover:hover {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-md-n7-focus:focus {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-md-n8 {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-md-n8-hover:hover {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-md-n8-focus:focus {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-md-n10 {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-md-n10-hover:hover {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-md-n10-focus:focus {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-md-n12 {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-md-n12-hover:hover {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-md-n12-focus:focus {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-md-n14 {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-md-n14-hover:hover {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-md-n14-focus:focus {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-md-n16 {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-md-n16-hover:hover {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-md-n16-focus:focus {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-md-n18 {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-md-n18-hover:hover {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-md-n18-focus:focus {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-md-n20 {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-md-n20-hover:hover {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-md-n20-focus:focus {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-md-n24 {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-md-n24-hover:hover {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-md-n24-focus:focus {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-md-n32 {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-md-n32-hover:hover {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-md-n32-focus:focus {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-md-n40 {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-md-n40-hover:hover {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-md-n40-focus:focus {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-md-n48 {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-md-n48-hover:hover {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-md-n48-focus:focus {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-md-n56 {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-md-n56-hover:hover {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-md-n56-focus:focus {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-md-n64 {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-md-n64-hover:hover {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-md-n64-focus:focus {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-md-n72 {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-md-n72-hover:hover {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-md-n72-focus:focus {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-md-n80 {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-md-n80-hover:hover {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-md-n80-focus:focus {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-md-n88 {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-md-n88-hover:hover {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-md-n88-focus:focus {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-md-n96 {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-md-n96-hover:hover {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-md-n96-focus:focus {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-md-npx {
    --bs-translate-x: -1px !important;
  }
  .translate-x-md-npx-hover:hover {
    --bs-translate-x: -1px !important;
  }
  .translate-x-md-npx-focus:focus {
    --bs-translate-x: -1px !important;
  }
  .translate-x-md-n1\/2 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n1\/2-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n1\/2-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n1\/3 {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-md-n1\/3-hover:hover {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-md-n1\/3-focus:focus {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-md-n1\/4 {
    --bs-translate-x: -25% !important;
  }
  .translate-x-md-n1\/4-hover:hover {
    --bs-translate-x: -25% !important;
  }
  .translate-x-md-n1\/4-focus:focus {
    --bs-translate-x: -25% !important;
  }
  .translate-x-md-n1\/5 {
    --bs-translate-x: -20% !important;
  }
  .translate-x-md-n1\/5-hover:hover {
    --bs-translate-x: -20% !important;
  }
  .translate-x-md-n1\/5-focus:focus {
    --bs-translate-x: -20% !important;
  }
  .translate-x-md-n2\/3 {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-md-n2\/3-hover:hover {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-md-n2\/3-focus:focus {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-md-n2\/5 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n2\/5-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n2\/5-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-md-n3\/4 {
    --bs-translate-x: -75% !important;
  }
  .translate-x-md-n3\/4-hover:hover {
    --bs-translate-x: -75% !important;
  }
  .translate-x-md-n3\/4-focus:focus {
    --bs-translate-x: -75% !important;
  }
  .translate-x-md-nfull {
    --bs-translate-x: -100% !important;
  }
  .translate-x-md-nfull-hover:hover {
    --bs-translate-x: -100% !important;
  }
  .translate-x-md-nfull-focus:focus {
    --bs-translate-x: -100% !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .overflow-lg-auto {
    overflow: auto !important;
  }
  .overflow-lg-hidden {
    overflow: hidden !important;
  }
  .overflow-lg-visible {
    overflow: visible !important;
  }
  .overflow-lg-scroll {
    overflow: scroll !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .shadow-lg {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-lg-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-lg-focus:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-lg-1 {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-1-hover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-1-focus:focus {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-2 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-lg-2-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-lg-2-focus:focus {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-lg-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-lg-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-inset-focus:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-outline-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-focus-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-lg-none {
    box-shadow: none !important;
  }
  .shadow-lg-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-lg-none-focus:focus {
    box-shadow: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-1 {
    top: 0.25rem !important;
  }
  .top-lg-2 {
    top: 0.5rem !important;
  }
  .top-lg-3 {
    top: 0.75rem !important;
  }
  .top-lg-4 {
    top: 1rem !important;
  }
  .top-lg-5 {
    top: 1.25rem !important;
  }
  .top-lg-6 {
    top: 1.5rem !important;
  }
  .top-lg-7 {
    top: 1.75rem !important;
  }
  .top-lg-8 {
    top: 2rem !important;
  }
  .top-lg-10 {
    top: 2.5rem !important;
  }
  .top-lg-12 {
    top: 3rem !important;
  }
  .top-lg-14 {
    top: 3.5rem !important;
  }
  .top-lg-16 {
    top: 4rem !important;
  }
  .top-lg-18 {
    top: 4.5rem !important;
  }
  .top-lg-20 {
    top: 5rem !important;
  }
  .top-lg-24 {
    top: 6rem !important;
  }
  .top-lg-32 {
    top: 8rem !important;
  }
  .top-lg-40 {
    top: 10rem !important;
  }
  .top-lg-48 {
    top: 12rem !important;
  }
  .top-lg-56 {
    top: 14rem !important;
  }
  .top-lg-64 {
    top: 16rem !important;
  }
  .top-lg-72 {
    top: 18rem !important;
  }
  .top-lg-80 {
    top: 20rem !important;
  }
  .top-lg-88 {
    top: 22rem !important;
  }
  .top-lg-96 {
    top: 24rem !important;
  }
  .top-lg-px {
    top: 1px !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-1 {
    bottom: 0.25rem !important;
  }
  .bottom-lg-2 {
    bottom: 0.5rem !important;
  }
  .bottom-lg-3 {
    bottom: 0.75rem !important;
  }
  .bottom-lg-4 {
    bottom: 1rem !important;
  }
  .bottom-lg-5 {
    bottom: 1.25rem !important;
  }
  .bottom-lg-6 {
    bottom: 1.5rem !important;
  }
  .bottom-lg-7 {
    bottom: 1.75rem !important;
  }
  .bottom-lg-8 {
    bottom: 2rem !important;
  }
  .bottom-lg-10 {
    bottom: 2.5rem !important;
  }
  .bottom-lg-12 {
    bottom: 3rem !important;
  }
  .bottom-lg-14 {
    bottom: 3.5rem !important;
  }
  .bottom-lg-16 {
    bottom: 4rem !important;
  }
  .bottom-lg-18 {
    bottom: 4.5rem !important;
  }
  .bottom-lg-20 {
    bottom: 5rem !important;
  }
  .bottom-lg-24 {
    bottom: 6rem !important;
  }
  .bottom-lg-32 {
    bottom: 8rem !important;
  }
  .bottom-lg-40 {
    bottom: 10rem !important;
  }
  .bottom-lg-48 {
    bottom: 12rem !important;
  }
  .bottom-lg-56 {
    bottom: 14rem !important;
  }
  .bottom-lg-64 {
    bottom: 16rem !important;
  }
  .bottom-lg-72 {
    bottom: 18rem !important;
  }
  .bottom-lg-80 {
    bottom: 20rem !important;
  }
  .bottom-lg-88 {
    bottom: 22rem !important;
  }
  .bottom-lg-96 {
    bottom: 24rem !important;
  }
  .bottom-lg-px {
    bottom: 1px !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .start-lg-0 {
    left: 0 !important;
  }
  .start-lg-1 {
    left: 0.25rem !important;
  }
  .start-lg-2 {
    left: 0.5rem !important;
  }
  .start-lg-3 {
    left: 0.75rem !important;
  }
  .start-lg-4 {
    left: 1rem !important;
  }
  .start-lg-5 {
    left: 1.25rem !important;
  }
  .start-lg-6 {
    left: 1.5rem !important;
  }
  .start-lg-7 {
    left: 1.75rem !important;
  }
  .start-lg-8 {
    left: 2rem !important;
  }
  .start-lg-10 {
    left: 2.5rem !important;
  }
  .start-lg-12 {
    left: 3rem !important;
  }
  .start-lg-14 {
    left: 3.5rem !important;
  }
  .start-lg-16 {
    left: 4rem !important;
  }
  .start-lg-18 {
    left: 4.5rem !important;
  }
  .start-lg-20 {
    left: 5rem !important;
  }
  .start-lg-24 {
    left: 6rem !important;
  }
  .start-lg-32 {
    left: 8rem !important;
  }
  .start-lg-40 {
    left: 10rem !important;
  }
  .start-lg-48 {
    left: 12rem !important;
  }
  .start-lg-56 {
    left: 14rem !important;
  }
  .start-lg-64 {
    left: 16rem !important;
  }
  .start-lg-72 {
    left: 18rem !important;
  }
  .start-lg-80 {
    left: 20rem !important;
  }
  .start-lg-88 {
    left: 22rem !important;
  }
  .start-lg-96 {
    left: 24rem !important;
  }
  .start-lg-px {
    left: 1px !important;
  }
  .start-lg-50 {
    left: 50% !important;
  }
  .start-lg-100 {
    left: 100% !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-1 {
    right: 0.25rem !important;
  }
  .end-lg-2 {
    right: 0.5rem !important;
  }
  .end-lg-3 {
    right: 0.75rem !important;
  }
  .end-lg-4 {
    right: 1rem !important;
  }
  .end-lg-5 {
    right: 1.25rem !important;
  }
  .end-lg-6 {
    right: 1.5rem !important;
  }
  .end-lg-7 {
    right: 1.75rem !important;
  }
  .end-lg-8 {
    right: 2rem !important;
  }
  .end-lg-10 {
    right: 2.5rem !important;
  }
  .end-lg-12 {
    right: 3rem !important;
  }
  .end-lg-14 {
    right: 3.5rem !important;
  }
  .end-lg-16 {
    right: 4rem !important;
  }
  .end-lg-18 {
    right: 4.5rem !important;
  }
  .end-lg-20 {
    right: 5rem !important;
  }
  .end-lg-24 {
    right: 6rem !important;
  }
  .end-lg-32 {
    right: 8rem !important;
  }
  .end-lg-40 {
    right: 10rem !important;
  }
  .end-lg-48 {
    right: 12rem !important;
  }
  .end-lg-56 {
    right: 14rem !important;
  }
  .end-lg-64 {
    right: 16rem !important;
  }
  .end-lg-72 {
    right: 18rem !important;
  }
  .end-lg-80 {
    right: 20rem !important;
  }
  .end-lg-88 {
    right: 22rem !important;
  }
  .end-lg-96 {
    right: 24rem !important;
  }
  .end-lg-px {
    right: 1px !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .border-top-lg-0 {
    border-top-width: 0 !important;
  }
  .border-top-lg-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-lg-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top-lg {
    border-top-width: 1px !important;
  }
  .border-top-lg-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-lg-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-lg-2 {
    border-top-width: 2px !important;
  }
  .border-top-lg-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-lg-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-lg-3 {
    border-top-width: 3px !important;
  }
  .border-top-lg-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-lg-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-lg-4 {
    border-top-width: 4px !important;
  }
  .border-top-lg-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-lg-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-lg-5 {
    border-top-width: 5px !important;
  }
  .border-top-lg-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-lg-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-lg-6 {
    border-top-width: 6px !important;
  }
  .border-top-lg-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-lg-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-lg-7 {
    border-top-width: 7px !important;
  }
  .border-top-lg-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-lg-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-lg-8 {
    border-top-width: 8px !important;
  }
  .border-top-lg-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-lg-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-lg-0 {
    border-right-width: 0 !important;
  }
  .border-end-lg-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-lg-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end-lg {
    border-right-width: 1px !important;
  }
  .border-end-lg-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-lg-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-lg-2 {
    border-right-width: 2px !important;
  }
  .border-end-lg-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-lg-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-lg-3 {
    border-right-width: 3px !important;
  }
  .border-end-lg-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-lg-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-lg-4 {
    border-right-width: 4px !important;
  }
  .border-end-lg-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-lg-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-lg-5 {
    border-right-width: 5px !important;
  }
  .border-end-lg-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-lg-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-lg-6 {
    border-right-width: 6px !important;
  }
  .border-end-lg-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-lg-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-lg-7 {
    border-right-width: 7px !important;
  }
  .border-end-lg-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-lg-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-lg-8 {
    border-right-width: 8px !important;
  }
  .border-end-lg-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-lg-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-lg-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-lg-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-lg-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom-lg {
    border-bottom-width: 1px !important;
  }
  .border-bottom-lg-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-lg-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-lg-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-lg-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-lg-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-lg-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-lg-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-lg-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-lg-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-lg-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-lg-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-lg-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-lg-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-lg-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-lg-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-lg-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-lg-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-lg-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-lg-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-lg-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-lg-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-lg-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-lg-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-lg-0 {
    border-left-width: 0 !important;
  }
  .border-start-lg-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-lg-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start-lg {
    border-left-width: 1px !important;
  }
  .border-start-lg-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-lg-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-lg-2 {
    border-left-width: 2px !important;
  }
  .border-start-lg-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-lg-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-lg-3 {
    border-left-width: 3px !important;
  }
  .border-start-lg-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-lg-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-lg-4 {
    border-left-width: 4px !important;
  }
  .border-start-lg-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-lg-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-lg-5 {
    border-left-width: 5px !important;
  }
  .border-start-lg-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-lg-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-lg-6 {
    border-left-width: 6px !important;
  }
  .border-start-lg-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-lg-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-lg-7 {
    border-left-width: 7px !important;
  }
  .border-start-lg-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-lg-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-lg-8 {
    border-left-width: 8px !important;
  }
  .border-start-lg-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-lg-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-lg-primary {
    border-color: #1A16C0 !important;
  }
  .border-lg-primary-hover:hover {
    border-color: #1A16C0 !important;
  }
  .border-lg-primary-focus:focus {
    border-color: #1A16C0 !important;
  }
  .border-lg-primary-focus-within:focus-within {
    border-color: #1A16C0 !important;
  }
  .border-lg-secondary {
    border-color: #E4ECF7 !important;
  }
  .border-lg-secondary-hover:hover {
    border-color: #E4ECF7 !important;
  }
  .border-lg-secondary-focus:focus {
    border-color: #E4ECF7 !important;
  }
  .border-lg-secondary-focus-within:focus-within {
    border-color: #E4ECF7 !important;
  }
  .border-lg-success {
    border-color: #4AAE8C !important;
  }
  .border-lg-success-hover:hover {
    border-color: #4AAE8C !important;
  }
  .border-lg-success-focus:focus {
    border-color: #4AAE8C !important;
  }
  .border-lg-success-focus-within:focus-within {
    border-color: #4AAE8C !important;
  }
  .border-lg-info {
    border-color: #68B8F2 !important;
  }
  .border-lg-info-hover:hover {
    border-color: #68B8F2 !important;
  }
  .border-lg-info-focus:focus {
    border-color: #68B8F2 !important;
  }
  .border-lg-info-focus-within:focus-within {
    border-color: #68B8F2 !important;
  }
  .border-lg-warning {
    border-color: #ED9830 !important;
  }
  .border-lg-warning-hover:hover {
    border-color: #ED9830 !important;
  }
  .border-lg-warning-focus:focus {
    border-color: #ED9830 !important;
  }
  .border-lg-warning-focus-within:focus-within {
    border-color: #ED9830 !important;
  }
  .border-lg-danger {
    border-color: #F16063 !important;
  }
  .border-lg-danger-hover:hover {
    border-color: #F16063 !important;
  }
  .border-lg-danger-focus:focus {
    border-color: #F16063 !important;
  }
  .border-lg-danger-focus-within:focus-within {
    border-color: #F16063 !important;
  }
  .border-lg-light {
    border-color: #F7FAFC !important;
  }
  .border-lg-light-hover:hover {
    border-color: #F7FAFC !important;
  }
  .border-lg-light-focus:focus {
    border-color: #F7FAFC !important;
  }
  .border-lg-light-focus-within:focus-within {
    border-color: #F7FAFC !important;
  }
  .border-lg-dark {
    border-color: #27272E !important;
  }
  .border-lg-dark-hover:hover {
    border-color: #27272E !important;
  }
  .border-lg-dark-focus:focus {
    border-color: #27272E !important;
  }
  .border-lg-dark-focus-within:focus-within {
    border-color: #27272E !important;
  }
  .border-lg-transparent {
    border-color: transparent !important;
  }
  .border-lg-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-lg-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-lg-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-lg-current {
    border-color: currentColor !important;
  }
  .border-lg-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-lg-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-lg-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-lg-base {
    border-color: #E2E8F0 !important;
  }
  .border-lg-base-hover:hover {
    border-color: #E2E8F0 !important;
  }
  .border-lg-base-focus:focus {
    border-color: #E2E8F0 !important;
  }
  .border-lg-base-focus-within:focus-within {
    border-color: #E2E8F0 !important;
  }
  .border-lg-body {
    border-color: #fff !important;
  }
  .border-lg-body-hover:hover {
    border-color: #fff !important;
  }
  .border-lg-body-focus:focus {
    border-color: #fff !important;
  }
  .border-lg-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-lg-card {
    border-color: #fff !important;
  }
  .border-lg-card-hover:hover {
    border-color: #fff !important;
  }
  .border-lg-card-focus:focus {
    border-color: #fff !important;
  }
  .border-lg-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-lg-black {
    border-color: #000 !important;
  }
  .border-lg-black-hover:hover {
    border-color: #000 !important;
  }
  .border-lg-black-focus:focus {
    border-color: #000 !important;
  }
  .border-lg-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-lg-white {
    border-color: #fff !important;
  }
  .border-lg-white-hover:hover {
    border-color: #fff !important;
  }
  .border-lg-white-focus:focus {
    border-color: #fff !important;
  }
  .border-lg-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-lg-0 {
    border-width: 0 !important;
  }
  .border-lg-0-hover:hover {
    border-width: 0 !important;
  }
  .border-lg-0-focus:focus {
    border-width: 0 !important;
  }
  .border-lg {
    border-width: 1px !important;
  }
  .border-lg-hover:hover {
    border-width: 1px !important;
  }
  .border-lg-focus:focus {
    border-width: 1px !important;
  }
  .border-lg-2 {
    border-width: 2px !important;
  }
  .border-lg-2-hover:hover {
    border-width: 2px !important;
  }
  .border-lg-2-focus:focus {
    border-width: 2px !important;
  }
  .border-lg-3 {
    border-width: 3px !important;
  }
  .border-lg-3-hover:hover {
    border-width: 3px !important;
  }
  .border-lg-3-focus:focus {
    border-width: 3px !important;
  }
  .border-lg-4 {
    border-width: 4px !important;
  }
  .border-lg-4-hover:hover {
    border-width: 4px !important;
  }
  .border-lg-4-focus:focus {
    border-width: 4px !important;
  }
  .border-lg-5 {
    border-width: 5px !important;
  }
  .border-lg-5-hover:hover {
    border-width: 5px !important;
  }
  .border-lg-5-focus:focus {
    border-width: 5px !important;
  }
  .border-lg-6 {
    border-width: 6px !important;
  }
  .border-lg-6-hover:hover {
    border-width: 6px !important;
  }
  .border-lg-6-focus:focus {
    border-width: 6px !important;
  }
  .border-lg-7 {
    border-width: 7px !important;
  }
  .border-lg-7-hover:hover {
    border-width: 7px !important;
  }
  .border-lg-7-focus:focus {
    border-width: 7px !important;
  }
  .border-lg-8 {
    border-width: 8px !important;
  }
  .border-lg-8-hover:hover {
    border-width: 8px !important;
  }
  .border-lg-8-focus:focus {
    border-width: 8px !important;
  }
  .w-lg-0 {
    width: 0 !important;
  }
  .w-lg-1 {
    width: 0.25rem !important;
  }
  .w-lg-2 {
    width: 0.5rem !important;
  }
  .w-lg-3 {
    width: 0.75rem !important;
  }
  .w-lg-4 {
    width: 1rem !important;
  }
  .w-lg-5 {
    width: 1.25rem !important;
  }
  .w-lg-6 {
    width: 1.5rem !important;
  }
  .w-lg-7 {
    width: 1.75rem !important;
  }
  .w-lg-8 {
    width: 2rem !important;
  }
  .w-lg-10 {
    width: 2.5rem !important;
  }
  .w-lg-12 {
    width: 3rem !important;
  }
  .w-lg-14 {
    width: 3.5rem !important;
  }
  .w-lg-16 {
    width: 4rem !important;
  }
  .w-lg-18 {
    width: 4.5rem !important;
  }
  .w-lg-20 {
    width: 5rem !important;
  }
  .w-lg-24 {
    width: 6rem !important;
  }
  .w-lg-32 {
    width: 8rem !important;
  }
  .w-lg-40 {
    width: 10rem !important;
  }
  .w-lg-48 {
    width: 12rem !important;
  }
  .w-lg-56 {
    width: 14rem !important;
  }
  .w-lg-64 {
    width: 16rem !important;
  }
  .w-lg-72 {
    width: 18rem !important;
  }
  .w-lg-80 {
    width: 20rem !important;
  }
  .w-lg-88 {
    width: 22rem !important;
  }
  .w-lg-96 {
    width: 24rem !important;
  }
  .w-lg-px {
    width: 1px !important;
  }
  .w-lg-1\/2 {
    width: 50% !important;
  }
  .w-lg-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-lg-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-lg-1\/4 {
    width: 25% !important;
  }
  .w-lg-2\/4 {
    width: 50% !important;
  }
  .w-lg-3\/4 {
    width: 75% !important;
  }
  .w-lg-1\/5 {
    width: 20% !important;
  }
  .w-lg-2\/5 {
    width: 40% !important;
  }
  .w-lg-3\/5 {
    width: 60% !important;
  }
  .w-lg-4\/5 {
    width: 80% !important;
  }
  .w-lg-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-lg-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-lg-3\/6 {
    width: 50% !important;
  }
  .w-lg-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-lg-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-lg-11\/10 {
    width: 110% !important;
  }
  .w-lg-12\/10 {
    width: 120% !important;
  }
  .w-lg-13\/10 {
    width: 130% !important;
  }
  .w-lg-14\/10 {
    width: 140% !important;
  }
  .w-lg-15\/10 {
    width: 150% !important;
  }
  .w-lg-screen-sm {
    width: 640px !important;
  }
  .w-lg-screen-md {
    width: 768px !important;
  }
  .w-lg-screen-lg {
    width: 1024px !important;
  }
  .w-lg-screen-xl {
    width: 1280px !important;
  }
  .w-lg-screen-xxl {
    width: 1536px !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-full {
    width: 100% !important;
  }
  .w-lg-screen {
    width: 100vw !important;
  }
  .w-lg-min {
    width: min-content !important;
  }
  .w-lg-max {
    width: max-content !important;
  }
  .max-w-lg-screen-sm {
    max-width: 640px !important;
  }
  .max-w-lg-screen-md {
    max-width: 768px !important;
  }
  .max-w-lg-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-lg-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-lg-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-lg-0 {
    max-width: 0 !important;
  }
  .max-w-lg-full {
    max-width: 100% !important;
  }
  .max-w-lg-read {
    max-width: 65ch !important;
  }
  .max-w-lg-min {
    max-width: min-content !important;
  }
  .max-w-lg-max {
    max-width: max-content !important;
  }
  .h-lg-0 {
    height: 0 !important;
  }
  .h-lg-1 {
    height: 0.25rem !important;
  }
  .h-lg-2 {
    height: 0.5rem !important;
  }
  .h-lg-3 {
    height: 0.75rem !important;
  }
  .h-lg-4 {
    height: 1rem !important;
  }
  .h-lg-5 {
    height: 1.25rem !important;
  }
  .h-lg-6 {
    height: 1.5rem !important;
  }
  .h-lg-7 {
    height: 1.75rem !important;
  }
  .h-lg-8 {
    height: 2rem !important;
  }
  .h-lg-10 {
    height: 2.5rem !important;
  }
  .h-lg-12 {
    height: 3rem !important;
  }
  .h-lg-14 {
    height: 3.5rem !important;
  }
  .h-lg-16 {
    height: 4rem !important;
  }
  .h-lg-18 {
    height: 4.5rem !important;
  }
  .h-lg-20 {
    height: 5rem !important;
  }
  .h-lg-24 {
    height: 6rem !important;
  }
  .h-lg-32 {
    height: 8rem !important;
  }
  .h-lg-40 {
    height: 10rem !important;
  }
  .h-lg-48 {
    height: 12rem !important;
  }
  .h-lg-56 {
    height: 14rem !important;
  }
  .h-lg-64 {
    height: 16rem !important;
  }
  .h-lg-72 {
    height: 18rem !important;
  }
  .h-lg-80 {
    height: 20rem !important;
  }
  .h-lg-88 {
    height: 22rem !important;
  }
  .h-lg-96 {
    height: 24rem !important;
  }
  .h-lg-px {
    height: 1px !important;
  }
  .h-lg-1\/2 {
    height: 50% !important;
  }
  .h-lg-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-lg-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-lg-1\/4 {
    height: 25% !important;
  }
  .h-lg-2\/4 {
    height: 50% !important;
  }
  .h-lg-3\/4 {
    height: 75% !important;
  }
  .h-lg-1\/5 {
    height: 20% !important;
  }
  .h-lg-2\/5 {
    height: 40% !important;
  }
  .h-lg-3\/5 {
    height: 60% !important;
  }
  .h-lg-4\/5 {
    height: 80% !important;
  }
  .h-lg-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-lg-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-lg-3\/6 {
    height: 50% !important;
  }
  .h-lg-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-lg-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-lg-11\/10 {
    height: 110% !important;
  }
  .h-lg-12\/10 {
    height: 120% !important;
  }
  .h-lg-13\/10 {
    height: 130% !important;
  }
  .h-lg-14\/10 {
    height: 140% !important;
  }
  .h-lg-15\/10 {
    height: 150% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-full {
    height: 100% !important;
  }
  .h-lg-screen {
    height: 100vh !important;
  }
  .max-h-lg-0 {
    max-height: 0 !important;
  }
  .max-h-lg-1 {
    max-height: 0.25rem !important;
  }
  .max-h-lg-2 {
    max-height: 0.5rem !important;
  }
  .max-h-lg-3 {
    max-height: 0.75rem !important;
  }
  .max-h-lg-4 {
    max-height: 1rem !important;
  }
  .max-h-lg-5 {
    max-height: 1.25rem !important;
  }
  .max-h-lg-6 {
    max-height: 1.5rem !important;
  }
  .max-h-lg-7 {
    max-height: 1.75rem !important;
  }
  .max-h-lg-8 {
    max-height: 2rem !important;
  }
  .max-h-lg-10 {
    max-height: 2.5rem !important;
  }
  .max-h-lg-12 {
    max-height: 3rem !important;
  }
  .max-h-lg-14 {
    max-height: 3.5rem !important;
  }
  .max-h-lg-16 {
    max-height: 4rem !important;
  }
  .max-h-lg-18 {
    max-height: 4.5rem !important;
  }
  .max-h-lg-20 {
    max-height: 5rem !important;
  }
  .max-h-lg-24 {
    max-height: 6rem !important;
  }
  .max-h-lg-32 {
    max-height: 8rem !important;
  }
  .max-h-lg-40 {
    max-height: 10rem !important;
  }
  .max-h-lg-48 {
    max-height: 12rem !important;
  }
  .max-h-lg-56 {
    max-height: 14rem !important;
  }
  .max-h-lg-64 {
    max-height: 16rem !important;
  }
  .max-h-lg-72 {
    max-height: 18rem !important;
  }
  .max-h-lg-80 {
    max-height: 20rem !important;
  }
  .max-h-lg-88 {
    max-height: 22rem !important;
  }
  .max-h-lg-96 {
    max-height: 24rem !important;
  }
  .max-h-lg-px {
    max-height: 1px !important;
  }
  .max-h-lg-full {
    max-height: 100% !important;
  }
  .max-h-lg-screen {
    max-height: 100vh !important;
  }
  .flex-lg-1 {
    flex: 1 1 0% !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-initial {
    flex: 0 1 auto !important;
  }
  .flex-lg-none {
    flex: none !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-10 {
    gap: 2.5rem !important;
  }
  .gap-lg-12 {
    gap: 3rem !important;
  }
  .gap-lg-14 {
    gap: 3.5rem !important;
  }
  .gap-lg-16 {
    gap: 4rem !important;
  }
  .gap-lg-18 {
    gap: 4.5rem !important;
  }
  .gap-lg-20 {
    gap: 5rem !important;
  }
  .gap-lg-24 {
    gap: 6rem !important;
  }
  .gap-lg-32 {
    gap: 8rem !important;
  }
  .gap-lg-40 {
    gap: 10rem !important;
  }
  .gap-lg-48 {
    gap: 12rem !important;
  }
  .gap-lg-56 {
    gap: 14rem !important;
  }
  .gap-lg-64 {
    gap: 16rem !important;
  }
  .gap-lg-72 {
    gap: 18rem !important;
  }
  .gap-lg-80 {
    gap: 20rem !important;
  }
  .gap-lg-88 {
    gap: 22rem !important;
  }
  .gap-lg-96 {
    gap: 24rem !important;
  }
  .gap-lg-px {
    gap: 1px !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-evenly {
    align-content: space-evenly !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-10 {
    margin: 2.5rem !important;
  }
  .m-lg-12 {
    margin: 3rem !important;
  }
  .m-lg-14 {
    margin: 3.5rem !important;
  }
  .m-lg-16 {
    margin: 4rem !important;
  }
  .m-lg-18 {
    margin: 4.5rem !important;
  }
  .m-lg-20 {
    margin: 5rem !important;
  }
  .m-lg-24 {
    margin: 6rem !important;
  }
  .m-lg-32 {
    margin: 8rem !important;
  }
  .m-lg-40 {
    margin: 10rem !important;
  }
  .m-lg-48 {
    margin: 12rem !important;
  }
  .m-lg-56 {
    margin: 14rem !important;
  }
  .m-lg-64 {
    margin: 16rem !important;
  }
  .m-lg-72 {
    margin: 18rem !important;
  }
  .m-lg-80 {
    margin: 20rem !important;
  }
  .m-lg-88 {
    margin: 22rem !important;
  }
  .m-lg-96 {
    margin: 24rem !important;
  }
  .m-lg-px {
    margin: 1px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-lg-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-lg-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-lg-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-lg-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-lg-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-lg-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-lg-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-lg-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-lg-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-lg-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 2rem !important;
  }
  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-12 {
    margin-top: 3rem !important;
  }
  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-16 {
    margin-top: 4rem !important;
  }
  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-20 {
    margin-top: 5rem !important;
  }
  .mt-lg-24 {
    margin-top: 6rem !important;
  }
  .mt-lg-32 {
    margin-top: 8rem !important;
  }
  .mt-lg-40 {
    margin-top: 10rem !important;
  }
  .mt-lg-48 {
    margin-top: 12rem !important;
  }
  .mt-lg-56 {
    margin-top: 14rem !important;
  }
  .mt-lg-64 {
    margin-top: 16rem !important;
  }
  .mt-lg-72 {
    margin-top: 18rem !important;
  }
  .mt-lg-80 {
    margin-top: 20rem !important;
  }
  .mt-lg-88 {
    margin-top: 22rem !important;
  }
  .mt-lg-96 {
    margin-top: 24rem !important;
  }
  .mt-lg-px {
    margin-top: 1px !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.25rem !important;
  }
  .me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .me-lg-7 {
    margin-right: 1.75rem !important;
  }
  .me-lg-8 {
    margin-right: 2rem !important;
  }
  .me-lg-10 {
    margin-right: 2.5rem !important;
  }
  .me-lg-12 {
    margin-right: 3rem !important;
  }
  .me-lg-14 {
    margin-right: 3.5rem !important;
  }
  .me-lg-16 {
    margin-right: 4rem !important;
  }
  .me-lg-18 {
    margin-right: 4.5rem !important;
  }
  .me-lg-20 {
    margin-right: 5rem !important;
  }
  .me-lg-24 {
    margin-right: 6rem !important;
  }
  .me-lg-32 {
    margin-right: 8rem !important;
  }
  .me-lg-40 {
    margin-right: 10rem !important;
  }
  .me-lg-48 {
    margin-right: 12rem !important;
  }
  .me-lg-56 {
    margin-right: 14rem !important;
  }
  .me-lg-64 {
    margin-right: 16rem !important;
  }
  .me-lg-72 {
    margin-right: 18rem !important;
  }
  .me-lg-80 {
    margin-right: 20rem !important;
  }
  .me-lg-88 {
    margin-right: 22rem !important;
  }
  .me-lg-96 {
    margin-right: 24rem !important;
  }
  .me-lg-px {
    margin-right: 1px !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-24 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-32 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-48 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-56 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-64 {
    margin-bottom: 16rem !important;
  }
  .mb-lg-72 {
    margin-bottom: 18rem !important;
  }
  .mb-lg-80 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-88 {
    margin-bottom: 22rem !important;
  }
  .mb-lg-96 {
    margin-bottom: 24rem !important;
  }
  .mb-lg-px {
    margin-bottom: 1px !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 2rem !important;
  }
  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-12 {
    margin-left: 3rem !important;
  }
  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-16 {
    margin-left: 4rem !important;
  }
  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-20 {
    margin-left: 5rem !important;
  }
  .ms-lg-24 {
    margin-left: 6rem !important;
  }
  .ms-lg-32 {
    margin-left: 8rem !important;
  }
  .ms-lg-40 {
    margin-left: 10rem !important;
  }
  .ms-lg-48 {
    margin-left: 12rem !important;
  }
  .ms-lg-56 {
    margin-left: 14rem !important;
  }
  .ms-lg-64 {
    margin-left: 16rem !important;
  }
  .ms-lg-72 {
    margin-left: 18rem !important;
  }
  .ms-lg-80 {
    margin-left: 20rem !important;
  }
  .ms-lg-88 {
    margin-left: 22rem !important;
  }
  .ms-lg-96 {
    margin-left: 24rem !important;
  }
  .ms-lg-px {
    margin-left: 1px !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-10 {
    padding: 2.5rem !important;
  }
  .p-lg-12 {
    padding: 3rem !important;
  }
  .p-lg-14 {
    padding: 3.5rem !important;
  }
  .p-lg-16 {
    padding: 4rem !important;
  }
  .p-lg-18 {
    padding: 4.5rem !important;
  }
  .p-lg-20 {
    padding: 5rem !important;
  }
  .p-lg-24 {
    padding: 6rem !important;
  }
  .p-lg-32 {
    padding: 8rem !important;
  }
  .p-lg-40 {
    padding: 10rem !important;
  }
  .p-lg-48 {
    padding: 12rem !important;
  }
  .p-lg-56 {
    padding: 14rem !important;
  }
  .p-lg-64 {
    padding: 16rem !important;
  }
  .p-lg-72 {
    padding: 18rem !important;
  }
  .p-lg-80 {
    padding: 20rem !important;
  }
  .p-lg-88 {
    padding: 22rem !important;
  }
  .p-lg-96 {
    padding: 24rem !important;
  }
  .p-lg-px {
    padding: 1px !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-lg-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-lg-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-lg-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-lg-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-lg-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-lg-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-lg-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-lg-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-lg-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-lg-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-lg-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-lg-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-lg-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-lg-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 2rem !important;
  }
  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-12 {
    padding-top: 3rem !important;
  }
  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-16 {
    padding-top: 4rem !important;
  }
  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-20 {
    padding-top: 5rem !important;
  }
  .pt-lg-24 {
    padding-top: 6rem !important;
  }
  .pt-lg-32 {
    padding-top: 8rem !important;
  }
  .pt-lg-40 {
    padding-top: 10rem !important;
  }
  .pt-lg-48 {
    padding-top: 12rem !important;
  }
  .pt-lg-56 {
    padding-top: 14rem !important;
  }
  .pt-lg-64 {
    padding-top: 16rem !important;
  }
  .pt-lg-72 {
    padding-top: 18rem !important;
  }
  .pt-lg-80 {
    padding-top: 20rem !important;
  }
  .pt-lg-88 {
    padding-top: 22rem !important;
  }
  .pt-lg-96 {
    padding-top: 24rem !important;
  }
  .pt-lg-px {
    padding-top: 1px !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 2rem !important;
  }
  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-12 {
    padding-right: 3rem !important;
  }
  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-16 {
    padding-right: 4rem !important;
  }
  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-20 {
    padding-right: 5rem !important;
  }
  .pe-lg-24 {
    padding-right: 6rem !important;
  }
  .pe-lg-32 {
    padding-right: 8rem !important;
  }
  .pe-lg-40 {
    padding-right: 10rem !important;
  }
  .pe-lg-48 {
    padding-right: 12rem !important;
  }
  .pe-lg-56 {
    padding-right: 14rem !important;
  }
  .pe-lg-64 {
    padding-right: 16rem !important;
  }
  .pe-lg-72 {
    padding-right: 18rem !important;
  }
  .pe-lg-80 {
    padding-right: 20rem !important;
  }
  .pe-lg-88 {
    padding-right: 22rem !important;
  }
  .pe-lg-96 {
    padding-right: 24rem !important;
  }
  .pe-lg-px {
    padding-right: 1px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-24 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-32 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-48 {
    padding-bottom: 12rem !important;
  }
  .pb-lg-56 {
    padding-bottom: 14rem !important;
  }
  .pb-lg-64 {
    padding-bottom: 16rem !important;
  }
  .pb-lg-72 {
    padding-bottom: 18rem !important;
  }
  .pb-lg-80 {
    padding-bottom: 20rem !important;
  }
  .pb-lg-88 {
    padding-bottom: 22rem !important;
  }
  .pb-lg-96 {
    padding-bottom: 24rem !important;
  }
  .pb-lg-px {
    padding-bottom: 1px !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 2rem !important;
  }
  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-12 {
    padding-left: 3rem !important;
  }
  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-16 {
    padding-left: 4rem !important;
  }
  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-20 {
    padding-left: 5rem !important;
  }
  .ps-lg-24 {
    padding-left: 6rem !important;
  }
  .ps-lg-32 {
    padding-left: 8rem !important;
  }
  .ps-lg-40 {
    padding-left: 10rem !important;
  }
  .ps-lg-48 {
    padding-left: 12rem !important;
  }
  .ps-lg-56 {
    padding-left: 14rem !important;
  }
  .ps-lg-64 {
    padding-left: 16rem !important;
  }
  .ps-lg-72 {
    padding-left: 18rem !important;
  }
  .ps-lg-80 {
    padding-left: 20rem !important;
  }
  .ps-lg-88 {
    padding-left: 22rem !important;
  }
  .ps-lg-96 {
    padding-left: 24rem !important;
  }
  .ps-lg-px {
    padding-left: 1px !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-primary-hover:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-primary-focus:focus {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-secondary-hover:hover {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-secondary-focus:focus {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-success-hover:hover {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-success-focus:focus {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-info-hover:hover {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-info-focus:focus {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-warning-hover:hover {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-warning-focus:focus {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-danger-hover:hover {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-danger-focus:focus {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-light-hover:hover {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-light-focus:focus {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-dark-hover:hover {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-dark-focus:focus {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-black-hover:hover {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-black-focus:focus {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-white-hover:hover {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-white-focus:focus {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-body-hover:hover {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-lg-body-focus:focus {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .rounded-lg {
    border-radius: 0.25rem !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
  .rounded-lg-1 {
    border-radius: 0.2rem !important;
  }
  .rounded-lg-2 {
    border-radius: 0.3rem !important;
  }
  .rounded-lg-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-lg-4 {
    border-radius: 1rem !important;
  }
  .rounded-lg-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-lg-6 {
    border-radius: 3rem !important;
  }
  .rounded-lg-7 {
    border-radius: 4rem !important;
  }
  .rounded-lg-8 {
    border-radius: 5rem !important;
  }
  .rounded-lg-9 {
    border-radius: 6rem !important;
  }
  .rounded-lg-10 {
    border-radius: 7rem !important;
  }
  .rounded-lg-pill {
    border-radius: 50rem !important;
  }
  .rounded-lg-circle {
    border-radius: 50% !important;
  }
  .rounded-lg-card {
    border-radius: 0.25rem !important;
  }
  .rounded-top-lg {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-lg-1 {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-lg-2 {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-lg-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-lg-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-lg-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-lg-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-lg-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-lg-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-lg-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-lg-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-lg-card {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-end-lg {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-lg-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-lg-1 {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-end-lg-2 {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-end-lg-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-lg-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-lg-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-lg-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-lg-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-lg-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-lg-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-lg-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-lg-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-lg-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-lg-card {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-lg {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-lg-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-lg-1 {
    border-bottom-right-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-lg-2 {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-lg-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-lg-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-lg-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-lg-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-lg-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-lg-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-lg-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-lg-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-lg-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-lg-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-lg-card {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-start-lg {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-lg-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-lg-1 {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-start-lg-2 {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-start-lg-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-lg-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-lg-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-lg-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-lg-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-lg-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-lg-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-lg-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-lg-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-lg-card {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-lg {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-lg-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-lg-1 {
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-top-start-lg-2 {
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-top-start-lg-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-lg-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-lg-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-lg-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-lg-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-lg-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-lg-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-lg-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-lg-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-lg-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-lg-card {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-end-lg {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-lg-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-lg-1 {
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-end-lg-2 {
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-end-lg-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-lg-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-lg-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-lg-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-lg-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-lg-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-lg-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-lg-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-lg-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-lg-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-lg-card {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-lg {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-lg-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-lg-1 {
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-bottom-end-lg-2 {
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-bottom-end-lg-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-lg-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-lg-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-lg-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-lg-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-lg-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-lg-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-lg-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-lg-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-lg-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-lg-card {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-start-lg {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-lg-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-lg-1 {
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-start-lg-2 {
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-start-lg-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-lg-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-lg-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-lg-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-lg-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-lg-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-lg-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-lg-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-lg-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-lg-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-lg-card {
    border-bottom-left-radius: 0.25rem !important;
  }
  .border-lg-solid {
    border-style: solid !important;
  }
  .border-lg-dashed {
    border-style: dashed !important;
  }
  .border-lg-dotted {
    border-style: dotted !important;
  }
  .border-lg-double {
    border-style: double !important;
  }
  .border-lg-groove {
    border-style: groove !important;
  }
  .border-lg-none {
    border-style: none !important;
  }
  .text-lg-transparent {
    color: transparent !important;
  }
  .text-lg-transparent-hover:hover {
    color: transparent !important;
  }
  .text-lg-transparent-focus:focus {
    color: transparent !important;
  }
  .text-lg-current {
    color: currentColor !important;
  }
  .text-lg-current-hover:hover {
    color: currentColor !important;
  }
  .text-lg-current-focus:focus {
    color: currentColor !important;
  }
  .text-lg-reset {
    color: inherit !important;
  }
  .text-lg-reset-hover:hover {
    color: inherit !important;
  }
  .text-lg-reset-focus:focus {
    color: inherit !important;
  }
  .shadow-soft-lg-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-1-focus:focus {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-2-focus:focus {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-lg-none {
    box-shadow: none !important;
  }
  .shadow-soft-lg-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-soft-lg-none-focus:focus {
    box-shadow: none !important;
  }
  .min-w-lg-0 {
    min-width: 0 !important;
  }
  .min-w-lg-full {
    min-width: 100% !important;
  }
  .min-w-lg-min {
    min-width: min-content !important;
  }
  .min-w-lg-max {
    min-width: max-content !important;
  }
  .min-h-lg-0 {
    min-height: 0 !important;
  }
  .min-h-lg-full {
    min-height: 100% !important;
  }
  .min-h-lg-screen {
    min-height: 100vh !important;
  }
  .place-content-lg-start {
    place-content: flex-start !important;
  }
  .place-content-lg-end {
    place-content: flex-end !important;
  }
  .place-content-lg-center {
    place-content: center !important;
  }
  .place-content-lg-between {
    place-content: space-between !important;
  }
  .place-content-lg-around {
    place-content: space-around !important;
  }
  .place-content-lg-evenly {
    place-content: space-evenly !important;
  }
  .place-content-lg-stretch {
    place-content: stretch !important;
  }
  .overflow-x-lg-auto {
    overflow-x: auto !important;
  }
  .overflow-x-lg-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-lg-visible {
    overflow-x: visible !important;
  }
  .overflow-x-lg-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-lg-auto {
    overflow-y: auto !important;
  }
  .overflow-y-lg-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-lg-visible {
    overflow-y: visible !important;
  }
  .overflow-y-lg-scroll {
    overflow-y: scroll !important;
  }
  .transition-lg {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }
  .transition-lg-none {
    transition-property: none !important;
  }
  .transition-lg-all {
    transition-property: all !important;
  }
  .transition-lg-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-lg-opacity {
    transition-property: opacity !important;
  }
  .transition-lg-shadow {
    transition-property: box-shadow !important;
  }
  .transition-lg-transform {
    transition-property: transform !important;
  }
  .ease-lg-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-lg-linear {
    transition-timing-function: linear !important;
  }
  .ease-lg-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-lg-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-lg-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .transform-lg-none {
    transform: none !important;
  }
  .origin-lg-center {
    transform-origin: center !important;
  }
  .origin-lg-top {
    transform-origin: top !important;
  }
  .origin-lg-top-right {
    transform-origin: top right !important;
  }
  .origin-lg-right {
    transform-origin: right !important;
  }
  .origin-lg-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-lg-bottom {
    transform-origin: bottom !important;
  }
  .origin-lg-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-lg-left {
    transform-origin: left !important;
  }
  .origin-lg-top-left {
    transform-origin: top left !important;
  }
  .scale-lg-0 {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-lg-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-lg-0-focus:focus {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-lg-25 {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-lg-25-hover:hover {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-lg-25-focus:focus {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-lg-50 {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-lg-50-hover:hover {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-lg-50-focus:focus {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-lg-75 {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-lg-75-hover:hover {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-lg-75-focus:focus {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-lg-90 {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-lg-90-hover:hover {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-lg-90-focus:focus {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-lg-95 {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-lg-95-hover:hover {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-lg-95-focus:focus {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-lg-100 {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-lg-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-lg-100-focus:focus {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-lg-105 {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-lg-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-lg-105-focus:focus {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-lg-110 {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-lg-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-lg-110-focus:focus {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-lg-125 {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-lg-125-hover:hover {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-lg-125-focus:focus {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-lg-150 {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-lg-150-hover:hover {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-lg-150-focus:focus {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-lg-200 {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-lg-200-hover:hover {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-lg-200-focus:focus {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-y-lg-0 {
    --bs--scale-y: 0 !important;
  }
  .scale-y-lg-0-hover:hover {
    --bs--scale-y: 0 !important;
  }
  .scale-y-lg-0-focus:focus {
    --bs--scale-y: 0 !important;
  }
  .scale-y-lg-25 {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-lg-25-hover:hover {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-lg-25-focus:focus {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-lg-50 {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-lg-50-hover:hover {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-lg-50-focus:focus {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-lg-75 {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-lg-75-hover:hover {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-lg-75-focus:focus {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-lg-90 {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-lg-90-hover:hover {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-lg-90-focus:focus {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-lg-95 {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-lg-95-hover:hover {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-lg-95-focus:focus {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-lg-100 {
    --bs--scale-y: 1 !important;
  }
  .scale-y-lg-100-hover:hover {
    --bs--scale-y: 1 !important;
  }
  .scale-y-lg-100-focus:focus {
    --bs--scale-y: 1 !important;
  }
  .scale-y-lg-105 {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-lg-105-hover:hover {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-lg-105-focus:focus {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-lg-110 {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-lg-110-hover:hover {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-lg-110-focus:focus {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-lg-125 {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-lg-125-hover:hover {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-lg-125-focus:focus {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-lg-150 {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-lg-150-hover:hover {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-lg-150-focus:focus {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-lg-200 {
    --bs--scale-y: 2 !important;
  }
  .scale-y-lg-200-hover:hover {
    --bs--scale-y: 2 !important;
  }
  .scale-y-lg-200-focus:focus {
    --bs--scale-y: 2 !important;
  }
  .scale-x-lg-0 {
    --bs--scale-x: 0 !important;
  }
  .scale-x-lg-0-hover:hover {
    --bs--scale-x: 0 !important;
  }
  .scale-x-lg-0-focus:focus {
    --bs--scale-x: 0 !important;
  }
  .scale-x-lg-25 {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-lg-25-hover:hover {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-lg-25-focus:focus {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-lg-50 {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-lg-50-hover:hover {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-lg-50-focus:focus {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-lg-75 {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-lg-75-hover:hover {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-lg-75-focus:focus {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-lg-90 {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-lg-90-hover:hover {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-lg-90-focus:focus {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-lg-95 {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-lg-95-hover:hover {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-lg-95-focus:focus {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-lg-100 {
    --bs--scale-x: 1 !important;
  }
  .scale-x-lg-100-hover:hover {
    --bs--scale-x: 1 !important;
  }
  .scale-x-lg-100-focus:focus {
    --bs--scale-x: 1 !important;
  }
  .scale-x-lg-105 {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-lg-105-hover:hover {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-lg-105-focus:focus {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-lg-110 {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-lg-110-hover:hover {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-lg-110-focus:focus {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-lg-125 {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-lg-125-hover:hover {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-lg-125-focus:focus {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-lg-150 {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-lg-150-hover:hover {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-lg-150-focus:focus {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-lg-200 {
    --bs--scale-x: 2 !important;
  }
  .scale-x-lg-200-hover:hover {
    --bs--scale-x: 2 !important;
  }
  .scale-x-lg-200-focus:focus {
    --bs--scale-x: 2 !important;
  }
  .rotate-lg-0 {
    --bs-rotate: 0deg !important;
  }
  .rotate-lg-0-hover:hover {
    --bs-rotate: 0deg !important;
  }
  .rotate-lg-0-focus:focus {
    --bs-rotate: 0deg !important;
  }
  .rotate-lg-1 {
    --bs-rotate: 1deg !important;
  }
  .rotate-lg-1-hover:hover {
    --bs-rotate: 1deg !important;
  }
  .rotate-lg-1-focus:focus {
    --bs-rotate: 1deg !important;
  }
  .rotate-lg-2 {
    --bs-rotate: 2deg !important;
  }
  .rotate-lg-2-hover:hover {
    --bs-rotate: 2deg !important;
  }
  .rotate-lg-2-focus:focus {
    --bs-rotate: 2deg !important;
  }
  .rotate-lg-3 {
    --bs-rotate: 3deg !important;
  }
  .rotate-lg-3-hover:hover {
    --bs-rotate: 3deg !important;
  }
  .rotate-lg-3-focus:focus {
    --bs-rotate: 3deg !important;
  }
  .rotate-lg-6 {
    --bs-rotate: 6deg !important;
  }
  .rotate-lg-6-hover:hover {
    --bs-rotate: 6deg !important;
  }
  .rotate-lg-6-focus:focus {
    --bs-rotate: 6deg !important;
  }
  .rotate-lg-12 {
    --bs-rotate: 12deg !important;
  }
  .rotate-lg-12-hover:hover {
    --bs-rotate: 12deg !important;
  }
  .rotate-lg-12-focus:focus {
    --bs-rotate: 12deg !important;
  }
  .rotate-lg-30 {
    --bs-rotate: 30deg !important;
  }
  .rotate-lg-30-hover:hover {
    --bs-rotate: 30deg !important;
  }
  .rotate-lg-30-focus:focus {
    --bs-rotate: 30deg !important;
  }
  .rotate-lg-45 {
    --bs-rotate: 45deg !important;
  }
  .rotate-lg-45-hover:hover {
    --bs-rotate: 45deg !important;
  }
  .rotate-lg-45-focus:focus {
    --bs-rotate: 45deg !important;
  }
  .rotate-lg-90 {
    --bs-rotate: 90deg !important;
  }
  .rotate-lg-90-hover:hover {
    --bs-rotate: 90deg !important;
  }
  .rotate-lg-90-focus:focus {
    --bs-rotate: 90deg !important;
  }
  .rotate-lg-180 {
    --bs-rotate: 180deg !important;
  }
  .rotate-lg-180-hover:hover {
    --bs-rotate: 180deg !important;
  }
  .rotate-lg-180-focus:focus {
    --bs-rotate: 180deg !important;
  }
  .rotate-lg-n1 {
    --bs-rotate: -1deg !important;
  }
  .rotate-lg-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }
  .rotate-lg-n1-focus:focus {
    --bs-rotate: -1deg !important;
  }
  .rotate-lg-n2 {
    --bs-rotate: -2deg !important;
  }
  .rotate-lg-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }
  .rotate-lg-n2-focus:focus {
    --bs-rotate: -2deg !important;
  }
  .rotate-lg-n3 {
    --bs-rotate: -3deg !important;
  }
  .rotate-lg-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }
  .rotate-lg-n3-focus:focus {
    --bs-rotate: -3deg !important;
  }
  .rotate-lg-n6 {
    --bs-rotate: -6deg !important;
  }
  .rotate-lg-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }
  .rotate-lg-n6-focus:focus {
    --bs-rotate: -6deg !important;
  }
  .rotate-lg-n12 {
    --bs-rotate: -12deg !important;
  }
  .rotate-lg-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }
  .rotate-lg-n12-focus:focus {
    --bs-rotate: -12deg !important;
  }
  .rotate-lg-n30 {
    --bs-rotate: -30deg !important;
  }
  .rotate-lg-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }
  .rotate-lg-n30-focus:focus {
    --bs-rotate: -30deg !important;
  }
  .rotate-lg-n45 {
    --bs-rotate: -45deg !important;
  }
  .rotate-lg-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }
  .rotate-lg-n45-focus:focus {
    --bs-rotate: -45deg !important;
  }
  .rotate-lg-n90 {
    --bs-rotate: -90deg !important;
  }
  .rotate-lg-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }
  .rotate-lg-n90-focus:focus {
    --bs-rotate: -90deg !important;
  }
  .rotate-lg-n180 {
    --bs-rotate: -180deg !important;
  }
  .rotate-lg-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }
  .rotate-lg-n180-focus:focus {
    --bs-rotate: -180deg !important;
  }
  .rotate-y-lg-0 {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-lg-0-hover:hover {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-lg-0-focus:focus {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-lg-1 {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-lg-1-hover:hover {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-lg-1-focus:focus {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-lg-2 {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-lg-2-hover:hover {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-lg-2-focus:focus {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-lg-3 {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-lg-3-hover:hover {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-lg-3-focus:focus {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-lg-6 {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-lg-6-hover:hover {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-lg-6-focus:focus {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-lg-12 {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-lg-12-hover:hover {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-lg-12-focus:focus {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-lg-30 {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-lg-30-hover:hover {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-lg-30-focus:focus {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-lg-45 {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-lg-45-hover:hover {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-lg-45-focus:focus {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-lg-90 {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-lg-90-hover:hover {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-lg-90-focus:focus {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-lg-180 {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-lg-180-hover:hover {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-lg-180-focus:focus {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-lg-n1 {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-lg-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-lg-n1-focus:focus {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-lg-n2 {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-lg-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-lg-n2-focus:focus {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-lg-n3 {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-lg-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-lg-n3-focus:focus {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-lg-n6 {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-lg-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-lg-n6-focus:focus {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-lg-n12 {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-lg-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-lg-n12-focus:focus {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-lg-n30 {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-lg-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-lg-n30-focus:focus {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-lg-n45 {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-lg-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-lg-n45-focus:focus {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-lg-n90 {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-lg-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-lg-n90-focus:focus {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-lg-n180 {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-lg-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-lg-n180-focus:focus {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-x-lg-0 {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-lg-0-hover:hover {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-lg-0-focus:focus {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-lg-1 {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-lg-1-hover:hover {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-lg-1-focus:focus {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-lg-2 {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-lg-2-hover:hover {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-lg-2-focus:focus {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-lg-3 {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-lg-3-hover:hover {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-lg-3-focus:focus {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-lg-6 {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-lg-6-hover:hover {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-lg-6-focus:focus {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-lg-12 {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-lg-12-hover:hover {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-lg-12-focus:focus {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-lg-30 {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-lg-30-hover:hover {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-lg-30-focus:focus {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-lg-45 {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-lg-45-hover:hover {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-lg-45-focus:focus {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-lg-90 {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-lg-90-hover:hover {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-lg-90-focus:focus {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-lg-180 {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-lg-180-hover:hover {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-lg-180-focus:focus {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-lg-n1 {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-lg-n1-hover:hover {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-lg-n1-focus:focus {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-lg-n2 {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-lg-n2-hover:hover {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-lg-n2-focus:focus {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-lg-n3 {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-lg-n3-hover:hover {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-lg-n3-focus:focus {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-lg-n6 {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-lg-n6-hover:hover {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-lg-n6-focus:focus {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-lg-n12 {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-lg-n12-hover:hover {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-lg-n12-focus:focus {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-lg-n30 {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-lg-n30-hover:hover {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-lg-n30-focus:focus {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-lg-n45 {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-lg-n45-hover:hover {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-lg-n45-focus:focus {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-lg-n90 {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-lg-n90-hover:hover {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-lg-n90-focus:focus {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-lg-n180 {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-lg-n180-hover:hover {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-lg-n180-focus:focus {
    --bs-rotate-x: -180deg !important;
  }
  .perspective-lg-100 {
    --bs-perspective: 100px !important;
  }
  .perspective-lg-100-hover:hover {
    --bs-perspective: 100px !important;
  }
  .perspective-lg-100-focus:focus {
    --bs-perspective: 100px !important;
  }
  .perspective-lg-200 {
    --bs-perspective: 200px !important;
  }
  .perspective-lg-200-hover:hover {
    --bs-perspective: 200px !important;
  }
  .perspective-lg-200-focus:focus {
    --bs-perspective: 200px !important;
  }
  .perspective-lg-300 {
    --bs-perspective: 300px !important;
  }
  .perspective-lg-300-hover:hover {
    --bs-perspective: 300px !important;
  }
  .perspective-lg-300-focus:focus {
    --bs-perspective: 300px !important;
  }
  .perspective-lg-400 {
    --bs-perspective: 400px !important;
  }
  .perspective-lg-400-hover:hover {
    --bs-perspective: 400px !important;
  }
  .perspective-lg-400-focus:focus {
    --bs-perspective: 400px !important;
  }
  .perspective-lg-500 {
    --bs-perspective: 500px !important;
  }
  .perspective-lg-500-hover:hover {
    --bs-perspective: 500px !important;
  }
  .perspective-lg-500-focus:focus {
    --bs-perspective: 500px !important;
  }
  .perspective-lg-600 {
    --bs-perspective: 600px !important;
  }
  .perspective-lg-600-hover:hover {
    --bs-perspective: 600px !important;
  }
  .perspective-lg-600-focus:focus {
    --bs-perspective: 600px !important;
  }
  .perspective-lg-700 {
    --bs-perspective: 700px !important;
  }
  .perspective-lg-700-hover:hover {
    --bs-perspective: 700px !important;
  }
  .perspective-lg-700-focus:focus {
    --bs-perspective: 700px !important;
  }
  .perspective-lg-800 {
    --bs-perspective: 800px !important;
  }
  .perspective-lg-800-hover:hover {
    --bs-perspective: 800px !important;
  }
  .perspective-lg-800-focus:focus {
    --bs-perspective: 800px !important;
  }
  .perspective-lg-1000 {
    --bs-perspective: 1000px !important;
  }
  .perspective-lg-1000-hover:hover {
    --bs-perspective: 1000px !important;
  }
  .perspective-lg-1000-focus:focus {
    --bs-perspective: 1000px !important;
  }
  .perspective-lg-1250 {
    --bs-perspective: 1250px !important;
  }
  .perspective-lg-1250-hover:hover {
    --bs-perspective: 1250px !important;
  }
  .perspective-lg-1250-focus:focus {
    --bs-perspective: 1250px !important;
  }
  .perspective-lg-1500 {
    --bs-perspective: 1500px !important;
  }
  .perspective-lg-1500-hover:hover {
    --bs-perspective: 1500px !important;
  }
  .perspective-lg-1500-focus:focus {
    --bs-perspective: 1500px !important;
  }
  .translate-y-lg-0 {
    --bs-translate-y: 0 !important;
  }
  .translate-y-lg-0-hover:hover {
    --bs-translate-y: 0 !important;
  }
  .translate-y-lg-0-focus:focus {
    --bs-translate-y: 0 !important;
  }
  .translate-y-lg-1 {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-lg-1-hover:hover {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-lg-1-focus:focus {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-lg-2 {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-lg-2-hover:hover {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-lg-2-focus:focus {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-lg-3 {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-lg-3-hover:hover {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-lg-3-focus:focus {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-lg-4 {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-lg-4-hover:hover {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-lg-4-focus:focus {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-lg-5 {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-lg-5-hover:hover {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-lg-5-focus:focus {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-lg-6 {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-lg-6-hover:hover {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-lg-6-focus:focus {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-lg-7 {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-lg-7-hover:hover {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-lg-7-focus:focus {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-lg-8 {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-lg-8-hover:hover {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-lg-8-focus:focus {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-lg-10 {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-lg-10-hover:hover {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-lg-10-focus:focus {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-lg-12 {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-lg-12-hover:hover {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-lg-12-focus:focus {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-lg-14 {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-lg-14-hover:hover {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-lg-14-focus:focus {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-lg-16 {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-lg-16-hover:hover {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-lg-16-focus:focus {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-lg-18 {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-lg-18-hover:hover {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-lg-18-focus:focus {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-lg-20 {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-lg-20-hover:hover {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-lg-20-focus:focus {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-lg-24 {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-lg-24-hover:hover {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-lg-24-focus:focus {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-lg-32 {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-lg-32-hover:hover {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-lg-32-focus:focus {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-lg-40 {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-lg-40-hover:hover {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-lg-40-focus:focus {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-lg-48 {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-lg-48-hover:hover {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-lg-48-focus:focus {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-lg-56 {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-lg-56-hover:hover {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-lg-56-focus:focus {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-lg-64 {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-lg-64-hover:hover {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-lg-64-focus:focus {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-lg-72 {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-lg-72-hover:hover {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-lg-72-focus:focus {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-lg-80 {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-lg-80-hover:hover {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-lg-80-focus:focus {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-lg-88 {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-lg-88-hover:hover {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-lg-88-focus:focus {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-lg-96 {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-lg-96-hover:hover {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-lg-96-focus:focus {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-lg-px {
    --bs-translate-y: 1px !important;
  }
  .translate-y-lg-px-hover:hover {
    --bs-translate-y: 1px !important;
  }
  .translate-y-lg-px-focus:focus {
    --bs-translate-y: 1px !important;
  }
  .translate-y-lg-1\/2 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-1\/2-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-1\/2-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-1\/3 {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-lg-1\/3-hover:hover {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-lg-1\/3-focus:focus {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-lg-1\/4 {
    --bs-translate-y: 25% !important;
  }
  .translate-y-lg-1\/4-hover:hover {
    --bs-translate-y: 25% !important;
  }
  .translate-y-lg-1\/4-focus:focus {
    --bs-translate-y: 25% !important;
  }
  .translate-y-lg-1\/5 {
    --bs-translate-y: 20% !important;
  }
  .translate-y-lg-1\/5-hover:hover {
    --bs-translate-y: 20% !important;
  }
  .translate-y-lg-1\/5-focus:focus {
    --bs-translate-y: 20% !important;
  }
  .translate-y-lg-2\/3 {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-lg-2\/3-hover:hover {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-lg-2\/3-focus:focus {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-lg-2\/5 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-2\/5-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-2\/5-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-lg-3\/4 {
    --bs-translate-y: 75% !important;
  }
  .translate-y-lg-3\/4-hover:hover {
    --bs-translate-y: 75% !important;
  }
  .translate-y-lg-3\/4-focus:focus {
    --bs-translate-y: 75% !important;
  }
  .translate-y-lg-full {
    --bs-translate-y: 100% !important;
  }
  .translate-y-lg-full-hover:hover {
    --bs-translate-y: 100% !important;
  }
  .translate-y-lg-full-focus:focus {
    --bs-translate-y: 100% !important;
  }
  .translate-y-lg-n1 {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-lg-n1-hover:hover {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-lg-n1-focus:focus {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-lg-n2 {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-lg-n2-hover:hover {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-lg-n2-focus:focus {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-lg-n3 {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-lg-n3-hover:hover {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-lg-n3-focus:focus {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-lg-n4 {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-lg-n4-hover:hover {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-lg-n4-focus:focus {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-lg-n5 {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-lg-n5-hover:hover {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-lg-n5-focus:focus {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-lg-n6 {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-lg-n6-hover:hover {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-lg-n6-focus:focus {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-lg-n7 {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-lg-n7-hover:hover {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-lg-n7-focus:focus {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-lg-n8 {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-lg-n8-hover:hover {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-lg-n8-focus:focus {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-lg-n10 {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-lg-n10-hover:hover {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-lg-n10-focus:focus {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-lg-n12 {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-lg-n12-hover:hover {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-lg-n12-focus:focus {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-lg-n14 {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-lg-n14-hover:hover {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-lg-n14-focus:focus {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-lg-n16 {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-lg-n16-hover:hover {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-lg-n16-focus:focus {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-lg-n18 {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-lg-n18-hover:hover {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-lg-n18-focus:focus {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-lg-n20 {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-lg-n20-hover:hover {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-lg-n20-focus:focus {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-lg-n24 {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-lg-n24-hover:hover {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-lg-n24-focus:focus {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-lg-n32 {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-lg-n32-hover:hover {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-lg-n32-focus:focus {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-lg-n40 {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-lg-n40-hover:hover {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-lg-n40-focus:focus {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-lg-n48 {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-lg-n48-hover:hover {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-lg-n48-focus:focus {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-lg-n56 {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-lg-n56-hover:hover {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-lg-n56-focus:focus {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-lg-n64 {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-lg-n64-hover:hover {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-lg-n64-focus:focus {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-lg-n72 {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-lg-n72-hover:hover {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-lg-n72-focus:focus {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-lg-n80 {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-lg-n80-hover:hover {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-lg-n80-focus:focus {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-lg-n88 {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-lg-n88-hover:hover {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-lg-n88-focus:focus {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-lg-n96 {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-lg-n96-hover:hover {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-lg-n96-focus:focus {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-lg-npx {
    --bs-translate-y: -1px !important;
  }
  .translate-y-lg-npx-hover:hover {
    --bs-translate-y: -1px !important;
  }
  .translate-y-lg-npx-focus:focus {
    --bs-translate-y: -1px !important;
  }
  .translate-y-lg-n1\/2 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n1\/2-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n1\/2-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n1\/3 {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-lg-n1\/3-hover:hover {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-lg-n1\/3-focus:focus {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-lg-n1\/4 {
    --bs-translate-y: -25% !important;
  }
  .translate-y-lg-n1\/4-hover:hover {
    --bs-translate-y: -25% !important;
  }
  .translate-y-lg-n1\/4-focus:focus {
    --bs-translate-y: -25% !important;
  }
  .translate-y-lg-n1\/5 {
    --bs-translate-y: -20% !important;
  }
  .translate-y-lg-n1\/5-hover:hover {
    --bs-translate-y: -20% !important;
  }
  .translate-y-lg-n1\/5-focus:focus {
    --bs-translate-y: -20% !important;
  }
  .translate-y-lg-n2\/3 {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-lg-n2\/3-hover:hover {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-lg-n2\/3-focus:focus {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-lg-n2\/5 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n2\/5-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n2\/5-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-lg-n3\/4 {
    --bs-translate-y: -75% !important;
  }
  .translate-y-lg-n3\/4-hover:hover {
    --bs-translate-y: -75% !important;
  }
  .translate-y-lg-n3\/4-focus:focus {
    --bs-translate-y: -75% !important;
  }
  .translate-y-lg-nfull {
    --bs-translate-y: -100% !important;
  }
  .translate-y-lg-nfull-hover:hover {
    --bs-translate-y: -100% !important;
  }
  .translate-y-lg-nfull-focus:focus {
    --bs-translate-y: -100% !important;
  }
  .translate-x-lg-0 {
    --bs-translate-x: 0 !important;
  }
  .translate-x-lg-0-hover:hover {
    --bs-translate-x: 0 !important;
  }
  .translate-x-lg-0-focus:focus {
    --bs-translate-x: 0 !important;
  }
  .translate-x-lg-1 {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-lg-1-hover:hover {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-lg-1-focus:focus {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-lg-2 {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-lg-2-hover:hover {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-lg-2-focus:focus {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-lg-3 {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-lg-3-hover:hover {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-lg-3-focus:focus {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-lg-4 {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-lg-4-hover:hover {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-lg-4-focus:focus {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-lg-5 {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-lg-5-hover:hover {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-lg-5-focus:focus {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-lg-6 {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-lg-6-hover:hover {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-lg-6-focus:focus {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-lg-7 {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-lg-7-hover:hover {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-lg-7-focus:focus {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-lg-8 {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-lg-8-hover:hover {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-lg-8-focus:focus {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-lg-10 {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-lg-10-hover:hover {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-lg-10-focus:focus {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-lg-12 {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-lg-12-hover:hover {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-lg-12-focus:focus {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-lg-14 {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-lg-14-hover:hover {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-lg-14-focus:focus {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-lg-16 {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-lg-16-hover:hover {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-lg-16-focus:focus {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-lg-18 {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-lg-18-hover:hover {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-lg-18-focus:focus {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-lg-20 {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-lg-20-hover:hover {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-lg-20-focus:focus {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-lg-24 {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-lg-24-hover:hover {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-lg-24-focus:focus {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-lg-32 {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-lg-32-hover:hover {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-lg-32-focus:focus {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-lg-40 {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-lg-40-hover:hover {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-lg-40-focus:focus {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-lg-48 {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-lg-48-hover:hover {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-lg-48-focus:focus {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-lg-56 {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-lg-56-hover:hover {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-lg-56-focus:focus {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-lg-64 {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-lg-64-hover:hover {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-lg-64-focus:focus {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-lg-72 {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-lg-72-hover:hover {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-lg-72-focus:focus {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-lg-80 {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-lg-80-hover:hover {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-lg-80-focus:focus {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-lg-88 {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-lg-88-hover:hover {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-lg-88-focus:focus {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-lg-96 {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-lg-96-hover:hover {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-lg-96-focus:focus {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-lg-px {
    --bs-translate-x: 1px !important;
  }
  .translate-x-lg-px-hover:hover {
    --bs-translate-x: 1px !important;
  }
  .translate-x-lg-px-focus:focus {
    --bs-translate-x: 1px !important;
  }
  .translate-x-lg-1\/2 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-1\/2-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-1\/2-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-1\/3 {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-lg-1\/3-hover:hover {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-lg-1\/3-focus:focus {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-lg-1\/4 {
    --bs-translate-x: 25% !important;
  }
  .translate-x-lg-1\/4-hover:hover {
    --bs-translate-x: 25% !important;
  }
  .translate-x-lg-1\/4-focus:focus {
    --bs-translate-x: 25% !important;
  }
  .translate-x-lg-1\/5 {
    --bs-translate-x: 20% !important;
  }
  .translate-x-lg-1\/5-hover:hover {
    --bs-translate-x: 20% !important;
  }
  .translate-x-lg-1\/5-focus:focus {
    --bs-translate-x: 20% !important;
  }
  .translate-x-lg-2\/3 {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-lg-2\/3-hover:hover {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-lg-2\/3-focus:focus {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-lg-2\/5 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-2\/5-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-2\/5-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-lg-3\/4 {
    --bs-translate-x: 75% !important;
  }
  .translate-x-lg-3\/4-hover:hover {
    --bs-translate-x: 75% !important;
  }
  .translate-x-lg-3\/4-focus:focus {
    --bs-translate-x: 75% !important;
  }
  .translate-x-lg-full {
    --bs-translate-x: 100% !important;
  }
  .translate-x-lg-full-hover:hover {
    --bs-translate-x: 100% !important;
  }
  .translate-x-lg-full-focus:focus {
    --bs-translate-x: 100% !important;
  }
  .translate-x-lg-n1 {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-lg-n1-hover:hover {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-lg-n1-focus:focus {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-lg-n2 {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-lg-n2-hover:hover {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-lg-n2-focus:focus {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-lg-n3 {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-lg-n3-hover:hover {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-lg-n3-focus:focus {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-lg-n4 {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-lg-n4-hover:hover {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-lg-n4-focus:focus {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-lg-n5 {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-lg-n5-hover:hover {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-lg-n5-focus:focus {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-lg-n6 {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-lg-n6-hover:hover {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-lg-n6-focus:focus {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-lg-n7 {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-lg-n7-hover:hover {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-lg-n7-focus:focus {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-lg-n8 {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-lg-n8-hover:hover {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-lg-n8-focus:focus {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-lg-n10 {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-lg-n10-hover:hover {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-lg-n10-focus:focus {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-lg-n12 {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-lg-n12-hover:hover {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-lg-n12-focus:focus {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-lg-n14 {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-lg-n14-hover:hover {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-lg-n14-focus:focus {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-lg-n16 {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-lg-n16-hover:hover {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-lg-n16-focus:focus {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-lg-n18 {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-lg-n18-hover:hover {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-lg-n18-focus:focus {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-lg-n20 {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-lg-n20-hover:hover {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-lg-n20-focus:focus {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-lg-n24 {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-lg-n24-hover:hover {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-lg-n24-focus:focus {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-lg-n32 {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-lg-n32-hover:hover {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-lg-n32-focus:focus {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-lg-n40 {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-lg-n40-hover:hover {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-lg-n40-focus:focus {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-lg-n48 {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-lg-n48-hover:hover {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-lg-n48-focus:focus {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-lg-n56 {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-lg-n56-hover:hover {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-lg-n56-focus:focus {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-lg-n64 {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-lg-n64-hover:hover {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-lg-n64-focus:focus {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-lg-n72 {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-lg-n72-hover:hover {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-lg-n72-focus:focus {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-lg-n80 {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-lg-n80-hover:hover {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-lg-n80-focus:focus {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-lg-n88 {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-lg-n88-hover:hover {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-lg-n88-focus:focus {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-lg-n96 {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-lg-n96-hover:hover {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-lg-n96-focus:focus {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-lg-npx {
    --bs-translate-x: -1px !important;
  }
  .translate-x-lg-npx-hover:hover {
    --bs-translate-x: -1px !important;
  }
  .translate-x-lg-npx-focus:focus {
    --bs-translate-x: -1px !important;
  }
  .translate-x-lg-n1\/2 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n1\/2-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n1\/2-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n1\/3 {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-lg-n1\/3-hover:hover {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-lg-n1\/3-focus:focus {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-lg-n1\/4 {
    --bs-translate-x: -25% !important;
  }
  .translate-x-lg-n1\/4-hover:hover {
    --bs-translate-x: -25% !important;
  }
  .translate-x-lg-n1\/4-focus:focus {
    --bs-translate-x: -25% !important;
  }
  .translate-x-lg-n1\/5 {
    --bs-translate-x: -20% !important;
  }
  .translate-x-lg-n1\/5-hover:hover {
    --bs-translate-x: -20% !important;
  }
  .translate-x-lg-n1\/5-focus:focus {
    --bs-translate-x: -20% !important;
  }
  .translate-x-lg-n2\/3 {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-lg-n2\/3-hover:hover {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-lg-n2\/3-focus:focus {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-lg-n2\/5 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n2\/5-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n2\/5-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-lg-n3\/4 {
    --bs-translate-x: -75% !important;
  }
  .translate-x-lg-n3\/4-hover:hover {
    --bs-translate-x: -75% !important;
  }
  .translate-x-lg-n3\/4-focus:focus {
    --bs-translate-x: -75% !important;
  }
  .translate-x-lg-nfull {
    --bs-translate-x: -100% !important;
  }
  .translate-x-lg-nfull-hover:hover {
    --bs-translate-x: -100% !important;
  }
  .translate-x-lg-nfull-focus:focus {
    --bs-translate-x: -100% !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .overflow-xl-auto {
    overflow: auto !important;
  }
  .overflow-xl-hidden {
    overflow: hidden !important;
  }
  .overflow-xl-visible {
    overflow: visible !important;
  }
  .overflow-xl-scroll {
    overflow: scroll !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .shadow-xl {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xl-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xl-focus:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xl-1 {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-1-hover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-1-focus:focus {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-2 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xl-2-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xl-2-focus:focus {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xl-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xl-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-inset-focus:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-outline-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-focus-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xl-none {
    box-shadow: none !important;
  }
  .shadow-xl-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-xl-none-focus:focus {
    box-shadow: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-1 {
    top: 0.25rem !important;
  }
  .top-xl-2 {
    top: 0.5rem !important;
  }
  .top-xl-3 {
    top: 0.75rem !important;
  }
  .top-xl-4 {
    top: 1rem !important;
  }
  .top-xl-5 {
    top: 1.25rem !important;
  }
  .top-xl-6 {
    top: 1.5rem !important;
  }
  .top-xl-7 {
    top: 1.75rem !important;
  }
  .top-xl-8 {
    top: 2rem !important;
  }
  .top-xl-10 {
    top: 2.5rem !important;
  }
  .top-xl-12 {
    top: 3rem !important;
  }
  .top-xl-14 {
    top: 3.5rem !important;
  }
  .top-xl-16 {
    top: 4rem !important;
  }
  .top-xl-18 {
    top: 4.5rem !important;
  }
  .top-xl-20 {
    top: 5rem !important;
  }
  .top-xl-24 {
    top: 6rem !important;
  }
  .top-xl-32 {
    top: 8rem !important;
  }
  .top-xl-40 {
    top: 10rem !important;
  }
  .top-xl-48 {
    top: 12rem !important;
  }
  .top-xl-56 {
    top: 14rem !important;
  }
  .top-xl-64 {
    top: 16rem !important;
  }
  .top-xl-72 {
    top: 18rem !important;
  }
  .top-xl-80 {
    top: 20rem !important;
  }
  .top-xl-88 {
    top: 22rem !important;
  }
  .top-xl-96 {
    top: 24rem !important;
  }
  .top-xl-px {
    top: 1px !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-1 {
    bottom: 0.25rem !important;
  }
  .bottom-xl-2 {
    bottom: 0.5rem !important;
  }
  .bottom-xl-3 {
    bottom: 0.75rem !important;
  }
  .bottom-xl-4 {
    bottom: 1rem !important;
  }
  .bottom-xl-5 {
    bottom: 1.25rem !important;
  }
  .bottom-xl-6 {
    bottom: 1.5rem !important;
  }
  .bottom-xl-7 {
    bottom: 1.75rem !important;
  }
  .bottom-xl-8 {
    bottom: 2rem !important;
  }
  .bottom-xl-10 {
    bottom: 2.5rem !important;
  }
  .bottom-xl-12 {
    bottom: 3rem !important;
  }
  .bottom-xl-14 {
    bottom: 3.5rem !important;
  }
  .bottom-xl-16 {
    bottom: 4rem !important;
  }
  .bottom-xl-18 {
    bottom: 4.5rem !important;
  }
  .bottom-xl-20 {
    bottom: 5rem !important;
  }
  .bottom-xl-24 {
    bottom: 6rem !important;
  }
  .bottom-xl-32 {
    bottom: 8rem !important;
  }
  .bottom-xl-40 {
    bottom: 10rem !important;
  }
  .bottom-xl-48 {
    bottom: 12rem !important;
  }
  .bottom-xl-56 {
    bottom: 14rem !important;
  }
  .bottom-xl-64 {
    bottom: 16rem !important;
  }
  .bottom-xl-72 {
    bottom: 18rem !important;
  }
  .bottom-xl-80 {
    bottom: 20rem !important;
  }
  .bottom-xl-88 {
    bottom: 22rem !important;
  }
  .bottom-xl-96 {
    bottom: 24rem !important;
  }
  .bottom-xl-px {
    bottom: 1px !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .start-xl-0 {
    left: 0 !important;
  }
  .start-xl-1 {
    left: 0.25rem !important;
  }
  .start-xl-2 {
    left: 0.5rem !important;
  }
  .start-xl-3 {
    left: 0.75rem !important;
  }
  .start-xl-4 {
    left: 1rem !important;
  }
  .start-xl-5 {
    left: 1.25rem !important;
  }
  .start-xl-6 {
    left: 1.5rem !important;
  }
  .start-xl-7 {
    left: 1.75rem !important;
  }
  .start-xl-8 {
    left: 2rem !important;
  }
  .start-xl-10 {
    left: 2.5rem !important;
  }
  .start-xl-12 {
    left: 3rem !important;
  }
  .start-xl-14 {
    left: 3.5rem !important;
  }
  .start-xl-16 {
    left: 4rem !important;
  }
  .start-xl-18 {
    left: 4.5rem !important;
  }
  .start-xl-20 {
    left: 5rem !important;
  }
  .start-xl-24 {
    left: 6rem !important;
  }
  .start-xl-32 {
    left: 8rem !important;
  }
  .start-xl-40 {
    left: 10rem !important;
  }
  .start-xl-48 {
    left: 12rem !important;
  }
  .start-xl-56 {
    left: 14rem !important;
  }
  .start-xl-64 {
    left: 16rem !important;
  }
  .start-xl-72 {
    left: 18rem !important;
  }
  .start-xl-80 {
    left: 20rem !important;
  }
  .start-xl-88 {
    left: 22rem !important;
  }
  .start-xl-96 {
    left: 24rem !important;
  }
  .start-xl-px {
    left: 1px !important;
  }
  .start-xl-50 {
    left: 50% !important;
  }
  .start-xl-100 {
    left: 100% !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-1 {
    right: 0.25rem !important;
  }
  .end-xl-2 {
    right: 0.5rem !important;
  }
  .end-xl-3 {
    right: 0.75rem !important;
  }
  .end-xl-4 {
    right: 1rem !important;
  }
  .end-xl-5 {
    right: 1.25rem !important;
  }
  .end-xl-6 {
    right: 1.5rem !important;
  }
  .end-xl-7 {
    right: 1.75rem !important;
  }
  .end-xl-8 {
    right: 2rem !important;
  }
  .end-xl-10 {
    right: 2.5rem !important;
  }
  .end-xl-12 {
    right: 3rem !important;
  }
  .end-xl-14 {
    right: 3.5rem !important;
  }
  .end-xl-16 {
    right: 4rem !important;
  }
  .end-xl-18 {
    right: 4.5rem !important;
  }
  .end-xl-20 {
    right: 5rem !important;
  }
  .end-xl-24 {
    right: 6rem !important;
  }
  .end-xl-32 {
    right: 8rem !important;
  }
  .end-xl-40 {
    right: 10rem !important;
  }
  .end-xl-48 {
    right: 12rem !important;
  }
  .end-xl-56 {
    right: 14rem !important;
  }
  .end-xl-64 {
    right: 16rem !important;
  }
  .end-xl-72 {
    right: 18rem !important;
  }
  .end-xl-80 {
    right: 20rem !important;
  }
  .end-xl-88 {
    right: 22rem !important;
  }
  .end-xl-96 {
    right: 24rem !important;
  }
  .end-xl-px {
    right: 1px !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .border-top-xl-0 {
    border-top-width: 0 !important;
  }
  .border-top-xl-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-xl-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top-xl {
    border-top-width: 1px !important;
  }
  .border-top-xl-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-xl-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-xl-2 {
    border-top-width: 2px !important;
  }
  .border-top-xl-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-xl-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-xl-3 {
    border-top-width: 3px !important;
  }
  .border-top-xl-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-xl-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-xl-4 {
    border-top-width: 4px !important;
  }
  .border-top-xl-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-xl-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-xl-5 {
    border-top-width: 5px !important;
  }
  .border-top-xl-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-xl-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-xl-6 {
    border-top-width: 6px !important;
  }
  .border-top-xl-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-xl-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-xl-7 {
    border-top-width: 7px !important;
  }
  .border-top-xl-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-xl-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-xl-8 {
    border-top-width: 8px !important;
  }
  .border-top-xl-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-xl-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-xl-0 {
    border-right-width: 0 !important;
  }
  .border-end-xl-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-xl-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end-xl {
    border-right-width: 1px !important;
  }
  .border-end-xl-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-xl-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-xl-2 {
    border-right-width: 2px !important;
  }
  .border-end-xl-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-xl-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-xl-3 {
    border-right-width: 3px !important;
  }
  .border-end-xl-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-xl-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-xl-4 {
    border-right-width: 4px !important;
  }
  .border-end-xl-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-xl-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-xl-5 {
    border-right-width: 5px !important;
  }
  .border-end-xl-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-xl-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-xl-6 {
    border-right-width: 6px !important;
  }
  .border-end-xl-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-xl-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-xl-7 {
    border-right-width: 7px !important;
  }
  .border-end-xl-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-xl-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-xl-8 {
    border-right-width: 8px !important;
  }
  .border-end-xl-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-xl-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-xl-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xl-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xl-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xl {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xl-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xl-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xl-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xl-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xl-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xl-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xl-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xl-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xl-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xl-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xl-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xl-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xl-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xl-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xl-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xl-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xl-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xl-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xl-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xl-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xl-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-xl-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-xl-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-xl-0 {
    border-left-width: 0 !important;
  }
  .border-start-xl-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-xl-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start-xl {
    border-left-width: 1px !important;
  }
  .border-start-xl-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-xl-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-xl-2 {
    border-left-width: 2px !important;
  }
  .border-start-xl-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-xl-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-xl-3 {
    border-left-width: 3px !important;
  }
  .border-start-xl-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-xl-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-xl-4 {
    border-left-width: 4px !important;
  }
  .border-start-xl-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-xl-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-xl-5 {
    border-left-width: 5px !important;
  }
  .border-start-xl-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-xl-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-xl-6 {
    border-left-width: 6px !important;
  }
  .border-start-xl-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-xl-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-xl-7 {
    border-left-width: 7px !important;
  }
  .border-start-xl-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-xl-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-xl-8 {
    border-left-width: 8px !important;
  }
  .border-start-xl-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-xl-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-xl-primary {
    border-color: #1A16C0 !important;
  }
  .border-xl-primary-hover:hover {
    border-color: #1A16C0 !important;
  }
  .border-xl-primary-focus:focus {
    border-color: #1A16C0 !important;
  }
  .border-xl-primary-focus-within:focus-within {
    border-color: #1A16C0 !important;
  }
  .border-xl-secondary {
    border-color: #E4ECF7 !important;
  }
  .border-xl-secondary-hover:hover {
    border-color: #E4ECF7 !important;
  }
  .border-xl-secondary-focus:focus {
    border-color: #E4ECF7 !important;
  }
  .border-xl-secondary-focus-within:focus-within {
    border-color: #E4ECF7 !important;
  }
  .border-xl-success {
    border-color: #4AAE8C !important;
  }
  .border-xl-success-hover:hover {
    border-color: #4AAE8C !important;
  }
  .border-xl-success-focus:focus {
    border-color: #4AAE8C !important;
  }
  .border-xl-success-focus-within:focus-within {
    border-color: #4AAE8C !important;
  }
  .border-xl-info {
    border-color: #68B8F2 !important;
  }
  .border-xl-info-hover:hover {
    border-color: #68B8F2 !important;
  }
  .border-xl-info-focus:focus {
    border-color: #68B8F2 !important;
  }
  .border-xl-info-focus-within:focus-within {
    border-color: #68B8F2 !important;
  }
  .border-xl-warning {
    border-color: #ED9830 !important;
  }
  .border-xl-warning-hover:hover {
    border-color: #ED9830 !important;
  }
  .border-xl-warning-focus:focus {
    border-color: #ED9830 !important;
  }
  .border-xl-warning-focus-within:focus-within {
    border-color: #ED9830 !important;
  }
  .border-xl-danger {
    border-color: #F16063 !important;
  }
  .border-xl-danger-hover:hover {
    border-color: #F16063 !important;
  }
  .border-xl-danger-focus:focus {
    border-color: #F16063 !important;
  }
  .border-xl-danger-focus-within:focus-within {
    border-color: #F16063 !important;
  }
  .border-xl-light {
    border-color: #F7FAFC !important;
  }
  .border-xl-light-hover:hover {
    border-color: #F7FAFC !important;
  }
  .border-xl-light-focus:focus {
    border-color: #F7FAFC !important;
  }
  .border-xl-light-focus-within:focus-within {
    border-color: #F7FAFC !important;
  }
  .border-xl-dark {
    border-color: #27272E !important;
  }
  .border-xl-dark-hover:hover {
    border-color: #27272E !important;
  }
  .border-xl-dark-focus:focus {
    border-color: #27272E !important;
  }
  .border-xl-dark-focus-within:focus-within {
    border-color: #27272E !important;
  }
  .border-xl-transparent {
    border-color: transparent !important;
  }
  .border-xl-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-xl-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-xl-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-xl-current {
    border-color: currentColor !important;
  }
  .border-xl-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-xl-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-xl-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-xl-base {
    border-color: #E2E8F0 !important;
  }
  .border-xl-base-hover:hover {
    border-color: #E2E8F0 !important;
  }
  .border-xl-base-focus:focus {
    border-color: #E2E8F0 !important;
  }
  .border-xl-base-focus-within:focus-within {
    border-color: #E2E8F0 !important;
  }
  .border-xl-body {
    border-color: #fff !important;
  }
  .border-xl-body-hover:hover {
    border-color: #fff !important;
  }
  .border-xl-body-focus:focus {
    border-color: #fff !important;
  }
  .border-xl-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xl-card {
    border-color: #fff !important;
  }
  .border-xl-card-hover:hover {
    border-color: #fff !important;
  }
  .border-xl-card-focus:focus {
    border-color: #fff !important;
  }
  .border-xl-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xl-black {
    border-color: #000 !important;
  }
  .border-xl-black-hover:hover {
    border-color: #000 !important;
  }
  .border-xl-black-focus:focus {
    border-color: #000 !important;
  }
  .border-xl-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-xl-white {
    border-color: #fff !important;
  }
  .border-xl-white-hover:hover {
    border-color: #fff !important;
  }
  .border-xl-white-focus:focus {
    border-color: #fff !important;
  }
  .border-xl-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xl-0 {
    border-width: 0 !important;
  }
  .border-xl-0-hover:hover {
    border-width: 0 !important;
  }
  .border-xl-0-focus:focus {
    border-width: 0 !important;
  }
  .border-xl {
    border-width: 1px !important;
  }
  .border-xl-hover:hover {
    border-width: 1px !important;
  }
  .border-xl-focus:focus {
    border-width: 1px !important;
  }
  .border-xl-2 {
    border-width: 2px !important;
  }
  .border-xl-2-hover:hover {
    border-width: 2px !important;
  }
  .border-xl-2-focus:focus {
    border-width: 2px !important;
  }
  .border-xl-3 {
    border-width: 3px !important;
  }
  .border-xl-3-hover:hover {
    border-width: 3px !important;
  }
  .border-xl-3-focus:focus {
    border-width: 3px !important;
  }
  .border-xl-4 {
    border-width: 4px !important;
  }
  .border-xl-4-hover:hover {
    border-width: 4px !important;
  }
  .border-xl-4-focus:focus {
    border-width: 4px !important;
  }
  .border-xl-5 {
    border-width: 5px !important;
  }
  .border-xl-5-hover:hover {
    border-width: 5px !important;
  }
  .border-xl-5-focus:focus {
    border-width: 5px !important;
  }
  .border-xl-6 {
    border-width: 6px !important;
  }
  .border-xl-6-hover:hover {
    border-width: 6px !important;
  }
  .border-xl-6-focus:focus {
    border-width: 6px !important;
  }
  .border-xl-7 {
    border-width: 7px !important;
  }
  .border-xl-7-hover:hover {
    border-width: 7px !important;
  }
  .border-xl-7-focus:focus {
    border-width: 7px !important;
  }
  .border-xl-8 {
    border-width: 8px !important;
  }
  .border-xl-8-hover:hover {
    border-width: 8px !important;
  }
  .border-xl-8-focus:focus {
    border-width: 8px !important;
  }
  .w-xl-0 {
    width: 0 !important;
  }
  .w-xl-1 {
    width: 0.25rem !important;
  }
  .w-xl-2 {
    width: 0.5rem !important;
  }
  .w-xl-3 {
    width: 0.75rem !important;
  }
  .w-xl-4 {
    width: 1rem !important;
  }
  .w-xl-5 {
    width: 1.25rem !important;
  }
  .w-xl-6 {
    width: 1.5rem !important;
  }
  .w-xl-7 {
    width: 1.75rem !important;
  }
  .w-xl-8 {
    width: 2rem !important;
  }
  .w-xl-10 {
    width: 2.5rem !important;
  }
  .w-xl-12 {
    width: 3rem !important;
  }
  .w-xl-14 {
    width: 3.5rem !important;
  }
  .w-xl-16 {
    width: 4rem !important;
  }
  .w-xl-18 {
    width: 4.5rem !important;
  }
  .w-xl-20 {
    width: 5rem !important;
  }
  .w-xl-24 {
    width: 6rem !important;
  }
  .w-xl-32 {
    width: 8rem !important;
  }
  .w-xl-40 {
    width: 10rem !important;
  }
  .w-xl-48 {
    width: 12rem !important;
  }
  .w-xl-56 {
    width: 14rem !important;
  }
  .w-xl-64 {
    width: 16rem !important;
  }
  .w-xl-72 {
    width: 18rem !important;
  }
  .w-xl-80 {
    width: 20rem !important;
  }
  .w-xl-88 {
    width: 22rem !important;
  }
  .w-xl-96 {
    width: 24rem !important;
  }
  .w-xl-px {
    width: 1px !important;
  }
  .w-xl-1\/2 {
    width: 50% !important;
  }
  .w-xl-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-xl-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-xl-1\/4 {
    width: 25% !important;
  }
  .w-xl-2\/4 {
    width: 50% !important;
  }
  .w-xl-3\/4 {
    width: 75% !important;
  }
  .w-xl-1\/5 {
    width: 20% !important;
  }
  .w-xl-2\/5 {
    width: 40% !important;
  }
  .w-xl-3\/5 {
    width: 60% !important;
  }
  .w-xl-4\/5 {
    width: 80% !important;
  }
  .w-xl-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-xl-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-xl-3\/6 {
    width: 50% !important;
  }
  .w-xl-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-xl-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-xl-11\/10 {
    width: 110% !important;
  }
  .w-xl-12\/10 {
    width: 120% !important;
  }
  .w-xl-13\/10 {
    width: 130% !important;
  }
  .w-xl-14\/10 {
    width: 140% !important;
  }
  .w-xl-15\/10 {
    width: 150% !important;
  }
  .w-xl-screen-sm {
    width: 640px !important;
  }
  .w-xl-screen-md {
    width: 768px !important;
  }
  .w-xl-screen-lg {
    width: 1024px !important;
  }
  .w-xl-screen-xl {
    width: 1280px !important;
  }
  .w-xl-screen-xxl {
    width: 1536px !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-full {
    width: 100% !important;
  }
  .w-xl-screen {
    width: 100vw !important;
  }
  .w-xl-min {
    width: min-content !important;
  }
  .w-xl-max {
    width: max-content !important;
  }
  .max-w-xl-screen-sm {
    max-width: 640px !important;
  }
  .max-w-xl-screen-md {
    max-width: 768px !important;
  }
  .max-w-xl-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-xl-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-xl-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-xl-0 {
    max-width: 0 !important;
  }
  .max-w-xl-full {
    max-width: 100% !important;
  }
  .max-w-xl-read {
    max-width: 65ch !important;
  }
  .max-w-xl-min {
    max-width: min-content !important;
  }
  .max-w-xl-max {
    max-width: max-content !important;
  }
  .h-xl-0 {
    height: 0 !important;
  }
  .h-xl-1 {
    height: 0.25rem !important;
  }
  .h-xl-2 {
    height: 0.5rem !important;
  }
  .h-xl-3 {
    height: 0.75rem !important;
  }
  .h-xl-4 {
    height: 1rem !important;
  }
  .h-xl-5 {
    height: 1.25rem !important;
  }
  .h-xl-6 {
    height: 1.5rem !important;
  }
  .h-xl-7 {
    height: 1.75rem !important;
  }
  .h-xl-8 {
    height: 2rem !important;
  }
  .h-xl-10 {
    height: 2.5rem !important;
  }
  .h-xl-12 {
    height: 3rem !important;
  }
  .h-xl-14 {
    height: 3.5rem !important;
  }
  .h-xl-16 {
    height: 4rem !important;
  }
  .h-xl-18 {
    height: 4.5rem !important;
  }
  .h-xl-20 {
    height: 5rem !important;
  }
  .h-xl-24 {
    height: 6rem !important;
  }
  .h-xl-32 {
    height: 8rem !important;
  }
  .h-xl-40 {
    height: 10rem !important;
  }
  .h-xl-48 {
    height: 12rem !important;
  }
  .h-xl-56 {
    height: 14rem !important;
  }
  .h-xl-64 {
    height: 16rem !important;
  }
  .h-xl-72 {
    height: 18rem !important;
  }
  .h-xl-80 {
    height: 20rem !important;
  }
  .h-xl-88 {
    height: 22rem !important;
  }
  .h-xl-96 {
    height: 24rem !important;
  }
  .h-xl-px {
    height: 1px !important;
  }
  .h-xl-1\/2 {
    height: 50% !important;
  }
  .h-xl-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-xl-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-xl-1\/4 {
    height: 25% !important;
  }
  .h-xl-2\/4 {
    height: 50% !important;
  }
  .h-xl-3\/4 {
    height: 75% !important;
  }
  .h-xl-1\/5 {
    height: 20% !important;
  }
  .h-xl-2\/5 {
    height: 40% !important;
  }
  .h-xl-3\/5 {
    height: 60% !important;
  }
  .h-xl-4\/5 {
    height: 80% !important;
  }
  .h-xl-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-xl-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-xl-3\/6 {
    height: 50% !important;
  }
  .h-xl-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-xl-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-xl-11\/10 {
    height: 110% !important;
  }
  .h-xl-12\/10 {
    height: 120% !important;
  }
  .h-xl-13\/10 {
    height: 130% !important;
  }
  .h-xl-14\/10 {
    height: 140% !important;
  }
  .h-xl-15\/10 {
    height: 150% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-full {
    height: 100% !important;
  }
  .h-xl-screen {
    height: 100vh !important;
  }
  .max-h-xl-0 {
    max-height: 0 !important;
  }
  .max-h-xl-1 {
    max-height: 0.25rem !important;
  }
  .max-h-xl-2 {
    max-height: 0.5rem !important;
  }
  .max-h-xl-3 {
    max-height: 0.75rem !important;
  }
  .max-h-xl-4 {
    max-height: 1rem !important;
  }
  .max-h-xl-5 {
    max-height: 1.25rem !important;
  }
  .max-h-xl-6 {
    max-height: 1.5rem !important;
  }
  .max-h-xl-7 {
    max-height: 1.75rem !important;
  }
  .max-h-xl-8 {
    max-height: 2rem !important;
  }
  .max-h-xl-10 {
    max-height: 2.5rem !important;
  }
  .max-h-xl-12 {
    max-height: 3rem !important;
  }
  .max-h-xl-14 {
    max-height: 3.5rem !important;
  }
  .max-h-xl-16 {
    max-height: 4rem !important;
  }
  .max-h-xl-18 {
    max-height: 4.5rem !important;
  }
  .max-h-xl-20 {
    max-height: 5rem !important;
  }
  .max-h-xl-24 {
    max-height: 6rem !important;
  }
  .max-h-xl-32 {
    max-height: 8rem !important;
  }
  .max-h-xl-40 {
    max-height: 10rem !important;
  }
  .max-h-xl-48 {
    max-height: 12rem !important;
  }
  .max-h-xl-56 {
    max-height: 14rem !important;
  }
  .max-h-xl-64 {
    max-height: 16rem !important;
  }
  .max-h-xl-72 {
    max-height: 18rem !important;
  }
  .max-h-xl-80 {
    max-height: 20rem !important;
  }
  .max-h-xl-88 {
    max-height: 22rem !important;
  }
  .max-h-xl-96 {
    max-height: 24rem !important;
  }
  .max-h-xl-px {
    max-height: 1px !important;
  }
  .max-h-xl-full {
    max-height: 100% !important;
  }
  .max-h-xl-screen {
    max-height: 100vh !important;
  }
  .flex-xl-1 {
    flex: 1 1 0% !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-initial {
    flex: 0 1 auto !important;
  }
  .flex-xl-none {
    flex: none !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-10 {
    gap: 2.5rem !important;
  }
  .gap-xl-12 {
    gap: 3rem !important;
  }
  .gap-xl-14 {
    gap: 3.5rem !important;
  }
  .gap-xl-16 {
    gap: 4rem !important;
  }
  .gap-xl-18 {
    gap: 4.5rem !important;
  }
  .gap-xl-20 {
    gap: 5rem !important;
  }
  .gap-xl-24 {
    gap: 6rem !important;
  }
  .gap-xl-32 {
    gap: 8rem !important;
  }
  .gap-xl-40 {
    gap: 10rem !important;
  }
  .gap-xl-48 {
    gap: 12rem !important;
  }
  .gap-xl-56 {
    gap: 14rem !important;
  }
  .gap-xl-64 {
    gap: 16rem !important;
  }
  .gap-xl-72 {
    gap: 18rem !important;
  }
  .gap-xl-80 {
    gap: 20rem !important;
  }
  .gap-xl-88 {
    gap: 22rem !important;
  }
  .gap-xl-96 {
    gap: 24rem !important;
  }
  .gap-xl-px {
    gap: 1px !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-evenly {
    align-content: space-evenly !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-10 {
    margin: 2.5rem !important;
  }
  .m-xl-12 {
    margin: 3rem !important;
  }
  .m-xl-14 {
    margin: 3.5rem !important;
  }
  .m-xl-16 {
    margin: 4rem !important;
  }
  .m-xl-18 {
    margin: 4.5rem !important;
  }
  .m-xl-20 {
    margin: 5rem !important;
  }
  .m-xl-24 {
    margin: 6rem !important;
  }
  .m-xl-32 {
    margin: 8rem !important;
  }
  .m-xl-40 {
    margin: 10rem !important;
  }
  .m-xl-48 {
    margin: 12rem !important;
  }
  .m-xl-56 {
    margin: 14rem !important;
  }
  .m-xl-64 {
    margin: 16rem !important;
  }
  .m-xl-72 {
    margin: 18rem !important;
  }
  .m-xl-80 {
    margin: 20rem !important;
  }
  .m-xl-88 {
    margin: 22rem !important;
  }
  .m-xl-96 {
    margin: 24rem !important;
  }
  .m-xl-px {
    margin: 1px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xl-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xl-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xl-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xl-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xl-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xl-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 2rem !important;
  }
  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-12 {
    margin-top: 3rem !important;
  }
  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-16 {
    margin-top: 4rem !important;
  }
  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-20 {
    margin-top: 5rem !important;
  }
  .mt-xl-24 {
    margin-top: 6rem !important;
  }
  .mt-xl-32 {
    margin-top: 8rem !important;
  }
  .mt-xl-40 {
    margin-top: 10rem !important;
  }
  .mt-xl-48 {
    margin-top: 12rem !important;
  }
  .mt-xl-56 {
    margin-top: 14rem !important;
  }
  .mt-xl-64 {
    margin-top: 16rem !important;
  }
  .mt-xl-72 {
    margin-top: 18rem !important;
  }
  .mt-xl-80 {
    margin-top: 20rem !important;
  }
  .mt-xl-88 {
    margin-top: 22rem !important;
  }
  .mt-xl-96 {
    margin-top: 24rem !important;
  }
  .mt-xl-px {
    margin-top: 1px !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xl-8 {
    margin-right: 2rem !important;
  }
  .me-xl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xl-12 {
    margin-right: 3rem !important;
  }
  .me-xl-14 {
    margin-right: 3.5rem !important;
  }
  .me-xl-16 {
    margin-right: 4rem !important;
  }
  .me-xl-18 {
    margin-right: 4.5rem !important;
  }
  .me-xl-20 {
    margin-right: 5rem !important;
  }
  .me-xl-24 {
    margin-right: 6rem !important;
  }
  .me-xl-32 {
    margin-right: 8rem !important;
  }
  .me-xl-40 {
    margin-right: 10rem !important;
  }
  .me-xl-48 {
    margin-right: 12rem !important;
  }
  .me-xl-56 {
    margin-right: 14rem !important;
  }
  .me-xl-64 {
    margin-right: 16rem !important;
  }
  .me-xl-72 {
    margin-right: 18rem !important;
  }
  .me-xl-80 {
    margin-right: 20rem !important;
  }
  .me-xl-88 {
    margin-right: 22rem !important;
  }
  .me-xl-96 {
    margin-right: 24rem !important;
  }
  .me-xl-px {
    margin-right: 1px !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-24 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-32 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-40 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-48 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-56 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-64 {
    margin-bottom: 16rem !important;
  }
  .mb-xl-72 {
    margin-bottom: 18rem !important;
  }
  .mb-xl-80 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-88 {
    margin-bottom: 22rem !important;
  }
  .mb-xl-96 {
    margin-bottom: 24rem !important;
  }
  .mb-xl-px {
    margin-bottom: 1px !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 2rem !important;
  }
  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-12 {
    margin-left: 3rem !important;
  }
  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-16 {
    margin-left: 4rem !important;
  }
  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-20 {
    margin-left: 5rem !important;
  }
  .ms-xl-24 {
    margin-left: 6rem !important;
  }
  .ms-xl-32 {
    margin-left: 8rem !important;
  }
  .ms-xl-40 {
    margin-left: 10rem !important;
  }
  .ms-xl-48 {
    margin-left: 12rem !important;
  }
  .ms-xl-56 {
    margin-left: 14rem !important;
  }
  .ms-xl-64 {
    margin-left: 16rem !important;
  }
  .ms-xl-72 {
    margin-left: 18rem !important;
  }
  .ms-xl-80 {
    margin-left: 20rem !important;
  }
  .ms-xl-88 {
    margin-left: 22rem !important;
  }
  .ms-xl-96 {
    margin-left: 24rem !important;
  }
  .ms-xl-px {
    margin-left: 1px !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-10 {
    padding: 2.5rem !important;
  }
  .p-xl-12 {
    padding: 3rem !important;
  }
  .p-xl-14 {
    padding: 3.5rem !important;
  }
  .p-xl-16 {
    padding: 4rem !important;
  }
  .p-xl-18 {
    padding: 4.5rem !important;
  }
  .p-xl-20 {
    padding: 5rem !important;
  }
  .p-xl-24 {
    padding: 6rem !important;
  }
  .p-xl-32 {
    padding: 8rem !important;
  }
  .p-xl-40 {
    padding: 10rem !important;
  }
  .p-xl-48 {
    padding: 12rem !important;
  }
  .p-xl-56 {
    padding: 14rem !important;
  }
  .p-xl-64 {
    padding: 16rem !important;
  }
  .p-xl-72 {
    padding: 18rem !important;
  }
  .p-xl-80 {
    padding: 20rem !important;
  }
  .p-xl-88 {
    padding: 22rem !important;
  }
  .p-xl-96 {
    padding: 24rem !important;
  }
  .p-xl-px {
    padding: 1px !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xl-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xl-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xl-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xl-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xl-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xl-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-xl-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xl-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xl-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-xl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 2rem !important;
  }
  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-12 {
    padding-top: 3rem !important;
  }
  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-16 {
    padding-top: 4rem !important;
  }
  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-20 {
    padding-top: 5rem !important;
  }
  .pt-xl-24 {
    padding-top: 6rem !important;
  }
  .pt-xl-32 {
    padding-top: 8rem !important;
  }
  .pt-xl-40 {
    padding-top: 10rem !important;
  }
  .pt-xl-48 {
    padding-top: 12rem !important;
  }
  .pt-xl-56 {
    padding-top: 14rem !important;
  }
  .pt-xl-64 {
    padding-top: 16rem !important;
  }
  .pt-xl-72 {
    padding-top: 18rem !important;
  }
  .pt-xl-80 {
    padding-top: 20rem !important;
  }
  .pt-xl-88 {
    padding-top: 22rem !important;
  }
  .pt-xl-96 {
    padding-top: 24rem !important;
  }
  .pt-xl-px {
    padding-top: 1px !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 2rem !important;
  }
  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-12 {
    padding-right: 3rem !important;
  }
  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-16 {
    padding-right: 4rem !important;
  }
  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-20 {
    padding-right: 5rem !important;
  }
  .pe-xl-24 {
    padding-right: 6rem !important;
  }
  .pe-xl-32 {
    padding-right: 8rem !important;
  }
  .pe-xl-40 {
    padding-right: 10rem !important;
  }
  .pe-xl-48 {
    padding-right: 12rem !important;
  }
  .pe-xl-56 {
    padding-right: 14rem !important;
  }
  .pe-xl-64 {
    padding-right: 16rem !important;
  }
  .pe-xl-72 {
    padding-right: 18rem !important;
  }
  .pe-xl-80 {
    padding-right: 20rem !important;
  }
  .pe-xl-88 {
    padding-right: 22rem !important;
  }
  .pe-xl-96 {
    padding-right: 24rem !important;
  }
  .pe-xl-px {
    padding-right: 1px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-24 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-32 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-40 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-48 {
    padding-bottom: 12rem !important;
  }
  .pb-xl-56 {
    padding-bottom: 14rem !important;
  }
  .pb-xl-64 {
    padding-bottom: 16rem !important;
  }
  .pb-xl-72 {
    padding-bottom: 18rem !important;
  }
  .pb-xl-80 {
    padding-bottom: 20rem !important;
  }
  .pb-xl-88 {
    padding-bottom: 22rem !important;
  }
  .pb-xl-96 {
    padding-bottom: 24rem !important;
  }
  .pb-xl-px {
    padding-bottom: 1px !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 2rem !important;
  }
  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-12 {
    padding-left: 3rem !important;
  }
  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-16 {
    padding-left: 4rem !important;
  }
  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-20 {
    padding-left: 5rem !important;
  }
  .ps-xl-24 {
    padding-left: 6rem !important;
  }
  .ps-xl-32 {
    padding-left: 8rem !important;
  }
  .ps-xl-40 {
    padding-left: 10rem !important;
  }
  .ps-xl-48 {
    padding-left: 12rem !important;
  }
  .ps-xl-56 {
    padding-left: 14rem !important;
  }
  .ps-xl-64 {
    padding-left: 16rem !important;
  }
  .ps-xl-72 {
    padding-left: 18rem !important;
  }
  .ps-xl-80 {
    padding-left: 20rem !important;
  }
  .ps-xl-88 {
    padding-left: 22rem !important;
  }
  .ps-xl-96 {
    padding-left: 24rem !important;
  }
  .ps-xl-px {
    padding-left: 1px !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-primary-hover:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-primary-focus:focus {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-secondary-hover:hover {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-secondary-focus:focus {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-success-hover:hover {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-success-focus:focus {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-info-hover:hover {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-info-focus:focus {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-warning-hover:hover {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-warning-focus:focus {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-danger-hover:hover {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-danger-focus:focus {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-light-hover:hover {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-light-focus:focus {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-dark-hover:hover {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-dark-focus:focus {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-black-hover:hover {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-black-focus:focus {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-white-hover:hover {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-white-focus:focus {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-body-hover:hover {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xl-body-focus:focus {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .rounded-xl {
    border-radius: 0.25rem !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
  .rounded-xl-1 {
    border-radius: 0.2rem !important;
  }
  .rounded-xl-2 {
    border-radius: 0.3rem !important;
  }
  .rounded-xl-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-xl-4 {
    border-radius: 1rem !important;
  }
  .rounded-xl-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-xl-6 {
    border-radius: 3rem !important;
  }
  .rounded-xl-7 {
    border-radius: 4rem !important;
  }
  .rounded-xl-8 {
    border-radius: 5rem !important;
  }
  .rounded-xl-9 {
    border-radius: 6rem !important;
  }
  .rounded-xl-10 {
    border-radius: 7rem !important;
  }
  .rounded-xl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xl-circle {
    border-radius: 50% !important;
  }
  .rounded-xl-card {
    border-radius: 0.25rem !important;
  }
  .rounded-top-xl {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-xl-1 {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-xl-2 {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-xl-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-xl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-xl-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-xl-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-xl-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-xl-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-xl-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-xl-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-xl-card {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-end-xl {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-xl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-xl-1 {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-end-xl-2 {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-end-xl-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-xl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-xl-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-xl-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-xl-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-xl-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-xl-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-xl-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-xl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-xl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-xl-card {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-xl {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-xl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-xl-1 {
    border-bottom-right-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-xl-2 {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-xl-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-xl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-xl-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-xl-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-xl-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-xl-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-xl-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-xl-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-xl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-xl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-xl-card {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-start-xl {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-xl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-xl-1 {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-start-xl-2 {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-start-xl-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-xl-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-xl-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-xl-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-xl-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-xl-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-xl-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-xl-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-xl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-xl-card {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-xl {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-xl-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-xl-1 {
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-top-start-xl-2 {
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-top-start-xl-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-xl-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-xl-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-xl-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-xl-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-xl-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-xl-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-xl-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-xl-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-xl-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-xl-card {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-end-xl {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-xl-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-xl-1 {
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-end-xl-2 {
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-end-xl-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-xl-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-xl-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-xl-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-xl-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-xl-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-xl-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-xl-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-xl-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-xl-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-xl-card {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-xl {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-xl-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-xl-1 {
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-bottom-end-xl-2 {
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-bottom-end-xl-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-xl-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-xl-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-xl-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-xl-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-xl-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-xl-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-xl-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-xl-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-xl-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-xl-card {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-start-xl {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-xl-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-xl-1 {
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-start-xl-2 {
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-start-xl-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-xl-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-xl-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-xl-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-xl-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-xl-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-xl-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-xl-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-xl-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-xl-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-xl-card {
    border-bottom-left-radius: 0.25rem !important;
  }
  .border-xl-solid {
    border-style: solid !important;
  }
  .border-xl-dashed {
    border-style: dashed !important;
  }
  .border-xl-dotted {
    border-style: dotted !important;
  }
  .border-xl-double {
    border-style: double !important;
  }
  .border-xl-groove {
    border-style: groove !important;
  }
  .border-xl-none {
    border-style: none !important;
  }
  .text-xl-transparent {
    color: transparent !important;
  }
  .text-xl-transparent-hover:hover {
    color: transparent !important;
  }
  .text-xl-transparent-focus:focus {
    color: transparent !important;
  }
  .text-xl-current {
    color: currentColor !important;
  }
  .text-xl-current-hover:hover {
    color: currentColor !important;
  }
  .text-xl-current-focus:focus {
    color: currentColor !important;
  }
  .text-xl-reset {
    color: inherit !important;
  }
  .text-xl-reset-hover:hover {
    color: inherit !important;
  }
  .text-xl-reset-focus:focus {
    color: inherit !important;
  }
  .shadow-soft-xl-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-1-focus:focus {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-2-focus:focus {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xl-none {
    box-shadow: none !important;
  }
  .shadow-soft-xl-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-soft-xl-none-focus:focus {
    box-shadow: none !important;
  }
  .min-w-xl-0 {
    min-width: 0 !important;
  }
  .min-w-xl-full {
    min-width: 100% !important;
  }
  .min-w-xl-min {
    min-width: min-content !important;
  }
  .min-w-xl-max {
    min-width: max-content !important;
  }
  .min-h-xl-0 {
    min-height: 0 !important;
  }
  .min-h-xl-full {
    min-height: 100% !important;
  }
  .min-h-xl-screen {
    min-height: 100vh !important;
  }
  .place-content-xl-start {
    place-content: flex-start !important;
  }
  .place-content-xl-end {
    place-content: flex-end !important;
  }
  .place-content-xl-center {
    place-content: center !important;
  }
  .place-content-xl-between {
    place-content: space-between !important;
  }
  .place-content-xl-around {
    place-content: space-around !important;
  }
  .place-content-xl-evenly {
    place-content: space-evenly !important;
  }
  .place-content-xl-stretch {
    place-content: stretch !important;
  }
  .overflow-x-xl-auto {
    overflow-x: auto !important;
  }
  .overflow-x-xl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-xl-visible {
    overflow-x: visible !important;
  }
  .overflow-x-xl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-xl-auto {
    overflow-y: auto !important;
  }
  .overflow-y-xl-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-xl-visible {
    overflow-y: visible !important;
  }
  .overflow-y-xl-scroll {
    overflow-y: scroll !important;
  }
  .transition-xl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }
  .transition-xl-none {
    transition-property: none !important;
  }
  .transition-xl-all {
    transition-property: all !important;
  }
  .transition-xl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-xl-opacity {
    transition-property: opacity !important;
  }
  .transition-xl-shadow {
    transition-property: box-shadow !important;
  }
  .transition-xl-transform {
    transition-property: transform !important;
  }
  .ease-xl-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-xl-linear {
    transition-timing-function: linear !important;
  }
  .ease-xl-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-xl-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-xl-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .transform-xl-none {
    transform: none !important;
  }
  .origin-xl-center {
    transform-origin: center !important;
  }
  .origin-xl-top {
    transform-origin: top !important;
  }
  .origin-xl-top-right {
    transform-origin: top right !important;
  }
  .origin-xl-right {
    transform-origin: right !important;
  }
  .origin-xl-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-xl-bottom {
    transform-origin: bottom !important;
  }
  .origin-xl-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-xl-left {
    transform-origin: left !important;
  }
  .origin-xl-top-left {
    transform-origin: top left !important;
  }
  .scale-xl-0 {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xl-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xl-0-focus:focus {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xl-25 {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xl-25-hover:hover {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xl-25-focus:focus {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xl-50 {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xl-50-hover:hover {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xl-50-focus:focus {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xl-75 {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xl-75-hover:hover {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xl-75-focus:focus {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xl-90 {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xl-90-hover:hover {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xl-90-focus:focus {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xl-95 {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xl-95-hover:hover {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xl-95-focus:focus {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xl-100 {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xl-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xl-100-focus:focus {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xl-105 {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xl-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xl-105-focus:focus {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xl-110 {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xl-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xl-110-focus:focus {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xl-125 {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xl-125-hover:hover {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xl-125-focus:focus {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xl-150 {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xl-150-hover:hover {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xl-150-focus:focus {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xl-200 {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-xl-200-hover:hover {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-xl-200-focus:focus {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-y-xl-0 {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xl-0-hover:hover {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xl-0-focus:focus {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xl-25 {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xl-25-hover:hover {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xl-25-focus:focus {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xl-50 {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xl-50-hover:hover {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xl-50-focus:focus {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xl-75 {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xl-75-hover:hover {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xl-75-focus:focus {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xl-90 {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xl-90-hover:hover {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xl-90-focus:focus {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xl-95 {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xl-95-hover:hover {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xl-95-focus:focus {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xl-100 {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xl-100-hover:hover {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xl-100-focus:focus {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xl-105 {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xl-105-hover:hover {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xl-105-focus:focus {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xl-110 {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xl-110-hover:hover {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xl-110-focus:focus {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xl-125 {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xl-125-hover:hover {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xl-125-focus:focus {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xl-150 {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xl-150-hover:hover {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xl-150-focus:focus {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xl-200 {
    --bs--scale-y: 2 !important;
  }
  .scale-y-xl-200-hover:hover {
    --bs--scale-y: 2 !important;
  }
  .scale-y-xl-200-focus:focus {
    --bs--scale-y: 2 !important;
  }
  .scale-x-xl-0 {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xl-0-hover:hover {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xl-0-focus:focus {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xl-25 {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xl-25-hover:hover {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xl-25-focus:focus {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xl-50 {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xl-50-hover:hover {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xl-50-focus:focus {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xl-75 {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xl-75-hover:hover {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xl-75-focus:focus {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xl-90 {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xl-90-hover:hover {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xl-90-focus:focus {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xl-95 {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xl-95-hover:hover {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xl-95-focus:focus {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xl-100 {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xl-100-hover:hover {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xl-100-focus:focus {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xl-105 {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xl-105-hover:hover {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xl-105-focus:focus {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xl-110 {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xl-110-hover:hover {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xl-110-focus:focus {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xl-125 {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xl-125-hover:hover {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xl-125-focus:focus {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xl-150 {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xl-150-hover:hover {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xl-150-focus:focus {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xl-200 {
    --bs--scale-x: 2 !important;
  }
  .scale-x-xl-200-hover:hover {
    --bs--scale-x: 2 !important;
  }
  .scale-x-xl-200-focus:focus {
    --bs--scale-x: 2 !important;
  }
  .rotate-xl-0 {
    --bs-rotate: 0deg !important;
  }
  .rotate-xl-0-hover:hover {
    --bs-rotate: 0deg !important;
  }
  .rotate-xl-0-focus:focus {
    --bs-rotate: 0deg !important;
  }
  .rotate-xl-1 {
    --bs-rotate: 1deg !important;
  }
  .rotate-xl-1-hover:hover {
    --bs-rotate: 1deg !important;
  }
  .rotate-xl-1-focus:focus {
    --bs-rotate: 1deg !important;
  }
  .rotate-xl-2 {
    --bs-rotate: 2deg !important;
  }
  .rotate-xl-2-hover:hover {
    --bs-rotate: 2deg !important;
  }
  .rotate-xl-2-focus:focus {
    --bs-rotate: 2deg !important;
  }
  .rotate-xl-3 {
    --bs-rotate: 3deg !important;
  }
  .rotate-xl-3-hover:hover {
    --bs-rotate: 3deg !important;
  }
  .rotate-xl-3-focus:focus {
    --bs-rotate: 3deg !important;
  }
  .rotate-xl-6 {
    --bs-rotate: 6deg !important;
  }
  .rotate-xl-6-hover:hover {
    --bs-rotate: 6deg !important;
  }
  .rotate-xl-6-focus:focus {
    --bs-rotate: 6deg !important;
  }
  .rotate-xl-12 {
    --bs-rotate: 12deg !important;
  }
  .rotate-xl-12-hover:hover {
    --bs-rotate: 12deg !important;
  }
  .rotate-xl-12-focus:focus {
    --bs-rotate: 12deg !important;
  }
  .rotate-xl-30 {
    --bs-rotate: 30deg !important;
  }
  .rotate-xl-30-hover:hover {
    --bs-rotate: 30deg !important;
  }
  .rotate-xl-30-focus:focus {
    --bs-rotate: 30deg !important;
  }
  .rotate-xl-45 {
    --bs-rotate: 45deg !important;
  }
  .rotate-xl-45-hover:hover {
    --bs-rotate: 45deg !important;
  }
  .rotate-xl-45-focus:focus {
    --bs-rotate: 45deg !important;
  }
  .rotate-xl-90 {
    --bs-rotate: 90deg !important;
  }
  .rotate-xl-90-hover:hover {
    --bs-rotate: 90deg !important;
  }
  .rotate-xl-90-focus:focus {
    --bs-rotate: 90deg !important;
  }
  .rotate-xl-180 {
    --bs-rotate: 180deg !important;
  }
  .rotate-xl-180-hover:hover {
    --bs-rotate: 180deg !important;
  }
  .rotate-xl-180-focus:focus {
    --bs-rotate: 180deg !important;
  }
  .rotate-xl-n1 {
    --bs-rotate: -1deg !important;
  }
  .rotate-xl-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }
  .rotate-xl-n1-focus:focus {
    --bs-rotate: -1deg !important;
  }
  .rotate-xl-n2 {
    --bs-rotate: -2deg !important;
  }
  .rotate-xl-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }
  .rotate-xl-n2-focus:focus {
    --bs-rotate: -2deg !important;
  }
  .rotate-xl-n3 {
    --bs-rotate: -3deg !important;
  }
  .rotate-xl-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }
  .rotate-xl-n3-focus:focus {
    --bs-rotate: -3deg !important;
  }
  .rotate-xl-n6 {
    --bs-rotate: -6deg !important;
  }
  .rotate-xl-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }
  .rotate-xl-n6-focus:focus {
    --bs-rotate: -6deg !important;
  }
  .rotate-xl-n12 {
    --bs-rotate: -12deg !important;
  }
  .rotate-xl-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }
  .rotate-xl-n12-focus:focus {
    --bs-rotate: -12deg !important;
  }
  .rotate-xl-n30 {
    --bs-rotate: -30deg !important;
  }
  .rotate-xl-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }
  .rotate-xl-n30-focus:focus {
    --bs-rotate: -30deg !important;
  }
  .rotate-xl-n45 {
    --bs-rotate: -45deg !important;
  }
  .rotate-xl-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }
  .rotate-xl-n45-focus:focus {
    --bs-rotate: -45deg !important;
  }
  .rotate-xl-n90 {
    --bs-rotate: -90deg !important;
  }
  .rotate-xl-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }
  .rotate-xl-n90-focus:focus {
    --bs-rotate: -90deg !important;
  }
  .rotate-xl-n180 {
    --bs-rotate: -180deg !important;
  }
  .rotate-xl-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }
  .rotate-xl-n180-focus:focus {
    --bs-rotate: -180deg !important;
  }
  .rotate-y-xl-0 {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xl-0-hover:hover {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xl-0-focus:focus {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xl-1 {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xl-1-hover:hover {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xl-1-focus:focus {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xl-2 {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xl-2-hover:hover {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xl-2-focus:focus {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xl-3 {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xl-3-hover:hover {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xl-3-focus:focus {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xl-6 {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xl-6-hover:hover {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xl-6-focus:focus {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xl-12 {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xl-12-hover:hover {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xl-12-focus:focus {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xl-30 {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xl-30-hover:hover {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xl-30-focus:focus {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xl-45 {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xl-45-hover:hover {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xl-45-focus:focus {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xl-90 {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xl-90-hover:hover {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xl-90-focus:focus {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xl-180 {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xl-180-hover:hover {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xl-180-focus:focus {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xl-n1 {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xl-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xl-n1-focus:focus {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xl-n2 {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xl-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xl-n2-focus:focus {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xl-n3 {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xl-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xl-n3-focus:focus {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xl-n6 {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xl-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xl-n6-focus:focus {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xl-n12 {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xl-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xl-n12-focus:focus {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xl-n30 {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xl-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xl-n30-focus:focus {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xl-n45 {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xl-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xl-n45-focus:focus {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xl-n90 {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xl-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xl-n90-focus:focus {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xl-n180 {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-xl-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-xl-n180-focus:focus {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-x-xl-0 {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xl-0-hover:hover {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xl-0-focus:focus {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xl-1 {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xl-1-hover:hover {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xl-1-focus:focus {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xl-2 {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xl-2-hover:hover {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xl-2-focus:focus {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xl-3 {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xl-3-hover:hover {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xl-3-focus:focus {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xl-6 {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xl-6-hover:hover {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xl-6-focus:focus {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xl-12 {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xl-12-hover:hover {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xl-12-focus:focus {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xl-30 {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xl-30-hover:hover {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xl-30-focus:focus {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xl-45 {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xl-45-hover:hover {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xl-45-focus:focus {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xl-90 {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xl-90-hover:hover {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xl-90-focus:focus {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xl-180 {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xl-180-hover:hover {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xl-180-focus:focus {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xl-n1 {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xl-n1-hover:hover {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xl-n1-focus:focus {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xl-n2 {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xl-n2-hover:hover {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xl-n2-focus:focus {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xl-n3 {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xl-n3-hover:hover {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xl-n3-focus:focus {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xl-n6 {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xl-n6-hover:hover {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xl-n6-focus:focus {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xl-n12 {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xl-n12-hover:hover {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xl-n12-focus:focus {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xl-n30 {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xl-n30-hover:hover {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xl-n30-focus:focus {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xl-n45 {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xl-n45-hover:hover {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xl-n45-focus:focus {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xl-n90 {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xl-n90-hover:hover {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xl-n90-focus:focus {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xl-n180 {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-xl-n180-hover:hover {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-xl-n180-focus:focus {
    --bs-rotate-x: -180deg !important;
  }
  .perspective-xl-100 {
    --bs-perspective: 100px !important;
  }
  .perspective-xl-100-hover:hover {
    --bs-perspective: 100px !important;
  }
  .perspective-xl-100-focus:focus {
    --bs-perspective: 100px !important;
  }
  .perspective-xl-200 {
    --bs-perspective: 200px !important;
  }
  .perspective-xl-200-hover:hover {
    --bs-perspective: 200px !important;
  }
  .perspective-xl-200-focus:focus {
    --bs-perspective: 200px !important;
  }
  .perspective-xl-300 {
    --bs-perspective: 300px !important;
  }
  .perspective-xl-300-hover:hover {
    --bs-perspective: 300px !important;
  }
  .perspective-xl-300-focus:focus {
    --bs-perspective: 300px !important;
  }
  .perspective-xl-400 {
    --bs-perspective: 400px !important;
  }
  .perspective-xl-400-hover:hover {
    --bs-perspective: 400px !important;
  }
  .perspective-xl-400-focus:focus {
    --bs-perspective: 400px !important;
  }
  .perspective-xl-500 {
    --bs-perspective: 500px !important;
  }
  .perspective-xl-500-hover:hover {
    --bs-perspective: 500px !important;
  }
  .perspective-xl-500-focus:focus {
    --bs-perspective: 500px !important;
  }
  .perspective-xl-600 {
    --bs-perspective: 600px !important;
  }
  .perspective-xl-600-hover:hover {
    --bs-perspective: 600px !important;
  }
  .perspective-xl-600-focus:focus {
    --bs-perspective: 600px !important;
  }
  .perspective-xl-700 {
    --bs-perspective: 700px !important;
  }
  .perspective-xl-700-hover:hover {
    --bs-perspective: 700px !important;
  }
  .perspective-xl-700-focus:focus {
    --bs-perspective: 700px !important;
  }
  .perspective-xl-800 {
    --bs-perspective: 800px !important;
  }
  .perspective-xl-800-hover:hover {
    --bs-perspective: 800px !important;
  }
  .perspective-xl-800-focus:focus {
    --bs-perspective: 800px !important;
  }
  .perspective-xl-1000 {
    --bs-perspective: 1000px !important;
  }
  .perspective-xl-1000-hover:hover {
    --bs-perspective: 1000px !important;
  }
  .perspective-xl-1000-focus:focus {
    --bs-perspective: 1000px !important;
  }
  .perspective-xl-1250 {
    --bs-perspective: 1250px !important;
  }
  .perspective-xl-1250-hover:hover {
    --bs-perspective: 1250px !important;
  }
  .perspective-xl-1250-focus:focus {
    --bs-perspective: 1250px !important;
  }
  .perspective-xl-1500 {
    --bs-perspective: 1500px !important;
  }
  .perspective-xl-1500-hover:hover {
    --bs-perspective: 1500px !important;
  }
  .perspective-xl-1500-focus:focus {
    --bs-perspective: 1500px !important;
  }
  .translate-y-xl-0 {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xl-0-hover:hover {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xl-0-focus:focus {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xl-1 {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xl-1-hover:hover {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xl-1-focus:focus {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xl-2 {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xl-2-hover:hover {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xl-2-focus:focus {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xl-3 {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xl-3-hover:hover {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xl-3-focus:focus {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xl-4 {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xl-4-hover:hover {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xl-4-focus:focus {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xl-5 {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xl-5-hover:hover {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xl-5-focus:focus {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xl-6 {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xl-6-hover:hover {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xl-6-focus:focus {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xl-7 {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xl-7-hover:hover {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xl-7-focus:focus {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xl-8 {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xl-8-hover:hover {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xl-8-focus:focus {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xl-10 {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xl-10-hover:hover {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xl-10-focus:focus {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xl-12 {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xl-12-hover:hover {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xl-12-focus:focus {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xl-14 {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xl-14-hover:hover {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xl-14-focus:focus {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xl-16 {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xl-16-hover:hover {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xl-16-focus:focus {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xl-18 {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xl-18-hover:hover {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xl-18-focus:focus {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xl-20 {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xl-20-hover:hover {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xl-20-focus:focus {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xl-24 {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xl-24-hover:hover {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xl-24-focus:focus {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xl-32 {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xl-32-hover:hover {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xl-32-focus:focus {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xl-40 {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xl-40-hover:hover {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xl-40-focus:focus {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xl-48 {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xl-48-hover:hover {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xl-48-focus:focus {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xl-56 {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xl-56-hover:hover {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xl-56-focus:focus {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xl-64 {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xl-64-hover:hover {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xl-64-focus:focus {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xl-72 {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xl-72-hover:hover {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xl-72-focus:focus {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xl-80 {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xl-80-hover:hover {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xl-80-focus:focus {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xl-88 {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xl-88-hover:hover {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xl-88-focus:focus {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xl-96 {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xl-96-hover:hover {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xl-96-focus:focus {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xl-px {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xl-px-hover:hover {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xl-px-focus:focus {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xl-1\/2 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-1\/2-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-1\/2-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-1\/3 {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xl-1\/3-hover:hover {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xl-1\/3-focus:focus {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xl-1\/4 {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xl-1\/4-hover:hover {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xl-1\/4-focus:focus {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xl-1\/5 {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xl-1\/5-hover:hover {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xl-1\/5-focus:focus {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xl-2\/3 {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xl-2\/3-hover:hover {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xl-2\/3-focus:focus {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xl-2\/5 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-2\/5-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-2\/5-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xl-3\/4 {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xl-3\/4-hover:hover {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xl-3\/4-focus:focus {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xl-full {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xl-full-hover:hover {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xl-full-focus:focus {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xl-n1 {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xl-n1-hover:hover {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xl-n1-focus:focus {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xl-n2 {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xl-n2-hover:hover {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xl-n2-focus:focus {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xl-n3 {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xl-n3-hover:hover {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xl-n3-focus:focus {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xl-n4 {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xl-n4-hover:hover {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xl-n4-focus:focus {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xl-n5 {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xl-n5-hover:hover {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xl-n5-focus:focus {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xl-n6 {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xl-n6-hover:hover {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xl-n6-focus:focus {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xl-n7 {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xl-n7-hover:hover {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xl-n7-focus:focus {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xl-n8 {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xl-n8-hover:hover {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xl-n8-focus:focus {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xl-n10 {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xl-n10-hover:hover {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xl-n10-focus:focus {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xl-n12 {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xl-n12-hover:hover {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xl-n12-focus:focus {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xl-n14 {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xl-n14-hover:hover {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xl-n14-focus:focus {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xl-n16 {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xl-n16-hover:hover {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xl-n16-focus:focus {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xl-n18 {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xl-n18-hover:hover {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xl-n18-focus:focus {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xl-n20 {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xl-n20-hover:hover {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xl-n20-focus:focus {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xl-n24 {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xl-n24-hover:hover {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xl-n24-focus:focus {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xl-n32 {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xl-n32-hover:hover {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xl-n32-focus:focus {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xl-n40 {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xl-n40-hover:hover {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xl-n40-focus:focus {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xl-n48 {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xl-n48-hover:hover {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xl-n48-focus:focus {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xl-n56 {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xl-n56-hover:hover {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xl-n56-focus:focus {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xl-n64 {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xl-n64-hover:hover {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xl-n64-focus:focus {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xl-n72 {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xl-n72-hover:hover {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xl-n72-focus:focus {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xl-n80 {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xl-n80-hover:hover {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xl-n80-focus:focus {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xl-n88 {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xl-n88-hover:hover {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xl-n88-focus:focus {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xl-n96 {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xl-n96-hover:hover {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xl-n96-focus:focus {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xl-npx {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xl-npx-hover:hover {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xl-npx-focus:focus {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xl-n1\/2 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n1\/2-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n1\/2-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n1\/3 {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xl-n1\/3-hover:hover {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xl-n1\/3-focus:focus {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xl-n1\/4 {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xl-n1\/4-hover:hover {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xl-n1\/4-focus:focus {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xl-n1\/5 {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xl-n1\/5-hover:hover {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xl-n1\/5-focus:focus {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xl-n2\/3 {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xl-n2\/3-hover:hover {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xl-n2\/3-focus:focus {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xl-n2\/5 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n2\/5-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n2\/5-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xl-n3\/4 {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xl-n3\/4-hover:hover {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xl-n3\/4-focus:focus {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xl-nfull {
    --bs-translate-y: -100% !important;
  }
  .translate-y-xl-nfull-hover:hover {
    --bs-translate-y: -100% !important;
  }
  .translate-y-xl-nfull-focus:focus {
    --bs-translate-y: -100% !important;
  }
  .translate-x-xl-0 {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xl-0-hover:hover {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xl-0-focus:focus {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xl-1 {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xl-1-hover:hover {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xl-1-focus:focus {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xl-2 {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xl-2-hover:hover {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xl-2-focus:focus {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xl-3 {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xl-3-hover:hover {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xl-3-focus:focus {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xl-4 {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xl-4-hover:hover {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xl-4-focus:focus {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xl-5 {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xl-5-hover:hover {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xl-5-focus:focus {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xl-6 {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xl-6-hover:hover {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xl-6-focus:focus {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xl-7 {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xl-7-hover:hover {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xl-7-focus:focus {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xl-8 {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xl-8-hover:hover {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xl-8-focus:focus {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xl-10 {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xl-10-hover:hover {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xl-10-focus:focus {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xl-12 {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xl-12-hover:hover {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xl-12-focus:focus {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xl-14 {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xl-14-hover:hover {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xl-14-focus:focus {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xl-16 {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xl-16-hover:hover {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xl-16-focus:focus {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xl-18 {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xl-18-hover:hover {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xl-18-focus:focus {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xl-20 {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xl-20-hover:hover {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xl-20-focus:focus {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xl-24 {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xl-24-hover:hover {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xl-24-focus:focus {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xl-32 {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xl-32-hover:hover {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xl-32-focus:focus {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xl-40 {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xl-40-hover:hover {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xl-40-focus:focus {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xl-48 {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xl-48-hover:hover {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xl-48-focus:focus {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xl-56 {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xl-56-hover:hover {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xl-56-focus:focus {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xl-64 {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xl-64-hover:hover {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xl-64-focus:focus {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xl-72 {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xl-72-hover:hover {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xl-72-focus:focus {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xl-80 {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xl-80-hover:hover {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xl-80-focus:focus {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xl-88 {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xl-88-hover:hover {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xl-88-focus:focus {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xl-96 {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xl-96-hover:hover {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xl-96-focus:focus {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xl-px {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xl-px-hover:hover {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xl-px-focus:focus {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xl-1\/2 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-1\/2-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-1\/2-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-1\/3 {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xl-1\/3-hover:hover {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xl-1\/3-focus:focus {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xl-1\/4 {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xl-1\/4-hover:hover {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xl-1\/4-focus:focus {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xl-1\/5 {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xl-1\/5-hover:hover {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xl-1\/5-focus:focus {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xl-2\/3 {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xl-2\/3-hover:hover {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xl-2\/3-focus:focus {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xl-2\/5 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-2\/5-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-2\/5-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xl-3\/4 {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xl-3\/4-hover:hover {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xl-3\/4-focus:focus {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xl-full {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xl-full-hover:hover {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xl-full-focus:focus {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xl-n1 {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xl-n1-hover:hover {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xl-n1-focus:focus {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xl-n2 {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xl-n2-hover:hover {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xl-n2-focus:focus {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xl-n3 {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xl-n3-hover:hover {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xl-n3-focus:focus {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xl-n4 {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xl-n4-hover:hover {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xl-n4-focus:focus {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xl-n5 {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xl-n5-hover:hover {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xl-n5-focus:focus {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xl-n6 {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xl-n6-hover:hover {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xl-n6-focus:focus {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xl-n7 {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xl-n7-hover:hover {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xl-n7-focus:focus {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xl-n8 {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xl-n8-hover:hover {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xl-n8-focus:focus {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xl-n10 {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xl-n10-hover:hover {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xl-n10-focus:focus {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xl-n12 {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xl-n12-hover:hover {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xl-n12-focus:focus {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xl-n14 {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xl-n14-hover:hover {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xl-n14-focus:focus {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xl-n16 {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xl-n16-hover:hover {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xl-n16-focus:focus {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xl-n18 {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xl-n18-hover:hover {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xl-n18-focus:focus {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xl-n20 {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xl-n20-hover:hover {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xl-n20-focus:focus {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xl-n24 {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xl-n24-hover:hover {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xl-n24-focus:focus {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xl-n32 {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xl-n32-hover:hover {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xl-n32-focus:focus {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xl-n40 {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xl-n40-hover:hover {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xl-n40-focus:focus {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xl-n48 {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xl-n48-hover:hover {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xl-n48-focus:focus {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xl-n56 {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xl-n56-hover:hover {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xl-n56-focus:focus {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xl-n64 {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xl-n64-hover:hover {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xl-n64-focus:focus {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xl-n72 {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xl-n72-hover:hover {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xl-n72-focus:focus {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xl-n80 {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xl-n80-hover:hover {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xl-n80-focus:focus {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xl-n88 {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xl-n88-hover:hover {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xl-n88-focus:focus {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xl-n96 {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xl-n96-hover:hover {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xl-n96-focus:focus {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xl-npx {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xl-npx-hover:hover {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xl-npx-focus:focus {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xl-n1\/2 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n1\/2-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n1\/2-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n1\/3 {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xl-n1\/3-hover:hover {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xl-n1\/3-focus:focus {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xl-n1\/4 {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xl-n1\/4-hover:hover {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xl-n1\/4-focus:focus {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xl-n1\/5 {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xl-n1\/5-hover:hover {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xl-n1\/5-focus:focus {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xl-n2\/3 {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xl-n2\/3-hover:hover {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xl-n2\/3-focus:focus {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xl-n2\/5 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n2\/5-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n2\/5-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xl-n3\/4 {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xl-n3\/4-hover:hover {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xl-n3\/4-focus:focus {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xl-nfull {
    --bs-translate-x: -100% !important;
  }
  .translate-x-xl-nfull-hover:hover {
    --bs-translate-x: -100% !important;
  }
  .translate-x-xl-nfull-focus:focus {
    --bs-translate-x: -100% !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .overflow-xxl-auto {
    overflow: auto !important;
  }
  .overflow-xxl-hidden {
    overflow: hidden !important;
  }
  .overflow-xxl-visible {
    overflow: visible !important;
  }
  .overflow-xxl-scroll {
    overflow: scroll !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .shadow-xxl {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xxl-hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xxl-focus:focus {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-xxl-1 {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-1-hover:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-1-focus:focus {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-2 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xxl-2-hover:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xxl-2-focus:focus {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-xxl-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
  }
  .shadow-xxl-inset {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-inset-hover:hover {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-inset-focus:focus {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-outline-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-outline-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-focus-hover:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-focus-focus:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }
  .shadow-xxl-none {
    box-shadow: none !important;
  }
  .shadow-xxl-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-xxl-none-focus:focus {
    box-shadow: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-1 {
    top: 0.25rem !important;
  }
  .top-xxl-2 {
    top: 0.5rem !important;
  }
  .top-xxl-3 {
    top: 0.75rem !important;
  }
  .top-xxl-4 {
    top: 1rem !important;
  }
  .top-xxl-5 {
    top: 1.25rem !important;
  }
  .top-xxl-6 {
    top: 1.5rem !important;
  }
  .top-xxl-7 {
    top: 1.75rem !important;
  }
  .top-xxl-8 {
    top: 2rem !important;
  }
  .top-xxl-10 {
    top: 2.5rem !important;
  }
  .top-xxl-12 {
    top: 3rem !important;
  }
  .top-xxl-14 {
    top: 3.5rem !important;
  }
  .top-xxl-16 {
    top: 4rem !important;
  }
  .top-xxl-18 {
    top: 4.5rem !important;
  }
  .top-xxl-20 {
    top: 5rem !important;
  }
  .top-xxl-24 {
    top: 6rem !important;
  }
  .top-xxl-32 {
    top: 8rem !important;
  }
  .top-xxl-40 {
    top: 10rem !important;
  }
  .top-xxl-48 {
    top: 12rem !important;
  }
  .top-xxl-56 {
    top: 14rem !important;
  }
  .top-xxl-64 {
    top: 16rem !important;
  }
  .top-xxl-72 {
    top: 18rem !important;
  }
  .top-xxl-80 {
    top: 20rem !important;
  }
  .top-xxl-88 {
    top: 22rem !important;
  }
  .top-xxl-96 {
    top: 24rem !important;
  }
  .top-xxl-px {
    top: 1px !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-1 {
    bottom: 0.25rem !important;
  }
  .bottom-xxl-2 {
    bottom: 0.5rem !important;
  }
  .bottom-xxl-3 {
    bottom: 0.75rem !important;
  }
  .bottom-xxl-4 {
    bottom: 1rem !important;
  }
  .bottom-xxl-5 {
    bottom: 1.25rem !important;
  }
  .bottom-xxl-6 {
    bottom: 1.5rem !important;
  }
  .bottom-xxl-7 {
    bottom: 1.75rem !important;
  }
  .bottom-xxl-8 {
    bottom: 2rem !important;
  }
  .bottom-xxl-10 {
    bottom: 2.5rem !important;
  }
  .bottom-xxl-12 {
    bottom: 3rem !important;
  }
  .bottom-xxl-14 {
    bottom: 3.5rem !important;
  }
  .bottom-xxl-16 {
    bottom: 4rem !important;
  }
  .bottom-xxl-18 {
    bottom: 4.5rem !important;
  }
  .bottom-xxl-20 {
    bottom: 5rem !important;
  }
  .bottom-xxl-24 {
    bottom: 6rem !important;
  }
  .bottom-xxl-32 {
    bottom: 8rem !important;
  }
  .bottom-xxl-40 {
    bottom: 10rem !important;
  }
  .bottom-xxl-48 {
    bottom: 12rem !important;
  }
  .bottom-xxl-56 {
    bottom: 14rem !important;
  }
  .bottom-xxl-64 {
    bottom: 16rem !important;
  }
  .bottom-xxl-72 {
    bottom: 18rem !important;
  }
  .bottom-xxl-80 {
    bottom: 20rem !important;
  }
  .bottom-xxl-88 {
    bottom: 22rem !important;
  }
  .bottom-xxl-96 {
    bottom: 24rem !important;
  }
  .bottom-xxl-px {
    bottom: 1px !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .start-xxl-0 {
    left: 0 !important;
  }
  .start-xxl-1 {
    left: 0.25rem !important;
  }
  .start-xxl-2 {
    left: 0.5rem !important;
  }
  .start-xxl-3 {
    left: 0.75rem !important;
  }
  .start-xxl-4 {
    left: 1rem !important;
  }
  .start-xxl-5 {
    left: 1.25rem !important;
  }
  .start-xxl-6 {
    left: 1.5rem !important;
  }
  .start-xxl-7 {
    left: 1.75rem !important;
  }
  .start-xxl-8 {
    left: 2rem !important;
  }
  .start-xxl-10 {
    left: 2.5rem !important;
  }
  .start-xxl-12 {
    left: 3rem !important;
  }
  .start-xxl-14 {
    left: 3.5rem !important;
  }
  .start-xxl-16 {
    left: 4rem !important;
  }
  .start-xxl-18 {
    left: 4.5rem !important;
  }
  .start-xxl-20 {
    left: 5rem !important;
  }
  .start-xxl-24 {
    left: 6rem !important;
  }
  .start-xxl-32 {
    left: 8rem !important;
  }
  .start-xxl-40 {
    left: 10rem !important;
  }
  .start-xxl-48 {
    left: 12rem !important;
  }
  .start-xxl-56 {
    left: 14rem !important;
  }
  .start-xxl-64 {
    left: 16rem !important;
  }
  .start-xxl-72 {
    left: 18rem !important;
  }
  .start-xxl-80 {
    left: 20rem !important;
  }
  .start-xxl-88 {
    left: 22rem !important;
  }
  .start-xxl-96 {
    left: 24rem !important;
  }
  .start-xxl-px {
    left: 1px !important;
  }
  .start-xxl-50 {
    left: 50% !important;
  }
  .start-xxl-100 {
    left: 100% !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-1 {
    right: 0.25rem !important;
  }
  .end-xxl-2 {
    right: 0.5rem !important;
  }
  .end-xxl-3 {
    right: 0.75rem !important;
  }
  .end-xxl-4 {
    right: 1rem !important;
  }
  .end-xxl-5 {
    right: 1.25rem !important;
  }
  .end-xxl-6 {
    right: 1.5rem !important;
  }
  .end-xxl-7 {
    right: 1.75rem !important;
  }
  .end-xxl-8 {
    right: 2rem !important;
  }
  .end-xxl-10 {
    right: 2.5rem !important;
  }
  .end-xxl-12 {
    right: 3rem !important;
  }
  .end-xxl-14 {
    right: 3.5rem !important;
  }
  .end-xxl-16 {
    right: 4rem !important;
  }
  .end-xxl-18 {
    right: 4.5rem !important;
  }
  .end-xxl-20 {
    right: 5rem !important;
  }
  .end-xxl-24 {
    right: 6rem !important;
  }
  .end-xxl-32 {
    right: 8rem !important;
  }
  .end-xxl-40 {
    right: 10rem !important;
  }
  .end-xxl-48 {
    right: 12rem !important;
  }
  .end-xxl-56 {
    right: 14rem !important;
  }
  .end-xxl-64 {
    right: 16rem !important;
  }
  .end-xxl-72 {
    right: 18rem !important;
  }
  .end-xxl-80 {
    right: 20rem !important;
  }
  .end-xxl-88 {
    right: 22rem !important;
  }
  .end-xxl-96 {
    right: 24rem !important;
  }
  .end-xxl-px {
    right: 1px !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .border-top-xxl-0 {
    border-top-width: 0 !important;
  }
  .border-top-xxl-0-hover:hover {
    border-top-width: 0 !important;
  }
  .border-top-xxl-0-focus:focus {
    border-top-width: 0 !important;
  }
  .border-top-xxl {
    border-top-width: 1px !important;
  }
  .border-top-xxl-hover:hover {
    border-top-width: 1px !important;
  }
  .border-top-xxl-focus:focus {
    border-top-width: 1px !important;
  }
  .border-top-xxl-2 {
    border-top-width: 2px !important;
  }
  .border-top-xxl-2-hover:hover {
    border-top-width: 2px !important;
  }
  .border-top-xxl-2-focus:focus {
    border-top-width: 2px !important;
  }
  .border-top-xxl-3 {
    border-top-width: 3px !important;
  }
  .border-top-xxl-3-hover:hover {
    border-top-width: 3px !important;
  }
  .border-top-xxl-3-focus:focus {
    border-top-width: 3px !important;
  }
  .border-top-xxl-4 {
    border-top-width: 4px !important;
  }
  .border-top-xxl-4-hover:hover {
    border-top-width: 4px !important;
  }
  .border-top-xxl-4-focus:focus {
    border-top-width: 4px !important;
  }
  .border-top-xxl-5 {
    border-top-width: 5px !important;
  }
  .border-top-xxl-5-hover:hover {
    border-top-width: 5px !important;
  }
  .border-top-xxl-5-focus:focus {
    border-top-width: 5px !important;
  }
  .border-top-xxl-6 {
    border-top-width: 6px !important;
  }
  .border-top-xxl-6-hover:hover {
    border-top-width: 6px !important;
  }
  .border-top-xxl-6-focus:focus {
    border-top-width: 6px !important;
  }
  .border-top-xxl-7 {
    border-top-width: 7px !important;
  }
  .border-top-xxl-7-hover:hover {
    border-top-width: 7px !important;
  }
  .border-top-xxl-7-focus:focus {
    border-top-width: 7px !important;
  }
  .border-top-xxl-8 {
    border-top-width: 8px !important;
  }
  .border-top-xxl-8-hover:hover {
    border-top-width: 8px !important;
  }
  .border-top-xxl-8-focus:focus {
    border-top-width: 8px !important;
  }
  .border-end-xxl-0 {
    border-right-width: 0 !important;
  }
  .border-end-xxl-0-hover:hover {
    border-right-width: 0 !important;
  }
  .border-end-xxl-0-focus:focus {
    border-right-width: 0 !important;
  }
  .border-end-xxl {
    border-right-width: 1px !important;
  }
  .border-end-xxl-hover:hover {
    border-right-width: 1px !important;
  }
  .border-end-xxl-focus:focus {
    border-right-width: 1px !important;
  }
  .border-end-xxl-2 {
    border-right-width: 2px !important;
  }
  .border-end-xxl-2-hover:hover {
    border-right-width: 2px !important;
  }
  .border-end-xxl-2-focus:focus {
    border-right-width: 2px !important;
  }
  .border-end-xxl-3 {
    border-right-width: 3px !important;
  }
  .border-end-xxl-3-hover:hover {
    border-right-width: 3px !important;
  }
  .border-end-xxl-3-focus:focus {
    border-right-width: 3px !important;
  }
  .border-end-xxl-4 {
    border-right-width: 4px !important;
  }
  .border-end-xxl-4-hover:hover {
    border-right-width: 4px !important;
  }
  .border-end-xxl-4-focus:focus {
    border-right-width: 4px !important;
  }
  .border-end-xxl-5 {
    border-right-width: 5px !important;
  }
  .border-end-xxl-5-hover:hover {
    border-right-width: 5px !important;
  }
  .border-end-xxl-5-focus:focus {
    border-right-width: 5px !important;
  }
  .border-end-xxl-6 {
    border-right-width: 6px !important;
  }
  .border-end-xxl-6-hover:hover {
    border-right-width: 6px !important;
  }
  .border-end-xxl-6-focus:focus {
    border-right-width: 6px !important;
  }
  .border-end-xxl-7 {
    border-right-width: 7px !important;
  }
  .border-end-xxl-7-hover:hover {
    border-right-width: 7px !important;
  }
  .border-end-xxl-7-focus:focus {
    border-right-width: 7px !important;
  }
  .border-end-xxl-8 {
    border-right-width: 8px !important;
  }
  .border-end-xxl-8-hover:hover {
    border-right-width: 8px !important;
  }
  .border-end-xxl-8-focus:focus {
    border-right-width: 8px !important;
  }
  .border-bottom-xxl-0 {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xxl-0-hover:hover {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xxl-0-focus:focus {
    border-bottom-width: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xxl-hover:hover {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xxl-focus:focus {
    border-bottom-width: 1px !important;
  }
  .border-bottom-xxl-2 {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xxl-2-hover:hover {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xxl-2-focus:focus {
    border-bottom-width: 2px !important;
  }
  .border-bottom-xxl-3 {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xxl-3-hover:hover {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xxl-3-focus:focus {
    border-bottom-width: 3px !important;
  }
  .border-bottom-xxl-4 {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xxl-4-hover:hover {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xxl-4-focus:focus {
    border-bottom-width: 4px !important;
  }
  .border-bottom-xxl-5 {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xxl-5-hover:hover {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xxl-5-focus:focus {
    border-bottom-width: 5px !important;
  }
  .border-bottom-xxl-6 {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xxl-6-hover:hover {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xxl-6-focus:focus {
    border-bottom-width: 6px !important;
  }
  .border-bottom-xxl-7 {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xxl-7-hover:hover {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xxl-7-focus:focus {
    border-bottom-width: 7px !important;
  }
  .border-bottom-xxl-8 {
    border-bottom-width: 8px !important;
  }
  .border-bottom-xxl-8-hover:hover {
    border-bottom-width: 8px !important;
  }
  .border-bottom-xxl-8-focus:focus {
    border-bottom-width: 8px !important;
  }
  .border-start-xxl-0 {
    border-left-width: 0 !important;
  }
  .border-start-xxl-0-hover:hover {
    border-left-width: 0 !important;
  }
  .border-start-xxl-0-focus:focus {
    border-left-width: 0 !important;
  }
  .border-start-xxl {
    border-left-width: 1px !important;
  }
  .border-start-xxl-hover:hover {
    border-left-width: 1px !important;
  }
  .border-start-xxl-focus:focus {
    border-left-width: 1px !important;
  }
  .border-start-xxl-2 {
    border-left-width: 2px !important;
  }
  .border-start-xxl-2-hover:hover {
    border-left-width: 2px !important;
  }
  .border-start-xxl-2-focus:focus {
    border-left-width: 2px !important;
  }
  .border-start-xxl-3 {
    border-left-width: 3px !important;
  }
  .border-start-xxl-3-hover:hover {
    border-left-width: 3px !important;
  }
  .border-start-xxl-3-focus:focus {
    border-left-width: 3px !important;
  }
  .border-start-xxl-4 {
    border-left-width: 4px !important;
  }
  .border-start-xxl-4-hover:hover {
    border-left-width: 4px !important;
  }
  .border-start-xxl-4-focus:focus {
    border-left-width: 4px !important;
  }
  .border-start-xxl-5 {
    border-left-width: 5px !important;
  }
  .border-start-xxl-5-hover:hover {
    border-left-width: 5px !important;
  }
  .border-start-xxl-5-focus:focus {
    border-left-width: 5px !important;
  }
  .border-start-xxl-6 {
    border-left-width: 6px !important;
  }
  .border-start-xxl-6-hover:hover {
    border-left-width: 6px !important;
  }
  .border-start-xxl-6-focus:focus {
    border-left-width: 6px !important;
  }
  .border-start-xxl-7 {
    border-left-width: 7px !important;
  }
  .border-start-xxl-7-hover:hover {
    border-left-width: 7px !important;
  }
  .border-start-xxl-7-focus:focus {
    border-left-width: 7px !important;
  }
  .border-start-xxl-8 {
    border-left-width: 8px !important;
  }
  .border-start-xxl-8-hover:hover {
    border-left-width: 8px !important;
  }
  .border-start-xxl-8-focus:focus {
    border-left-width: 8px !important;
  }
  .border-xxl-primary {
    border-color: #1A16C0 !important;
  }
  .border-xxl-primary-hover:hover {
    border-color: #1A16C0 !important;
  }
  .border-xxl-primary-focus:focus {
    border-color: #1A16C0 !important;
  }
  .border-xxl-primary-focus-within:focus-within {
    border-color: #1A16C0 !important;
  }
  .border-xxl-secondary {
    border-color: #E4ECF7 !important;
  }
  .border-xxl-secondary-hover:hover {
    border-color: #E4ECF7 !important;
  }
  .border-xxl-secondary-focus:focus {
    border-color: #E4ECF7 !important;
  }
  .border-xxl-secondary-focus-within:focus-within {
    border-color: #E4ECF7 !important;
  }
  .border-xxl-success {
    border-color: #4AAE8C !important;
  }
  .border-xxl-success-hover:hover {
    border-color: #4AAE8C !important;
  }
  .border-xxl-success-focus:focus {
    border-color: #4AAE8C !important;
  }
  .border-xxl-success-focus-within:focus-within {
    border-color: #4AAE8C !important;
  }
  .border-xxl-info {
    border-color: #68B8F2 !important;
  }
  .border-xxl-info-hover:hover {
    border-color: #68B8F2 !important;
  }
  .border-xxl-info-focus:focus {
    border-color: #68B8F2 !important;
  }
  .border-xxl-info-focus-within:focus-within {
    border-color: #68B8F2 !important;
  }
  .border-xxl-warning {
    border-color: #ED9830 !important;
  }
  .border-xxl-warning-hover:hover {
    border-color: #ED9830 !important;
  }
  .border-xxl-warning-focus:focus {
    border-color: #ED9830 !important;
  }
  .border-xxl-warning-focus-within:focus-within {
    border-color: #ED9830 !important;
  }
  .border-xxl-danger {
    border-color: #F16063 !important;
  }
  .border-xxl-danger-hover:hover {
    border-color: #F16063 !important;
  }
  .border-xxl-danger-focus:focus {
    border-color: #F16063 !important;
  }
  .border-xxl-danger-focus-within:focus-within {
    border-color: #F16063 !important;
  }
  .border-xxl-light {
    border-color: #F7FAFC !important;
  }
  .border-xxl-light-hover:hover {
    border-color: #F7FAFC !important;
  }
  .border-xxl-light-focus:focus {
    border-color: #F7FAFC !important;
  }
  .border-xxl-light-focus-within:focus-within {
    border-color: #F7FAFC !important;
  }
  .border-xxl-dark {
    border-color: #27272E !important;
  }
  .border-xxl-dark-hover:hover {
    border-color: #27272E !important;
  }
  .border-xxl-dark-focus:focus {
    border-color: #27272E !important;
  }
  .border-xxl-dark-focus-within:focus-within {
    border-color: #27272E !important;
  }
  .border-xxl-transparent {
    border-color: transparent !important;
  }
  .border-xxl-transparent-hover:hover {
    border-color: transparent !important;
  }
  .border-xxl-transparent-focus:focus {
    border-color: transparent !important;
  }
  .border-xxl-transparent-focus-within:focus-within {
    border-color: transparent !important;
  }
  .border-xxl-current {
    border-color: currentColor !important;
  }
  .border-xxl-current-hover:hover {
    border-color: currentColor !important;
  }
  .border-xxl-current-focus:focus {
    border-color: currentColor !important;
  }
  .border-xxl-current-focus-within:focus-within {
    border-color: currentColor !important;
  }
  .border-xxl-base {
    border-color: #E2E8F0 !important;
  }
  .border-xxl-base-hover:hover {
    border-color: #E2E8F0 !important;
  }
  .border-xxl-base-focus:focus {
    border-color: #E2E8F0 !important;
  }
  .border-xxl-base-focus-within:focus-within {
    border-color: #E2E8F0 !important;
  }
  .border-xxl-body {
    border-color: #fff !important;
  }
  .border-xxl-body-hover:hover {
    border-color: #fff !important;
  }
  .border-xxl-body-focus:focus {
    border-color: #fff !important;
  }
  .border-xxl-body-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xxl-card {
    border-color: #fff !important;
  }
  .border-xxl-card-hover:hover {
    border-color: #fff !important;
  }
  .border-xxl-card-focus:focus {
    border-color: #fff !important;
  }
  .border-xxl-card-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xxl-black {
    border-color: #000 !important;
  }
  .border-xxl-black-hover:hover {
    border-color: #000 !important;
  }
  .border-xxl-black-focus:focus {
    border-color: #000 !important;
  }
  .border-xxl-black-focus-within:focus-within {
    border-color: #000 !important;
  }
  .border-xxl-white {
    border-color: #fff !important;
  }
  .border-xxl-white-hover:hover {
    border-color: #fff !important;
  }
  .border-xxl-white-focus:focus {
    border-color: #fff !important;
  }
  .border-xxl-white-focus-within:focus-within {
    border-color: #fff !important;
  }
  .border-xxl-0 {
    border-width: 0 !important;
  }
  .border-xxl-0-hover:hover {
    border-width: 0 !important;
  }
  .border-xxl-0-focus:focus {
    border-width: 0 !important;
  }
  .border-xxl {
    border-width: 1px !important;
  }
  .border-xxl-hover:hover {
    border-width: 1px !important;
  }
  .border-xxl-focus:focus {
    border-width: 1px !important;
  }
  .border-xxl-2 {
    border-width: 2px !important;
  }
  .border-xxl-2-hover:hover {
    border-width: 2px !important;
  }
  .border-xxl-2-focus:focus {
    border-width: 2px !important;
  }
  .border-xxl-3 {
    border-width: 3px !important;
  }
  .border-xxl-3-hover:hover {
    border-width: 3px !important;
  }
  .border-xxl-3-focus:focus {
    border-width: 3px !important;
  }
  .border-xxl-4 {
    border-width: 4px !important;
  }
  .border-xxl-4-hover:hover {
    border-width: 4px !important;
  }
  .border-xxl-4-focus:focus {
    border-width: 4px !important;
  }
  .border-xxl-5 {
    border-width: 5px !important;
  }
  .border-xxl-5-hover:hover {
    border-width: 5px !important;
  }
  .border-xxl-5-focus:focus {
    border-width: 5px !important;
  }
  .border-xxl-6 {
    border-width: 6px !important;
  }
  .border-xxl-6-hover:hover {
    border-width: 6px !important;
  }
  .border-xxl-6-focus:focus {
    border-width: 6px !important;
  }
  .border-xxl-7 {
    border-width: 7px !important;
  }
  .border-xxl-7-hover:hover {
    border-width: 7px !important;
  }
  .border-xxl-7-focus:focus {
    border-width: 7px !important;
  }
  .border-xxl-8 {
    border-width: 8px !important;
  }
  .border-xxl-8-hover:hover {
    border-width: 8px !important;
  }
  .border-xxl-8-focus:focus {
    border-width: 8px !important;
  }
  .w-xxl-0 {
    width: 0 !important;
  }
  .w-xxl-1 {
    width: 0.25rem !important;
  }
  .w-xxl-2 {
    width: 0.5rem !important;
  }
  .w-xxl-3 {
    width: 0.75rem !important;
  }
  .w-xxl-4 {
    width: 1rem !important;
  }
  .w-xxl-5 {
    width: 1.25rem !important;
  }
  .w-xxl-6 {
    width: 1.5rem !important;
  }
  .w-xxl-7 {
    width: 1.75rem !important;
  }
  .w-xxl-8 {
    width: 2rem !important;
  }
  .w-xxl-10 {
    width: 2.5rem !important;
  }
  .w-xxl-12 {
    width: 3rem !important;
  }
  .w-xxl-14 {
    width: 3.5rem !important;
  }
  .w-xxl-16 {
    width: 4rem !important;
  }
  .w-xxl-18 {
    width: 4.5rem !important;
  }
  .w-xxl-20 {
    width: 5rem !important;
  }
  .w-xxl-24 {
    width: 6rem !important;
  }
  .w-xxl-32 {
    width: 8rem !important;
  }
  .w-xxl-40 {
    width: 10rem !important;
  }
  .w-xxl-48 {
    width: 12rem !important;
  }
  .w-xxl-56 {
    width: 14rem !important;
  }
  .w-xxl-64 {
    width: 16rem !important;
  }
  .w-xxl-72 {
    width: 18rem !important;
  }
  .w-xxl-80 {
    width: 20rem !important;
  }
  .w-xxl-88 {
    width: 22rem !important;
  }
  .w-xxl-96 {
    width: 24rem !important;
  }
  .w-xxl-px {
    width: 1px !important;
  }
  .w-xxl-1\/2 {
    width: 50% !important;
  }
  .w-xxl-1\/3 {
    width: 33.3333333333% !important;
  }
  .w-xxl-2\/3 {
    width: 66.6666666667% !important;
  }
  .w-xxl-1\/4 {
    width: 25% !important;
  }
  .w-xxl-2\/4 {
    width: 50% !important;
  }
  .w-xxl-3\/4 {
    width: 75% !important;
  }
  .w-xxl-1\/5 {
    width: 20% !important;
  }
  .w-xxl-2\/5 {
    width: 40% !important;
  }
  .w-xxl-3\/5 {
    width: 60% !important;
  }
  .w-xxl-4\/5 {
    width: 80% !important;
  }
  .w-xxl-1\/6 {
    width: 16.6666666667% !important;
  }
  .w-xxl-2\/6 {
    width: 33.3333333333% !important;
  }
  .w-xxl-3\/6 {
    width: 50% !important;
  }
  .w-xxl-4\/6 {
    width: 66.6666666667% !important;
  }
  .w-xxl-5\/6 {
    width: 83.3333333333% !important;
  }
  .w-xxl-11\/10 {
    width: 110% !important;
  }
  .w-xxl-12\/10 {
    width: 120% !important;
  }
  .w-xxl-13\/10 {
    width: 130% !important;
  }
  .w-xxl-14\/10 {
    width: 140% !important;
  }
  .w-xxl-15\/10 {
    width: 150% !important;
  }
  .w-xxl-screen-sm {
    width: 640px !important;
  }
  .w-xxl-screen-md {
    width: 768px !important;
  }
  .w-xxl-screen-lg {
    width: 1024px !important;
  }
  .w-xxl-screen-xl {
    width: 1280px !important;
  }
  .w-xxl-screen-xxl {
    width: 1536px !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-full {
    width: 100% !important;
  }
  .w-xxl-screen {
    width: 100vw !important;
  }
  .w-xxl-min {
    width: min-content !important;
  }
  .w-xxl-max {
    width: max-content !important;
  }
  .max-w-xxl-screen-sm {
    max-width: 640px !important;
  }
  .max-w-xxl-screen-md {
    max-width: 768px !important;
  }
  .max-w-xxl-screen-lg {
    max-width: 1024px !important;
  }
  .max-w-xxl-screen-xl {
    max-width: 1280px !important;
  }
  .max-w-xxl-screen-xxl {
    max-width: 1536px !important;
  }
  .max-w-xxl-0 {
    max-width: 0 !important;
  }
  .max-w-xxl-full {
    max-width: 100% !important;
  }
  .max-w-xxl-read {
    max-width: 65ch !important;
  }
  .max-w-xxl-min {
    max-width: min-content !important;
  }
  .max-w-xxl-max {
    max-width: max-content !important;
  }
  .h-xxl-0 {
    height: 0 !important;
  }
  .h-xxl-1 {
    height: 0.25rem !important;
  }
  .h-xxl-2 {
    height: 0.5rem !important;
  }
  .h-xxl-3 {
    height: 0.75rem !important;
  }
  .h-xxl-4 {
    height: 1rem !important;
  }
  .h-xxl-5 {
    height: 1.25rem !important;
  }
  .h-xxl-6 {
    height: 1.5rem !important;
  }
  .h-xxl-7 {
    height: 1.75rem !important;
  }
  .h-xxl-8 {
    height: 2rem !important;
  }
  .h-xxl-10 {
    height: 2.5rem !important;
  }
  .h-xxl-12 {
    height: 3rem !important;
  }
  .h-xxl-14 {
    height: 3.5rem !important;
  }
  .h-xxl-16 {
    height: 4rem !important;
  }
  .h-xxl-18 {
    height: 4.5rem !important;
  }
  .h-xxl-20 {
    height: 5rem !important;
  }
  .h-xxl-24 {
    height: 6rem !important;
  }
  .h-xxl-32 {
    height: 8rem !important;
  }
  .h-xxl-40 {
    height: 10rem !important;
  }
  .h-xxl-48 {
    height: 12rem !important;
  }
  .h-xxl-56 {
    height: 14rem !important;
  }
  .h-xxl-64 {
    height: 16rem !important;
  }
  .h-xxl-72 {
    height: 18rem !important;
  }
  .h-xxl-80 {
    height: 20rem !important;
  }
  .h-xxl-88 {
    height: 22rem !important;
  }
  .h-xxl-96 {
    height: 24rem !important;
  }
  .h-xxl-px {
    height: 1px !important;
  }
  .h-xxl-1\/2 {
    height: 50% !important;
  }
  .h-xxl-1\/3 {
    height: 33.3333333333% !important;
  }
  .h-xxl-2\/3 {
    height: 66.6666666667% !important;
  }
  .h-xxl-1\/4 {
    height: 25% !important;
  }
  .h-xxl-2\/4 {
    height: 50% !important;
  }
  .h-xxl-3\/4 {
    height: 75% !important;
  }
  .h-xxl-1\/5 {
    height: 20% !important;
  }
  .h-xxl-2\/5 {
    height: 40% !important;
  }
  .h-xxl-3\/5 {
    height: 60% !important;
  }
  .h-xxl-4\/5 {
    height: 80% !important;
  }
  .h-xxl-1\/6 {
    height: 16.6666666667% !important;
  }
  .h-xxl-2\/6 {
    height: 33.3333333333% !important;
  }
  .h-xxl-3\/6 {
    height: 50% !important;
  }
  .h-xxl-4\/6 {
    height: 66.6666666667% !important;
  }
  .h-xxl-5\/6 {
    height: 83.3333333333% !important;
  }
  .h-xxl-11\/10 {
    height: 110% !important;
  }
  .h-xxl-12\/10 {
    height: 120% !important;
  }
  .h-xxl-13\/10 {
    height: 130% !important;
  }
  .h-xxl-14\/10 {
    height: 140% !important;
  }
  .h-xxl-15\/10 {
    height: 150% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .h-xxl-full {
    height: 100% !important;
  }
  .h-xxl-screen {
    height: 100vh !important;
  }
  .max-h-xxl-0 {
    max-height: 0 !important;
  }
  .max-h-xxl-1 {
    max-height: 0.25rem !important;
  }
  .max-h-xxl-2 {
    max-height: 0.5rem !important;
  }
  .max-h-xxl-3 {
    max-height: 0.75rem !important;
  }
  .max-h-xxl-4 {
    max-height: 1rem !important;
  }
  .max-h-xxl-5 {
    max-height: 1.25rem !important;
  }
  .max-h-xxl-6 {
    max-height: 1.5rem !important;
  }
  .max-h-xxl-7 {
    max-height: 1.75rem !important;
  }
  .max-h-xxl-8 {
    max-height: 2rem !important;
  }
  .max-h-xxl-10 {
    max-height: 2.5rem !important;
  }
  .max-h-xxl-12 {
    max-height: 3rem !important;
  }
  .max-h-xxl-14 {
    max-height: 3.5rem !important;
  }
  .max-h-xxl-16 {
    max-height: 4rem !important;
  }
  .max-h-xxl-18 {
    max-height: 4.5rem !important;
  }
  .max-h-xxl-20 {
    max-height: 5rem !important;
  }
  .max-h-xxl-24 {
    max-height: 6rem !important;
  }
  .max-h-xxl-32 {
    max-height: 8rem !important;
  }
  .max-h-xxl-40 {
    max-height: 10rem !important;
  }
  .max-h-xxl-48 {
    max-height: 12rem !important;
  }
  .max-h-xxl-56 {
    max-height: 14rem !important;
  }
  .max-h-xxl-64 {
    max-height: 16rem !important;
  }
  .max-h-xxl-72 {
    max-height: 18rem !important;
  }
  .max-h-xxl-80 {
    max-height: 20rem !important;
  }
  .max-h-xxl-88 {
    max-height: 22rem !important;
  }
  .max-h-xxl-96 {
    max-height: 24rem !important;
  }
  .max-h-xxl-px {
    max-height: 1px !important;
  }
  .max-h-xxl-full {
    max-height: 100% !important;
  }
  .max-h-xxl-screen {
    max-height: 100vh !important;
  }
  .flex-xxl-1 {
    flex: 1 1 0% !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-initial {
    flex: 0 1 auto !important;
  }
  .flex-xxl-none {
    flex: none !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-10 {
    gap: 2.5rem !important;
  }
  .gap-xxl-12 {
    gap: 3rem !important;
  }
  .gap-xxl-14 {
    gap: 3.5rem !important;
  }
  .gap-xxl-16 {
    gap: 4rem !important;
  }
  .gap-xxl-18 {
    gap: 4.5rem !important;
  }
  .gap-xxl-20 {
    gap: 5rem !important;
  }
  .gap-xxl-24 {
    gap: 6rem !important;
  }
  .gap-xxl-32 {
    gap: 8rem !important;
  }
  .gap-xxl-40 {
    gap: 10rem !important;
  }
  .gap-xxl-48 {
    gap: 12rem !important;
  }
  .gap-xxl-56 {
    gap: 14rem !important;
  }
  .gap-xxl-64 {
    gap: 16rem !important;
  }
  .gap-xxl-72 {
    gap: 18rem !important;
  }
  .gap-xxl-80 {
    gap: 20rem !important;
  }
  .gap-xxl-88 {
    gap: 22rem !important;
  }
  .gap-xxl-96 {
    gap: 24rem !important;
  }
  .gap-xxl-px {
    gap: 1px !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-evenly {
    align-content: space-evenly !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-10 {
    margin: 2.5rem !important;
  }
  .m-xxl-12 {
    margin: 3rem !important;
  }
  .m-xxl-14 {
    margin: 3.5rem !important;
  }
  .m-xxl-16 {
    margin: 4rem !important;
  }
  .m-xxl-18 {
    margin: 4.5rem !important;
  }
  .m-xxl-20 {
    margin: 5rem !important;
  }
  .m-xxl-24 {
    margin: 6rem !important;
  }
  .m-xxl-32 {
    margin: 8rem !important;
  }
  .m-xxl-40 {
    margin: 10rem !important;
  }
  .m-xxl-48 {
    margin: 12rem !important;
  }
  .m-xxl-56 {
    margin: 14rem !important;
  }
  .m-xxl-64 {
    margin: 16rem !important;
  }
  .m-xxl-72 {
    margin: 18rem !important;
  }
  .m-xxl-80 {
    margin: 20rem !important;
  }
  .m-xxl-88 {
    margin: 22rem !important;
  }
  .m-xxl-96 {
    margin: 24rem !important;
  }
  .m-xxl-px {
    margin: 1px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-40 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-48 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  .mx-xxl-56 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-64 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  .mx-xxl-72 {
    margin-right: 18rem !important;
    margin-left: 18rem !important;
  }
  .mx-xxl-80 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-88 {
    margin-right: 22rem !important;
    margin-left: 22rem !important;
  }
  .mx-xxl-96 {
    margin-right: 24rem !important;
    margin-left: 24rem !important;
  }
  .mx-xxl-px {
    margin-right: 1px !important;
    margin-left: 1px !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  .my-xxl-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  .my-xxl-72 {
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }
  .my-xxl-80 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-88 {
    margin-top: 22rem !important;
    margin-bottom: 22rem !important;
  }
  .my-xxl-96 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }
  .my-xxl-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2rem !important;
  }
  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-12 {
    margin-top: 3rem !important;
  }
  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-16 {
    margin-top: 4rem !important;
  }
  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-20 {
    margin-top: 5rem !important;
  }
  .mt-xxl-24 {
    margin-top: 6rem !important;
  }
  .mt-xxl-32 {
    margin-top: 8rem !important;
  }
  .mt-xxl-40 {
    margin-top: 10rem !important;
  }
  .mt-xxl-48 {
    margin-top: 12rem !important;
  }
  .mt-xxl-56 {
    margin-top: 14rem !important;
  }
  .mt-xxl-64 {
    margin-top: 16rem !important;
  }
  .mt-xxl-72 {
    margin-top: 18rem !important;
  }
  .mt-xxl-80 {
    margin-top: 20rem !important;
  }
  .mt-xxl-88 {
    margin-top: 22rem !important;
  }
  .mt-xxl-96 {
    margin-top: 24rem !important;
  }
  .mt-xxl-px {
    margin-top: 1px !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 2rem !important;
  }
  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-12 {
    margin-right: 3rem !important;
  }
  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-16 {
    margin-right: 4rem !important;
  }
  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-20 {
    margin-right: 5rem !important;
  }
  .me-xxl-24 {
    margin-right: 6rem !important;
  }
  .me-xxl-32 {
    margin-right: 8rem !important;
  }
  .me-xxl-40 {
    margin-right: 10rem !important;
  }
  .me-xxl-48 {
    margin-right: 12rem !important;
  }
  .me-xxl-56 {
    margin-right: 14rem !important;
  }
  .me-xxl-64 {
    margin-right: 16rem !important;
  }
  .me-xxl-72 {
    margin-right: 18rem !important;
  }
  .me-xxl-80 {
    margin-right: 20rem !important;
  }
  .me-xxl-88 {
    margin-right: 22rem !important;
  }
  .me-xxl-96 {
    margin-right: 24rem !important;
  }
  .me-xxl-px {
    margin-right: 1px !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-24 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-32 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-40 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-48 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-56 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-64 {
    margin-bottom: 16rem !important;
  }
  .mb-xxl-72 {
    margin-bottom: 18rem !important;
  }
  .mb-xxl-80 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-88 {
    margin-bottom: 22rem !important;
  }
  .mb-xxl-96 {
    margin-bottom: 24rem !important;
  }
  .mb-xxl-px {
    margin-bottom: 1px !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2rem !important;
  }
  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-12 {
    margin-left: 3rem !important;
  }
  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-16 {
    margin-left: 4rem !important;
  }
  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-20 {
    margin-left: 5rem !important;
  }
  .ms-xxl-24 {
    margin-left: 6rem !important;
  }
  .ms-xxl-32 {
    margin-left: 8rem !important;
  }
  .ms-xxl-40 {
    margin-left: 10rem !important;
  }
  .ms-xxl-48 {
    margin-left: 12rem !important;
  }
  .ms-xxl-56 {
    margin-left: 14rem !important;
  }
  .ms-xxl-64 {
    margin-left: 16rem !important;
  }
  .ms-xxl-72 {
    margin-left: 18rem !important;
  }
  .ms-xxl-80 {
    margin-left: 20rem !important;
  }
  .ms-xxl-88 {
    margin-left: 22rem !important;
  }
  .ms-xxl-96 {
    margin-left: 24rem !important;
  }
  .ms-xxl-px {
    margin-left: 1px !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-10 {
    padding: 2.5rem !important;
  }
  .p-xxl-12 {
    padding: 3rem !important;
  }
  .p-xxl-14 {
    padding: 3.5rem !important;
  }
  .p-xxl-16 {
    padding: 4rem !important;
  }
  .p-xxl-18 {
    padding: 4.5rem !important;
  }
  .p-xxl-20 {
    padding: 5rem !important;
  }
  .p-xxl-24 {
    padding: 6rem !important;
  }
  .p-xxl-32 {
    padding: 8rem !important;
  }
  .p-xxl-40 {
    padding: 10rem !important;
  }
  .p-xxl-48 {
    padding: 12rem !important;
  }
  .p-xxl-56 {
    padding: 14rem !important;
  }
  .p-xxl-64 {
    padding: 16rem !important;
  }
  .p-xxl-72 {
    padding: 18rem !important;
  }
  .p-xxl-80 {
    padding: 20rem !important;
  }
  .p-xxl-88 {
    padding: 22rem !important;
  }
  .p-xxl-96 {
    padding: 24rem !important;
  }
  .p-xxl-px {
    padding: 1px !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-24 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-32 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-40 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-48 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  .px-xxl-56 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .px-xxl-64 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  .px-xxl-72 {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
  .px-xxl-80 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxl-88 {
    padding-right: 22rem !important;
    padding-left: 22rem !important;
  }
  .px-xxl-96 {
    padding-right: 24rem !important;
    padding-left: 24rem !important;
  }
  .px-xxl-px {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .py-xxl-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .py-xxl-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .py-xxl-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .py-xxl-80 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxl-88 {
    padding-top: 22rem !important;
    padding-bottom: 22rem !important;
  }
  .py-xxl-96 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }
  .py-xxl-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2rem !important;
  }
  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-12 {
    padding-top: 3rem !important;
  }
  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-16 {
    padding-top: 4rem !important;
  }
  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-20 {
    padding-top: 5rem !important;
  }
  .pt-xxl-24 {
    padding-top: 6rem !important;
  }
  .pt-xxl-32 {
    padding-top: 8rem !important;
  }
  .pt-xxl-40 {
    padding-top: 10rem !important;
  }
  .pt-xxl-48 {
    padding-top: 12rem !important;
  }
  .pt-xxl-56 {
    padding-top: 14rem !important;
  }
  .pt-xxl-64 {
    padding-top: 16rem !important;
  }
  .pt-xxl-72 {
    padding-top: 18rem !important;
  }
  .pt-xxl-80 {
    padding-top: 20rem !important;
  }
  .pt-xxl-88 {
    padding-top: 22rem !important;
  }
  .pt-xxl-96 {
    padding-top: 24rem !important;
  }
  .pt-xxl-px {
    padding-top: 1px !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2rem !important;
  }
  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-12 {
    padding-right: 3rem !important;
  }
  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-16 {
    padding-right: 4rem !important;
  }
  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-20 {
    padding-right: 5rem !important;
  }
  .pe-xxl-24 {
    padding-right: 6rem !important;
  }
  .pe-xxl-32 {
    padding-right: 8rem !important;
  }
  .pe-xxl-40 {
    padding-right: 10rem !important;
  }
  .pe-xxl-48 {
    padding-right: 12rem !important;
  }
  .pe-xxl-56 {
    padding-right: 14rem !important;
  }
  .pe-xxl-64 {
    padding-right: 16rem !important;
  }
  .pe-xxl-72 {
    padding-right: 18rem !important;
  }
  .pe-xxl-80 {
    padding-right: 20rem !important;
  }
  .pe-xxl-88 {
    padding-right: 22rem !important;
  }
  .pe-xxl-96 {
    padding-right: 24rem !important;
  }
  .pe-xxl-px {
    padding-right: 1px !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-24 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-32 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-40 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-48 {
    padding-bottom: 12rem !important;
  }
  .pb-xxl-56 {
    padding-bottom: 14rem !important;
  }
  .pb-xxl-64 {
    padding-bottom: 16rem !important;
  }
  .pb-xxl-72 {
    padding-bottom: 18rem !important;
  }
  .pb-xxl-80 {
    padding-bottom: 20rem !important;
  }
  .pb-xxl-88 {
    padding-bottom: 22rem !important;
  }
  .pb-xxl-96 {
    padding-bottom: 24rem !important;
  }
  .pb-xxl-px {
    padding-bottom: 1px !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2rem !important;
  }
  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-12 {
    padding-left: 3rem !important;
  }
  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-16 {
    padding-left: 4rem !important;
  }
  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-20 {
    padding-left: 5rem !important;
  }
  .ps-xxl-24 {
    padding-left: 6rem !important;
  }
  .ps-xxl-32 {
    padding-left: 8rem !important;
  }
  .ps-xxl-40 {
    padding-left: 10rem !important;
  }
  .ps-xxl-48 {
    padding-left: 12rem !important;
  }
  .ps-xxl-56 {
    padding-left: 14rem !important;
  }
  .ps-xxl-64 {
    padding-left: 16rem !important;
  }
  .ps-xxl-72 {
    padding-left: 18rem !important;
  }
  .ps-xxl-80 {
    padding-left: 20rem !important;
  }
  .ps-xxl-88 {
    padding-left: 22rem !important;
  }
  .ps-xxl-96 {
    padding-left: 24rem !important;
  }
  .ps-xxl-px {
    padding-left: 1px !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .text-xxl-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-primary-hover:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-primary-focus:focus {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-secondary-hover:hover {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-secondary-focus:focus {
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-success-hover:hover {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-success-focus:focus {
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-info-hover:hover {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-info-focus:focus {
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-warning-hover:hover {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-warning-focus:focus {
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-danger-hover:hover {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-danger-focus:focus {
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-light-hover:hover {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-light-focus:focus {
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-dark-hover:hover {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-dark-focus:focus {
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-black-hover:hover {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-black-focus:focus {
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-white-hover:hover {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-white-focus:focus {
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-body-hover:hover {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-xxl-body-focus:focus {
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .rounded-xxl {
    border-radius: 0.25rem !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
  .rounded-xxl-1 {
    border-radius: 0.2rem !important;
  }
  .rounded-xxl-2 {
    border-radius: 0.3rem !important;
  }
  .rounded-xxl-3 {
    border-radius: 0.75rem !important;
  }
  .rounded-xxl-4 {
    border-radius: 1rem !important;
  }
  .rounded-xxl-5 {
    border-radius: 1.5rem !important;
  }
  .rounded-xxl-6 {
    border-radius: 3rem !important;
  }
  .rounded-xxl-7 {
    border-radius: 4rem !important;
  }
  .rounded-xxl-8 {
    border-radius: 5rem !important;
  }
  .rounded-xxl-9 {
    border-radius: 6rem !important;
  }
  .rounded-xxl-10 {
    border-radius: 7rem !important;
  }
  .rounded-xxl-pill {
    border-radius: 50rem !important;
  }
  .rounded-xxl-circle {
    border-radius: 50% !important;
  }
  .rounded-xxl-card {
    border-radius: 0.25rem !important;
  }
  .rounded-top-xxl {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-top-xxl-1 {
    border-top-left-radius: 0.2rem !important;
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-xxl-2 {
    border-top-left-radius: 0.3rem !important;
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-xxl-3 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-xxl-4 {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-xxl-5 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-xxl-6 {
    border-top-left-radius: 3rem !important;
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-xxl-7 {
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-xxl-8 {
    border-top-left-radius: 5rem !important;
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-xxl-9 {
    border-top-left-radius: 6rem !important;
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-xxl-10 {
    border-top-left-radius: 7rem !important;
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .rounded-top-xxl-card {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-end-xxl {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-end-xxl-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-end-xxl-1 {
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-end-xxl-2 {
    border-top-right-radius: 0.3rem !important;
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-end-xxl-3 {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-end-xxl-4 {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-end-xxl-5 {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-end-xxl-6 {
    border-top-right-radius: 3rem !important;
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-end-xxl-7 {
    border-top-right-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-end-xxl-8 {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-end-xxl-9 {
    border-top-right-radius: 6rem !important;
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-end-xxl-10 {
    border-top-right-radius: 7rem !important;
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-end-xxl-pill {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-end-xxl-circle {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .rounded-end-xxl-card {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-xxl {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-xxl-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-xxl-1 {
    border-bottom-right-radius: 0.2rem !important;
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-xxl-2 {
    border-bottom-right-radius: 0.3rem !important;
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-xxl-3 {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-xxl-4 {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-xxl-5 {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-xxl-6 {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-xxl-7 {
    border-bottom-right-radius: 4rem !important;
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-xxl-8 {
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-xxl-9 {
    border-bottom-right-radius: 6rem !important;
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-xxl-10 {
    border-bottom-right-radius: 7rem !important;
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-xxl-pill {
    border-bottom-right-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-xxl-circle {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-xxl-card {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-start-xxl {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-start-xxl-1 {
    border-bottom-left-radius: 0.2rem !important;
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-start-xxl-2 {
    border-bottom-left-radius: 0.3rem !important;
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-start-xxl-3 {
    border-bottom-left-radius: 0.75rem !important;
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-start-xxl-4 {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rounded-start-xxl-5 {
    border-bottom-left-radius: 1.5rem !important;
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-start-xxl-6 {
    border-bottom-left-radius: 3rem !important;
    border-top-left-radius: 3rem !important;
  }
  .rounded-start-xxl-7 {
    border-bottom-left-radius: 4rem !important;
    border-top-left-radius: 4rem !important;
  }
  .rounded-start-xxl-8 {
    border-bottom-left-radius: 5rem !important;
    border-top-left-radius: 5rem !important;
  }
  .rounded-start-xxl-9 {
    border-bottom-left-radius: 6rem !important;
    border-top-left-radius: 6rem !important;
  }
  .rounded-start-xxl-10 {
    border-bottom-left-radius: 7rem !important;
    border-top-left-radius: 7rem !important;
  }
  .rounded-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
    border-top-left-radius: 50rem !important;
  }
  .rounded-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
    border-top-left-radius: 50% !important;
  }
  .rounded-start-xxl-card {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-xxl {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-start-xxl-0 {
    border-top-left-radius: 0 !important;
  }
  .rounded-top-start-xxl-1 {
    border-top-left-radius: 0.2rem !important;
  }
  .rounded-top-start-xxl-2 {
    border-top-left-radius: 0.3rem !important;
  }
  .rounded-top-start-xxl-3 {
    border-top-left-radius: 0.75rem !important;
  }
  .rounded-top-start-xxl-4 {
    border-top-left-radius: 1rem !important;
  }
  .rounded-top-start-xxl-5 {
    border-top-left-radius: 1.5rem !important;
  }
  .rounded-top-start-xxl-6 {
    border-top-left-radius: 3rem !important;
  }
  .rounded-top-start-xxl-7 {
    border-top-left-radius: 4rem !important;
  }
  .rounded-top-start-xxl-8 {
    border-top-left-radius: 5rem !important;
  }
  .rounded-top-start-xxl-9 {
    border-top-left-radius: 6rem !important;
  }
  .rounded-top-start-xxl-10 {
    border-top-left-radius: 7rem !important;
  }
  .rounded-top-start-xxl-pill {
    border-top-left-radius: 50rem !important;
  }
  .rounded-top-start-xxl-circle {
    border-top-left-radius: 50% !important;
  }
  .rounded-top-start-xxl-card {
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-top-end-xxl {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-top-end-xxl-0 {
    border-top-right-radius: 0 !important;
  }
  .rounded-top-end-xxl-1 {
    border-top-right-radius: 0.2rem !important;
  }
  .rounded-top-end-xxl-2 {
    border-top-right-radius: 0.3rem !important;
  }
  .rounded-top-end-xxl-3 {
    border-top-right-radius: 0.75rem !important;
  }
  .rounded-top-end-xxl-4 {
    border-top-right-radius: 1rem !important;
  }
  .rounded-top-end-xxl-5 {
    border-top-right-radius: 1.5rem !important;
  }
  .rounded-top-end-xxl-6 {
    border-top-right-radius: 3rem !important;
  }
  .rounded-top-end-xxl-7 {
    border-top-right-radius: 4rem !important;
  }
  .rounded-top-end-xxl-8 {
    border-top-right-radius: 5rem !important;
  }
  .rounded-top-end-xxl-9 {
    border-top-right-radius: 6rem !important;
  }
  .rounded-top-end-xxl-10 {
    border-top-right-radius: 7rem !important;
  }
  .rounded-top-end-xxl-pill {
    border-top-right-radius: 50rem !important;
  }
  .rounded-top-end-xxl-circle {
    border-top-right-radius: 50% !important;
  }
  .rounded-top-end-xxl-card {
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-xxl {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-end-xxl-0 {
    border-bottom-right-radius: 0 !important;
  }
  .rounded-bottom-end-xxl-1 {
    border-bottom-right-radius: 0.2rem !important;
  }
  .rounded-bottom-end-xxl-2 {
    border-bottom-right-radius: 0.3rem !important;
  }
  .rounded-bottom-end-xxl-3 {
    border-bottom-right-radius: 0.75rem !important;
  }
  .rounded-bottom-end-xxl-4 {
    border-bottom-right-radius: 1rem !important;
  }
  .rounded-bottom-end-xxl-5 {
    border-bottom-right-radius: 1.5rem !important;
  }
  .rounded-bottom-end-xxl-6 {
    border-bottom-right-radius: 3rem !important;
  }
  .rounded-bottom-end-xxl-7 {
    border-bottom-right-radius: 4rem !important;
  }
  .rounded-bottom-end-xxl-8 {
    border-bottom-right-radius: 5rem !important;
  }
  .rounded-bottom-end-xxl-9 {
    border-bottom-right-radius: 6rem !important;
  }
  .rounded-bottom-end-xxl-10 {
    border-bottom-right-radius: 7rem !important;
  }
  .rounded-bottom-end-xxl-pill {
    border-bottom-right-radius: 50rem !important;
  }
  .rounded-bottom-end-xxl-circle {
    border-bottom-right-radius: 50% !important;
  }
  .rounded-bottom-end-xxl-card {
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom-start-xxl {
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-bottom-start-xxl-0 {
    border-bottom-left-radius: 0 !important;
  }
  .rounded-bottom-start-xxl-1 {
    border-bottom-left-radius: 0.2rem !important;
  }
  .rounded-bottom-start-xxl-2 {
    border-bottom-left-radius: 0.3rem !important;
  }
  .rounded-bottom-start-xxl-3 {
    border-bottom-left-radius: 0.75rem !important;
  }
  .rounded-bottom-start-xxl-4 {
    border-bottom-left-radius: 1rem !important;
  }
  .rounded-bottom-start-xxl-5 {
    border-bottom-left-radius: 1.5rem !important;
  }
  .rounded-bottom-start-xxl-6 {
    border-bottom-left-radius: 3rem !important;
  }
  .rounded-bottom-start-xxl-7 {
    border-bottom-left-radius: 4rem !important;
  }
  .rounded-bottom-start-xxl-8 {
    border-bottom-left-radius: 5rem !important;
  }
  .rounded-bottom-start-xxl-9 {
    border-bottom-left-radius: 6rem !important;
  }
  .rounded-bottom-start-xxl-10 {
    border-bottom-left-radius: 7rem !important;
  }
  .rounded-bottom-start-xxl-pill {
    border-bottom-left-radius: 50rem !important;
  }
  .rounded-bottom-start-xxl-circle {
    border-bottom-left-radius: 50% !important;
  }
  .rounded-bottom-start-xxl-card {
    border-bottom-left-radius: 0.25rem !important;
  }
  .border-xxl-solid {
    border-style: solid !important;
  }
  .border-xxl-dashed {
    border-style: dashed !important;
  }
  .border-xxl-dotted {
    border-style: dotted !important;
  }
  .border-xxl-double {
    border-style: double !important;
  }
  .border-xxl-groove {
    border-style: groove !important;
  }
  .border-xxl-none {
    border-style: none !important;
  }
  .text-xxl-transparent {
    color: transparent !important;
  }
  .text-xxl-transparent-hover:hover {
    color: transparent !important;
  }
  .text-xxl-transparent-focus:focus {
    color: transparent !important;
  }
  .text-xxl-current {
    color: currentColor !important;
  }
  .text-xxl-current-hover:hover {
    color: currentColor !important;
  }
  .text-xxl-current-focus:focus {
    color: currentColor !important;
  }
  .text-xxl-reset {
    color: inherit !important;
  }
  .text-xxl-reset-hover:hover {
    color: inherit !important;
  }
  .text-xxl-reset-focus:focus {
    color: inherit !important;
  }
  .shadow-soft-xxl-1 {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-1-hover:hover {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-1-focus:focus {
    box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-2 {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-2-hover:hover {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-2-focus:focus {
    box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-3 {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-3-hover:hover {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-3-focus:focus {
    box-shadow: 0px 9px 9px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-4 {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-4-hover:hover {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-4-focus:focus {
    box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-5 {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-5-hover:hover {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-5-focus:focus {
    box-shadow: 0px 32px 40px -2px rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-6 {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-6-hover:hover {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-6-focus:focus {
    box-shadow: 0px 36px 50px 0 rgba(10, 22, 70, 0.04) !important;
  }
  .shadow-soft-xxl-none {
    box-shadow: none !important;
  }
  .shadow-soft-xxl-none-hover:hover {
    box-shadow: none !important;
  }
  .shadow-soft-xxl-none-focus:focus {
    box-shadow: none !important;
  }
  .min-w-xxl-0 {
    min-width: 0 !important;
  }
  .min-w-xxl-full {
    min-width: 100% !important;
  }
  .min-w-xxl-min {
    min-width: min-content !important;
  }
  .min-w-xxl-max {
    min-width: max-content !important;
  }
  .min-h-xxl-0 {
    min-height: 0 !important;
  }
  .min-h-xxl-full {
    min-height: 100% !important;
  }
  .min-h-xxl-screen {
    min-height: 100vh !important;
  }
  .place-content-xxl-start {
    place-content: flex-start !important;
  }
  .place-content-xxl-end {
    place-content: flex-end !important;
  }
  .place-content-xxl-center {
    place-content: center !important;
  }
  .place-content-xxl-between {
    place-content: space-between !important;
  }
  .place-content-xxl-around {
    place-content: space-around !important;
  }
  .place-content-xxl-evenly {
    place-content: space-evenly !important;
  }
  .place-content-xxl-stretch {
    place-content: stretch !important;
  }
  .overflow-x-xxl-auto {
    overflow-x: auto !important;
  }
  .overflow-x-xxl-hidden {
    overflow-x: hidden !important;
  }
  .overflow-x-xxl-visible {
    overflow-x: visible !important;
  }
  .overflow-x-xxl-scroll {
    overflow-x: scroll !important;
  }
  .overflow-y-xxl-auto {
    overflow-y: auto !important;
  }
  .overflow-y-xxl-hidden {
    overflow-y: hidden !important;
  }
  .overflow-y-xxl-visible {
    overflow-y: visible !important;
  }
  .overflow-y-xxl-scroll {
    overflow-y: scroll !important;
  }
  .transition-xxl {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }
  .transition-xxl-none {
    transition-property: none !important;
  }
  .transition-xxl-all {
    transition-property: all !important;
  }
  .transition-xxl-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }
  .transition-xxl-opacity {
    transition-property: opacity !important;
  }
  .transition-xxl-shadow {
    transition-property: box-shadow !important;
  }
  .transition-xxl-transform {
    transition-property: transform !important;
  }
  .ease-xxl-base {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .ease-xxl-linear {
    transition-timing-function: linear !important;
  }
  .ease-xxl-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }
  .ease-xxl-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }
  .ease-xxl-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
  .transform-xxl-none {
    transform: none !important;
  }
  .origin-xxl-center {
    transform-origin: center !important;
  }
  .origin-xxl-top {
    transform-origin: top !important;
  }
  .origin-xxl-top-right {
    transform-origin: top right !important;
  }
  .origin-xxl-right {
    transform-origin: right !important;
  }
  .origin-xxl-bottom-right {
    transform-origin: bottom right !important;
  }
  .origin-xxl-bottom {
    transform-origin: bottom !important;
  }
  .origin-xxl-bottom-left {
    transform-origin: bottom left !important;
  }
  .origin-xxl-left {
    transform-origin: left !important;
  }
  .origin-xxl-top-left {
    transform-origin: top left !important;
  }
  .scale-xxl-0 {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xxl-0-hover:hover {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xxl-0-focus:focus {
    --bs-scale-x: 0 !important;
    --bs-scale-y: 0 !important;
  }
  .scale-xxl-25 {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xxl-25-hover:hover {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xxl-25-focus:focus {
    --bs-scale-x: 0.25 !important;
    --bs-scale-y: 0.25 !important;
  }
  .scale-xxl-50 {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xxl-50-hover:hover {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xxl-50-focus:focus {
    --bs-scale-x: 0.5 !important;
    --bs-scale-y: 0.5 !important;
  }
  .scale-xxl-75 {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xxl-75-hover:hover {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xxl-75-focus:focus {
    --bs-scale-x: 0.75 !important;
    --bs-scale-y: 0.75 !important;
  }
  .scale-xxl-90 {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xxl-90-hover:hover {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xxl-90-focus:focus {
    --bs-scale-x: 0.9 !important;
    --bs-scale-y: 0.9 !important;
  }
  .scale-xxl-95 {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xxl-95-hover:hover {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xxl-95-focus:focus {
    --bs-scale-x: 0.95 !important;
    --bs-scale-y: 0.95 !important;
  }
  .scale-xxl-100 {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xxl-100-hover:hover {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xxl-100-focus:focus {
    --bs-scale-x: 1 !important;
    --bs-scale-y: 1 !important;
  }
  .scale-xxl-105 {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xxl-105-hover:hover {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xxl-105-focus:focus {
    --bs-scale-x: 1.05 !important;
    --bs-scale-y: 1.05 !important;
  }
  .scale-xxl-110 {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xxl-110-hover:hover {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xxl-110-focus:focus {
    --bs-scale-x: 1.1 !important;
    --bs-scale-y: 1.1 !important;
  }
  .scale-xxl-125 {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xxl-125-hover:hover {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xxl-125-focus:focus {
    --bs-scale-x: 1.25 !important;
    --bs-scale-y: 1.25 !important;
  }
  .scale-xxl-150 {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xxl-150-hover:hover {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xxl-150-focus:focus {
    --bs-scale-x: 1.5 !important;
    --bs-scale-y: 1.5 !important;
  }
  .scale-xxl-200 {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-xxl-200-hover:hover {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-xxl-200-focus:focus {
    --bs-scale-x: 2 !important;
    --bs-scale-y: 2 !important;
  }
  .scale-y-xxl-0 {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xxl-0-hover:hover {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xxl-0-focus:focus {
    --bs--scale-y: 0 !important;
  }
  .scale-y-xxl-25 {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xxl-25-hover:hover {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xxl-25-focus:focus {
    --bs--scale-y: 0.25 !important;
  }
  .scale-y-xxl-50 {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xxl-50-hover:hover {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xxl-50-focus:focus {
    --bs--scale-y: 0.5 !important;
  }
  .scale-y-xxl-75 {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xxl-75-hover:hover {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xxl-75-focus:focus {
    --bs--scale-y: 0.75 !important;
  }
  .scale-y-xxl-90 {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xxl-90-hover:hover {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xxl-90-focus:focus {
    --bs--scale-y: 0.9 !important;
  }
  .scale-y-xxl-95 {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xxl-95-hover:hover {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xxl-95-focus:focus {
    --bs--scale-y: 0.95 !important;
  }
  .scale-y-xxl-100 {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xxl-100-hover:hover {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xxl-100-focus:focus {
    --bs--scale-y: 1 !important;
  }
  .scale-y-xxl-105 {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xxl-105-hover:hover {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xxl-105-focus:focus {
    --bs--scale-y: 1.05 !important;
  }
  .scale-y-xxl-110 {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xxl-110-hover:hover {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xxl-110-focus:focus {
    --bs--scale-y: 1.1 !important;
  }
  .scale-y-xxl-125 {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xxl-125-hover:hover {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xxl-125-focus:focus {
    --bs--scale-y: 1.25 !important;
  }
  .scale-y-xxl-150 {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xxl-150-hover:hover {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xxl-150-focus:focus {
    --bs--scale-y: 1.5 !important;
  }
  .scale-y-xxl-200 {
    --bs--scale-y: 2 !important;
  }
  .scale-y-xxl-200-hover:hover {
    --bs--scale-y: 2 !important;
  }
  .scale-y-xxl-200-focus:focus {
    --bs--scale-y: 2 !important;
  }
  .scale-x-xxl-0 {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xxl-0-hover:hover {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xxl-0-focus:focus {
    --bs--scale-x: 0 !important;
  }
  .scale-x-xxl-25 {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xxl-25-hover:hover {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xxl-25-focus:focus {
    --bs--scale-x: 0.25 !important;
  }
  .scale-x-xxl-50 {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xxl-50-hover:hover {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xxl-50-focus:focus {
    --bs--scale-x: 0.5 !important;
  }
  .scale-x-xxl-75 {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xxl-75-hover:hover {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xxl-75-focus:focus {
    --bs--scale-x: 0.75 !important;
  }
  .scale-x-xxl-90 {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xxl-90-hover:hover {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xxl-90-focus:focus {
    --bs--scale-x: 0.9 !important;
  }
  .scale-x-xxl-95 {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xxl-95-hover:hover {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xxl-95-focus:focus {
    --bs--scale-x: 0.95 !important;
  }
  .scale-x-xxl-100 {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xxl-100-hover:hover {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xxl-100-focus:focus {
    --bs--scale-x: 1 !important;
  }
  .scale-x-xxl-105 {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xxl-105-hover:hover {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xxl-105-focus:focus {
    --bs--scale-x: 1.05 !important;
  }
  .scale-x-xxl-110 {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xxl-110-hover:hover {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xxl-110-focus:focus {
    --bs--scale-x: 1.1 !important;
  }
  .scale-x-xxl-125 {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xxl-125-hover:hover {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xxl-125-focus:focus {
    --bs--scale-x: 1.25 !important;
  }
  .scale-x-xxl-150 {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xxl-150-hover:hover {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xxl-150-focus:focus {
    --bs--scale-x: 1.5 !important;
  }
  .scale-x-xxl-200 {
    --bs--scale-x: 2 !important;
  }
  .scale-x-xxl-200-hover:hover {
    --bs--scale-x: 2 !important;
  }
  .scale-x-xxl-200-focus:focus {
    --bs--scale-x: 2 !important;
  }
  .rotate-xxl-0 {
    --bs-rotate: 0deg !important;
  }
  .rotate-xxl-0-hover:hover {
    --bs-rotate: 0deg !important;
  }
  .rotate-xxl-0-focus:focus {
    --bs-rotate: 0deg !important;
  }
  .rotate-xxl-1 {
    --bs-rotate: 1deg !important;
  }
  .rotate-xxl-1-hover:hover {
    --bs-rotate: 1deg !important;
  }
  .rotate-xxl-1-focus:focus {
    --bs-rotate: 1deg !important;
  }
  .rotate-xxl-2 {
    --bs-rotate: 2deg !important;
  }
  .rotate-xxl-2-hover:hover {
    --bs-rotate: 2deg !important;
  }
  .rotate-xxl-2-focus:focus {
    --bs-rotate: 2deg !important;
  }
  .rotate-xxl-3 {
    --bs-rotate: 3deg !important;
  }
  .rotate-xxl-3-hover:hover {
    --bs-rotate: 3deg !important;
  }
  .rotate-xxl-3-focus:focus {
    --bs-rotate: 3deg !important;
  }
  .rotate-xxl-6 {
    --bs-rotate: 6deg !important;
  }
  .rotate-xxl-6-hover:hover {
    --bs-rotate: 6deg !important;
  }
  .rotate-xxl-6-focus:focus {
    --bs-rotate: 6deg !important;
  }
  .rotate-xxl-12 {
    --bs-rotate: 12deg !important;
  }
  .rotate-xxl-12-hover:hover {
    --bs-rotate: 12deg !important;
  }
  .rotate-xxl-12-focus:focus {
    --bs-rotate: 12deg !important;
  }
  .rotate-xxl-30 {
    --bs-rotate: 30deg !important;
  }
  .rotate-xxl-30-hover:hover {
    --bs-rotate: 30deg !important;
  }
  .rotate-xxl-30-focus:focus {
    --bs-rotate: 30deg !important;
  }
  .rotate-xxl-45 {
    --bs-rotate: 45deg !important;
  }
  .rotate-xxl-45-hover:hover {
    --bs-rotate: 45deg !important;
  }
  .rotate-xxl-45-focus:focus {
    --bs-rotate: 45deg !important;
  }
  .rotate-xxl-90 {
    --bs-rotate: 90deg !important;
  }
  .rotate-xxl-90-hover:hover {
    --bs-rotate: 90deg !important;
  }
  .rotate-xxl-90-focus:focus {
    --bs-rotate: 90deg !important;
  }
  .rotate-xxl-180 {
    --bs-rotate: 180deg !important;
  }
  .rotate-xxl-180-hover:hover {
    --bs-rotate: 180deg !important;
  }
  .rotate-xxl-180-focus:focus {
    --bs-rotate: 180deg !important;
  }
  .rotate-xxl-n1 {
    --bs-rotate: -1deg !important;
  }
  .rotate-xxl-n1-hover:hover {
    --bs-rotate: -1deg !important;
  }
  .rotate-xxl-n1-focus:focus {
    --bs-rotate: -1deg !important;
  }
  .rotate-xxl-n2 {
    --bs-rotate: -2deg !important;
  }
  .rotate-xxl-n2-hover:hover {
    --bs-rotate: -2deg !important;
  }
  .rotate-xxl-n2-focus:focus {
    --bs-rotate: -2deg !important;
  }
  .rotate-xxl-n3 {
    --bs-rotate: -3deg !important;
  }
  .rotate-xxl-n3-hover:hover {
    --bs-rotate: -3deg !important;
  }
  .rotate-xxl-n3-focus:focus {
    --bs-rotate: -3deg !important;
  }
  .rotate-xxl-n6 {
    --bs-rotate: -6deg !important;
  }
  .rotate-xxl-n6-hover:hover {
    --bs-rotate: -6deg !important;
  }
  .rotate-xxl-n6-focus:focus {
    --bs-rotate: -6deg !important;
  }
  .rotate-xxl-n12 {
    --bs-rotate: -12deg !important;
  }
  .rotate-xxl-n12-hover:hover {
    --bs-rotate: -12deg !important;
  }
  .rotate-xxl-n12-focus:focus {
    --bs-rotate: -12deg !important;
  }
  .rotate-xxl-n30 {
    --bs-rotate: -30deg !important;
  }
  .rotate-xxl-n30-hover:hover {
    --bs-rotate: -30deg !important;
  }
  .rotate-xxl-n30-focus:focus {
    --bs-rotate: -30deg !important;
  }
  .rotate-xxl-n45 {
    --bs-rotate: -45deg !important;
  }
  .rotate-xxl-n45-hover:hover {
    --bs-rotate: -45deg !important;
  }
  .rotate-xxl-n45-focus:focus {
    --bs-rotate: -45deg !important;
  }
  .rotate-xxl-n90 {
    --bs-rotate: -90deg !important;
  }
  .rotate-xxl-n90-hover:hover {
    --bs-rotate: -90deg !important;
  }
  .rotate-xxl-n90-focus:focus {
    --bs-rotate: -90deg !important;
  }
  .rotate-xxl-n180 {
    --bs-rotate: -180deg !important;
  }
  .rotate-xxl-n180-hover:hover {
    --bs-rotate: -180deg !important;
  }
  .rotate-xxl-n180-focus:focus {
    --bs-rotate: -180deg !important;
  }
  .rotate-y-xxl-0 {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xxl-0-hover:hover {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xxl-0-focus:focus {
    --bs-rotate-y: 0deg !important;
  }
  .rotate-y-xxl-1 {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xxl-1-hover:hover {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xxl-1-focus:focus {
    --bs-rotate-y: 1deg !important;
  }
  .rotate-y-xxl-2 {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xxl-2-hover:hover {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xxl-2-focus:focus {
    --bs-rotate-y: 2deg !important;
  }
  .rotate-y-xxl-3 {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xxl-3-hover:hover {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xxl-3-focus:focus {
    --bs-rotate-y: 3deg !important;
  }
  .rotate-y-xxl-6 {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xxl-6-hover:hover {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xxl-6-focus:focus {
    --bs-rotate-y: 6deg !important;
  }
  .rotate-y-xxl-12 {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xxl-12-hover:hover {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xxl-12-focus:focus {
    --bs-rotate-y: 12deg !important;
  }
  .rotate-y-xxl-30 {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xxl-30-hover:hover {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xxl-30-focus:focus {
    --bs-rotate-y: 30deg !important;
  }
  .rotate-y-xxl-45 {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xxl-45-hover:hover {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xxl-45-focus:focus {
    --bs-rotate-y: 45deg !important;
  }
  .rotate-y-xxl-90 {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xxl-90-hover:hover {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xxl-90-focus:focus {
    --bs-rotate-y: 90deg !important;
  }
  .rotate-y-xxl-180 {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xxl-180-hover:hover {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xxl-180-focus:focus {
    --bs-rotate-y: 180deg !important;
  }
  .rotate-y-xxl-n1 {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xxl-n1-hover:hover {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xxl-n1-focus:focus {
    --bs-rotate-y: -1deg !important;
  }
  .rotate-y-xxl-n2 {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xxl-n2-hover:hover {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xxl-n2-focus:focus {
    --bs-rotate-y: -2deg !important;
  }
  .rotate-y-xxl-n3 {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xxl-n3-hover:hover {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xxl-n3-focus:focus {
    --bs-rotate-y: -3deg !important;
  }
  .rotate-y-xxl-n6 {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xxl-n6-hover:hover {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xxl-n6-focus:focus {
    --bs-rotate-y: -6deg !important;
  }
  .rotate-y-xxl-n12 {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xxl-n12-hover:hover {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xxl-n12-focus:focus {
    --bs-rotate-y: -12deg !important;
  }
  .rotate-y-xxl-n30 {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xxl-n30-hover:hover {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xxl-n30-focus:focus {
    --bs-rotate-y: -30deg !important;
  }
  .rotate-y-xxl-n45 {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xxl-n45-hover:hover {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xxl-n45-focus:focus {
    --bs-rotate-y: -45deg !important;
  }
  .rotate-y-xxl-n90 {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xxl-n90-hover:hover {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xxl-n90-focus:focus {
    --bs-rotate-y: -90deg !important;
  }
  .rotate-y-xxl-n180 {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-xxl-n180-hover:hover {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-y-xxl-n180-focus:focus {
    --bs-rotate-y: -180deg !important;
  }
  .rotate-x-xxl-0 {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xxl-0-hover:hover {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xxl-0-focus:focus {
    --bs-rotate-x: 0deg !important;
  }
  .rotate-x-xxl-1 {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xxl-1-hover:hover {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xxl-1-focus:focus {
    --bs-rotate-x: 1deg !important;
  }
  .rotate-x-xxl-2 {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xxl-2-hover:hover {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xxl-2-focus:focus {
    --bs-rotate-x: 2deg !important;
  }
  .rotate-x-xxl-3 {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xxl-3-hover:hover {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xxl-3-focus:focus {
    --bs-rotate-x: 3deg !important;
  }
  .rotate-x-xxl-6 {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xxl-6-hover:hover {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xxl-6-focus:focus {
    --bs-rotate-x: 6deg !important;
  }
  .rotate-x-xxl-12 {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xxl-12-hover:hover {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xxl-12-focus:focus {
    --bs-rotate-x: 12deg !important;
  }
  .rotate-x-xxl-30 {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xxl-30-hover:hover {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xxl-30-focus:focus {
    --bs-rotate-x: 30deg !important;
  }
  .rotate-x-xxl-45 {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xxl-45-hover:hover {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xxl-45-focus:focus {
    --bs-rotate-x: 45deg !important;
  }
  .rotate-x-xxl-90 {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xxl-90-hover:hover {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xxl-90-focus:focus {
    --bs-rotate-x: 90deg !important;
  }
  .rotate-x-xxl-180 {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xxl-180-hover:hover {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xxl-180-focus:focus {
    --bs-rotate-x: 180deg !important;
  }
  .rotate-x-xxl-n1 {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xxl-n1-hover:hover {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xxl-n1-focus:focus {
    --bs-rotate-x: -1deg !important;
  }
  .rotate-x-xxl-n2 {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xxl-n2-hover:hover {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xxl-n2-focus:focus {
    --bs-rotate-x: -2deg !important;
  }
  .rotate-x-xxl-n3 {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xxl-n3-hover:hover {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xxl-n3-focus:focus {
    --bs-rotate-x: -3deg !important;
  }
  .rotate-x-xxl-n6 {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xxl-n6-hover:hover {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xxl-n6-focus:focus {
    --bs-rotate-x: -6deg !important;
  }
  .rotate-x-xxl-n12 {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xxl-n12-hover:hover {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xxl-n12-focus:focus {
    --bs-rotate-x: -12deg !important;
  }
  .rotate-x-xxl-n30 {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xxl-n30-hover:hover {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xxl-n30-focus:focus {
    --bs-rotate-x: -30deg !important;
  }
  .rotate-x-xxl-n45 {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xxl-n45-hover:hover {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xxl-n45-focus:focus {
    --bs-rotate-x: -45deg !important;
  }
  .rotate-x-xxl-n90 {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xxl-n90-hover:hover {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xxl-n90-focus:focus {
    --bs-rotate-x: -90deg !important;
  }
  .rotate-x-xxl-n180 {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-xxl-n180-hover:hover {
    --bs-rotate-x: -180deg !important;
  }
  .rotate-x-xxl-n180-focus:focus {
    --bs-rotate-x: -180deg !important;
  }
  .perspective-xxl-100 {
    --bs-perspective: 100px !important;
  }
  .perspective-xxl-100-hover:hover {
    --bs-perspective: 100px !important;
  }
  .perspective-xxl-100-focus:focus {
    --bs-perspective: 100px !important;
  }
  .perspective-xxl-200 {
    --bs-perspective: 200px !important;
  }
  .perspective-xxl-200-hover:hover {
    --bs-perspective: 200px !important;
  }
  .perspective-xxl-200-focus:focus {
    --bs-perspective: 200px !important;
  }
  .perspective-xxl-300 {
    --bs-perspective: 300px !important;
  }
  .perspective-xxl-300-hover:hover {
    --bs-perspective: 300px !important;
  }
  .perspective-xxl-300-focus:focus {
    --bs-perspective: 300px !important;
  }
  .perspective-xxl-400 {
    --bs-perspective: 400px !important;
  }
  .perspective-xxl-400-hover:hover {
    --bs-perspective: 400px !important;
  }
  .perspective-xxl-400-focus:focus {
    --bs-perspective: 400px !important;
  }
  .perspective-xxl-500 {
    --bs-perspective: 500px !important;
  }
  .perspective-xxl-500-hover:hover {
    --bs-perspective: 500px !important;
  }
  .perspective-xxl-500-focus:focus {
    --bs-perspective: 500px !important;
  }
  .perspective-xxl-600 {
    --bs-perspective: 600px !important;
  }
  .perspective-xxl-600-hover:hover {
    --bs-perspective: 600px !important;
  }
  .perspective-xxl-600-focus:focus {
    --bs-perspective: 600px !important;
  }
  .perspective-xxl-700 {
    --bs-perspective: 700px !important;
  }
  .perspective-xxl-700-hover:hover {
    --bs-perspective: 700px !important;
  }
  .perspective-xxl-700-focus:focus {
    --bs-perspective: 700px !important;
  }
  .perspective-xxl-800 {
    --bs-perspective: 800px !important;
  }
  .perspective-xxl-800-hover:hover {
    --bs-perspective: 800px !important;
  }
  .perspective-xxl-800-focus:focus {
    --bs-perspective: 800px !important;
  }
  .perspective-xxl-1000 {
    --bs-perspective: 1000px !important;
  }
  .perspective-xxl-1000-hover:hover {
    --bs-perspective: 1000px !important;
  }
  .perspective-xxl-1000-focus:focus {
    --bs-perspective: 1000px !important;
  }
  .perspective-xxl-1250 {
    --bs-perspective: 1250px !important;
  }
  .perspective-xxl-1250-hover:hover {
    --bs-perspective: 1250px !important;
  }
  .perspective-xxl-1250-focus:focus {
    --bs-perspective: 1250px !important;
  }
  .perspective-xxl-1500 {
    --bs-perspective: 1500px !important;
  }
  .perspective-xxl-1500-hover:hover {
    --bs-perspective: 1500px !important;
  }
  .perspective-xxl-1500-focus:focus {
    --bs-perspective: 1500px !important;
  }
  .translate-y-xxl-0 {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xxl-0-hover:hover {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xxl-0-focus:focus {
    --bs-translate-y: 0 !important;
  }
  .translate-y-xxl-1 {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xxl-1-hover:hover {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xxl-1-focus:focus {
    --bs-translate-y: 0.25rem !important;
  }
  .translate-y-xxl-2 {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xxl-2-hover:hover {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xxl-2-focus:focus {
    --bs-translate-y: 0.5rem !important;
  }
  .translate-y-xxl-3 {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xxl-3-hover:hover {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xxl-3-focus:focus {
    --bs-translate-y: 0.75rem !important;
  }
  .translate-y-xxl-4 {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xxl-4-hover:hover {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xxl-4-focus:focus {
    --bs-translate-y: 1rem !important;
  }
  .translate-y-xxl-5 {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xxl-5-hover:hover {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xxl-5-focus:focus {
    --bs-translate-y: 1.25rem !important;
  }
  .translate-y-xxl-6 {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xxl-6-hover:hover {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xxl-6-focus:focus {
    --bs-translate-y: 1.5rem !important;
  }
  .translate-y-xxl-7 {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xxl-7-hover:hover {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xxl-7-focus:focus {
    --bs-translate-y: 1.75rem !important;
  }
  .translate-y-xxl-8 {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xxl-8-hover:hover {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xxl-8-focus:focus {
    --bs-translate-y: 2rem !important;
  }
  .translate-y-xxl-10 {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xxl-10-hover:hover {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xxl-10-focus:focus {
    --bs-translate-y: 2.5rem !important;
  }
  .translate-y-xxl-12 {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xxl-12-hover:hover {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xxl-12-focus:focus {
    --bs-translate-y: 3rem !important;
  }
  .translate-y-xxl-14 {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xxl-14-hover:hover {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xxl-14-focus:focus {
    --bs-translate-y: 3.5rem !important;
  }
  .translate-y-xxl-16 {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xxl-16-hover:hover {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xxl-16-focus:focus {
    --bs-translate-y: 4rem !important;
  }
  .translate-y-xxl-18 {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xxl-18-hover:hover {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xxl-18-focus:focus {
    --bs-translate-y: 4.5rem !important;
  }
  .translate-y-xxl-20 {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xxl-20-hover:hover {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xxl-20-focus:focus {
    --bs-translate-y: 5rem !important;
  }
  .translate-y-xxl-24 {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xxl-24-hover:hover {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xxl-24-focus:focus {
    --bs-translate-y: 6rem !important;
  }
  .translate-y-xxl-32 {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xxl-32-hover:hover {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xxl-32-focus:focus {
    --bs-translate-y: 8rem !important;
  }
  .translate-y-xxl-40 {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xxl-40-hover:hover {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xxl-40-focus:focus {
    --bs-translate-y: 10rem !important;
  }
  .translate-y-xxl-48 {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xxl-48-hover:hover {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xxl-48-focus:focus {
    --bs-translate-y: 12rem !important;
  }
  .translate-y-xxl-56 {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xxl-56-hover:hover {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xxl-56-focus:focus {
    --bs-translate-y: 14rem !important;
  }
  .translate-y-xxl-64 {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xxl-64-hover:hover {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xxl-64-focus:focus {
    --bs-translate-y: 16rem !important;
  }
  .translate-y-xxl-72 {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xxl-72-hover:hover {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xxl-72-focus:focus {
    --bs-translate-y: 18rem !important;
  }
  .translate-y-xxl-80 {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xxl-80-hover:hover {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xxl-80-focus:focus {
    --bs-translate-y: 20rem !important;
  }
  .translate-y-xxl-88 {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xxl-88-hover:hover {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xxl-88-focus:focus {
    --bs-translate-y: 22rem !important;
  }
  .translate-y-xxl-96 {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xxl-96-hover:hover {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xxl-96-focus:focus {
    --bs-translate-y: 24rem !important;
  }
  .translate-y-xxl-px {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xxl-px-hover:hover {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xxl-px-focus:focus {
    --bs-translate-y: 1px !important;
  }
  .translate-y-xxl-1\/2 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-1\/2-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-1\/2-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-1\/3 {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xxl-1\/3-hover:hover {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xxl-1\/3-focus:focus {
    --bs-translate-y: 33.333333% !important;
  }
  .translate-y-xxl-1\/4 {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xxl-1\/4-hover:hover {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xxl-1\/4-focus:focus {
    --bs-translate-y: 25% !important;
  }
  .translate-y-xxl-1\/5 {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xxl-1\/5-hover:hover {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xxl-1\/5-focus:focus {
    --bs-translate-y: 20% !important;
  }
  .translate-y-xxl-2\/3 {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xxl-2\/3-hover:hover {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xxl-2\/3-focus:focus {
    --bs-translate-y: 66.666667% !important;
  }
  .translate-y-xxl-2\/5 {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-2\/5-hover:hover {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-2\/5-focus:focus {
    --bs-translate-y: 50% !important;
  }
  .translate-y-xxl-3\/4 {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xxl-3\/4-hover:hover {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xxl-3\/4-focus:focus {
    --bs-translate-y: 75% !important;
  }
  .translate-y-xxl-full {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xxl-full-hover:hover {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xxl-full-focus:focus {
    --bs-translate-y: 100% !important;
  }
  .translate-y-xxl-n1 {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xxl-n1-hover:hover {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xxl-n1-focus:focus {
    --bs-translate-y: -0.25rem !important;
  }
  .translate-y-xxl-n2 {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xxl-n2-hover:hover {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xxl-n2-focus:focus {
    --bs-translate-y: -0.5rem !important;
  }
  .translate-y-xxl-n3 {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xxl-n3-hover:hover {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xxl-n3-focus:focus {
    --bs-translate-y: -0.75rem !important;
  }
  .translate-y-xxl-n4 {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xxl-n4-hover:hover {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xxl-n4-focus:focus {
    --bs-translate-y: -1rem !important;
  }
  .translate-y-xxl-n5 {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xxl-n5-hover:hover {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xxl-n5-focus:focus {
    --bs-translate-y: -1.25rem !important;
  }
  .translate-y-xxl-n6 {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xxl-n6-hover:hover {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xxl-n6-focus:focus {
    --bs-translate-y: -1.5rem !important;
  }
  .translate-y-xxl-n7 {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xxl-n7-hover:hover {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xxl-n7-focus:focus {
    --bs-translate-y: -1.75rem !important;
  }
  .translate-y-xxl-n8 {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xxl-n8-hover:hover {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xxl-n8-focus:focus {
    --bs-translate-y: -2rem !important;
  }
  .translate-y-xxl-n10 {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xxl-n10-hover:hover {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xxl-n10-focus:focus {
    --bs-translate-y: -2.5rem !important;
  }
  .translate-y-xxl-n12 {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xxl-n12-hover:hover {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xxl-n12-focus:focus {
    --bs-translate-y: -3rem !important;
  }
  .translate-y-xxl-n14 {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xxl-n14-hover:hover {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xxl-n14-focus:focus {
    --bs-translate-y: -3.5rem !important;
  }
  .translate-y-xxl-n16 {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xxl-n16-hover:hover {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xxl-n16-focus:focus {
    --bs-translate-y: -4rem !important;
  }
  .translate-y-xxl-n18 {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xxl-n18-hover:hover {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xxl-n18-focus:focus {
    --bs-translate-y: -4.5rem !important;
  }
  .translate-y-xxl-n20 {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xxl-n20-hover:hover {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xxl-n20-focus:focus {
    --bs-translate-y: -5rem !important;
  }
  .translate-y-xxl-n24 {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xxl-n24-hover:hover {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xxl-n24-focus:focus {
    --bs-translate-y: -6rem !important;
  }
  .translate-y-xxl-n32 {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xxl-n32-hover:hover {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xxl-n32-focus:focus {
    --bs-translate-y: -8rem !important;
  }
  .translate-y-xxl-n40 {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xxl-n40-hover:hover {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xxl-n40-focus:focus {
    --bs-translate-y: -10rem !important;
  }
  .translate-y-xxl-n48 {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xxl-n48-hover:hover {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xxl-n48-focus:focus {
    --bs-translate-y: -12rem !important;
  }
  .translate-y-xxl-n56 {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xxl-n56-hover:hover {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xxl-n56-focus:focus {
    --bs-translate-y: -14rem !important;
  }
  .translate-y-xxl-n64 {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xxl-n64-hover:hover {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xxl-n64-focus:focus {
    --bs-translate-y: -16rem !important;
  }
  .translate-y-xxl-n72 {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xxl-n72-hover:hover {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xxl-n72-focus:focus {
    --bs-translate-y: -18rem !important;
  }
  .translate-y-xxl-n80 {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xxl-n80-hover:hover {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xxl-n80-focus:focus {
    --bs-translate-y: -20rem !important;
  }
  .translate-y-xxl-n88 {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xxl-n88-hover:hover {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xxl-n88-focus:focus {
    --bs-translate-y: -22rem !important;
  }
  .translate-y-xxl-n96 {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xxl-n96-hover:hover {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xxl-n96-focus:focus {
    --bs-translate-y: -24rem !important;
  }
  .translate-y-xxl-npx {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xxl-npx-hover:hover {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xxl-npx-focus:focus {
    --bs-translate-y: -1px !important;
  }
  .translate-y-xxl-n1\/2 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n1\/2-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n1\/2-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n1\/3 {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xxl-n1\/3-hover:hover {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xxl-n1\/3-focus:focus {
    --bs-translate-y: -33.333333% !important;
  }
  .translate-y-xxl-n1\/4 {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xxl-n1\/4-hover:hover {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xxl-n1\/4-focus:focus {
    --bs-translate-y: -25% !important;
  }
  .translate-y-xxl-n1\/5 {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xxl-n1\/5-hover:hover {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xxl-n1\/5-focus:focus {
    --bs-translate-y: -20% !important;
  }
  .translate-y-xxl-n2\/3 {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xxl-n2\/3-hover:hover {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xxl-n2\/3-focus:focus {
    --bs-translate-y: -66.666667% !important;
  }
  .translate-y-xxl-n2\/5 {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n2\/5-hover:hover {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n2\/5-focus:focus {
    --bs-translate-y: -50% !important;
  }
  .translate-y-xxl-n3\/4 {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xxl-n3\/4-hover:hover {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xxl-n3\/4-focus:focus {
    --bs-translate-y: -75% !important;
  }
  .translate-y-xxl-nfull {
    --bs-translate-y: -100% !important;
  }
  .translate-y-xxl-nfull-hover:hover {
    --bs-translate-y: -100% !important;
  }
  .translate-y-xxl-nfull-focus:focus {
    --bs-translate-y: -100% !important;
  }
  .translate-x-xxl-0 {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xxl-0-hover:hover {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xxl-0-focus:focus {
    --bs-translate-x: 0 !important;
  }
  .translate-x-xxl-1 {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xxl-1-hover:hover {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xxl-1-focus:focus {
    --bs-translate-x: 0.25rem !important;
  }
  .translate-x-xxl-2 {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xxl-2-hover:hover {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xxl-2-focus:focus {
    --bs-translate-x: 0.5rem !important;
  }
  .translate-x-xxl-3 {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xxl-3-hover:hover {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xxl-3-focus:focus {
    --bs-translate-x: 0.75rem !important;
  }
  .translate-x-xxl-4 {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xxl-4-hover:hover {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xxl-4-focus:focus {
    --bs-translate-x: 1rem !important;
  }
  .translate-x-xxl-5 {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xxl-5-hover:hover {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xxl-5-focus:focus {
    --bs-translate-x: 1.25rem !important;
  }
  .translate-x-xxl-6 {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xxl-6-hover:hover {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xxl-6-focus:focus {
    --bs-translate-x: 1.5rem !important;
  }
  .translate-x-xxl-7 {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xxl-7-hover:hover {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xxl-7-focus:focus {
    --bs-translate-x: 1.75rem !important;
  }
  .translate-x-xxl-8 {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xxl-8-hover:hover {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xxl-8-focus:focus {
    --bs-translate-x: 2rem !important;
  }
  .translate-x-xxl-10 {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xxl-10-hover:hover {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xxl-10-focus:focus {
    --bs-translate-x: 2.5rem !important;
  }
  .translate-x-xxl-12 {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xxl-12-hover:hover {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xxl-12-focus:focus {
    --bs-translate-x: 3rem !important;
  }
  .translate-x-xxl-14 {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xxl-14-hover:hover {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xxl-14-focus:focus {
    --bs-translate-x: 3.5rem !important;
  }
  .translate-x-xxl-16 {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xxl-16-hover:hover {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xxl-16-focus:focus {
    --bs-translate-x: 4rem !important;
  }
  .translate-x-xxl-18 {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xxl-18-hover:hover {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xxl-18-focus:focus {
    --bs-translate-x: 4.5rem !important;
  }
  .translate-x-xxl-20 {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xxl-20-hover:hover {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xxl-20-focus:focus {
    --bs-translate-x: 5rem !important;
  }
  .translate-x-xxl-24 {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xxl-24-hover:hover {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xxl-24-focus:focus {
    --bs-translate-x: 6rem !important;
  }
  .translate-x-xxl-32 {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xxl-32-hover:hover {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xxl-32-focus:focus {
    --bs-translate-x: 8rem !important;
  }
  .translate-x-xxl-40 {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xxl-40-hover:hover {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xxl-40-focus:focus {
    --bs-translate-x: 10rem !important;
  }
  .translate-x-xxl-48 {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xxl-48-hover:hover {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xxl-48-focus:focus {
    --bs-translate-x: 12rem !important;
  }
  .translate-x-xxl-56 {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xxl-56-hover:hover {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xxl-56-focus:focus {
    --bs-translate-x: 14rem !important;
  }
  .translate-x-xxl-64 {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xxl-64-hover:hover {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xxl-64-focus:focus {
    --bs-translate-x: 16rem !important;
  }
  .translate-x-xxl-72 {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xxl-72-hover:hover {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xxl-72-focus:focus {
    --bs-translate-x: 18rem !important;
  }
  .translate-x-xxl-80 {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xxl-80-hover:hover {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xxl-80-focus:focus {
    --bs-translate-x: 20rem !important;
  }
  .translate-x-xxl-88 {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xxl-88-hover:hover {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xxl-88-focus:focus {
    --bs-translate-x: 22rem !important;
  }
  .translate-x-xxl-96 {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xxl-96-hover:hover {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xxl-96-focus:focus {
    --bs-translate-x: 24rem !important;
  }
  .translate-x-xxl-px {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xxl-px-hover:hover {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xxl-px-focus:focus {
    --bs-translate-x: 1px !important;
  }
  .translate-x-xxl-1\/2 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-1\/2-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-1\/2-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-1\/3 {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xxl-1\/3-hover:hover {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xxl-1\/3-focus:focus {
    --bs-translate-x: 33.333333% !important;
  }
  .translate-x-xxl-1\/4 {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xxl-1\/4-hover:hover {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xxl-1\/4-focus:focus {
    --bs-translate-x: 25% !important;
  }
  .translate-x-xxl-1\/5 {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xxl-1\/5-hover:hover {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xxl-1\/5-focus:focus {
    --bs-translate-x: 20% !important;
  }
  .translate-x-xxl-2\/3 {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xxl-2\/3-hover:hover {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xxl-2\/3-focus:focus {
    --bs-translate-x: 66.666667% !important;
  }
  .translate-x-xxl-2\/5 {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-2\/5-hover:hover {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-2\/5-focus:focus {
    --bs-translate-x: 50% !important;
  }
  .translate-x-xxl-3\/4 {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xxl-3\/4-hover:hover {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xxl-3\/4-focus:focus {
    --bs-translate-x: 75% !important;
  }
  .translate-x-xxl-full {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xxl-full-hover:hover {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xxl-full-focus:focus {
    --bs-translate-x: 100% !important;
  }
  .translate-x-xxl-n1 {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xxl-n1-hover:hover {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xxl-n1-focus:focus {
    --bs-translate-x: -0.25rem !important;
  }
  .translate-x-xxl-n2 {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xxl-n2-hover:hover {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xxl-n2-focus:focus {
    --bs-translate-x: -0.5rem !important;
  }
  .translate-x-xxl-n3 {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xxl-n3-hover:hover {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xxl-n3-focus:focus {
    --bs-translate-x: -0.75rem !important;
  }
  .translate-x-xxl-n4 {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xxl-n4-hover:hover {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xxl-n4-focus:focus {
    --bs-translate-x: -1rem !important;
  }
  .translate-x-xxl-n5 {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xxl-n5-hover:hover {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xxl-n5-focus:focus {
    --bs-translate-x: -1.25rem !important;
  }
  .translate-x-xxl-n6 {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xxl-n6-hover:hover {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xxl-n6-focus:focus {
    --bs-translate-x: -1.5rem !important;
  }
  .translate-x-xxl-n7 {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xxl-n7-hover:hover {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xxl-n7-focus:focus {
    --bs-translate-x: -1.75rem !important;
  }
  .translate-x-xxl-n8 {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xxl-n8-hover:hover {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xxl-n8-focus:focus {
    --bs-translate-x: -2rem !important;
  }
  .translate-x-xxl-n10 {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xxl-n10-hover:hover {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xxl-n10-focus:focus {
    --bs-translate-x: -2.5rem !important;
  }
  .translate-x-xxl-n12 {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xxl-n12-hover:hover {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xxl-n12-focus:focus {
    --bs-translate-x: -3rem !important;
  }
  .translate-x-xxl-n14 {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xxl-n14-hover:hover {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xxl-n14-focus:focus {
    --bs-translate-x: -3.5rem !important;
  }
  .translate-x-xxl-n16 {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xxl-n16-hover:hover {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xxl-n16-focus:focus {
    --bs-translate-x: -4rem !important;
  }
  .translate-x-xxl-n18 {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xxl-n18-hover:hover {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xxl-n18-focus:focus {
    --bs-translate-x: -4.5rem !important;
  }
  .translate-x-xxl-n20 {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xxl-n20-hover:hover {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xxl-n20-focus:focus {
    --bs-translate-x: -5rem !important;
  }
  .translate-x-xxl-n24 {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xxl-n24-hover:hover {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xxl-n24-focus:focus {
    --bs-translate-x: -6rem !important;
  }
  .translate-x-xxl-n32 {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xxl-n32-hover:hover {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xxl-n32-focus:focus {
    --bs-translate-x: -8rem !important;
  }
  .translate-x-xxl-n40 {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xxl-n40-hover:hover {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xxl-n40-focus:focus {
    --bs-translate-x: -10rem !important;
  }
  .translate-x-xxl-n48 {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xxl-n48-hover:hover {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xxl-n48-focus:focus {
    --bs-translate-x: -12rem !important;
  }
  .translate-x-xxl-n56 {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xxl-n56-hover:hover {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xxl-n56-focus:focus {
    --bs-translate-x: -14rem !important;
  }
  .translate-x-xxl-n64 {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xxl-n64-hover:hover {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xxl-n64-focus:focus {
    --bs-translate-x: -16rem !important;
  }
  .translate-x-xxl-n72 {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xxl-n72-hover:hover {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xxl-n72-focus:focus {
    --bs-translate-x: -18rem !important;
  }
  .translate-x-xxl-n80 {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xxl-n80-hover:hover {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xxl-n80-focus:focus {
    --bs-translate-x: -20rem !important;
  }
  .translate-x-xxl-n88 {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xxl-n88-hover:hover {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xxl-n88-focus:focus {
    --bs-translate-x: -22rem !important;
  }
  .translate-x-xxl-n96 {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xxl-n96-hover:hover {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xxl-n96-focus:focus {
    --bs-translate-x: -24rem !important;
  }
  .translate-x-xxl-npx {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xxl-npx-hover:hover {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xxl-npx-focus:focus {
    --bs-translate-x: -1px !important;
  }
  .translate-x-xxl-n1\/2 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n1\/2-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n1\/2-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n1\/3 {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xxl-n1\/3-hover:hover {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xxl-n1\/3-focus:focus {
    --bs-translate-x: -33.333333% !important;
  }
  .translate-x-xxl-n1\/4 {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xxl-n1\/4-hover:hover {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xxl-n1\/4-focus:focus {
    --bs-translate-x: -25% !important;
  }
  .translate-x-xxl-n1\/5 {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xxl-n1\/5-hover:hover {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xxl-n1\/5-focus:focus {
    --bs-translate-x: -20% !important;
  }
  .translate-x-xxl-n2\/3 {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xxl-n2\/3-hover:hover {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xxl-n2\/3-focus:focus {
    --bs-translate-x: -66.666667% !important;
  }
  .translate-x-xxl-n2\/5 {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n2\/5-hover:hover {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n2\/5-focus:focus {
    --bs-translate-x: -50% !important;
  }
  .translate-x-xxl-n3\/4 {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xxl-n3\/4-hover:hover {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xxl-n3\/4-focus:focus {
    --bs-translate-x: -75% !important;
  }
  .translate-x-xxl-nfull {
    --bs-translate-x: -100% !important;
  }
  .translate-x-xxl-nfull-hover:hover {
    --bs-translate-x: -100% !important;
  }
  .translate-x-xxl-nfull-focus:focus {
    --bs-translate-x: -100% !important;
  }
}
@media (min-width: 1200px) {
  .text-2xl {
    font-size: 1.6rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("./fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

.bi-alphabet-uppercase::before {
  content: "\f2a5";
}

.bi-alphabet::before {
  content: "\f68a";
}

.bi-amazon::before {
  content: "\f68d";
}

.bi-arrows-collapse-vertical::before {
  content: "\f690";
}

.bi-arrows-expand-vertical::before {
  content: "\f695";
}

.bi-arrows-vertical::before {
  content: "\f698";
}

.bi-arrows::before {
  content: "\f6a2";
}

.bi-ban-fill::before {
  content: "\f6a3";
}

.bi-ban::before {
  content: "\f6b6";
}

.bi-bing::before {
  content: "\f6c2";
}

.bi-cake::before {
  content: "\f6e0";
}

.bi-cake2::before {
  content: "\f6ed";
}

.bi-cookie::before {
  content: "\f6ee";
}

.bi-copy::before {
  content: "\f759";
}

.bi-crosshair::before {
  content: "\f769";
}

.bi-crosshair2::before {
  content: "\f794";
}

.bi-emoji-astonished-fill::before {
  content: "\f795";
}

.bi-emoji-astonished::before {
  content: "\f79a";
}

.bi-emoji-grimace-fill::before {
  content: "\f79b";
}

.bi-emoji-grimace::before {
  content: "\f7a0";
}

.bi-emoji-grin-fill::before {
  content: "\f7a1";
}

.bi-emoji-grin::before {
  content: "\f7a6";
}

.bi-emoji-surprise-fill::before {
  content: "\f7a7";
}

.bi-emoji-surprise::before {
  content: "\f7ac";
}

.bi-emoji-tear-fill::before {
  content: "\f7ad";
}

.bi-emoji-tear::before {
  content: "\f7b2";
}

.bi-envelope-arrow-down-fill::before {
  content: "\f7b3";
}

.bi-envelope-arrow-down::before {
  content: "\f7b8";
}

.bi-envelope-arrow-up-fill::before {
  content: "\f7b9";
}

.bi-envelope-arrow-up::before {
  content: "\f7be";
}

.bi-feather::before {
  content: "\f7bf";
}

.bi-feather2::before {
  content: "\f7c4";
}

.bi-floppy-fill::before {
  content: "\f7c5";
}

.bi-floppy::before {
  content: "\f7d8";
}

.bi-floppy2-fill::before {
  content: "\f7d9";
}

.bi-floppy2::before {
  content: "\f7e4";
}

.bi-gitlab::before {
  content: "\f7e5";
}

.bi-highlighter::before {
  content: "\f7f8";
}

.bi-marker-tip::before {
  content: "\f802";
}

.bi-nvme-fill::before {
  content: "\f803";
}

.bi-nvme::before {
  content: "\f80c";
}

.bi-opencollective::before {
  content: "\f80d";
}

.bi-pci-card-network::before {
  content: "\f8cd";
}

.bi-pci-card-sound::before {
  content: "\f8ce";
}

.bi-radar::before {
  content: "\f8cf";
}

.bi-send-arrow-down-fill::before {
  content: "\f8d0";
}

.bi-send-arrow-down::before {
  content: "\f8d1";
}

.bi-send-arrow-up-fill::before {
  content: "\f8d2";
}

.bi-send-arrow-up::before {
  content: "\f8d3";
}

.bi-sim-slash-fill::before {
  content: "\f8d4";
}

.bi-sim-slash::before {
  content: "\f8d5";
}

.bi-sourceforge::before {
  content: "\f8d6";
}

.bi-substack::before {
  content: "\f8d7";
}

.bi-threads-fill::before {
  content: "\f8d8";
}

.bi-threads::before {
  content: "\f8d9";
}

.bi-transparency::before {
  content: "\f8da";
}

.bi-twitter-x::before {
  content: "\f8db";
}

.bi-type-h4::before {
  content: "\f8dc";
}

.bi-type-h5::before {
  content: "\f8dd";
}

.bi-type-h6::before {
  content: "\f8de";
}

.bi-backpack-fill::before {
  content: "\f8df";
}

.bi-backpack::before {
  content: "\f8e0";
}

.bi-backpack2-fill::before {
  content: "\f8e1";
}

.bi-backpack2::before {
  content: "\f8e2";
}

.bi-backpack3-fill::before {
  content: "\f8e3";
}

.bi-backpack3::before {
  content: "\f8e4";
}

.bi-backpack4-fill::before {
  content: "\f8e5";
}

.bi-backpack4::before {
  content: "\f8e6";
}

.bi-brilliance::before {
  content: "\f8e7";
}

.bi-cake-fill::before {
  content: "\f8e8";
}

.bi-cake2-fill::before {
  content: "\f8e9";
}

.bi-duffle-fill::before {
  content: "\f8ea";
}

.bi-duffle::before {
  content: "\f8eb";
}

.bi-exposure::before {
  content: "\f8ec";
}

.bi-gender-neuter::before {
  content: "\f8ed";
}

.bi-highlights::before {
  content: "\f8ee";
}

.bi-luggage-fill::before {
  content: "\f8ef";
}

.bi-luggage::before {
  content: "\f8f0";
}

.bi-mailbox-flag::before {
  content: "\f8f1";
}

.bi-mailbox2-flag::before {
  content: "\f8f2";
}

.bi-noise-reduction::before {
  content: "\f8f3";
}

.bi-passport-fill::before {
  content: "\f8f4";
}

.bi-passport::before {
  content: "\f8f5";
}

.bi-person-arms-up::before {
  content: "\f8f6";
}

.bi-person-raised-hand::before {
  content: "\f8f7";
}

.bi-person-standing-dress::before {
  content: "\f8f8";
}

.bi-person-standing::before {
  content: "\f8f9";
}

.bi-person-walking::before {
  content: "\f8fa";
}

.bi-person-wheelchair::before {
  content: "\f8fb";
}

.bi-shadows::before {
  content: "\f8fc";
}

.bi-suitcase-fill::before {
  content: "\f8fd";
}

.bi-suitcase-lg-fill::before {
  content: "\f8fe";
}

.bi-suitcase-lg::before {
  content: "\f8ff";
}

.bi-suitcase::before {
  content: "豈";
}

.bi-suitcase2-fill::before {
  content: "更";
}

.bi-suitcase2::before {
  content: "車";
}

.bi-vignette::before {
  content: "賈";
}

@font-face {
  font-family: "Inter";
  src: font-url("Inter/Inter-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: font-url("Inter/Inter-Italic-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: italic;
}
body {
  font-family: "Inter", sans-serif;
}

.logo-image {
  max-width: 170px;
  width: 100%;
}

.full-height {
  height: 100vh;
}

.big-img {
  width: 80%;
}

.min-big-height {
  min-height: 100vh;
}

.big-height {
  min-height: 80vh;
  max-height: 100vh;
}
